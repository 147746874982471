import { createFileRoute, redirect } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

const SEARCH_PARAMS = object({
    token: string().when('id', {
        is: (id?: string) => id == null || id.length <= 0,
        then: schema => schema.required(),
    }),
    id: string(),
});

export const Route = createFileRoute('/registration/confirmation')({
    validateSearch(search): InferType<typeof SEARCH_PARAMS> {
        return SEARCH_PARAMS.cast(search);
    },
    onError(error) {
        if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as RegistrationConfirmationRoute };
