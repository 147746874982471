import { Link } from '@mui/material';
import React from 'react';
import ga4 from 'react-ga4';

import { type ActionLinkProps } from '@/components/elements/action/mod';
import { NavLink } from '@/components/elements/nav/nav-link';

export const ActionLink: React.FC<React.PropsWithChildren<ActionLinkProps>> = ({
    children,
    navigate,
    disabled,
    sx,
    gaEvent,
    onClick,
    ...linkProps
}) => {
    const linkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (gaEvent) ga4.event(gaEvent.action, { event_category: gaEvent.category });
        onClick?.(event);
    };

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!linkProps.href) event.preventDefault();
        linkClick?.(event);
    };

    if (navigate)
        return (
            <NavLink navigate={navigate} disabled={disabled} sx={sx} onClick={linkClick} {...linkProps}>
                {children}
            </NavLink>
        );

    return (
        <Link
            component={linkProps.href ? 'a' : 'button'}
            sx={{ ...sx, cursor: disabled ? 'normal' : 'pointer' }}
            onClick={disabled ? undefined : handleClick}
            {...linkProps}>
            {children}
        </Link>
    );
};
