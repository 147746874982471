import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

import callApi, { INTEGRATION_KEY_HEADER } from '@/api';
import { IntegrationError } from '@/api/IntegrationError';
import { Integration } from '@/entity/integration/Integration';

/**
 *********************************************************************
 * React Queries                                                     *
 *********************************************************************
 */

/**
 * Use integration {@link fetchIntegration}
 */
export const useIntegration = (params: FetchIntegrationParams): UseQueryResult<Integration> =>
    useQuery({
        queryKey: ['integration', params],
        queryFn: async () => fetchIntegration(params),
    });

/**
 *********************************************************************
 * Api Calls                                                         *
 *********************************************************************
 */

/**
 * Fetch integration
 */

interface FetchIntegrationParams {
    doFetch: boolean;
    fetchByName: boolean;
    integrationKey?: string;
}
export async function fetchIntegration({
    doFetch,
    fetchByName,
    integrationKey,
}: FetchIntegrationParams): Promise<Integration | null> {
    if (!doFetch) return null;

    if (integrationKey == null) throw new IntegrationError();

    try {
        const { data } = fetchByName
            ? await callApi('public/findIntegrationByName', {
                  method: 'GET',
                  params: {
                      name: integrationKey,
                  },
              })
            : await callApi('public/findIntegrationByURL', {
                  method: 'GET',
                  headers: {
                      [INTEGRATION_KEY_HEADER]: integrationKey,
                  },
              });

        if (!fetchByName) {
            // Set the axios default headers for all further API calls
            axios.defaults.headers.common[INTEGRATION_KEY_HEADER] = integrationKey;
        }

        return new Integration(data);
    } catch (error) {
        throw new IntegrationError(error as Error);
    }
}
