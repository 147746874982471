import { useTranslation } from 'react-i18next';

import { useAuth } from '@/core/auth/mod';
import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useJourney } from '@/core/journey/mod';
import { BusRouteStatus, type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export function useDetermineBusSelectability() {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { routeDetails } = useJourney();
    const { singleCompanyTranslationKey } = useIntegrationTranslation();

    const determine = (result: BookingSearchResult) => {
        const isOperatorButNotOwnerOfBus =
            user?.isOperatorOrDispatcher() && result.status === BusRouteStatus.FOREIGN_COMPANY;
        const isOperatorAndOneBusSelected =
            user?.isOperatorOrDispatcher() && routeDetails?.getSelectedSearchResults().length === 1;
        const isNoOrInvalidPriceOptions =
            result.status === BusRouteStatus.NO_PRICE_OPTIONS || result.status === BusRouteStatus.INVALID_PRICE_OPTION;
        const isUnavailable = result.status === BusRouteStatus.NOT_AVAILABLE;
        const isDisabled =
            (routeDetails?.sufficientPax ?? true) ||
            isNoOrInvalidPriceOptions ||
            isUnavailable ||
            isOperatorAndOneBusSelected ||
            isOperatorButNotOwnerOfBus ||
            user?.isAdmin();

        const reason = () => {
            if (!isDisabled) return undefined;
            // Show the payment explanation for no or invalid price options, so that the user know why they can't select the bus
            if (isNoOrInvalidPriceOptions)
                return t(
                    `bus_content.payment_explanation_status.${singleCompanyTranslationKey}.${result.paymentExplanation}`,
                );
            if (isUnavailable) return t('bus_selection.status.NOT_AVAILABLE');
            if (!user) return t('bookings.sufficient_pax');
            if (isOperatorButNotOwnerOfBus) return t('add_bus.operator_error');
            // We only allow one bus to be selected for operators for now. In the future, we may allow multiple buses to be selected. In that case, delete this check.
            if (isOperatorAndOneBusSelected) return t('add_bus.only_one_bus');
            if (user.isAdmin()) return t('add_bus.admin_error');
            return t('bookings.sufficient_pax');
        };

        return {
            isSelectable: !isDisabled,
            nonSelectableReason: reason(),
        };
    };

    return determine;
}
