import { Duration } from 'luxon';

import { type Basic } from '@/entity/basic/I_Basic';

export class DistanceAndDuration implements Basic<DistanceAndDuration> {
    public static fromJson(json: Record<string, any>): DistanceAndDuration {
        return new DistanceAndDuration(
            json.countryQuotients,
            json.distanceInKm,
            json.distanceInMeters,
            json.durationInSeconds,
            json.duration,
            json.legDistancesInMeters,
            json.legDurationsInSeconds,
        );
    }

    public countryQuotients: Record<string, number>;

    public distanceInKm: number;

    public distanceInMeters: number;

    public durationInSeconds: number;

    public duration: Duration;

    public legDistanceInMeters: number[];

    public legDurationInSeconds: number[];

    constructor(
        countryQuotients: Record<string, number>,
        distanceInKm: number,
        distanceInMeters: number,
        durationInSeconds: number,
        duration: string,
        legDistanceInMeters: number[],
        legDurationInSeconds: number[],
    ) {
        this.countryQuotients = countryQuotients;
        this.distanceInKm = distanceInKm;
        this.distanceInMeters = distanceInMeters;
        this.durationInSeconds = durationInSeconds;
        this.duration = Duration.fromISO(duration);
        this.legDistanceInMeters = legDistanceInMeters;
        this.legDurationInSeconds = legDurationInSeconds;
    }

    public equals(other: DistanceAndDuration): boolean {
        return (
            other != null &&
            this.distanceInMeters === other.distanceInMeters &&
            this.durationInSeconds === other.durationInSeconds &&
            this.duration === other.duration
        );
    }

    public toString(): string {
        return `distanceInKm = ${this.distanceInKm}, distanceInMeters = ${this.distanceInMeters}, durationInSeconds = ${this.durationInSeconds}, duration = ${this.duration}`;
    }
}
