import { Paper, useTheme, type PaperProps } from '@mui/material';
import { GoogleMap, type GoogleMapProps } from '@react-google-maps/api';
import React, { forwardRef } from 'react';

import { CENTER_REGION } from '../constants/region-centers';

import { type Region } from '@/config/localization';
import { useGoogleMaps } from '@/core/google/mod';

interface MapProps extends PaperProps, Pick<GoogleMapProps, 'center' | 'zoom' | 'onIdle'> {
    readonly centerOnRegion?: Region;
}

export const Map = forwardRef<GoogleMap, MapProps>(
    ({ center, centerOnRegion, zoom = 12, children, onIdle, ...paperProps }, ref) => {
        const { isLoaded, loadError } = useGoogleMaps();
        const theme = useTheme();

        if (!isLoaded || loadError) return null;

        return (
            <Paper
                {...paperProps}
                sx={{
                    width: '100%',
                    height: '400px',
                    // Override google maps default font
                    '.gm-style': {
                        font: 'inherit',
                        '.gm-style-iw': {
                            fontSize: 'inherit',
                        },
                    },
                    // Hide arrow on popup
                    '.gm-style-iw-tc::after': {
                        background: 'none',
                        boxShadow: 'none',
                    },
                    // Override default padding on popup to 0
                    '.gm-style-iw-c': {
                        padding: '0 !important',
                    },

                    // Make the popup scrollable
                    '.gm-style-iw-d': {
                        overflow: 'auto !important',
                    },

                    // Hide close button on the popup
                    '.gm-style-iw-chr': {
                        display: 'none',
                    },
                    ...paperProps.sx,
                }}>
                <GoogleMap
                    ref={ref}
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    options={{
                        styles: theme.googleMaps,
                        streetViewControl: false,
                        mapTypeControl: false,
                    }}
                    zoom={zoom}
                    center={centerOnRegion ? CENTER_REGION[centerOnRegion] : center}
                    onIdle={onIdle}>
                    {children}
                </GoogleMap>
            </Paper>
        );
    },
);
