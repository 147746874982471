import { type DateTime } from 'luxon';

import { getStorageJourneyToken } from '@/config/storage';
import { type IJourney } from '@/features/journey-planning';

export const dateToISO = (value: DateTime | null): string | null =>
    value?.set({ second: 0, millisecond: 0 }).toISO({
        includeOffset: false,
        suppressMilliseconds: true,
        suppressSeconds: true,
    }) ?? null;

/**
 * Transform Search Form Fields into right shape for the api.
 * Also include the current journey session token in the request body,
 * so the backend can receive the existing journey from the HttpSession
 */
export const serializeSearchFormFields = (data: IJourney): any => ({
    ...data,
    routes: data.routes.map(route => ({
        ...route,
        stops: route.stops.map((stop, i, stops) => ({
            uuid: stop.uuid,
            location: stop.location,
            localBus: stop.localBus,
            datetime: i === stops.length - 1 ? null : dateToISO(stop.departureDateTime),
            tripCompany: stop.tripCompany,
        })),
    })),
    token: getStorageJourneyToken(),
});
