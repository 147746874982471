import React, { useMemo } from 'react';

import { MapLayer } from '../components/map-layer';
import { useJourneyPlanningForm } from '../hooks/use-journey-planning-form';

import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';
import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { useLocalization } from '@/core/localization/mod';
import { Map, RouteRender } from '@/features/map/';
import { useFindTripCompanies, TripCompanyMapOverlay } from '@/features/trip-companies';

export const MapTile: React.FC = () => {
    // TODO: we use the journey from the parent provider, which is not necessarily the same as the one
    // the form is showing. We could use the getBookingJourney from the planningForm provider, but then
    // the map would show nothing if there is an error in the form.
    const { journey } = useJourneyPlanning();
    const { selectedRouteIndex } = useJourneyPlanningForm();
    const { data: tripCompanies } = useFindTripCompanies({ top: true });
    const { region } = useLocalization();

    const selectedRoute = useMemo(() => {
        if (!journey || journey.routes.length === 0) return undefined;
        return journey.routes[selectedRouteIndex];
    }, [journey, selectedRouteIndex]);

    return (
        <Tile disableScrollbar>
            <TilePart disablePadding sx={{ height: 'inherit' }}>
                <Map
                    centerOnRegion={region}
                    zoom={selectedRoute ? 7 : 5}
                    sx={{ height: 'inherit', borderRadius: 0 }}
                    data-cy="search-map">
                    <MapLayer />
                    <RouteRender route={selectedRoute} />
                    <TripCompanyMapOverlay tripCompanies={journey ? tripCompanies : undefined} />
                </Map>
            </TilePart>
        </Tile>
    );
};
