import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NavMenuItem } from '@/components/elements/nav/nav-menu-item';
import { type MobileNavigationProps } from '@/components/page/navigation/mobile/booking-progress-menu';

export const DefaultMenu = ({ items = [], backItem }: MobileNavigationProps) => {
    const { t } = useTranslation();

    return (
        <>
            {backItem && (
                <>
                    <NavMenuItem {...backItem}>{t('back_to_home')}</NavMenuItem>
                    <Divider />
                </>
            )}

            {items.map(item => (
                <NavMenuItem key={item.navigate.to} {...item} />
            ))}
        </>
    );
};
