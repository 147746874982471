import { DateTime } from 'luxon';

import { defaultJourneyFormValues } from '../utils/default-values';

import { Location } from '@/entity/basic/Location';
import { HomePageRoute } from '@/main/routes';

export function useDefaultJourneyFormValues() {
    const { fromAddress, fromLat, fromLng, pax, departureDateTime } = HomePageRoute.useSearch();

    const location =
        fromAddress != null && fromLat != null && fromLng != null
            ? new Location(fromAddress, fromLat, fromLng)
            : undefined;
    const dateTime = departureDateTime ? DateTime.fromISO(departureDateTime) : undefined;

    return defaultJourneyFormValues(pax ?? undefined, {
        location,
        departureDateTime: dateTime,
    });
}
