import { Link, type LinkProps } from '@mui/material';
import { useLinkProps, type LinkOptions } from '@tanstack/react-router';
import React from 'react';

import { NavName } from '@/components/elements/nav/nav-name';

interface NavLinkProps extends LinkProps {
    readonly navigate: LinkOptions;
    readonly disabled?: boolean;
    readonly activeClassName?: string;
}

export const NavLink: React.FC<NavLinkProps> = ({
    navigate,
    children,
    disabled,
    onClick,
    sx,
    activeClassName,
    ...linkProps
}) => {
    const _linkProps = useLinkProps({
        ...navigate,
        onClick,
        disabled,
        onFocus: linkProps.onFocus,
        onMouseEnter: linkProps.onMouseEnter,
        onMouseLeave: linkProps.onMouseLeave,
        onTouchStart: linkProps.onTouchStart,
        activeProps: {
            className: activeClassName,
        },
    });

    return (
        <Link
            component={disabled ? 'span' : 'a'}
            sx={{ ...sx, cursor: disabled ? 'normal' : 'pointer' }}
            onClick={disabled ? undefined : onClick}
            {...linkProps}
            {..._linkProps}>
            {children ?? <NavName navigate={navigate} />}
        </Link>
    );
};
