import { Alert, Card, Stack, styled } from '@mui/material';
import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentIcon } from '../elements/payment-icon';

import { BusCardConciseActions } from './bus-card-concise-actions';

import { BusCancellation } from '@/components/bus/bus-cancellation';
import { BusImage } from '@/components/bus/bus-image';
import { BusRoom } from '@/components/bus/bus-room';
import BusSeatsInfo from '@/components/bus/bus-seats-info';
import { BusPrice } from '@/components/bus/price/bus-price';
import { useAuth } from '@/core/auth/mod';
import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useJourney } from '@/core/journey/mod';
import { type BookingTask } from '@/entity/events/task/BookingTask';
import { BookingSearchResult, BusRouteStatus } from '@/entity/search-results/BookingSearchResult';

const BusCardConciseSingleCompanyGrid = styled(Card)(({ theme }) => ({
    display: 'grid',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    gap: theme.spacing(1),
    columnGap: theme.spacing(2),
    gridAutoFlow: 'row',
    [theme.breakpoints.down('small_mobile')]: {
        gridTemplateColumns: 'minmax(35%, 60%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
          "image image"
          "header price"
          "cancel cancel"
          "actions actions"
          "error error"
          `,
    },
    [theme.breakpoints.between('small_mobile', 'sm')]: {
        gridTemplateColumns: '30% minmax(30%, 50%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
            "image header price"
            "image room room"
            "image cancel cancel"
            "actions actions actions"
            "error error error"
            `,
    },
    [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: '1fr 1.4fr 1.1fr',
        gridTemplateRows: 'repeat(4, max-content)',
        gridTemplateAreas: `
            "image header price"
            "image cancel cancel"
            "image actions actions"
            "error error error"
            `,
    },
    [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: '23% minmax(30%, 50%) auto',
        gridTemplateRows: 'repeat(4, max-content)',
        gridTemplateAreas: `
          "image header price"
          "image cancel cancel"
          "actions actions actions"
          "error error error"
          `,
    },
    [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '30% minmax(30%, 40%) auto',
        gridTemplateRows: 'repeat(4, max-content)',
        gridTemplateAreas: `
          "image header price"
          "image cancel cancel"
          "image actions actions"
          "error error error"
          `,
    },
    '&>.image': { gridArea: 'image' },
    '&>.header': { gridArea: 'header' },
    '&>.price': { gridArea: 'price', justifySelf: 'end' },
    '&>.info': { gridArea: 'info' },
    '&>.cancel': { gridArea: 'cancel', justifySelf: 'end', alignSelf: 'end' },
    '&>.actions': { gridArea: 'actions', justifySelf: 'end', alignSelf: 'end' },
    '&>.room': { gridArea: 'room' },
    '&>.error': { gridArea: 'error', marginBottom: theme.spacing(1) },
}));
interface CardAreaProps {
    readonly area: 'image' | 'header' | 'price' | 'info' | 'cancel' | 'error' | 'actions' | 'room';
}

const CardArea: React.FC<PropsWithChildren<CardAreaProps>> = ({ area, children }) => {
    return <div className={area}>{children}</div>;
};

interface BusCardConciseProps {
    readonly resultOrTask: BookingSearchResult | BookingTask;
}

export const BusCardConciseSingleCompany: React.FC<BusCardConciseProps> = ({ resultOrTask }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { singleCompanyTranslationKey } = useIntegrationTranslation();
    const { routeDetails, journey } = useJourney();

    const { bus, price } = resultOrTask;

    const result = resultOrTask instanceof BookingSearchResult ? resultOrTask : undefined;
    const task = resultOrTask instanceof BookingSearchResult ? undefined : resultOrTask;

    return (
        <BusCardConciseSingleCompanyGrid>
            <CardArea area="image">
                <BusImage bus={bus} />
            </CardArea>
            <CardArea area="price">
                <BusPrice
                    pax={routeDetails?.pax ?? 1}
                    seats={bus.getTotalSeats()}
                    disableDiscounts={user?.isOperatorOrDispatcher()}
                    priceToShow={price}
                    priceDisplayRows={resultOrTask.priceDisplayRows}
                    icon={<PaymentIcon selectedPriceOption={result?.selectedPriceOption || task?.priceOption} />}
                />
            </CardArea>
            <CardArea area="header">
                <Stack gap={0.5}>
                    <BusSeatsInfo bus={bus} variant="bus-info" />
                    <BusSeatsInfo bus={bus} variant="seats" />
                    <BusRoom
                        variant="short"
                        companyProvides={routeDetails?.driverRoomsNeeded && journey?.companyProvidesDriverRooms}
                        userProvides={routeDetails?.driverRoomsNeeded && !journey?.companyProvidesDriverRooms}
                    />
                </Stack>
            </CardArea>
            <CardArea area="actions">
                <BusCardConciseActions
                    shouldUpdateAll={journey?.allResultsHaveSamePriceOptions()}
                    result={result}
                    pax={routeDetails?.pax ?? 1}
                />
            </CardArea>

            <CardArea area="cancel">
                <BusCancellation resultOrTask={result} />
            </CardArea>

            {resultOrTask instanceof BookingSearchResult && resultOrTask.hasInvalidStatus() && (
                <CardArea area="error">
                    <Alert severity="error">
                        {/* Also show the payment explanation for no or invalid price options, so that the user know why they can't select the bus */}
                        {resultOrTask.status === BusRouteStatus.INVALID_PRICE_OPTION ||
                        resultOrTask.status === BusRouteStatus.NO_PRICE_OPTIONS ? (
                            <>
                                {t(`bus_selection.status.${resultOrTask.status}`)}{' '}
                                {t(
                                    `bus_content.payment_explanation_status.${singleCompanyTranslationKey}.${resultOrTask.paymentExplanation}`,
                                )}
                            </>
                        ) : (
                            t(`bus_selection.status.${resultOrTask.status}`)
                        )}
                    </Alert>
                </CardArea>
            )}
        </BusCardConciseSingleCompanyGrid>
    );
};
