import CreditScore from '@mui/icons-material/CreditScore';
import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface PaymentIconsProps {
    readonly result: BookingSearchResult;
}

export const PaymentIcons: React.FC<PaymentIconsProps> = ({ result }) => {
    const { t } = useTranslation();
    return (
        <Stack>
            {result.isOnlinePaymentPossible() && (
                <Tooltip
                    arrow
                    placement="top"
                    title={
                        <Typography component="legend">{t('bus.payment_option.online_payment_available')}</Typography>
                    }>
                    <CreditScore fontSize="small" />
                </Tooltip>
            )}
        </Stack>
    );
};
