import { Entity } from '@/entity/A_Entity';
import { DistanceAndDuration } from '@/entity/basic/DistanceAndDuration';
import { BookingTask } from '@/entity/events/task/BookingTask';
import { fromJsonArray } from '@/entity/index';
import { PriceSummary } from '@/entity/journey/PriceSummary';
import { BookingStop } from '@/entity/journey/stop/BookingStop';

export class DetailsBookingRoute extends Entity {
    public uuid: string;

    public pax: number;

    public calculatedTravelWithBreaks: DistanceAndDuration;

    public group: number;

    public cancelled: boolean;

    public stops: BookingStop[];

    public priceSummary: PriceSummary;

    public tasks: BookingTask[];

    public sufficientPax = true;

    public driverRoomsNeeded: boolean;

    constructor(json: Record<string, any>) {
        super(json);
        this.uuid = json.uuid;
        this.pax = json.pax;
        this.calculatedTravelWithBreaks = DistanceAndDuration.fromJson(json.calculatedTravelWithBreaks);
        this.group = json.group;
        this.cancelled = json.cancelled;
        this.stops = fromJsonArray(BookingStop, json.stops);
        this.priceSummary = new PriceSummary(json.priceSummary);
        this.tasks = fromJsonArray(BookingTask, json.tasks);
        this.driverRoomsNeeded = json.driverRoomsNeeded;
    }

    public getNumberOfBuses() {
        return this.tasks.length;
    }
}
