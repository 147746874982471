import { Menu, type MenuProps } from '@mui/material';
import { type FC } from 'react';

import { PaymentSelectorItem } from './payment-selector-item';

import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface PaymentSelectorMenuProps extends Pick<MenuProps, 'anchorEl' | 'onClose'> {
    readonly open: boolean;
    readonly result: BookingSearchResult;
    readonly pax: number;
    readonly onSelect: (priceOption: PriceOption) => void;
}

export const PaymentSelectorMenu: FC<PaymentSelectorMenuProps> = ({
    open,
    result,
    pax,
    onSelect,
    onClose,
    anchorEl,
}) => {
    const priceOptions = result.priceOptions;

    return (
        <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
            {priceOptions.map(priceOption => {
                const priceDisplayRows = result.priceOptionDisplayRows.find(
                    row => row.id === priceOption.id,
                )?.priceDisplayRows;

                return (
                    <PaymentSelectorItem
                        key={priceOption.id}
                        priceOption={priceOption}
                        priceDisplayRows={priceDisplayRows}
                        pax={pax}
                        seats={result.bus.getTotalSeats()}
                        selected={result.selectedPriceOption?.id === priceOption.id}
                        onSelect={onSelect}
                    />
                );
            })}
        </Menu>
    );
};
