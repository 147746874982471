export class LocalBus {
    public static fromJson(json: Record<string, any>): LocalBus {
        return new LocalBus(json.description, json.distance);
    }

    public description: string;

    public distance: number;

    constructor(description: string, distance: number) {
        this.description = description;
        this.distance = distance;
    }
}
