import { type PropsWithChildren, type FC } from 'react';

import { type IPosition } from '@/features/journey-planning';

interface StopChipProps {
    readonly position: IPosition;
    readonly visible: ('intermediate' | 'first' | 'last')[];
}

export const StopChip: FC<PropsWithChildren<StopChipProps>> = ({ position, visible, children }) => {
    const isVisible = visible.some(visibility => {
        if (visibility === 'intermediate') {
            return !position.first && !position.last;
        }
        return position[visibility];
    });

    return isVisible ? children : null;
};
