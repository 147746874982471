import React, { type MutableRefObject } from 'react';

export interface IScrollbarContext {
    contentRef: MutableRefObject<HTMLElement | null>;
    scrollToTop(behavior?: 'smooth' | 'instant' | 'auto'): void;
}

export const ScrollbarContext = React.createContext<IScrollbarContext>({
    contentRef: { current: null },
    scrollToTop() {},
});

export function useScrollbar(): IScrollbarContext {
    const context = React.useContext(ScrollbarContext);

    return context;
}
