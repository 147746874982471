import { RouterProvider } from '@tanstack/react-router';
import React from 'react';

import 'src/config/schema/mod';
import { AppCtxProvider } from '@/core/app-ctx/AppCtxProvider';
import { useAppCtx } from '@/core/app-ctx/mod';
import { QueryProvider } from '@/core/query/QueryProvider';
import { useEmbedCtx } from '@/embed/mod';
import { createAppRouter } from '@/main/AppRouter';

export type AppRouterHistory = 'browser' | 'hash';

export interface AppProps {
    readonly routerHistory?: AppRouterHistory;
    readonly routerBasepath?: string;
}

const App: React.FC<AppProps> = props => {
    return (
        <QueryProvider>
            <AppCtxProvider>
                <InnerApp {...props} />
            </AppCtxProvider>
        </QueryProvider>
    );
};

const InnerApp: React.FC<AppProps> = props => {
    const appCtx = useAppCtx();
    const embedCtx = useEmbedCtx();

    const router = createAppRouter(props);

    return <RouterProvider router={router} context={{ appCtx, embedCtx }} />;
};

export default App;
