import { Dialog } from '@mui/material';
import React, { useState } from 'react';

import { CookieBanner } from './cookie-banner';
import { CookieCustomization } from './cookie-customization';

interface CookieBannerModalProps {
    readonly open: boolean;
    readonly onClose: () => void;
}

export const CookieBannerModal: React.FC<CookieBannerModalProps> = ({ open, onClose }) => {
    const [showPreferences, setShowPreferences] = useState(false);

    const togglePreferences = () => setShowPreferences(!showPreferences);

    return (
        <Dialog
            open={open}
            sx={{
                zIndex(theme) {
                    return theme.vars.zIndex.tooltip;
                },
            }}
            PaperProps={{
                sx: {
                    p: 2,
                    gap: 2,
                    maxWidth(theme) {
                        return theme.breakpoints.values.sm + 50;
                    },
                },
            }}>
            {showPreferences ? (
                <CookieCustomization onChangeView={togglePreferences} onClose={onClose} />
            ) : (
                <CookieBanner onChangeView={togglePreferences} onClose={onClose} />
            )}
        </Dialog>
    );
};
