export const ControlPositionMap = {
    TOP_LEFT: 1,
    TOP_CENTER: 2,
    TOP: 2,
    TOP_RIGHT: 3,
    LEFT_CENTER: 4,
    LEFT_TOP: 5,
    LEFT: 5,
    LEFT_BOTTOM: 6,
    RIGHT_TOP: 7,
    RIGHT: 7,
    RIGHT_CENTER: 8,
    RIGHT_BOTTOM: 9,
    BOTTOM_LEFT: 10,
    BOTTOM_CENTER: 11,
    BOTTOM: 11,
    BOTTOM_RIGHT: 12,
    CENTER: 13,
} as const;

export type ControlPosition = (typeof ControlPositionMap)[keyof typeof ControlPositionMap];
