import { Entity } from '@/entity/A_Entity';

export class Review extends Entity {
    public comment: string;

    public visible: boolean;

    public totalScore: number;

    constructor(json: Record<string, any>) {
        super(json);
        this.comment = json.comment;
        this.visible = json.visible;
        this.totalScore = json.totalScore;
    }
}
