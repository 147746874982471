import { LoadingButton } from '@mui/lab';
import React, { forwardRef, type MouseEvent } from 'react';
import ga4 from 'react-ga4';

import { type ActionLoadingButtonProps } from '@/components/elements/action/mod';
import { useAppCtx } from '@/core/app-ctx/mod';

export const ActionLoadingButton: React.FC<React.PropsWithChildren<ActionLoadingButtonProps>> = forwardRef(
    ({ category = 'primary', intent = 'normal', children, gaEvent, onClick, ...buttonProps }, ref) => {
        const {
            themeOverrides: {
                propertyMapping: { actionButton },
            },
        } = useAppCtx();

        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (gaEvent) ga4.event(gaEvent.action, { event_category: gaEvent.category });
            onClick?.(event);
        };

        return (
            <LoadingButton
                ref={ref}
                variant={actionButton.category[category]}
                color={actionButton.intent[intent]}
                onClick={handleClick}
                {...buttonProps}>
                {children}
            </LoadingButton>
        );
    },
);
