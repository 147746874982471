export class ReviewScores {
    public count: number;

    public totalScore: number;

    public categoryScores: Record<string, number>;

    constructor(json: Record<string, any>) {
        this.count = json.count;
        this.totalScore = json.totalScore;
        this.categoryScores = json.categoryScores;
    }
}
