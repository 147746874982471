import { createContext, useContext } from 'react';

import { type BookingTask } from '@/entity/events/task/BookingTask';
import { type BookingOfferJourney } from '@/entity/journey/BookingOfferJourney';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface IJourneyOfferContext {
    journey: BookingOfferJourney | null;
    isFetching: boolean;
    bookingToken?: string;
    selectedItem?: BookingSearchResult | BookingTask;
    selectItem: (result: BookingSearchResult | BookingTask) => void;
}

export const JourneyOfferProviderContext = createContext<IJourneyOfferContext>({
    isFetching: false,
    selectItem() {},
    journey: null,
});

export function useJourneyOffer() {
    const context = useContext(JourneyOfferProviderContext);

    return context;
}
