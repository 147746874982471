import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import { boolean, object, string, type InferType } from 'yup';

import { createLayout } from '@/components/page/layout/createLayout';
import { BookingNavigation } from '@/components/page/navigation/booking-navigation';
import { LoginLeafletTile, LoginTile } from '@/features/auth';

const LoginPage: React.FC = () => {
    const { Layout } = createLayout({
        tiles: {
            account_tile: <LoginLeafletTile />,
            login_tile: <LoginTile />,
        },
        base: [
            { name: 'account_tile', md: 6 },
            { name: 'login_tile', md: 6, mobileBase: true },
        ],
    });

    return <Layout useNavigation={BookingNavigation} />;
};

const LOGIN_SEARCH_PARAMS = object({
    // did logout
    dl: boolean().transform(value => {
        return value !== undefined;
    }),
    // Redirect href
    rhref: string(),
});

export const Route = createFileRoute('/login/')({
    validateSearch(search): InferType<typeof LOGIN_SEARCH_PARAMS> {
        return LOGIN_SEARCH_PARAMS.cast(search);
    },
    component: LoginPage,
});

export { Route as LoginRouteIndex };
