import { DragDropContext, Draggable, type DropResult, Droppable } from '@rechnerherz/dnd';

import { StopRow } from './stop-row';

import { DroppableIds } from '@/core/dnd/mod';
import { useEmbedCtx } from '@/embed/mod';
import { getPosition, useJourneyPlanningForm } from '@/features/journey-planning';

interface StopsArrayProps {
    readonly routeIndex: number;
}

export const StopsArray: React.FC<StopsArrayProps> = ({ routeIndex }) => {
    const { portalContainer } = useEmbedCtx();
    const { stopsArray, formMethods } = useJourneyPlanningForm();

    const {
        setValue,
        trigger,
        formState: { isValid, isValidating },
    } = formMethods;
    const { fields, move } = stopsArray;

    const handleDragEnd = ({ source, destination }: DropResult) => {
        if (!destination) return;

        const sourceField = fields[source.index];

        // The last stop's departure time is null, so we set it to it's arrival time first and then move it, so the form doesn't throw a validation error
        if (destination.index === 0 && sourceField.departureDateTime == null) {
            setValue(`routes.${routeIndex}.stops.${source.index}.departureDateTime`, sourceField.arrivalDateTime);
        }

        move(source.index, destination.index);
        void trigger(`routes.${routeIndex}.stops.${destination.index}`);
        void trigger(`routes.${routeIndex}.stops.${source.index}`);
        // Clear local bus values
        setValue(`routes.${routeIndex}.stops.${source.index}.localBus`, null);
        setValue(`routes.${routeIndex}.stops.${destination.index}.localBus`, null);
    };

    return (
        <DragDropContext stylesInsertionPoint={portalContainer} onDragEnd={handleDragEnd}>
            <Droppable key={routeIndex} droppableId={DroppableIds.ROUTE_FORM}>
                {(provided, { isDraggingOver }) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {fields.map((stop, stopIndex) => {
                            const position = getPosition(stopIndex, fields.length, routeIndex);
                            const isDragDisabled = !isValid || isValidating;

                            return (
                                <Draggable
                                    key={stop.id}
                                    draggableId={stop.id}
                                    index={position.index}
                                    isDragDisabled={isDragDisabled}>
                                    {draggableProvided => (
                                        <div
                                            key={stop.id}
                                            ref={draggableProvided.innerRef}
                                            {...draggableProvided.draggableProps}>
                                            <StopRow
                                                isAnyFieldBeingDragged={isDraggingOver}
                                                draggableProvided={draggableProvided}
                                                isDragDisabled={isDragDisabled}
                                                position={position}
                                                stop={stop}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
