import { DateTime, type Duration } from 'luxon';
import { type FieldArrayWithId } from 'react-hook-form';

import { Entity } from '@/entity/A_Entity';
import { Location } from '@/entity/basic/Location';
import { LegDetails } from '@/entity/journey/stop/LegDetails';
import { LocalBus } from '@/entity/journey/stop/LocalBus';
import { BookingTripCompany } from '@/entity/trip/company/BookingTripCompany';
import { type IJourney } from '@/features/journey-planning';

export class DefaultStop extends Entity {
    public static canShiftBreakTime = (
        field: FieldArrayWithId<IJourney, `routes.${number}.stops`>,
        shiftDuration: Duration,
    ) =>
        field.departureDateTime != null &&
        field.arrivalDateTime != null &&
        field.departureDateTime.diff(field.arrivalDateTime).normalize().as('minutes') >=
            Math.abs(shiftDuration.as('minutes'));

    public uuid: string;

    public arrivalDateTime: DateTime;

    public departureDateTime: DateTime;

    public location: Location;

    public localBus?: LocalBus;

    public legDetails?: LegDetails;

    public tripCompany?: BookingTripCompany;

    constructor(json: Record<string, any>) {
        super(json);
        this.arrivalDateTime = DateTime.fromISO(json.arrivalDateTime);
        this.departureDateTime = DateTime.fromISO(json.departureDateTime);
        this.location = Location.fromJson(json.location);
        this.localBus = json.localBus ? LocalBus.fromJson(json.localBus) : undefined;
        this.legDetails = json.legDetails ? new LegDetails(json.legDetails) : undefined;
        this.uuid = json.uuid;
        this.tripCompany = json.tripCompany ? new BookingTripCompany(json.tripCompany) : undefined;
    }
}
