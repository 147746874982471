import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { createLayout } from '@/components/page/layout/createLayout';
import { BookingNavigation } from '@/components/page/navigation/booking-navigation';
import { RegistrationLeafletTile, RegistrationTile } from '@/features/auth';

const { Layout } = createLayout({
    tiles: {
        account_tile: <RegistrationLeafletTile />,
        registration_tile: <RegistrationTile />,
    },
    base: [
        { name: 'account_tile', md: 4 },
        { name: 'registration_tile', md: 8, mobileBase: true },
    ],
});

export const Registration: React.FC = () => {
    return <Layout useNavigation={BookingNavigation} />;
};

export const Route = createFileRoute('/registration/')({
    component: Registration,
});
