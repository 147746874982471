import { useBooking } from '@/core/booking/mod';
import { useJourney } from '@/core/journey/mod';
import { useJourneyOffer } from '@/core/journey-offer/mod';

export const useJourneyOrBooking = () => {
    const { journey } = useJourney();
    const { journey: journeyOffer } = useJourneyOffer();
    const { booking } = useBooking();

    return booking || journeyOffer || journey;
};
