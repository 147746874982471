import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteDateTimeField } from '@/components/form/fields/time/route-date-time-field';
import { useTour } from '@/core/tour/mod';
import { type SearchFormBasicTour } from '@/core/tour/SearchFormBasicTour';
import { type SearchFormFirstIntermediateTour } from '@/core/tour/SearchFormFirstIntermediateTour';
import { type IJourney, type IPosition, type IStop, useJourneyPlanningForm } from '@/features/journey-planning';
import { SEARCH_FORM } from '@/utils/constants';

interface StopDepartureProps {
    readonly position: IPosition;
    readonly stop?: IStop;
    readonly isAnyFieldBeingDragged: boolean;
}

export const StopDeparture: React.FC<StopDepartureProps> = ({ position, stop, isAnyFieldBeingDragged }) => {
    const { register } = useTour<SearchFormBasicTour | SearchFormFirstIntermediateTour>();
    const { t } = useTranslation();
    const {
        formMethods: { trigger },
        stopsArray: { fields, replace },
        getBookingJourney,
    } = useJourneyPlanningForm();

    const [prevValue, setPrevValue] = useState<DateTime | null>(stop?.departureDateTime ?? null);

    const minDepartureDateTime = position.first ? SEARCH_FORM.minDepartureDateTime : stop?.arrivalDateTime;
    const journey = getBookingJourney();

    const handleAccept = (value: any) => {
        if (value == null || !(value instanceof DateTime) || !value.isValid) return;

        // If the departure time of the first stop is changed, the whole journey should be shifted by the same amount
        if (prevValue != null && prevValue !== value && position.index === 0 && journey != null) {
            const shiftDuration = value.diff(prevValue);

            replace(
                fields.map(field => {
                    return {
                        ...field,
                        departureDateTime: field.departureDateTime?.plus(shiftDuration) ?? null,
                        arrivalDateTime: field.arrivalDateTime?.plus(shiftDuration) ?? null,
                    };
                }),
            );
        }

        void trigger(`routes.${position.routeIndex}.stops.${position.index}.departureDateTime`);

        setPrevValue(value);
    };

    if (position.last) return null;
    if (stop?.departureDateTime == null && !position.first) return null;

    return (
        <RouteDateTimeField<IJourney>
            visible={!isAnyFieldBeingDragged}
            fieldRef={position.first || position.index === 1 ? register('from_time') : undefined}
            label={t('search_form.from.datetime.label')}
            name={`routes.${position.routeIndex}.stops.${position.index}.departureDateTime`}
            minDateTime={minDepartureDateTime}
            onAccept={handleAccept}
        />
    );
};
