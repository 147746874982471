import { OfferDetails } from '@/entity/basic/offer-details/OfferDetails';

export class JourneyOfferDetails extends OfferDetails {
    public identifier: string;

    constructor(json: Record<string, any>) {
        super(json);
        this.identifier = json.identifier;
    }
}
