import { createHashHistory, createRouter } from '@tanstack/react-router';
import { createElement } from 'react';

import { BackdropLoader } from '@/components/elements/loader/backdrop-loader';
import { QUERY_CLIENT } from '@/core/query/mod';
import { type AppProps } from '@/main/App';
import { routeTree } from '@/main/routeTree.gen';

// Set up a Router instance
const BaseRouter = createRouter({
    routeTree,
    context: {
        queryClient: QUERY_CLIENT,
    },
    defaultPreload: false,
    defaultPendingComponent: () => createElement(BackdropLoader, { open: true }),
});

export function createAppRouter(appProps: AppProps): typeof BaseRouter {
    switch (appProps.routerHistory) {
        case 'hash': {
            BaseRouter.update({
                history: createHashHistory(),
                context: {
                    queryClient: QUERY_CLIENT,
                },
            });

            return BaseRouter;
        }
        default: {
            BaseRouter.update({
                basepath: appProps.routerBasepath,
                context: {
                    queryClient: QUERY_CLIENT,
                },
            });

            return BaseRouter;
        }
    }
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof BaseRouter;
    }
}
