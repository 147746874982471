import { type Basic } from '@/entity/basic/I_Basic';

export class ContactDataAddress implements Basic<ContactDataAddress> {
    public static fromJson(json: Record<string, any>): ContactDataAddress {
        return new ContactDataAddress(json.fullAddress, json.postCode, json.street);
    }

    public readonly fullAddress: string;

    public readonly postCode: string;

    public readonly street: string;

    constructor(fullAddress: string, postCode: string, street: string) {
        this.fullAddress = fullAddress;
        this.postCode = postCode;
        this.street = street;
    }

    public equals(other: ContactDataAddress): boolean {
        return (
            other != null &&
            this.fullAddress === other.fullAddress &&
            this.postCode === other.postCode &&
            this.street === other.street
        );
    }
}
