import callApi from '@/api';
import { type AppUser } from '@/entity/account/Account';
import { createAccountFromJson } from '@/entity/account/factory';

/**
 * Api call for receiving the logged-in user account
 */
export async function getUser(): Promise<AppUser | null> {
    const { data } = await callApi(`public/account`);
    if (!data) return null;
    return createAccountFromJson(data);
}

/*
 * Api call to request a password reset link
 */
export async function postRequestPasswordReset(email: string) {
    await callApi('public/send-password-reset-link', {
        method: 'POST',
        data: { email },
    });
}

export async function patchLanguage(customerId: number) {
    await callApi(`api/customers/${customerId}/update-language`, {
        method: 'PATCH',
    });
}

/*
 * Api call to validate duplication of customer's email
 */
export async function duplicateCustomerEmailValidation(email: string) {
    await callApi(`public/checkExistingCustomerAccount?email=${encodeURIComponent(email)}`);
}

/*
 * Api call to validate duplication of email (for companies)
 */
export async function duplicateAccountEmailValidation(email: string) {
    await callApi(`public/checkExistingAccount?email=${encodeURIComponent(email)}`);
}

/*
 * Api call to validate duplication of company name
 */
export async function duplicateCompanyNameValidation(companyName: string) {
    await callApi(`public/checkExistingCompany?companyName=${encodeURIComponent(companyName)}`);
}
