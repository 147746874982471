import { Tooltip, Typography, useTheme } from '@mui/material';
import { type FC } from 'react';

import { type TripAttribute } from '@/entity/trip/attribute/TripAttribute';

interface AttributeIconsProps {
    readonly attribute: TripAttribute;
}

export const AttributeIcon: FC<AttributeIconsProps> = ({ attribute }) => {
    const theme = useTheme();

    if (!attribute.visible) return null;

    return (
        <Tooltip arrow placement="top" title={<Typography>{attribute.name}</Typography>}>
            <i className={`fa ${attribute.icon}`} style={{ color: theme.palette.secondary.main }} />
        </Tooltip>
    );
};
