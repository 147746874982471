import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SuccessText } from '../components/success-text';

import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { useSnack } from '@/core/snack/mod';
import { type TripCompany } from '@/entity/trip/company/TripCompany';
import { useCalculateJourney } from '@/features/journey-planning';

export const useAddCompanyToJourney = () => {
    const calculateJourney = useCalculateJourney();
    const { journey } = useJourneyPlanning();
    const { t } = useTranslation();
    const snack = useSnack();

    const addCompanyToJourney = async (company: TripCompany) => {
        if (!journey || !calculateJourney.isIdle) return;

        snack.push(
            t('trip_company.adding_company'),
            'info',
            <CircularProgress sx={{ color: theme => theme.vars.palette.Alert.infoIconColor }} size={18} />,
        );

        const formValues = journey.getFormValues();

        // TODO: BUF-1412 remove hardcoded route index and use the selected route index
        // Add the trip company as the last stop
        formValues.routes[0].stops.push({
            departureDateTime: null,
            arrivalDateTime: null,
            localBus: null,
            location: company.location,
            tripCompany: company.getFormValues(),
        });

        try {
            await calculateJourney.mutateAsync(formValues);

            snack.push(<SuccessText companyName={company.companyName} />, 'success');
        } catch {
            snack.push(t('validations:general.error'), 'error');
        }
    };

    return addCompanyToJourney;
};
