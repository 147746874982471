import { useQuery } from '@tanstack/react-query';

import { getBookingJourney, type GetBookingJourneyParams } from '@/api/journey/booking/endpoints';
import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { useUpdateAppProgress } from '@/core/app-progress/mod';

/**
 * Generic BookingJourney Query
 */
export function useBookingJourney(params: GetBookingJourneyParams, enabled = true) {
    const query = useQuery({
        queryKey: [JOURNEY_BOOKING_QUERY_KEY, params.step, params],
        queryFn: async () => getBookingJourney(params),
        initialData: null,
        placeholderData: null,
        staleTime: 0,
        throwOnError: false,
        refetchOnWindowFocus: false,
        enabled,
        gcTime: 0,
    });

    useUpdateAppProgress(query);

    return query;
}
