export class AccountIdName {
    public readonly id: number;

    public email: string;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.email = json.email;
    }

    public toString(): string {
        return `${this.email}`;
    }
}
