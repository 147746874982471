import { fromJsonArray } from '@/entity';
import { PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';
import { type PriceOptionId } from '@/utils/constants';

export class PriceOptionDisplayRow {
    public id: PriceOptionId;

    public priceDisplayRows: PriceDisplayRow[];

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.priceDisplayRows = fromJsonArray(PriceDisplayRow, json.priceDisplayRows);
    }
}
