import Close from '@mui/icons-material/Close';
import { Box, IconButton, Paper, Stack, type SxProps } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

import { useLayout } from '@/components/page/layout/mod';
import { Attachable } from '@/components/page/layout/tile/attachable';
import { iff } from '@/utils/helpers';

export interface IAttachable {
    readonly type: 'sticky' | 'fixed';
    readonly to: 'top' | 'bottom';
}

interface TilePartProps {
    readonly attachable?: IAttachable;
    readonly closeTileName?: string;
    readonly sx?: SxProps;
    readonly disablePadding?: boolean;
}

export const TilePart: FC<PropsWithChildren<TilePartProps>> = ({
    attachable,
    closeTileName,
    children,
    sx,
    disablePadding,
}) => {
    const content = (
        <TilePartContent className="TilePart-content" closeTileName={closeTileName} disablePadding={disablePadding}>
            {children}
        </TilePartContent>
    );

    return attachable ? (
        <Attachable attachable={attachable}>
            {({ isAttached }) => (
                <TilePartRoot isAttached={isAttached ? attachable.to : false} sx={sx}>
                    {content}
                </TilePartRoot>
            )}
        </Attachable>
    ) : (
        <TilePartRoot sx={sx}>{content}</TilePartRoot>
    );
};

interface TilePartRootProps {
    readonly isAttached?: IAttachable['to'] | false;
    readonly sx?: SxProps;
}

const TilePartRoot: FC<PropsWithChildren<TilePartRootProps>> = ({ isAttached, sx, children }) => {
    const { isDesktop } = useLayout();

    return (
        <Box
            className="TilePart-root"
            sx={{
                paddingTop: iff(isAttached === 'top', 0),
                paddingBottom: iff(isAttached === 'bottom', 0),
                paddingLeft: iff(isAttached, 0),
                paddingRight: iff(isAttached, 0),
                transition: theme =>
                    theme.transitions.create('padding', { duration: theme.transitions.duration.shortest }),
                // Min height is for the case when both left and right tiles are attached (account bookings), it looks better, if they have the same height
                minHeight: iff(isAttached && isDesktop, 75),
                ...sx,
            }}>
            <Paper
                square
                elevation={isAttached ? 5 : 0}
                sx={{
                    height: '100%',
                    backgroundColor: iff(!isAttached, 'transparent'),
                    transition: theme =>
                        theme.transitions.create(['background-color', 'box-shadow'], {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}>
                {children}
            </Paper>
        </Box>
    );
};

interface TilePartContentProps {
    readonly closeTileName?: string;
    readonly className?: string;
    readonly disablePadding?: boolean;
}

const TilePartContent: FC<PropsWithChildren<TilePartContentProps>> = ({
    children,
    closeTileName,
    className,
    disablePadding,
}) => {
    const layout = useLayout();

    const sx = {
        height: '100%',
        width: '100%',
        p: disablePadding
            ? 0
            : {
                  xs: 1,
                  sm: 2,
                  md: 3,
              },
    };

    if (closeTileName == null || !layout.isDrawer(closeTileName))
        return (
            <Box sx={sx} className={className}>
                {children}
            </Box>
        );

    const handleClose = () => {
        if (closeTileName) layout.hide(closeTileName);
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={sx} className={className}>
            <IconButton
                size="small"
                sx={{
                    ml: {
                        xs: -1 + 0.5,
                        sm: -2 + 0.5,
                        md: -3 + 0.5,
                    },
                }}
                onClick={handleClose}>
                <Close />
            </IconButton>
            <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                {children}
            </Box>
        </Stack>
    );
};
