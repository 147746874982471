import { useTheme } from '@mui/material';
import { useGoogleMap } from '@react-google-maps/api';
import React, { useEffect, useMemo, useRef } from 'react';

import MapMarker from './map-marker';

import { useGoogleDirectionsService } from '@/core/google/mod';
import { type BookingRoute } from '@/entity/journey/route/BookingRoute';
import { type DefaultRoute } from '@/entity/journey/saved/DefaultRoute';

interface RouteRenderProps {
    readonly route?: BookingRoute | DefaultRoute;
}

export const RouteRender: React.FC<RouteRenderProps> = ({ route }) => {
    const theme = useTheme();

    const directionRequest = useMemo(() => {
        if (route == null) return null;

        const { stops } = route;

        const [origin, ...rest] = [...stops];
        const [destination, ...waypoints] = rest.reverse();

        return {
            travelMode: google.maps.TravelMode.DRIVING,
            origin: origin.location.toGMapsLatLng(),
            waypoints: waypoints.reverse().map(stop => ({ location: stop.location.toGMapsLatLng() })),
            destination: destination.location.toGMapsLatLng(),
        };
    }, [route]);

    const { data: directions } = useGoogleDirectionsService(directionRequest);

    const map = useGoogleMap();

    const renderer = useRef(
        new google.maps.DirectionsRenderer({
            // We cannot use css vars here
            polylineOptions: { strokeColor: theme.palette.primary.main },
            suppressMarkers: true,
        }),
    );

    useEffect(() => {
        const cleanup = () => renderer.current.setMap(null);

        if (route && directions) {
            renderer.current.setMap(map);
            renderer.current.setDirections(directions);
        } else cleanup();

        return cleanup;
    }, [directions, map, route]);

    return route?.stops.map((stop, i) => {
        const position = { index: i, routeIndex: 0, first: i === 0, last: i === route.stops.length - 1 };

        return <MapMarker key={i} stop={stop} position={position} />;
    });
};
