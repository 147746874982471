import { useSearch } from '@tanstack/react-router';

import { getStorageJourneyToken } from '@/config/storage';
import { useJourney } from '@/core/journey/mod';
import { useJourneyOffer } from '@/core/journey-offer/mod';
import { useJourneyPlanning } from '@/core/journey-planning/mod';

// This should be used in pages/components that are sharable for the users.
export const useJourneyToken = () => {
    const { journey } = useJourney();
    const { journey: journeyPlanning } = useJourneyPlanning();
    const { journey: journeyOffer } = useJourneyOffer();
    const params: { token?: string } = useSearch({ strict: false });

    const tokenJourney = journeyPlanning?.token ?? journey?.token ?? journeyOffer?.token;
    const tokenSession = getStorageJourneyToken();
    const tokenUrl = params?.token;

    return tokenUrl ?? tokenJourney ?? tokenSession ?? undefined;
};
