import { type AxiosError, type Method } from 'axios';

type APIErrorBookingKey =
    | 'account_already_registered'
    | 'bus_unavailable'
    | 'other_account_exists'
    | 'wrong_account'
    | 'wrong_role';

type APIErrorJourneyKey = 'incomplete' | 'invalid' | 'max_operating_time_exceeded' | 'not_found';

type APIErrorMapsKey = 'no_results' | 'unavailable';

type APIErrorKey = `backend:exception.${
    | `booking.${APIErrorBookingKey}`
    | `journey.${APIErrorJourneyKey}`
    | `maps.${APIErrorMapsKey}`}`;

export type APIErrorResponse = {
    detail?: string;
    key?: string;
} & Record<string, any>;

/**
 * API Error holding most important error information on top level.
 * Detailed error information can be found in the original {@link AxiosError}
 */
export default class APIError<T = any> extends Error {
    public code: number; // Http Status Code

    public message: string; // Error Message

    public method?: Method; // Http Method

    public key?: APIErrorKey; // Error key for translations

    public axios: AxiosError<APIErrorResponse, T>; // Original Axios Error

    constructor(error: AxiosError<APIErrorResponse, T>) {
        super(error.message || 'Unknown Error');

        this.axios = error;
        this.code = error.response?.status ?? error.request?.status ?? -1;
        this.message = error.message || 'Unknown Error';
        this.method = error.config?.method as Method;
        this.key = error.response?.data.key as APIErrorKey;
    }

    public isNetworkError(): boolean {
        return !this.axios.response;
    }

    public getDetails(): string {
        return this.axios.response?.data.detail ?? 'No details available';
    }
}

export function isAPIError(error: any): error is APIError {
    return error instanceof APIError;
}
