import { redirect, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/editor/')({
    beforeLoad(): void {
        redirect({ to: '/editor/overview', replace: true, throw: true });
    },
    component() {
        return null;
    },
});
