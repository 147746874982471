import { type ObjectSchema, object, string } from 'yup';

export interface ILogin {
    readonly username: string;
    readonly password: string;
}

export const loginSchema: ObjectSchema<ILogin> = object({
    username: string().required(),
    password: string().required(),
});
