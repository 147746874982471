import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Box } from '@mui/system';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import { useAppCtx } from '@/core/app-ctx/mod';
import { MapControl, ControlPositionMap } from '@/features/map';
import { useJourneyToken } from '@/hooks/useJourneyToken';

export const MapLayer: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        appSettings: {
            searchResults: { disableTripCompanies },
        },
    } = useAppCtx();
    const token = useJourneyToken();

    const handleClick = async () => navigate({ to: '/trip-companies', search: { token } });

    if (disableTripCompanies) return null;

    return (
        <MapControl position={ControlPositionMap.LEFT_TOP}>
            <Box p={1.5}>
                <ActionButton
                    category="tertiary"
                    gaEvent={{ action: 'trip-company', category: 'explore-trip-companies' }}
                    sx={{
                        backgroundColor(theme) {
                            return theme.vars.palette.background.paper;
                        },
                        '&:hover': {
                            backgroundColor(theme) {
                                return theme.vars.palette.background.paper;
                            },
                        },
                    }}
                    startIcon={<TravelExploreIcon />}
                    onClick={handleClick}>
                    {t('trip_company.explore_destinations')}
                </ActionButton>
            </Box>
        </MapControl>
    );
};
