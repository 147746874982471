import { AppBar, Box, Container, Toolbar, type Breakpoint } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import { PageWarning } from '@/components/page/header/page-warning';
import { useLayout } from '@/components/page/layout/mod';
import { AccountActions } from '@/components/page/navigation/account-actions';
import { DesktopNavigation } from '@/components/page/navigation/desktop-navigation';
import { EditorActions } from '@/components/page/navigation/editor-actions';
import { MobileNavigation } from '@/components/page/navigation/mobile/mobile-navigation';
import { SettingsActions } from '@/components/page/navigation/settings-actions';
import { useDefaultNavigation, type InjectedUseNavigation } from '@/components/page/navigation/useNavigation';

interface HeaderProps {
    readonly maxWidth?: Breakpoint | false;
    readonly useNavigation?: InjectedUseNavigation;
}

export const Header: React.FC<HeaderProps> = ({ maxWidth = false, useNavigation = useDefaultNavigation }) => {
    const { isDesktop } = useLayout();
    const navigationProps = useNavigation();

    const headerRef = useRef<HTMLElement | null>(null);
    const scrollInto = useRef(true);

    useEffect(() => {
        if (scrollInto.current && !isDesktop && headerRef.current != null) {
            headerRef.current.scrollIntoView(true);
            scrollInto.current = false;
        }
    }, [isDesktop]);

    return (
        <>
            <AppBar ref={headerRef} position="relative" color="default">
                <Container maxWidth={maxWidth}>
                    <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: 'flex', gap: { xs: 0, md: 1 } }}>
                            {isDesktop ? (
                                <DesktopNavigation {...navigationProps} />
                            ) : (
                                <MobileNavigation {...navigationProps} />
                            )}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <EditorActions />
                            <AccountActions />
                            <SettingsActions />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <PageWarning />
        </>
    );
};
