import { ErrorBoundary, type FallbackRender } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { type FC, type PropsWithChildren } from 'react';

import { isIntegrationError } from '@/api/IntegrationError';
import ErrorSvg from '@/assets/images/error-background.svg';
import { IS_PRODUCTION } from '@/config/env';
import { useEmbedCtx } from '@/embed/mod';
import { iff } from '@/utils/helpers';

export const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <ErrorBoundary fallback={ErrorRender} onReset={reset}>
                    {children}
                </ErrorBoundary>
            )}
        </QueryErrorResetBoundary>
    );
};

const ErrorRender: FallbackRender = ({ error, componentStack, resetError }) => {
    if (isIntegrationError(error))
        return (
            <ErrorView includeContact desc="Integration not available" error={error} componentStack={componentStack} />
        );

    return (
        <ErrorView
            includeContact
            includeRetry
            desc="Please try refreshing the page."
            error={error}
            componentStack={componentStack}
            resetError={resetError}
        />
    );
};

interface ErrorViewProps {
    readonly title?: string;
    readonly desc: string;
    readonly includeContact?: boolean;
    readonly includeRetry?: boolean;
    readonly error: Error;
    readonly componentStack?: string;
    readonly resetError?: () => void;
}

const ErrorView: FC<ErrorViewProps> = ({
    title = 'Oops, something went wrong.',
    desc,
    includeContact,
    includeRetry,
    error,
    componentStack,
    resetError,
}) => {
    const { isEmbedding, embedType } = useEmbedCtx();

    const disableBackground = isEmbedding && embedType === 'integration';

    return (
        <div
            style={{
                boxSizing: 'border-box',
                inset: iff(!disableBackground, '0 0 0 0'),
                position: iff(!disableBackground, 'absolute'),
                backgroundImage: iff(!disableBackground, `url(${ErrorSvg})`),
                backgroundPosition: iff(!disableBackground, 'bottom center'),
                backgroundSize: iff(!disableBackground, 'cover'),
                color: iff(!disableBackground, 'white'),
                padding: '2em 8em',
                minHeight: iff(
                    !disableBackground,
                    'var(--bf-app-min-height, calc(100vh - var(--bf-env-boundary-offset-top, 0px) - var(--bf-env-boundary-offset-bottom, 0px)))',
                ),
            }}>
            <h1 style={{ fontSize: '3.5em', marginBottom: '0.5em' }}>{title}</h1>
            <h2 style={{ fontSize: '1.5em', marginBottom: '0.5em' }}>{desc}</h2>
            {includeContact && (
                <h2 style={{ fontSize: '1.5em', marginBottom: '0.5em' }}>
                    If the issue persists, do not hesitate to{' '}
                    <a style={{ color: 'white' }} href="mailto:office@busfinder.com">
                        contact us.
                    </a>
                </h2>
            )}
            {!IS_PRODUCTION && (
                <>
                    {includeRetry && (
                        <div style={{ display: 'flex', gap: '0.5em', marginBottom: '1em' }}>
                            {resetError && (
                                <button type="button" onClick={resetError}>
                                    Retry
                                </button>
                            )}
                            <button type="button" onClick={() => window.location.reload()}>
                                Reload
                            </button>
                        </div>
                    )}
                    <details>
                        <summary style={{ cursor: 'pointer' }}>{error.message}</summary>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{componentStack}</pre>
                    </details>
                </>
            )}
        </div>
    );
};
