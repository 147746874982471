import { Entity } from '@/entity/A_Entity';
import { fromJsonArray } from '@/entity/index';
import { DefaultRoute } from '@/entity/journey/saved/DefaultRoute';
import { type IJourney } from '@/features/journey-planning';

export class DefaultJourney extends Entity {
    public name?: string;

    public token: string;

    public applicableSchool: boolean;

    public onlyOwnBuses: boolean;

    public companyProvidesDriverRooms: boolean;

    public makePTVRequest: boolean;

    public routes: DefaultRoute[];

    public amendBookingToken?: string;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
        this.token = json.token;
        this.applicableSchool = json.applicableSchool;
        this.onlyOwnBuses = json.onlyOwnBuses;
        this.companyProvidesDriverRooms = json.companyProvidesDriverRooms;
        this.makePTVRequest = json.makePTVRequest;
        this.routes = fromJsonArray(DefaultRoute, json.routes);
        this.amendBookingToken = json.amendBookingToken;
    }

    public toString(): string {
        return this.token;
    }

    public getFormValues(): IJourney {
        return {
            applicableSchool: this.applicableSchool,
            onlyOwnBuses: this.onlyOwnBuses,
            companyProvidesDriverRooms: this.companyProvidesDriverRooms,
            makePTVRequest: this.makePTVRequest,
            routes: this.routes.map(route => ({
                pax: route.pax,
                considerSplit: route.considerSplit,
                confirmSplit: false,
                stops: route.stops.map(stop => ({
                    uuid: stop.uuid,
                    departureDateTime: stop.departureDateTime,
                    arrivalDateTime: stop.arrivalDateTime,
                    location: stop.location ?? null,
                    localBus: stop.localBus ?? null,
                    tripCompany: stop.tripCompany ? stop.tripCompany.getFormValues() : null,
                })),
            })),
            name: this.name,
        };
    }
}
