import { Entity } from '@/entity/A_Entity';

export class BusEngine extends Entity {
    public name: string;

    public rank?: number;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
        this.rank = json.rank;
    }
}
