import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckField } from '@/components/form/fields/check-field';
import { type IJourney } from '@/features/journey-planning';

export interface ConsiderSplitRoutesProps {
    readonly formValues: IJourney;
}

const ConsiderSplitRoutes: React.FC<ConsiderSplitRoutesProps> = ({ formValues }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="body2">{t('search_form.route.confirm_split.text')}</Typography>
            {formValues.routes.map((route, index) => {
                if (route.considerSplit)
                    return (
                        <CheckField
                            key={index}
                            label={
                                formValues.routes.length > 1
                                    ? t('search_form.route.split_route_index', { index: index + 1 })
                                    : t('search_form.route.split_route')
                            }
                            name={`routes.${index}.confirmSplit`}
                        />
                    );

                return <input key={index} type="hidden" name={`routes.${index}.confirmSplit`} />;
            })}
        </>
    );
};

export default ConsiderSplitRoutes;
