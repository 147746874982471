import { useEffect, useMemo } from 'react';
import ga4 from 'react-ga4';

import { useCookieBanner } from '@/core/cookie-banner/mod';
import { GoogleAnalyticsContext, type IGoogleAnalyticsContext } from '@/core/google/mod';
import { initGA } from '@/core/google/utils/ga-utils';

export const GoogleAnalyticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { isMarketingAccepted } = useCookieBanner();

    useEffect(() => {
        if (isMarketingAccepted && !ga4.isInitialized) initGA();
    }, [isMarketingAccepted]);

    const value: IGoogleAnalyticsContext = useMemo(
        () => ({
            dummy: false,
        }),
        [],
    );

    return <GoogleAnalyticsContext.Provider value={value}>{children}</GoogleAnalyticsContext.Provider>;
};
