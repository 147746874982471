import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { IconButton, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { type RatingItem } from '../../types/rating';

import { useEmbedCtx } from '@/embed/mod';

interface BusCustomerRatingProps {
    ratingItems: RatingItem[];
    highlightSelectedOnly?: boolean;
    hideResetIcon?: boolean;
    onClick?: (value: number) => void;
    onReset?: () => void;
    value?: number | number[];
}

export const BusRatingIcons: React.FC<BusCustomerRatingProps> = ({
    ratingItems,
    highlightSelectedOnly,
    hideResetIcon,
    value,
    onClick,
    onReset,
}) => {
    const initialValue = value ?? (highlightSelectedOnly ? 0 : []);
    const [indexes, setIndexes] = useState<number | number[]>(initialValue);

    const handleClick = (value: number) => {
        setIndexes(prevIndexes => {
            if (Array.isArray(prevIndexes))
                return prevIndexes.includes(value) ? prevIndexes.filter(i => i !== value) : [...prevIndexes, value];

            return prevIndexes === value ? 0 : value;
        });

        onClick?.(value);
    };
    const handleReset = () => {
        if (Array.isArray(indexes)) setIndexes([]);
        else setIndexes(0);

        onReset?.();
    };

    useEffect(() => {
        if (value !== undefined) setIndexes(value);
    }, [value]);

    const isActive = (value: number) => (Array.isArray(indexes) ? indexes.includes(value) : indexes === value);
    const isAnyActive = Array.isArray(indexes) ? indexes.length > 0 : indexes !== 0;

    return (
        <Stack direction="row" gap={0.5}>
            {ratingItems.map((item, i) => {
                return (
                    <BusCustomerRatingIcon key={i} {...item} isActive={isActive(item.value)} onClick={handleClick} />
                );
            })}
            {!hideResetIcon && (
                <IconButton size="small" sx={{ visibility: isAnyActive ? 'visible' : 'hidden' }} onClick={handleReset}>
                    <CloseOutlined
                        fontSize="small"
                        sx={{
                            color: theme => theme.vars.palette.grey[400],
                        }}
                    />
                </IconButton>
            )}
        </Stack>
    );
};

interface BusCustomerRatingIconProps extends RatingItem {
    isActive: boolean;
    onClick: (value: number) => void;
}

const BusCustomerRatingIcon: React.FC<BusCustomerRatingIconProps> = ({
    value,
    activeVariant,
    inactiveVariant,
    isActive,
    onClick,
}) => {
    const [hover, setHover] = useState(false);
    const { shadowRoot } = useEmbedCtx();

    const ref = useRef<HTMLButtonElement>(null);

    const handleMouseOver = () => setHover(true);
    const handleMouseOut = () => setHover(false);

    // If we cut through the icons with the mouse very fast, the hover state is not updated correctly. This is a workaround for that.
    useEffect(() => {
        const checkHover = (e: Event) => {
            if (ref.current && ref.current.contains(e.target as Node)) setHover(true);
            else setHover(false);
        };
        const container = shadowRoot ?? window;

        container.addEventListener('mousemove', checkHover, true);

        return () => {
            container.removeEventListener('mousemove', checkHover, true);
        };
    }, [shadowRoot]);

    return (
        <IconButton
            ref={ref}
            disableRipple
            sx={{
                padding: 0,
                '&:hover': {
                    transform: 'scale(1.15)',
                },
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => onClick(value)}>
            {isActive || hover ? activeVariant : inactiveVariant}
        </IconButton>
    );
};
