import * as Yup from 'yup';

import { type Account } from '@/entity/account/Account';
import { type ILocation, LocationSchema } from '@/entity/basic/Location';
import { type registrationSchema, type IRegistration } from '@/features/auth';

export type AccountCoreDataFormValues = Omit<IRegistration, 'password' | 'passwordConfirmation' | 'email'>;

export interface IContactData {
    readonly gender: string;
    readonly country: string;
    readonly city: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly postCode: string;
    readonly street: string;
    readonly phone: string;
}

export const contactDataSchema: Yup.ObjectSchema<IContactData> = Yup.object({
    gender: Yup.string().required(),
    country: Yup.string().required(),
    city: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    postCode: Yup.string().required(),
    street: Yup.string().required(),
    phone: Yup.string().required(),
});

export function getDefaultContactDataValues(user?: Account): IContactData {
    return {
        gender: user?.contactData.gender ?? '',
        country: user?.contactData.country ?? '',
        city: user?.contactData.city ?? '',
        firstName: user?.contactData.firstName ?? '',
        lastName: user?.contactData.lastName ?? '',
        postCode: user?.contactData.postCode ?? '',
        street: user?.contactData.street ?? '',
        phone: user?.contactData.phone ?? '',
    };
}

export interface IFullContactData extends IContactData {
    readonly email: string;
}

export const fullContactDataSchema: Yup.ObjectSchema<IFullContactData> = contactDataSchema.shape({
    email: Yup.string().email().required(),
});

export function getDefaultFullContactDataValues(user?: Account): IFullContactData {
    return {
        ...getDefaultContactDataValues(user),
        email: user?.contactData.email ?? '',
    };
}

export interface ICustomerGroupValues {
    readonly customerGroupId: number;
    readonly customerGroupName: string | null;
    readonly organizationName: string | null;
    readonly organizationLocation: ILocation | null;
    readonly organizationUID: string | null;
    readonly organizationPoNumber: string | null;
    readonly organizationCostCenter: string | null;
}

export const customerGroupSchema: Yup.ObjectSchema<ICustomerGroupValues> = Yup.object({
    customerGroupId: Yup.number().min(1, { key: 'general.required' }).required(),
    customerGroupName: Yup.string().defined().nullable(),
    organizationName: Yup.string()
        .defined()
        .nullable()
        .when('customerGroupId', {
            is: (customerGroupId: number) => customerGroupId !== 4,
            then: schema => schema.required(),
        }),
    organizationLocation: LocationSchema.defined()
        .nullable()
        .when('customerGroupId', {
            is: (customerGroupId: number) => customerGroupId !== 4,
            then: schema => schema.test('required_location', { key: 'general.required' }, value => value != null),
        }),
    organizationUID: Yup.string().defined().nullable(),
    organizationPoNumber: Yup.string().defined().nullable(),
    organizationCostCenter: Yup.string().defined().nullable(),
});

export function getDefaultCustomerGroupValues(customerGroupId = 0): {
    customerGroupId: number;
    customerGroupName: null;
    organizationName: null;
    organizationLocation: null;
    organizationUID: null;
    organizationPoNumber: null;
    organizationCostCenter: null;
} {
    return {
        customerGroupId,
        customerGroupName: null,
        organizationName: null,
        organizationLocation: null,
        organizationUID: null,
        organizationPoNumber: null,
        organizationCostCenter: null,
    };
}

export interface IRegistrationFormValues extends Yup.InferType<typeof registrationSchema> {}

export function getDefaultRegistrationFormValues(): IRegistrationFormValues {
    return {
        ...getDefaultCustomerGroupValues(),
        contactData: getDefaultContactDataValues(),
        email: '',
        password: '',
        passwordConfirmation: '',
        agb: false,
    };
}
