import { createFileRoute } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

const R_PRINT_OFFER_COMPARISON_SEARCH_PARAMS = object({
    token: string().when('jSessionId', {
        is: (jSessionId?: string) => jSessionId == null || jSessionId.length <= 0,
        then: schema => schema.required(),
    }),
    jSessionId: string(),
});

export const Route = createFileRoute('/print/offer-comparison')({
    validateSearch(search): InferType<typeof R_PRINT_OFFER_COMPARISON_SEARCH_PARAMS> {
        return R_PRINT_OFFER_COMPARISON_SEARCH_PARAMS.cast(search);
    },
});
