import { t } from 'i18next';
import { DateTime } from 'luxon';

export function genderOptions() {
    return [
        {
            value: 'OTHER',
            label: t('account.core_data.gender.OTHER'),
        },
        {
            value: 'FEMALE',
            label: t('account.core_data.gender.FEMALE'),
        },
        {
            value: 'MALE',
            label: t('account.core_data.gender.MALE'),
        },
    ];
}

export function getYesNoOptions() {
    return [
        {
            id: 'no',
            value: false,
            label: t('no'),
        },
        {
            id: 'yes',
            value: true,
            label: t('yes'),
        },
    ];
}

export function getAPIOptions() {
    return [
        {
            id: 'ratio',
            label: 'Ratio',
        },
        {
            id: 'csbus',
            label: 'CS-BUS',
        },
        {
            id: 'turista',
            label: 'Turista',
        },
        {
            id: 'kuschick',
            label: 'Kuschick',
        },
        {
            id: 'deltaplan',
            label: 'Deltaplan',
        },
    ];
}

export function getBdoNationalAssociations() {
    return [
        { value: '-', label: '-' },
        { value: 'Baden-Württemberg', label: 'Baden-Württemberg' },
        { value: 'Bayern', label: 'Bayern' },
        { value: 'Berlin', label: 'Berlin' },
        { value: 'Brandenburg', label: 'Brandenburg' },
        { value: 'Hessen', label: 'Hessen' },
        { value: 'Mecklenburg-Vorpommern', label: 'Mecklenburg-Vorpommern' },
        { value: 'Niedersachsen/Bremen', label: 'Niedersachsen/Bremen' },
        { value: 'Nordrhein-Westfalen', label: 'Nordrhein-Westfalen' },
        { value: 'Rheinland-Pfalz', label: 'Rheinland-Pfalz' },
        { value: 'Saarland', label: 'Saarland' },
        { value: 'Sachsen-Anhalt', label: 'Sachsen-Anhalt' },
        { value: 'Sachsen', label: 'Sachsen' },
        { value: 'Schleswig-Holstein/Hamburg', label: 'Schleswig-Holstein/Hamburg' },
        { value: 'Thüringen', label: 'Thüringen' },
    ];
}

export enum CompanyPlan {
    STARTER = 'STARTER',
    PRO = 'PRO',
    INTERN = 'INTERN',
}

export function getCompanyPlanOptions() {
    return [
        {
            id: CompanyPlan.STARTER,
            value: CompanyPlan.STARTER,
            label: t('company.company_plan.starter'),
        },
        {
            id: CompanyPlan.PRO,
            value: CompanyPlan.PRO,
            label: t('company.company_plan.pro'),
        },
    ];
}

export const Days = {
    INITIAL_FROM_DATE_TIME: 7,

    // XXX: Has to match the minimum value for the bookingLeadTime in the BusCompany entity.
    MIN_FROM_DATE_TIME: 2,

    MAX_OFFER_VALID: 365,
    MAX_OFFER_REMINDER: 30,
};

export const SEARCH_FORM = {
    defaultDepartureDateTime: DateTime.now().plus({ days: Days.INITIAL_FROM_DATE_TIME }),
    minDepartureDateTime: DateTime.now().plus({ days: Days.MIN_FROM_DATE_TIME }),
    defaultPax: 20,
};

export enum PriceOptionId {
    online_no_refund = 'online_no_refund',
    online = 'online',
    invoice_no_refund = 'invoice_no_refund',
    invoice = 'invoice',
}

export const FULL_BREAK_MINUTES = 9 * 60;

export const BUSES_PER_PAGE = 10;
