import * as Yup from 'yup';

/**
 * Yup Localization
 */
Yup.setLocale({
    mixed: {
        required: {
            // t("validations:general.required")
            key: 'general.required',
        },
    },
    string: {
        email: {
            // t("validations:email.invalid")
            key: 'email.invalid',
        },
        min: ({ min }: any) => ({
            // t("validations:string.min")
            key: 'string.min',
            values: { min },
        }),
        max: ({ max }: any) => ({
            // t("validations:string.max")
            key: 'string.max',
            values: { max },
        }),
    },
    number: {
        integer: {
            // t("validations:number.invalid")
            key: 'number.invalid',
        },
        min: ({ min }: any) => ({
            // t("validations:number.min")
            key: 'number.min',
            values: { min },
        }),
        max: ({ max }: any) => ({
            // t("validations:number.max")
            key: 'number.max',
            values: { max },
        }),
        positive: {
            // t("validations:number.positive")
            key: 'number.positive',
        },
    },
});

/**
 * Custom methods to the core schema types.
 */

// Check if two form fields has the same value (e.g. at password fields)
Yup.addMethod(Yup.string, 'matchWith', function (fieldToMatchWith: string, msg: Record<string, string>) {
    // t("validations:password.no_match")
    return this.oneOf([Yup.ref(fieldToMatchWith), ''], msg);
});
