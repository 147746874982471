import { Money } from '@/entity/basic/Money';
import { type PaymentOption } from '@/entity/common-constants';
import { type PriceOptionId } from '@/utils/constants';

export class PriceOption {
    public id: PriceOptionId;

    public paymentOption: PaymentOption;

    public noRefund: boolean;

    public price: Money;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.paymentOption = json.paymentOption;
        this.noRefund = json.noRefund;
        this.price = Money.fromJson(json.price);
    }
}
