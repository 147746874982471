import { Box, TextField, Autocomplete } from '@mui/material';
import { ReactCountryFlag } from 'react-country-flag';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@/components/form/error-message';
import { type FormFieldProps } from '@/components/form/mod';
import { SORTED_COUNTRIES } from '@/config/localization';

interface CountrySelectProps extends FormFieldProps {
    required?: boolean;
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ label, name, helperText, required }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        autoHighlight
                        autoSelect
                        id="country-select"
                        options={SORTED_COUNTRIES}
                        value={value}
                        getOptionLabel={countryCode => t(`countries:${countryCode}`)}
                        renderOption={(props, countryCode) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <ReactCountryFlag svg countryCode={countryCode} />
                                {t(`countries:${countryCode}`)}
                            </Box>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                required={required}
                                name={name}
                                label={label}
                                error={Boolean(error)}
                                helperText={error ? <ErrorMessage error={error} /> : helperText}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                        onChange={(_, newValue: (typeof SORTED_COUNTRIES)[number]) => {
                            onChange(newValue ?? '');
                        }}
                        onBlur={onBlur}
                    />
                );
            }}
        />
    );
};
