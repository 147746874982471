import { ENV } from '@/config/env';
import { type Basic } from '@/entity/basic/I_Basic';

export type SizeSuffix = 'sm' | 'md' | 'lg' | '';

interface SizeWidth {
    size: SizeSuffix;
    width: number;
}

const sizeWidthMap: SizeWidth[] = [
    { size: 'sm', width: 256 },
    { size: 'md', width: 512 },
    { size: 'lg', width: 1024 },
    { size: '', width: 2084 },
];

export function getSizes() {
    return sizeWidthMap
        .map(sizeWidth => {
            const first = sizeWidth.size === '' ? '' : `(max-width: ${sizeWidth.width}px) `;
            return `${first}${sizeWidth.width}px`;
        })
        .join(',');
}

export class Document implements Basic<Document> {
    public static fromJson(json: Record<string, any>): Document {
        return new Document(json.filename, json.path);
    }

    public readonly filename: string;

    public readonly path: string;

    constructor(filename: string, path: string) {
        this.filename = filename;
        this.path = path;
    }

    public equals(other: Document): boolean {
        return other != null && this.filename === other.filename;
    }

    public toString(): string {
        return this.filename;
    }

    public extension(): string {
        return this.filename.slice(this.filename.lastIndexOf('.') + 1);
    }

    public filenameWithoutExtension(): string {
        return this.filename.slice(0, this.filename.lastIndexOf('.'));
    }

    getSrc(suffix: SizeSuffix = '', url?: string): string {
        return `${url || ENV.apiUrl}public/documents/${this.filenameWithoutExtension()}${
            suffix === '' ? '' : `.${suffix}`
        }.${this.extension()}`;
    }

    getSrcSet() {
        return sizeWidthMap.map(sizeWidth => `${this.getSrc(sizeWidth.size)} ${sizeWidth.width}w`).join(',');
    }
}
