import { styled } from '@mui/material';
import useEvent from '@react-hook/event';
import useResizeObserver from '@react-hook/resize-observer';
import { useMemo, useRef, type FC, type PropsWithChildren } from 'react';

import { ScrollbarContext, type IScrollbarContext } from '@/components/elements/scrollbar/mod';

export const Scrollbar: FC<PropsWithChildren> = ({ children }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const contentProbeRef = useRef<HTMLDivElement | null>(null);
    const trackRef = useRef<HTMLDivElement | null>(null);
    const thumbRef = useRef<HTMLDivElement | null>(null);

    const isDragging = useRef(false);
    const scrollStartPosition = useRef(0);
    const initialScrollTop = useRef(0);

    const value: IScrollbarContext = useMemo(
        () => ({
            scrollToTop(behavior: 'smooth') {
                contentRef.current?.scrollTo({ top: 0, left: 0, behavior });
            },
            contentRef,
        }),
        [],
    );

    const showThumb = (type: 'visible' | 'dragging' | 'disabled', show: boolean) =>
        thumbRef.current?.classList.toggle(type, show);

    useResizeObserver(contentProbeRef, () => {
        const content = contentRef.current;
        const track = trackRef.current;
        const thumb = thumbRef.current;

        if (content == null || track == null || thumb == null) return;

        const height = content.clientHeight / content.scrollHeight;

        thumb.style.height = `${Math.max(height, 0.03) * 100}%`;
        showThumb('disabled', height >= 1);
    });

    useEvent(contentRef, 'scroll', () => {
        const content = contentRef.current;
        const track = trackRef.current;
        const thumb = thumbRef.current;

        if (content == null || track == null || thumb == null) return;

        const top = Math.min(content.scrollTop / content.scrollHeight, 1);

        thumb.style.top = `${top * 100}%`;
    });

    const handleDragStart = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        scrollStartPosition.current = e.clientY;
        if (contentRef.current) initialScrollTop.current = contentRef.current.scrollTop;
        showThumb('dragging', true);
        isDragging.current = true;
    };

    const handleDragStop = (e: MouseEvent) => {
        // e.preventDefault();
        e.stopPropagation();

        isDragging.current = false;
        showThumb('dragging', false);
    };

    const handleDrag = (e: MouseEvent) => {
        const content = contentRef.current;
        const track = trackRef.current;
        const thumb = thumbRef.current;

        if (!isDragging.current || content == null || track == null || thumb == null) return;

        e.preventDefault();
        e.stopPropagation();

        // Subtract the current mouse y position from where you started to get the pixel difference in mouse position.
        // Multiply by ratio of visible content height to thumb height to scale up the difference for content scrolling.
        const deltaY = (e.clientY - scrollStartPosition.current) * (content.offsetHeight / thumb.clientHeight);
        const newScrollTop = Math.min(initialScrollTop.current + deltaY, content.scrollHeight - content.offsetHeight);

        content.scrollTop = newScrollTop;
    };

    useEvent(thumbRef, 'mousedown', handleDragStart);
    useEvent(document, 'mousemove', handleDrag);
    useEvent(document, 'mouseup', handleDragStop);
    useEvent(document, 'mouseleave', handleDragStop);
    useEvent(contentRef, 'mouseenter', () => showThumb('visible', true));
    useEvent(contentRef, 'mouseleave', () => showThumb('visible', false));

    return (
        <ScrollbarContext.Provider value={value}>
            <ScrollContainer>
                <ScrollContent ref={contentRef}>
                    <div ref={contentProbeRef}>{children}</div>
                </ScrollContent>
                <Track ref={trackRef}>
                    <Thumb ref={thumbRef} />
                </Track>
            </ScrollContainer>
        </ScrollbarContext.Provider>
    );
};

const ScrollContainer = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
});

const ScrollContent = styled('div')({
    overflow: 'auto',
    position: 'absolute',
    inset: '0 0 0 0',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const THUMB_WIDTH = 4;

const Track = styled('div')(() => ({
    position: 'absolute',
    top: 2,
    bottom: 2,
    right: 2,
}));

const Thumb = styled('div')(({ theme }) => ({
    position: 'relative',
    left: 0,
    right: 0,
    width: THUMB_WIDTH,
    zIndex: theme.zIndex.appBar,
    borderRadius: theme.vars.shape.borderRadius,
    backgroundColor: theme.vars.palette.grey[400],
    opacity: 0.2,
    transition: theme.transitions.create(['opacity', 'backgroundColor'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
    }),
    '&.visible, &.dragging, &:hover': {
        opacity: 0.6,
        cursor: 'pointer',
    },
    '&.dragging, &:hover': {
        backgroundColor: theme.vars.palette.primary.main,
    },
    '&.disabled': {
        display: 'none',
    },
}));
