import { Box, Grid, type GridProps, Typography } from '@mui/material';
import React from 'react';

import { useLayout } from '@/components/page/layout/mod';

interface IFormFieldsContainerProps extends GridProps {
    readonly containerLabel?: string;
}

export const FormFieldsContainer: React.FC<IFormFieldsContainerProps> = props => {
    const { children, containerLabel, ...rest } = props;
    const { isDesktop } = useLayout();

    return (
        <Box sx={{ mb: 3 }}>
            {containerLabel && (
                <Typography sx={{ mb: 2 }} variant="h5">
                    {containerLabel}
                </Typography>
            )}
            <Grid container item columnSpacing={isDesktop ? 2 : 1} spacing={1} xs={12} {...rest}>
                {children}
            </Grid>
        </Box>
    );
};
