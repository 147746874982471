import { CircularProgress, Paper } from '@mui/material';

import { useLayout } from '@/components/page/layout/mod';
import { useJourneyPlanningForm } from '@/features/journey-planning';

export const Loader = () => {
    const { isCalculating } = useJourneyPlanningForm();
    const { isDesktop } = useLayout();

    if (isDesktop || !isCalculating) return null;

    return (
        <Paper
            elevation={3}
            sx={{
                position: 'fixed',
                top: 100,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex(theme) {
                    return theme.zIndex.tooltip;
                },
                p: 1.25,
                pb: 1,
            }}>
            <CircularProgress color="primary" size={25} />
        </Paper>
    );
};
