import { MenuList } from '@mui/material';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentSelectorItem } from './payment-selector-item';

import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export interface PaymentSelectorModalProps extends BaseModalProps {
    readonly pax: number;
    readonly result: BookingSearchResult;
    readonly onProceed: (priceOption: PriceOption) => Promise<void>;
}

export const PaymentSelectorModal: FC<PaymentSelectorModalProps> = ({ result, pax, handleClose, onProceed }) => {
    const { t } = useTranslation();
    const { priceOptions } = result;

    const [selectedPriceOption, setSelectedPriceOption] = useState(priceOptions[0]);

    const handleSelect = (priceOption: PriceOption) => setSelectedPriceOption(priceOption);
    const handleProceed = async () => onProceed(selectedPriceOption);

    return (
        <BaseModal
            open
            title={t('price_options.modal.title')}
            content={
                <MenuList>
                    {priceOptions.map(priceOption => {
                        const priceDisplayRows = result.priceOptionDisplayRows.find(
                            row => row.id === priceOption.id,
                        )?.priceDisplayRows;

                        return (
                            <PaymentSelectorItem
                                key={priceOption.id}
                                priceOption={priceOption}
                                selected={selectedPriceOption.id === priceOption.id}
                                priceDisplayRows={priceDisplayRows}
                                pax={pax}
                                seats={result.bus.getTotalSeats()}
                                onSelect={handleSelect}
                            />
                        );
                    })}
                </MenuList>
            }
            handleClose={handleClose}
            onProceed={handleProceed}
        />
    );
};
