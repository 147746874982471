import { Box, type BoxProps, Stack } from '@mui/material';
import React, { forwardRef } from 'react';

import { LegInfoTooltip } from '../stops/leg-info-tooltip';

import { type IPosition, useJourneyPlanningForm } from '@/features/journey-planning';

interface FormLineProps {
    readonly position?: [boolean, boolean];
    readonly legDetailsOf?: IPosition;
}

export const FormLine: React.FC<React.PropsWithChildren<FormLineProps>> = ({
    position = [true, true],
    legDetailsOf,
    children,
}) => {
    const { getBookingStop } = useJourneyPlanningForm();
    const [top, bottom] = position;

    const prevBookingStop = legDetailsOf ? getBookingStop({ ...legDetailsOf, index: legDetailsOf.index - 1 }) : null;
    const bookingStop = legDetailsOf ? getBookingStop(legDetailsOf) : null;
    const hideTooltip = position.every(p => !p);

    return (
        <Stack alignItems="center" height="100%">
            <LegInfoTooltip legDetails={prevBookingStop?.legDetails} hideTooltip={hideTooltip}>
                <Line isDisplayed={top} />
            </LegInfoTooltip>

            {children}

            <LegInfoTooltip autoOpen legDetails={bookingStop?.legDetails} hideTooltip={hideTooltip}>
                <Line isDisplayed={bottom} />
            </LegInfoTooltip>
        </Stack>
    );
};

interface LineProps {
    readonly isDisplayed: boolean;
}

const Line: React.FC<LineProps> = forwardRef(({ isDisplayed, ...props }, ref: React.Ref<HTMLDivElement>) => {
    const lineSx: BoxProps['sx'] = {
        width: '2px',
        backgroundColor(theme) {
            return theme.vars.palette.grey.A100;
        },
    };

    return (
        <Box {...props} ref={ref} display="flex" flex={1} px={1}>
            <Box sx={isDisplayed ? lineSx : undefined} flexBasis={0} flex={1} />
        </Box>
    );
});
