import { Skeleton } from '@mui/material';
import { useMemo, type FC } from 'react';

import { useTripCompanies } from '../hooks/use-trip-companies';

import { AspectBox } from '@/components/elements/image/aspect-box';
import { Image } from '@/components/elements/image/image';
import { ENV, IS_DEVELOPMENT } from '@/config/env';
import { type Document } from '@/entity/basic/Document';

interface PreviewImageProps {
    readonly documents: Document[];
    readonly logo?: Document;
    readonly alt: string;
}

export const PreviewImage: FC<PreviewImageProps> = ({ logo, documents, alt }) => {
    const { filterLoading } = useTripCompanies();

    const previewImage = useMemo(() => {
        // TODO: Maybe add a placeholder image if there are no documents
        const url = IS_DEVELOPMENT ? 'https://app.bus.busworld24.com/tomcat/' : ENV.apiUrl;
        return logo ? logo.getSrc('md', url) : documents[0]?.getSrc('md', url);
    }, [documents, logo]);

    if (filterLoading) return <Skeleton variant="rounded" width="100%" height={140} />;

    return (
        <AspectBox ratio="16:9">
            <Image src={previewImage} alt={alt} fit="cover" />
        </AspectBox>
    );
};
