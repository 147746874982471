import Cookies from 'js-cookie';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppCtx } from '@/core/app-ctx/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import {
    COOKIE_CONSENT,
    COOKIE_CONSENT_VALUES,
    CookieBannerContext,
    type CookieConsent,
    type ICookieBannerContext,
} from '@/core/cookie-banner/mod';
import { hasAcceptedGABefore } from '@/core/google/utils/ga-utils';
import { useEmbedCtx } from '@/embed/mod';
import { CookieBannerModal } from '@/features/cookie-banner';

export const CookieBannerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { isEmbedding } = useEmbedCtx();
    const {
        isIntegration,
        appSettings: {
            branding: { disableCookieBanner },
        },
    } = useAppCtx();
    const { isPrint } = useAppProgress();

    const [cookieConsent, setCookieConsent] = useState<CookieConsent[]>(getConsentCookie());
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        if (cookieConsent.length > 0) {
            const cookieConsentString = cookieConsent.join('|');
            Cookies.set(COOKIE_CONSENT, cookieConsentString, {
                expires: 365,
                secure: true,
                sameSite: 'none',
            });
        }
    }, [cookieConsent]);

    const hasGA = hasAcceptedGABefore();

    const acceptedNecessary = getConsentCookie().includes('necessary');

    useEffect(() => {
        if (isPrint) return;

        // If we are on an integration page and the integration's cookie consent is accepted and they have GA cookies, we set the cookie consent to all and don't show the banner
        if (isIntegration) {
            if (hasGA) return setCookieConsent(COOKIE_CONSENT_VALUES);
            if (disableCookieBanner) return;
            if (!acceptedNecessary) return handleOpen();
        }

        if (isEmbedding) {
            if (hasGA) return setCookieConsent(COOKIE_CONSENT_VALUES);
            if (!acceptedNecessary) return handleOpen();
        }

        // If we are on the main page and the cookie consent is not accepted, we show the banner
        if (!acceptedNecessary) return handleOpen();
    }, [acceptedNecessary, disableCookieBanner, hasGA, isEmbedding, isIntegration, isPrint]);

    const value: ICookieBannerContext = useMemo(
        () => ({
            cookieConsent,
            isNecessaryAccepted: cookieConsent.includes('necessary'),
            isMarketingAccepted: cookieConsent.includes('marketing'),
            setCookieConsent(c: CookieConsent[]) {
                setCookieConsent(c);
            },
        }),
        [cookieConsent],
    );

    return (
        <CookieBannerContext.Provider value={value}>
            <CookieBannerModal open={open} onClose={handleClose} />
            {children}
        </CookieBannerContext.Provider>
    );
};

function getConsentCookie() {
    const cookieConsentString = Cookies.get(COOKIE_CONSENT);

    return mapCookieConsentStringToArray(cookieConsentString);
}

function mapCookieConsentStringToArray(cookieConsentString?: string) {
    if (!cookieConsentString || cookieConsentString.length <= 0) return [];
    return cookieConsentString
        .split('|')
        .filter((c): c is CookieConsent => COOKIE_CONSENT_VALUES.includes(c as CookieConsent));
}
