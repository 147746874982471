import { type IPosition } from '@/features/journey-planning';

export function getPosition(index: number, size: number, routeIndex: number): IPosition {
    return {
        first: index === 0,
        last: index === size - 1,
        index,
        routeIndex,
    };
}
