import { useContext } from 'react';

import { JourneyPlanningFormContext } from '../types/context';

export function useJourneyPlanningForm() {
    const context = useContext(JourneyPlanningFormContext);

    if (!context) throw new Error('useJourneyPlanningForm must be used within JourneyPlanningFormContext.Provider');

    return context;
}
