import React from 'react';

import { useAppCtx } from '@/core/app-ctx/mod';
import { type IIntegrationTranslationContext, IntegrationTranslationContext } from '@/core/integration-translation/mod';

export const IntegrationTranslationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { integration, appSettings } = useAppCtx();

    const value: IIntegrationTranslationContext = React.useMemo(
        () => ({
            singleCompanyTranslationKey: integration?.singleCompany ? 'single_company' : 'default',
            bookingModeTranslationKey: appSettings.booking.enableReservation ? 'reservation' : 'booking',
        }),
        [appSettings.booking.enableReservation, integration?.singleCompany],
    );

    return <IntegrationTranslationContext.Provider value={value}>{children}</IntegrationTranslationContext.Provider>;
};
