import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { type FC } from 'react';

import { IS_DEVELOPMENT } from '@/config/env';
import { useAppProgress } from '@/core/app-progress/mod';
import { useEmbedCtx } from '@/embed/mod';

export const DevTools: FC = () => {
    const { isEmbedding } = useEmbedCtx();
    const { isPrint } = useAppProgress();

    if (!IS_DEVELOPMENT || isEmbedding || isPrint) return null;

    return (
        <>
            <TanStackRouterDevtools initialIsOpen={false} position="bottom-right" />
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </>
    );
};
