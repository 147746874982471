import { Grid, type GridProps } from '@mui/material';
import isBoolean from 'lodash/isBoolean';
import React from 'react';

interface FormFieldRowProps extends GridProps {
    readonly insertEmptyColumn?: boolean | number;
}

export const FormFieldRow: React.FC<FormFieldRowProps> = ({ children, insertEmptyColumn }) => {
    const emptyColumnLength = isBoolean(insertEmptyColumn) ? 1 : insertEmptyColumn ?? 0;
    const gridCount = 12 / (React.Children.toArray(children).length + emptyColumnLength);
    const finiteGridCount = Number.isFinite(gridCount) && gridCount;

    return (
        <>
            {React.Children.map(children, child => (
                <Grid item xs={12} sm={finiteGridCount}>
                    {child}
                </Grid>
            ))}
            {insertEmptyColumn && <Grid item display={{ xs: 'none', sm: 'block' }} sm={finiteGridCount} />}
        </>
    );
};
