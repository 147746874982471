import { Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import ga4 from 'react-ga4';
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import { InteractiveText } from '@/components/elements/text/interactive-text';
import ConsiderSplitRoutes from '@/components/modals/considerations/ConsiderSplitRoutes';
import DriverRoomsChoice from '@/components/modals/considerations/DriverRoomsChoice';
import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type IJourney } from '@/features/journey-planning';
import { FULL_BREAK_MINUTES } from '@/utils/constants';
import { nTimes } from '@/utils/helpers';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

// t('search_form.modal.title.local_bus_and_split_route')
// t('search_form.modal.title.consider_split_route')
// t('search_form.modal.title.driver_rooms_choice')
// t('search_form.modal.title.no_break_warning')
// t('search_form.modal.title.not_return_journey')
// t('search_form.modal.step_title.local_bus_and_split_route')
// t('search_form.modal.step_title.consider_split_route')
// t('search_form.modal.step_title.driver_rooms_choice')
// t('search_form.modal.step_title.no_break_warning')
// t('search_form.modal.step_title.not_return_journey')
export type ModalStep =
    | 'local_bus_and_split_route'
    | 'consider_split_route'
    | 'driver_rooms_choice'
    | 'no_break_warning'
    | 'not_return_journey';

export interface JourneyConsiderationsProps extends BaseModalProps {
    readonly journey: BookingJourney;
    readonly formValues: IJourney;
    readonly modals: ModalStep[];
    readonly onAddReturn: () => void;
}

export const JourneyConsiderationsModal: React.FC<JourneyConsiderationsProps> = props => {
    const { modals, formValues, journey, onProceed, onAddReturn, handleClose } = props;
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);

    const defaultValues = useMemo(
        () => ({
            ...formValues,
            routes: formValues.routes.map(route => ({
                ...route,
                confirmSplit: modals.includes('consider_split_route'),
            })),
            companyProvidesDriverRooms: modals.includes('driver_rooms_choice'),
        }),
        [formValues, modals],
    );

    const formMethods = useForm<IJourney>({ defaultValues });

    const handleBack = () => setActiveStep(prev => prev - 1);
    const handleNext = () => setActiveStep(prev => prev + 1);

    const handleToStep = (step: number) => () => {
        if (step > activeStep) return;
        setActiveStep(step);
    };

    const handleAddReturn = () => {
        onAddReturn();
        ga4.event('search-form', { event_category: 'add-return-in-modal' });
        handleClose();
    };

    const handleSubmit = () => {
        onProceed?.(formMethods.getValues());
        handleClose();
    };

    const stepsToShow = useStepsToShow(modals, formValues, formMethods, journey, handleAddReturn);
    const activeStepName = stepsToShow.at(activeStep)?.name;
    const isDisabled = activeStep !== stepsToShow.length && stepsToShow.length > 1;

    const ModalContent = (
        <FormProvider {...formMethods}>
            <form noValidate>
                <Stack gap={4}>
                    {stepsToShow.length > 1 ? (
                        <Stepper orientation="vertical" activeStep={activeStep}>
                            {stepsToShow.map(({ name, component }, i) => (
                                <Step key={name}>
                                    <StepLabel sx={{ cursor: 'pointer' }} onClick={handleToStep(i)}>
                                        {t(`search_form.modal.step_title.${name}`)}
                                    </StepLabel>
                                    <StepContent>
                                        <Stack gap={2}>
                                            {component}

                                            <Stack width="100%" direction="row" gap={2}>
                                                <ActionButton
                                                    intent="neutral"
                                                    category="secondary"
                                                    type="button"
                                                    disabled={i === 0}
                                                    onClick={handleBack}>
                                                    {t('back')}
                                                </ActionButton>
                                                <ActionButton type="button" onClick={handleNext}>
                                                    {i === stepsToShow.length - 1 ? t('confirm') : t('next')}
                                                </ActionButton>
                                            </Stack>
                                        </Stack>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    ) : (
                        <CurrentlyActiveStep
                            activeStepName={activeStepName}
                            formValues={formValues}
                            onAddReturn={handleAddReturn}
                        />
                    )}
                </Stack>
            </form>
        </FormProvider>
    );

    const ModalActions = (
        <Stack width="100%" direction={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent="space-between">
            <ActionButton intent="negative" category="secondary" type="button" onClick={handleClose}>
                {t('close')}
            </ActionButton>

            <ActionButton type="button" disabled={isDisabled} onClick={handleSubmit}>
                {t('search_form.submit')}
            </ActionButton>
        </Stack>
    );

    const title =
        stepsToShow.length > 1 ? t('search_form.modal.title.main') : t(`search_form.modal.title.${activeStepName}`);

    const augmentedModalProps = {
        ...props,
        title,
        content: ModalContent,
        actions: ModalActions,
    };

    return <BaseModal {...augmentedModalProps} />;
};

interface CurrentlyActiveStepProps {
    readonly formValues: IJourney;
    readonly activeStepName?: ModalStep;
    readonly onAddReturn: () => void;
}

const CurrentlyActiveStep: React.FC<CurrentlyActiveStepProps> = ({ activeStepName, formValues, onAddReturn }) => {
    const { t } = useTranslation();

    switch (activeStepName) {
        case 'not_return_journey': {
            return (
                <Typography variant="body2">
                    <Trans
                        i18nKey="search_form.modal.description.not_return_journey"
                        components={{ button: <InteractiveText color="primary" onClick={onAddReturn} /> }}
                    />
                </Typography>
            );
        }
        case 'no_break_warning': {
            return t('search_form.modal.description.no_break_warning');
        }
        case 'local_bus_and_split_route': {
            return <SanitizedInnerHTML html={t('stop.leg_details.status.local_bus_and_split_route.description')} />;
        }
        case 'consider_split_route': {
            return <ConsiderSplitRoutes formValues={formValues} />;
        }
        case 'driver_rooms_choice': {
            return <DriverRoomsChoice />;
        }
        default: {
            return null;
        }
    }
};

const useStepsToShow = (
    modals: ModalStep[],
    formValues: IJourney,
    formMethods: UseFormReturn<IJourney>,
    journey: BookingJourney,
    handleAddReturn: () => void,
) => {
    const routesCount = formValues.routes.length;

    // Array of booleans, each representing whether the user has confirmed the split for the corresponding route
    const confirmSplit: boolean[] = formMethods.watch(nTimes(routesCount).map(i => `routes.${i}.confirmSplit` as any));
    // Array of booleans, each representing the corresponding route if it has a long break and has a CONSIDER_SPLIT_ROUTE status
    const routesWithLongBreakAndSplit = journey.routes.map(route =>
        route.stops.some(
            stop =>
                stop.stayDuration.as('minutes') >= FULL_BREAK_MINUTES && stop.statuses.includes('CONSIDER_SPLIT_ROUTE'),
        ),
    );
    // Whether the journey has a long break that has no split status
    const journeyHasLongBreakAndNoSplit = journey.routes.some(route =>
        route.stops.some(stop => stop.stayDuration.as('minutes') >= FULL_BREAK_MINUTES && !stop.hasSplitStatus()),
    );

    // Show accommodation preference if one of these fulfill:
    // 1. Journey has a stop with a long break and no split status
    // 2. One of the routes has a long break and the user doesn't want to split that specific route
    const showDriverRoomsChoice =
        journeyHasLongBreakAndNoSplit ||
        routesWithLongBreakAndSplit.some((hasLongBreak, i) => hasLongBreak && !confirmSplit[i]);

    const filteredSteps = showDriverRoomsChoice ? modals : modals.filter(step => step !== 'driver_rooms_choice');

    const stepsToShow = filteredSteps.map(name => ({
        name,
        component: <CurrentlyActiveStep activeStepName={name} formValues={formValues} onAddReturn={handleAddReturn} />,
    }));

    return stepsToShow;
};
