import React from 'react';

export interface IIntegrationTranslationContext {
    bookingModeTranslationKey: 'booking' | 'reservation';
    singleCompanyTranslationKey: 'single_company' | 'default';
}

export const IntegrationTranslationContext = React.createContext<IIntegrationTranslationContext>({
    bookingModeTranslationKey: 'booking',
    singleCompanyTranslationKey: 'default',
});

export function useIntegrationTranslation(): IIntegrationTranslationContext {
    const context = React.useContext(IntegrationTranslationContext);

    if (!context) throw new Error('useIntegrationTranslation must be used within a IntegrationTranslationContext');

    return context;
}
