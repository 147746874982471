import { type Region } from '@/config/localization';

export const CENTER_REGION: Record<Region, google.maps.LatLngLiteral> = {
    AT: { lat: 47.5162, lng: 14.5501 },
    DE: { lat: 51.1657, lng: 10.4515 },
    NL: { lat: 52.1326, lng: 5.2913 },
    FR: { lat: 46.2276, lng: 2.2137 },
    BE: { lat: 50.8476, lng: 4.3572 },
    CH: { lat: 46.8182, lng: 8.2275 },
    CZ: { lat: 49.8175, lng: 15.473 },
    GB: { lat: 52.5392, lng: -2.0471 },
    IT: { lat: 43.1618, lng: 12.3657 },
    LI: { lat: 47.141, lng: 9.5209 },
    LU: { lat: 49.8153, lng: 6.1296 },
    PL: { lat: 51.9194, lng: 19.1451 },
    SE: { lat: 59.3289, lng: 18.0551 },
    SK: { lat: 48.669, lng: 19.699 },
};
