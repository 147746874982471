import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContentText, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from '@tanstack/react-router';
import { type TFunction } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import ga4 from 'react-ga4';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useSaveJourney } from '@/api/journey/entity/mutations';
import { ActionButton } from '@/components/elements/action/action-button';
import { TextField } from '@/components/form/fields/text-field';
import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { useSnack } from '@/core/snack/mod';
import { LoginForm } from '@/features/auth';
import { type IJourney } from '@/features/journey-planning';

export interface SaveJourneyModalProps extends BaseModalProps {
    readonly journey: IJourney;
    readonly routeIndex?: number;
    readonly isLoggedIn: boolean;
}

interface StepperButtons {
    nextBtnLabel: string;
    onNextBtnClick: () => void;
    backBtnLabel?: string;
    onBackBtnClick?: () => void;
}

// If the user is not logged in, we also show the login step
const getSteps = (isLoggedIn: boolean, t: TFunction) => {
    const steps = [t('login'), t('search_form.journey.save'), t('journey.finished')];
    if (isLoggedIn) return steps.slice(1);

    return steps;
};

const SaveJourneyModal: React.FC<SaveJourneyModalProps> = modalProps => {
    const { journey, handleClose, isLoggedIn } = modalProps;
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const steps = useMemo(() => getSteps(isLoggedIn, t), [isLoggedIn, t]);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const renderActiveElement = () => {
        switch (activeStep) {
            case 0: {
                return <LoginForm loginCallback={handleNext} />;
            }
            case 1: {
                return (
                    <SaveJourneyForm
                        journey={journey}
                        handleNext={handleNext}
                        renderStepperButtons={renderStepperButtons}
                    />
                );
            }
            case 2: {
                return <SaveJourneyFinished renderStepperButtons={renderStepperButtons} handleClose={handleClose} />;
            }
            default: {
                return <Typography>{t('validations:general.error')}</Typography>;
            }
        }
    };

    const renderActiveElementUserLoggedIn = () => {
        switch (activeStep) {
            case 0: {
                return (
                    <SaveJourneyForm
                        journey={journey}
                        handleNext={handleNext}
                        renderStepperButtons={renderStepperButtons}
                    />
                );
            }
            case 1: {
                return <SaveJourneyFinished renderStepperButtons={renderStepperButtons} handleClose={handleClose} />;
            }
            default: {
                return <Typography>{t('validations:general.error')}</Typography>;
            }
        }
    };

    const renderStepperButtons = ({ nextBtnLabel, onNextBtnClick, backBtnLabel, onBackBtnClick }: StepperButtons) => {
        return (
            <Box sx={{ display: 'flex', pt: 2 }}>
                {backBtnLabel && (
                    <ActionButton
                        category="secondary"
                        disabled={activeStep === 0}
                        sx={{ mr: 1 }}
                        onClick={onBackBtnClick}>
                        {backBtnLabel}
                    </ActionButton>
                )}
                <Box sx={{ flex: '1 1 auto' }} />

                <ActionButton onClick={onNextBtnClick}>{nextBtnLabel}</ActionButton>
            </Box>
        );
    };

    const ModalContent = (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Stepper activeStep={activeStep}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>{isLoggedIn ? renderActiveElementUserLoggedIn() : renderActiveElement()}</Box>
        </Box>
    );

    const augmentedModalProps = { ...modalProps, content: ModalContent, hideActions: true };

    return <BaseModal {...augmentedModalProps} />;
};
export default SaveJourneyModal;

interface SaveJourneyFormProps {
    readonly handleNext: () => void;
    readonly journey: IJourney;
    readonly renderStepperButtons: (options: StepperButtons) => JSX.Element;
}

const SaveJourneyForm: React.FC<SaveJourneyFormProps> = props => {
    const { t } = useTranslation();
    const { mutate: saveJourney, isSuccess } = useSaveJourney();
    const snack = useSnack();
    const { journey, handleNext, renderStepperButtons } = props;

    const onSubmit = (values: { journeyName: string }) => {
        saveJourney(
            { ...journey, name: values.journeyName },
            {
                onSuccess() {
                    ga4.event('misc', {
                        event_category: 'save-journey',
                    });
                    snack.push(t('search_form.journey.save_success'), 'success');
                },
                onError() {
                    snack.push(t('search_form.journey.save_error'), 'error');
                },
            },
        );
    };

    useEffect(() => {
        if (isSuccess) handleNext();
    }, [handleNext, isSuccess]);

    const schema = Yup.object({
        journeyName: Yup.string().required(),
    });

    const formMethods = useForm<{ journeyName: string }>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    return (
        <FormProvider {...formMethods}>
            <form
                noValidate
                onKeyDown={e => {
                    if (e.code === 'Enter') e.preventDefault();
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <DialogContentText>{t('search_form.journey.specify_name')}</DialogContentText>
                    <TextField name="journeyName" label={t('journey.name')} />
                    {renderStepperButtons({
                        nextBtnLabel: t('save'),
                        onNextBtnClick: formMethods.handleSubmit(onSubmit),
                    })}
                </Box>
            </form>
        </FormProvider>
    );
};

interface SaveJourneyFinishedProps {
    readonly handleClose: () => void;
    readonly renderStepperButtons: (options: StepperButtons) => JSX.Element;
}

const SaveJourneyFinished: React.FC<SaveJourneyFinishedProps> = ({ handleClose, renderStepperButtons }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{t('search_form.journey.save_success')}</Typography>
            {renderStepperButtons({
                backBtnLabel: t('close'),
                onBackBtnClick: handleClose,
                nextBtnLabel: t('search_form.journey.to_my_journey'),
                onNextBtnClick() {
                    void navigate({ to: '/account/journeys', replace: true });
                    handleClose();
                },
            })}
        </Box>
    );
};
