import { Link, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { TripCompanyForm } from './trip-company-form';

import { TripCompany } from '@/entity/trip/company/TripCompany';
import { Dialog } from '@/features/ui';
import { type UseDisclosure } from '@/hooks/useDisclosure';

interface TripCompanyDialogProps extends UseDisclosure {
    readonly company: TripCompany;
}

export const TripCompanyDialog: React.FC<TripCompanyDialogProps> = ({ company, ...rest }) => {
    return (
        <Dialog
            {...rest}
            fullWidth
            variant="form"
            maxWidth="md"
            title={t('trip_company.form.title')}
            actions={{
                reset: false,
            }}>
            <Stack gap={3}>
                <Typography variant="body2">
                    <Trans
                        i18nKey="trip_company.form.description"
                        values={{ companyName: company.companyName }}
                        tOptions={{ interpolation: { escapeValue: false } }}
                        components={{
                            anchor: <Link href={TripCompany.buildDetailsUrl(company.companyName)} target="_blank" />,
                        }}
                    />
                </Typography>
                <TripCompanyForm company={company} />
            </Stack>
        </Dialog>
    );
};
