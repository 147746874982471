import { Avatar, useTheme } from '@mui/material';
import { useGoogleMap } from '@react-google-maps/api';
import { type PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { type DefaultStop } from '@/entity/journey/saved/DefaultStop';
import { type BookingStop } from '@/entity/journey/stop/BookingStop';
import { type IPosition, getAvatarType, AvatarIcon } from '@/features/journey-planning';

const SIZE = 38;
const ARROW_SIZE = 9;

interface MapMarkerProps {
    readonly stop: BookingStop | DefaultStop;
    readonly position: IPosition;
}

export default function MapMarker({ position, stop }: MapMarkerProps) {
    const theme = useTheme();

    const latLng = stop.location.toGMapsLatLng();

    return (
        <OverlayView latLng={latLng}>
            <Avatar
                sx={{
                    width: SIZE,
                    height: SIZE,
                    backgroundColor(theme) {
                        return theme.vars.palette.error.main;
                    },
                    overflow: 'visible',
                    ':after': {
                        borderLeft: `${ARROW_SIZE}px solid transparent`,
                        borderRight: `${ARROW_SIZE}px solid transparent`,
                        borderTop: `${ARROW_SIZE}px solid ${theme.vars.palette.error.main}`,
                        position: 'absolute',
                        left: '50%',
                        top: '95%',
                        transform: 'translate(-50%, 0)',
                        content: '""',
                    },
                }}>
                <AvatarIcon type={getAvatarType(position, stop.tripCompany)} />
            </Avatar>
        </OverlayView>
    );
}

interface OverlayProps {
    readonly latLng: google.maps.LatLng | google.maps.LatLngLiteral;
    readonly pane?: keyof google.maps.MapPanes;
}

function OverlayView({ latLng, pane = 'floatPane', children }: PropsWithChildren<OverlayProps>) {
    const map = useGoogleMap();

    const container = useMemo(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        return div;
    }, []);

    const overlay = useMemo(() => {
        return createOverlay(container, pane, latLng);
    }, [container, pane, latLng]);

    useEffect(() => {
        overlay?.setMap(map);
        return () => overlay?.setMap(null);
    }, [map, overlay]);

    // TODO: Fix type issue if I remove the Fragment
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20942
    return <>{createPortal(children, container)}</>;
}

// At build time google.maps is not available yet, so we wrap the the class in a factory function, so this class will be created at runtime
function createOverlay(
    container: HTMLElement,
    pane: keyof google.maps.MapPanes,
    latLng: google.maps.LatLng | google.maps.LatLngLiteral,
) {
    class Overlay extends google.maps.OverlayView {
        container: HTMLElement;
        pane: keyof google.maps.MapPanes;
        latLng: google.maps.LatLng | google.maps.LatLngLiteral;

        constructor(
            container: HTMLElement,
            pane: keyof google.maps.MapPanes,
            latLng: google.maps.LatLng | google.maps.LatLngLiteral,
        ) {
            super();
            this.container = container;
            this.pane = pane;
            this.latLng = latLng;
        }

        onAdd() {
            const pane = this.getPanes()?.[this.pane];
            pane?.append(this.container);
        }

        draw() {
            const projection = this.getProjection();
            const point = projection.fromLatLngToDivPixel(this.latLng);

            if (point === null) return;

            const xTranslate = point.x - SIZE / 2;
            const yTranslate = point.y - SIZE - ARROW_SIZE;

            this.container.style.transform = `translate(${xTranslate}px, ${yTranslate}px)`;
        }

        onRemove() {
            if (this.container.parentNode !== null) {
                this.container.remove();
            }
        }
    }
    return new Overlay(container, pane, latLng);
}
