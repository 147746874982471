import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@tanstack/react-router';
import { cloneDeep } from 'lodash';
import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import ga4 from 'react-ga4';
import {
    type FieldPath,
    FormProvider,
    type UseFieldArrayReturn,
    type UseFormReturn,
    useFieldArray,
    useForm,
    useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCalculateJourney } from '../api/calculate-journey';
import { useResetJourney } from '../api/reset-journey';
import { useSubmitJourney } from '../api/submit-journey';
import { useDefaultJourneyFormValues } from '../hooks/use-default-journey-form-values';
import { type IJourneyPlanningFormContext, JourneyPlanningFormContext } from '../types/context';
import { type IJourney, JourneySchema } from '../types/journey';

import { type ModalStep } from '@/components/modals/JourneyConsiderationsModal';
import { getStorageJourneyToken } from '@/config/storage';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useGoogleGeocoder } from '@/core/google/mod';
import { trackJourney } from '@/core/google/utils/ga-utils';
import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { useModal } from '@/core/modal/mod';
import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';
import { HomePageRoute } from '@/main/routes';

function useJourneySync(formMethods: UseFormReturn<IJourney>) {
    const {
        getValues,
        formState: { isValid, isDirty, isValidating },
    } = formMethods;
    const { mutate: calculateJourney, isPending: isCalculating } = useCalculateJourney();

    // isSuccess from useCalculateJourney is not used here, because that doesn't reset to false when the journey calculation ends
    const [didCalculate, setDidCalculate] = useState(false);

    useEffect(() => {
        if (isValid && isDirty && !isValidating) {
            const values = getValues();

            // Revalidate the values for cases where the form is in an invalid state but we do not show errors yet
            // For example: Adding a new stop
            if (!JourneySchema.isValidSync(values)) return;

            calculateJourney(values, {
                onSuccess() {
                    setDidCalculate(true);
                    setTimeout(() => {
                        setDidCalculate(false);
                    }, 50);
                },
            });
        }
    }, [calculateJourney, getValues, isDirty, isValid, isValidating]);

    return {
        isCalculating,
        didCalculate,
    };
}

function useJourneyMethods(
    formMethods: UseFormReturn<IJourney>,
    defaultValues: IJourney,
    routes: UseFieldArrayReturn<IJourney, 'routes'>,
    stops: UseFieldArrayReturn<IJourney, `routes.${number}.stops`>,
    journey: BookingJourney | null,
) {
    const navigate = useNavigate();
    const ensureCountry = useEnsureCountry();
    const { appSettings } = useAppCtx();
    const { openJourneyConsiderationsModal } = useModal();
    const { getValues, reset, handleSubmit } = formMethods;
    const { fields, remove } = routes;

    const submitJourneyMutation = useSubmitJourney();
    const deleteJourneyMutation = useResetJourney();

    const addReturn = useCallback(() => {
        stops.append({
            location: stops.fields[0].location,
            arrivalDateTime: null,
            departureDateTime: null,
            localBus: null,
            tripCompany: null,
        });
    }, [stops]);

    const submitJourneyAction = useCallback(
        (values: IJourney) => {
            if (!submitJourneyMutation.isIdle) return;
            trackJourney(values);
            submitJourneyMutation.mutate(values, {
                onSuccess(token) {
                    void navigate({ to: '/search', search: { token } });
                },
                onError() {
                    void navigate({ to: '/', search: {} });
                },
            });
        },
        [navigate, submitJourneyMutation],
    );

    const submitJourneyValid = useCallback(
        async (formValues: IJourney) => {
            const values = await ensureCountry(formValues);

            // If we don't have a journey we can assume that something went wrong.
            // We still submit with the provided values from the form.
            if (journey == null) {
                console.warn('Submit Journey - No journey available');
                submitJourneyAction(values);
                return;
            }

            const canSubmit = journey?.isPossible() ?? false;

            const modals: ModalStep[] = [];

            // if local bus and split route -> open modal, to warn the user that we can't split the route
            if (journey.hasLocalBusAndSplitRoute()) modals.push('local_bus_and_split_route');
            // if minimum 1 route is considered for splitting -> open modal, so the user can choose
            // This or the previous modal will be shown, not both
            else if (journey.routes.some(route => route.considerSplit)) modals.push('consider_split_route');
            // If there's a break of 9 hours or more, we ask if the user wants to organize a room for the driver or if the company should handle it.
            if (journey.driverRoomsNeededConsideringSplitRoutes() && !appSettings.booking.disableDriverRoomsChoice)
                modals.push('driver_rooms_choice');
            // If the journey is a return journey but there's no break time between the stops, we display a warning that the journey might make no sense.
            if (journey.isReturnJourneyButNoBreakTime()) modals.push('no_break_warning');

            if (journey.isNotReturnJourney()) modals.push('not_return_journey');

            if (modals.length > 0)
                openJourneyConsiderationsModal({
                    formValues: values,
                    journey,
                    modals,
                    onProceed: async values => submitJourneyAction(values),
                    onAddReturn: addReturn,
                });
            else if (canSubmit) submitJourneyAction(values);
        },
        [
            addReturn,
            appSettings.booking.disableDriverRoomsChoice,
            ensureCountry,
            journey,
            openJourneyConsiderationsModal,
            submitJourneyAction,
        ],
    );

    const submitJourney = handleSubmit(submitJourneyValid, errors => {
        console.error('Submit Journey - Invalid', errors);
    });

    const resetJourney = useCallback(() => {
        const values = getValues();
        const token = values.token ?? getStorageJourneyToken();

        if (token)
            deleteJourneyMutation.mutate(token, {
                onSuccess() {
                    void navigate({ to: '/', search: {} });
                },
            });
        else void navigate({ to: '/', search: {} });

        reset(defaultValues);
        ga4.event('search-form', { event_category: 'clear-form' });
    }, [defaultValues, deleteJourneyMutation, getValues, navigate, reset]);

    const deleteRoute = useCallback(
        (index: number) => {
            if (fields.length === 1) {
                resetJourney();
            } else {
                remove(index);
            }
        },
        [fields.length, remove, resetJourney],
    );

    return {
        resetJourney,
        deleteRoute,
        submitJourney,
    };
}

function useAutofocus(formMethods: UseFormReturn<IJourney>, selectedRouteIndex: number) {
    const name = useAutoFocusTo(selectedRouteIndex);
    const [didFocus, setDidFocus] = useState(false);

    const { setFocus, control } = formMethods;

    const firstLocation = useWatch({
        control,
        name: `routes.${selectedRouteIndex}.stops.0.location`,
        disabled: didFocus,
    });

    const focus = useCallback(
        (name: FieldPath<IJourney>) => {
            setFocus(name);
            setDidFocus(true);
        },
        [setDidFocus, setFocus],
    );

    useEffect(() => {
        if (didFocus) return;
        if (name != null) focus(name);
        else if (firstLocation != null) focus(`routes.${selectedRouteIndex}.stops.0.departureDateTime`);
    }, [didFocus, firstLocation, focus, name, selectedRouteIndex]);
}

function useAutoFocusTo(selectedRouteIndex: number) {
    const { fromAddress, fromLat, fromLng, pax, departureDateTime } = HomePageRoute.useSearch();

    const hasLocation = fromAddress != null && fromLat != null && fromLng != null;
    const hasDateTime = departureDateTime != null;
    const hasPax = pax != null;

    if (hasPax && hasLocation && hasDateTime) return `routes.${selectedRouteIndex}.stops.1.location` as const;
    if (hasPax && hasLocation) return `routes.${selectedRouteIndex}.stops.0.departureDateTime` as const;
    if (hasPax && hasDateTime) return `routes.${selectedRouteIndex}.stops.0.location` as const;
    if (hasLocation && hasDateTime) return `routes.${selectedRouteIndex}.pax` as const;
    if (hasPax) return `routes.${selectedRouteIndex}.stops.0.location` as const;
    return null;
}

function useEnsureCountry() {
    const { getLocationDetails } = useGoogleGeocoder();

    return async (values: IJourney): Promise<IJourney> => {
        const newValues = cloneDeep(values);
        await Promise.all(
            newValues.routes.map(async route => {
                await Promise.all(
                    route.stops.map(async stop => {
                        if (stop.location?.country == null) {
                            const location = await getLocationDetails({ address: stop.location?.address });
                            stop.location = location;
                        }
                    }),
                );
            }),
        );
        return newValues;
    };
}

export const JourneyPlanningFormProvider: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const { journey } = useJourneyPlanning();
    const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);
    const defaultValues = useDefaultJourneyFormValues();

    const formMethods = useForm<IJourney>({
        resolver: yupResolver(JourneySchema, { recursive: true, abortEarly: false, strict: true }),
        defaultValues,
        values: journey?.getFormValues(),
        resetOptions: {
            keepDirty: false,
        },
        mode: 'onBlur',
        reValidateMode: 'onChange',
        // shouldUnregister: true,
    });

    const {
        formState: { isValid, isValidating },
        control,
    } = formMethods;

    const routesArray = useFieldArray({ control, name: 'routes' });
    const stopsArray = useFieldArray({ control, name: `routes.${selectedRouteIndex}.stops` });

    useAutofocus(formMethods, selectedRouteIndex);
    const { isCalculating, didCalculate } = useJourneySync(formMethods);
    const { deleteRoute, submitJourney, resetJourney } = useJourneyMethods(
        formMethods,
        defaultValues,
        routesArray,
        stopsArray,
        journey,
    );

    const resetJourneyDialog = useDisclosure();
    const deleteRouteDialog = useDisclosure();

    const value: IJourneyPlanningFormContext = useMemo(
        () => ({
            formId: 'journey-planning-form',
            formMethods,
            routesArray,
            stopsArray,
            selectedRouteIndex,
            submitJourney,
            isCalculating,
            didCalculate,
            resetJourney(noConfirm?: boolean) {
                setSelectedRouteIndex(0);
                if (noConfirm) resetJourney();
                else resetJourneyDialog.open();
            },
            selectRoute(index) {
                setSelectedRouteIndex(index);
            },
            deleteRoute(index) {
                setSelectedRouteIndex(index > 0 ? index - 1 : 0);
                deleteRouteDialog.open();
            },
            getBookingJourney() {
                if (!isValid || isValidating) return null;
                return journey ?? null;
            },
            getBookingRoute(index) {
                if (!isValid || isValidating) return null;
                return journey?.routes[index] ?? null;
            },
            getBookingStop(position) {
                return journey?.routes[position.routeIndex]?.stops[position.index] ?? null;
            },
        }),
        [
            deleteRouteDialog,
            didCalculate,
            formMethods,
            isCalculating,
            isValid,
            isValidating,
            journey,
            resetJourney,
            resetJourneyDialog,
            routesArray,
            selectedRouteIndex,
            stopsArray,
            submitJourney,
        ],
    );

    return (
        <JourneyPlanningFormContext.Provider value={value}>
            <FormProvider {...formMethods}>{children}</FormProvider>
            <Dialog
                {...resetJourneyDialog}
                fullWidth
                variant="confirm"
                title={t('search_form.route.confirm_delete.title')}
                description={t('search_form.route.confirm_delete.text')}
                actions={{
                    ok: {
                        label: t('delete'),
                        on: resetJourney,
                    },
                }}
            />
            <Dialog
                {...deleteRouteDialog}
                fullWidth
                variant="confirm"
                title={t('search_form.route.confirm_delete.title')}
                description={t('search_form.route.confirm_delete.text')}
                actions={{
                    ok: {
                        label: t('delete'),
                        on() {
                            deleteRoute(selectedRouteIndex);
                        },
                    },
                }}
            />
        </JourneyPlanningFormContext.Provider>
    );
};
