import { FormControl, FormHelperText, InputLabel, type InputBaseComponentProps } from '@mui/material';
import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    type ChangeEvent,
    type FC,
    type FocusEvent,
    type KeyboardEvent,
} from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { FormControlInput } from '@/components/form/form-control-input';
import { makeFieldIds, type FormFieldProps } from '@/components/form/mod';

// Keep this for a later time
// interface Span {
//     value: string;
//     start: number;
//     stop: number;
//     index: number;
// }

// function splitBy(span: Span, delim: string): Span[] {
//     const parts = span.value.split(delim);

//     let start = span.start;

//     const spans: Span[] = [];

//     parts.forEach((value, i) => {
//         const stop = start + value.length;
//         spans.push({
//             value,
//             start,
//             stop,
//             index: span.index + i,
//         });
//         start = stop + delim.length;
//     });

//     return spans;
// }

// function useTextSpans(value: string, delimiters: string[]) {
//     const spans = useMemo(
//         () =>
//             delimiters.reduce(
//                 (spans: Span[], delimiter) => {
//                     return spans.flatMap(span => splitBy(span, delimiter));
//                 },
//                 [{ start: 0, stop: value.length, value, index: 0 }],
//             ),
//         [delimiters, value],
//     );

//     return {
//         spans,
//         getSpan(index?: number | null): Span | null {
//             if (index == null) return null;
//             return spans.find(span => span.start <= index && index <= span.stop) ?? null;
//         },
//     };
// }

function useNumber(initialValue: string | number | null, precision: number, required: boolean) {
    const normalize = useCallback(
        (value: string | number | null) => {
            const number = value === null || value === '' ? Number.NaN : Number(value);
            if (Number.isNaN(number) || !Number.isFinite(number)) return required ? 0 : null;

            return number;
        },
        [required],
    );

    const [value, setValue] = useState(normalize(initialValue));

    return useMemo(
        () => ({
            value,
            stringyValue: value?.toFixed(precision) ?? '',
            setValue(value: string | number | null) {
                const number = normalize(value);
                setValue(number);
                return number;
            },
        }),
        [normalize, precision, value],
    );
}
interface TextFieldProps extends FormFieldProps {
    readonly inputProps?: InputBaseComponentProps;
    readonly precision?: number;
    readonly endAdornment?: React.ReactNode;
    readonly startAdornment?: React.ReactNode;
    readonly propagateOnBlur?: boolean;
    readonly onBlur?: () => void;
}

export const NumberField: FC<TextFieldProps> = ({
    name,
    id,
    label,
    helperText,
    precision = 0,
    inputProps,
    startAdornment,
    endAdornment,
    onBlur,
    fieldRef,
    propagateOnBlur = false,
    ...formControlProps
}) => {
    const { field, fieldState } = useController({ name, defaultValue: 0 });
    const { value, stringyValue, setValue } = useNumber(field.value, precision, formControlProps.required ?? false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const { error } = fieldState;
    const isError = Boolean(error);

    const [uid, helperUid] = makeFieldIds(name, id);

    const changeValue = (value: number | string, propagate: boolean) => {
        const immediateValue = setValue(value);
        if (propagate) field.onChange(immediateValue ?? '');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        changeValue(event.target.value, !propagateOnBlur);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        changeValue(event.target.value, propagateOnBlur);
        onBlur?.();
        field.onBlur();
    };
    const handleClick = () => {
        inputRef.current?.select();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (value === null) return;

        const baseValue = 1 / 10 ** precision;

        switch (event.key) {
            case 'ArrowUp': {
                changeValue(value + baseValue, !propagateOnBlur);
                break;
            }
            case 'ArrowDown': {
                changeValue(value - baseValue, !propagateOnBlur);
                break;
            }

            default:
        }
    };

    return (
        <FormControl fullWidth error={isError} {...formControlProps}>
            {label && (
                <InputLabel shrink={field.value === '' ? undefined : true} htmlFor={uid}>
                    {label}
                </InputLabel>
            )}

            <FormControlInput
                ref={fieldRef}
                inputRef={el => {
                    inputRef.current = el;
                    field.ref(el);
                }}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                value={stringyValue}
                id={uid}
                aria-describedby={helperUid}
                inputProps={inputProps}
                name={name}
                label={label}
                type="text"
                onClick={handleClick}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <FormHelperText id={helperUid}>{isError ? <ErrorMessage error={error} /> : helperText}</FormHelperText>
        </FormControl>
    );
};
