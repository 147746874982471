import slug from 'slug';

import { ENV } from '@/config/env';
import { Document } from '@/entity/basic/Document';
import { fromJsonArray, fromJsonArrayWith } from '@/entity/index';
import { TripAttribute } from '@/entity/trip/attribute/TripAttribute';
import { TripCategory } from '@/entity/trip/category/TripCategory';
import { DefaultTripCompany } from '@/entity/trip/company/DefaultTripCompany';
import { type ITripCompany } from '@/features/journey-planning';

export class TripCompany extends DefaultTripCompany {
    static buildDetailsUrl(name: string): string {
        const slug = this.slugify(name);
        return `${ENV.wordPressUrl}busziel/${slug}`;
    }

    static slugify(text: string): string {
        text = text.replace(/\./g, '-');

        const name = slug(text, { lower: true, trim: true, replacement: '-', locale: 'de' });

        return name;
    }

    public categories: TripCategory[];

    public attributes: TripAttribute[];

    public documents: Document[];

    constructor(json: Record<string, any>) {
        super(json);

        this.categories = fromJsonArray(TripCategory, json.categories);
        this.attributes = fromJsonArray(TripAttribute, json.attributes);
        this.documents = fromJsonArrayWith(Document.fromJson, json.documents);
    }

    public getFormValues(): ITripCompany {
        return {
            id: this.id,
            companyName: this.companyName,
            recommendedDurationOfStayInMinutes: this.recommendedDurationOfStayInMinutes,
        };
    }
}
