import { useDialog } from '../hooks/use-dialog';
import { type DialogVariant, type DialogVariantAction } from '../types';

import { ActionButton } from '@/components/elements/action/action-button';
import { type ActionButtonProps } from '@/components/elements/action/mod';

/**
 * Map of action button props for each dialog variant action
 */
const DialogVariantActionButtonProps: Record<DialogVariantAction, Partial<ActionButtonProps>> = {
    cancel: {
        category: 'secondary',
        intent: 'neutral',
    },
    reset: {
        category: 'secondary',
        intent: 'normal',
    },
    submit: {
        category: 'primary',
        intent: 'normal',
    },
    ok: {
        category: 'primary',
        intent: 'normal',
    },
};

/**
 * Check if action is a form action and can be passed as a button type
 */
function isFormAction<Variant extends DialogVariant>(name: DialogVariantAction<Variant>): name is 'submit' | 'reset' {
    return ['submit', 'reset'].includes(name);
}

export interface DialogActionProps<Variant extends DialogVariant = DialogVariant> {
    readonly name: DialogVariantAction<Variant>;
    readonly label: string;
    readonly on?: () => void;
}

export const DialogAction = <Variant extends DialogVariant>({ name, label, on }: DialogActionProps<Variant>) => {
    const { id, close } = useDialog();

    const handleAction = () => {
        on?.();
        if (['ok', 'cancel'].includes(name)) close();
    };

    return (
        <ActionButton
            onClick={handleAction}
            {...DialogVariantActionButtonProps[name]}
            {...(isFormAction(name) && {
                form: id,
                type: name,
            })}>
            {label}
        </ActionButton>
    );
};
