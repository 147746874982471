import { FormHelperText } from '@mui/material';
import {
    DateTimePicker,
    PickersLayoutContentWrapper,
    PickersLayoutRoot,
    pickersLayoutClasses,
    usePickerLayout,
    renderMultiSectionDigitalClockTimeView,
    type PickersLayoutProps,
} from '@mui/x-date-pickers';
import { type DateTime } from 'luxon';
import { useState, type RefCallback } from 'react';
import { useController, type FieldValues, type Path, type PathValue, type FieldPathByValue } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { DigitalClockSectionHeader } from '@/components/form/fields/time/digital-clock-section-header';
import { makeFieldIds } from '@/components/form/mod';

interface RouteDateTimeFieldProps<T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>> {
    readonly name: P;
    readonly id?: string;
    readonly label?: string;
    readonly visible?: boolean;
    readonly disabled?: boolean;
    readonly minDateTime?: V;
    readonly maxDateTime?: V;
    readonly defaultValue?: V;
    readonly onAccept?: (value: V | null) => void;
    readonly fieldRef?: RefCallback<HTMLElement>;
    readonly deferHookFormPropagation?: boolean;
}

export function RouteDateTimeField<
    T extends FieldValues,
    P extends Path<T> = Path<T>,
    V extends PathValue<T, P> = PathValue<T, P>,
>({
    name,
    id,
    label,
    disabled,
    minDateTime,
    maxDateTime,
    defaultValue,
    onAccept,
    fieldRef,
    visible,
    deferHookFormPropagation,
}: RouteDateTimeFieldProps<T, P, V>) {
    const { field, fieldState } = useController({ name, defaultValue });
    const isError = Boolean(fieldState.error);
    const [uid, helperUid] = makeFieldIds(name, id);

    const [value, setValue] = useState<V | null>(field.value);
    const [open, setOpen] = useState(false);

    const handleChange = (val: V | null) => setValue(val);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAccept = () => {
        if (deferHookFormPropagation !== true) field.onChange(value);
        if (onAccept) onAccept(value);
    };
    const handleBlur = () => {
        if (open) return;
        handleAccept();
        field.onBlur();
    };

    return (
        <>
            <DateTimePicker
                ref={fieldRef}
                disableHighlightToday
                inputRef={field.ref}
                maxDateTime={maxDateTime}
                minDateTime={minDateTime}
                disabled={disabled}
                closeOnSelect={false}
                sx={{ visibility: visible ? 'visible' : 'hidden' }}
                slots={{
                    layout: CustomLayout,
                }}
                slotProps={{
                    textField: {
                        id: uid,
                        name,
                        fullWidth: true,
                        InputLabelProps: {
                            error: isError,
                            htmlFor: uid,
                            sx: {
                                '&.Mui-disabled': {
                                    color: 'currentColor',
                                },
                            },
                        },
                        onBlur: handleBlur,
                    },
                    actionBar: {
                        actions: ['cancel', 'accept'],
                    },
                    popper: {
                        placement: 'right-start',
                    },
                }}
                viewRenderers={{
                    minutes: renderMultiSectionDigitalClockTimeView,
                    hours: renderMultiSectionDigitalClockTimeView,
                }}
                label={label}
                value={value}
                open={open}
                onAccept={handleAccept}
                onChange={handleChange}
                onOpen={handleOpen}
                onClose={handleClose}
            />
            {isError && (
                <FormHelperText sx={{ color: 'error.main', textAlign: 'right' }} id={helperUid}>
                    <ErrorMessage error={fieldState.error} />
                </FormHelperText>
            )}
        </>
    );
}

function CustomLayout<
    T extends FieldValues,
    P extends FieldPathByValue<T, DateTime> = FieldPathByValue<T, DateTime>,
    V extends PathValue<T, P> | null = PathValue<T, P> | null,
>(props: PickersLayoutProps<V, any, any>) {
    const { toolbar, tabs, content, actionBar } = usePickerLayout(props);

    // Put the action bar before the content
    return (
        <PickersLayoutRoot
            className={pickersLayoutClasses.root}
            ownerState={props}
            sx={{
                ul: {
                    '::-webkit-scrollbar': {
                        width: 4,
                    },
                    '::-webkit-scrollbar-thumb'(theme) {
                        return {
                            borderRadius: theme.vars.shape.borderRadius,
                            backgroundColor: theme.vars.palette.grey[400],
                            opacity: 0.4,
                        };
                    },
                },

                '.MuiDateTimePickerToolbar-timeDigitsContainer': {
                    alignItems: 'center',
                },
            }}>
            {toolbar}
            {actionBar}

            <PickersLayoutContentWrapper
                // TODO: Remove grid related stuffs here and gridColumn={3} in DigitalClockSectionHeader when the below bug is fixed.
                // https://github.com/mui/mui-x/issues/12884
                sx={{
                    display: 'grid',
                    gridColumn: '2 / 4',
                    '.MuiMultiSectionDigitalClock-root': {
                        justifyContent: 'center',
                    },
                }}
                className={pickersLayoutClasses.contentWrapper}>
                {tabs}
                {props.wrapperVariant === 'desktop' && <DigitalClockSectionHeader />}
                {content}
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    );
}
