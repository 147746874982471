import { type ObjectSchema, object, string, number } from 'yup';

export interface ITripCompany {
    id: number;
    companyName: string;
    recommendedDurationOfStayInMinutes: number;
}

export const TripCompanySchema: ObjectSchema<ITripCompany> = object({
    id: number().defined(),
    companyName: string().defined(),
    recommendedDurationOfStayInMinutes: number().defined(),
});
