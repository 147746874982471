import { Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NavMenuItem } from '@/components/elements/nav/nav-menu-item';
import { NavAvatar } from '@/components/page/navigation/mobile/nav-avatar';
import { type NavigationItem } from '@/components/page/navigation/useNavigation';
import { FormCell, FormGrid, FormRow } from '@/features/journey-planning';

export interface MobileNavigationProps {
    readonly items: NavigationItem[];
    readonly backItem?: NavigationItem;
    readonly isBookingProgress?: boolean;
}

const COLUMNS = {
    left: '0',
    line: '24px',
    main: '2fr',
    off: '0',
};

export const BookingProgressMenu = ({ items = [], backItem }: MobileNavigationProps) => {
    const { t } = useTranslation();

    return (
        <FormGrid columns={COLUMNS}>
            {backItem && (
                <FormRow>
                    <FormCell gridColumn="line">
                        <Icon
                            fontSize="small"
                            sx={{
                                color: theme => theme.vars.palette.primary.main,
                            }}>
                            {backItem.icon}
                        </Icon>
                    </FormCell>
                    <FormCell gridColumn="main">
                        <NavMenuItem {...backItem}>{t('back_to_home')}</NavMenuItem>
                    </FormCell>
                </FormRow>
            )}

            {items.map((item, i) => {
                const first = i === 0;
                const last = i === items.length - 1;

                return (
                    <FormRow key={item.navigate.to}>
                        <FormCell showLine={[!first, !last]} gridColumn="line">
                            <NavAvatar {...item} />
                        </FormCell>
                        <FormCell gridColumn="main">
                            <NavMenuItem {...item} />
                        </FormCell>
                    </FormRow>
                );
            })}
        </FormGrid>
    );
};
