import { type LinkOptions } from '@tanstack/react-router';

import { BookingStep } from '@/entity/booking-progress/BookingProgress';

interface AppProgressStepOptions {
    name: BookingStep;
    completed: boolean;
    token?: string;
    amendBookingToken?: string;
}
export class AppProgressStep {
    public readonly name: BookingStep;
    public readonly completed: boolean;
    public readonly navigate: LinkOptions;

    constructor({ name, completed, token, amendBookingToken }: AppProgressStepOptions) {
        this.name = name;
        this.completed = completed;
        this.navigate = this.configureNavigate(token, amendBookingToken);
    }

    private configureNavigate(token?: string, _amendBookingToken?: string): LinkOptions {
        switch (this.name) {
            case BookingStep.ROUTE_PLANNING: {
                // return { to: '/', search: amendBookingToken ? { amendBookingToken } : {} };
                return { to: '/', search: {} };
            }
            case BookingStep.BUS_SELECTION: {
                return { to: '/search', search: token ? { token } : {} };
            }
            case BookingStep.CUSTOMER_OFFER: {
                return { to: '/customer-offer', search: token ? { token } : {} };
            }
            case BookingStep.CONTACT_DATA: {
                return { to: '/contact-data', search: {} };
            }
            case BookingStep.SUMMARY: {
                return { to: '/summary', search: {} };
            }
            case BookingStep.BOOKING_CONFIRMATION: {
                return { to: '/booking-confirmation', search: token ? { token } : {} };
            }
            case BookingStep.OFFER_CONFIRMATION: {
                return { to: '/offer-confirmation', search: token ? { token } : {} };
            }
            default: {
                return { to: '/', search: {} };
            }
        }
    }
}
