import { type DateTime } from 'luxon';
import { type ObjectSchema, lazy, object, string } from 'yup';

import { dateTimeSchema } from './date-time';
import { type ILocalBus, localBusSchema } from './local-bus';

import { type ILocation, LocationSchema } from '@/entity/basic/Location';
import { BookingStop } from '@/entity/journey/stop/BookingStop';
import { TripCompanySchema, type ITripCompany } from '@/features/journey-planning';

export interface IStop {
    uuid?: string;
    location: ILocation | null;
    localBus: ILocalBus | null;
    departureDateTime: DateTime | null;
    arrivalDateTime: DateTime | null;
    tripCompany: ITripCompany | null;
}

export const StopSchema = lazy<ObjectSchema<IStop>>((value: IStop) => {
    const [minDistance, maxDistance] = BookingStop.getLocalBusMinAndMaxKm(
        value.departureDateTime,
        value.arrivalDateTime,
    );

    return object({
        uuid: string(),
        location: LocationSchema.defined().nullable().default(null),
        localBus: localBusSchema(minDistance, maxDistance).defined().nullable().default(null),
        departureDateTime: dateTimeSchema.defined().nullable().default(null),
        arrivalDateTime: dateTimeSchema.defined().nullable().default(null),
        tripCompany: TripCompanySchema.defined().nullable().default(null),
    })
        .test({
            name: 'required_location',
            test(stop, ctx) {
                if (stop.location == null)
                    return ctx.createError({
                        message: {
                            key: 'general.required',
                        },
                        path: `${ctx.path}.location`,
                    });

                return true;
            },
        })
        .test({
            name: 'departure_before_arrival',
            test(stop, ctx) {
                const { arrivalDateTime, departureDateTime } = stop;
                if (arrivalDateTime == null || departureDateTime == null) return true;
                if (departureDateTime < arrivalDateTime)
                    return ctx.createError({
                        message: {
                            // t("validations:journey.departure_before_arrival")
                            key: 'journey.departure_before_arrival',
                        },
                        path: `${ctx.path}.departureDateTime`,
                    });

                return true;
            },
        });
});
