import { AppProgressStep } from '@/core/app-progress/AppProgressStep';
import { isAmending } from '@/core/app-progress/mod';
import { type AppUser } from '@/entity/account/Account';
import { DetailsBooking } from '@/entity/booking/DetailsBooking';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { BookingJourney } from '@/entity/journey/BookingJourney';

export type Entity = BookingJourney | DetailsBooking | null;

export class AppProgress {
    static fromAny(entity?: Entity, user?: AppUser | null): AppProgress {
        if (entity instanceof BookingJourney) return AppProgress.fromJourney(entity, user);
        if (entity instanceof DetailsBooking) return AppProgress.fromBooking(entity, user);

        const steps = [
            new AppProgressStep({ name: BookingStep.ROUTE_PLANNING, completed: false }),
            new AppProgressStep({ name: BookingStep.BUS_SELECTION, completed: false }),
            new AppProgressStep({ name: BookingStep.CONTACT_DATA, completed: false }),
            new AppProgressStep({ name: BookingStep.SUMMARY, completed: false }),
        ];

        steps.push(new AppProgressStep({ name: confirmationStep(entity, user), completed: false }));

        return new AppProgress(steps, false, false);
    }

    static fromBooking(booking: DetailsBooking, user?: AppUser | null): AppProgress {
        const bookingToken = booking.token;

        const isOfferAndNotOperator = booking.offerDetails != null && !user?.isOperatorOrDispatcher();

        const steps: AppProgressStep[] = isOfferAndNotOperator
            ? // Offer has been accepted by customer
              [
                  new AppProgressStep({
                      name: BookingStep.CUSTOMER_OFFER,
                      completed: true,
                      token: bookingToken,
                  }),
                  new AppProgressStep({
                      name: BookingStep.CONTACT_DATA,
                      completed: true,
                      token: bookingToken,
                  }),
                  new AppProgressStep({
                      name: BookingStep.SUMMARY,
                      completed: true,
                      token: bookingToken,
                  }),
              ]
            : // Booking or creating offer steps
              [
                  new AppProgressStep({
                      name: BookingStep.ROUTE_PLANNING,
                      completed: true,
                      token: bookingToken,
                  }),
                  new AppProgressStep({
                      name: BookingStep.BUS_SELECTION,
                      completed: true,
                      token: bookingToken,
                  }),
                  new AppProgressStep({
                      name: BookingStep.CONTACT_DATA,
                      completed: true,
                      token: bookingToken,
                  }),
                  new AppProgressStep({
                      name: BookingStep.SUMMARY,
                      completed: true,
                      token: bookingToken,
                  }),
              ];

        steps.push(
            new AppProgressStep({ name: confirmationStep(booking, user), completed: true, token: bookingToken }),
        );

        return new AppProgress(steps, true, isOfferAndNotOperator);
    }

    static fromJourney(journey: BookingJourney, user?: AppUser | null): AppProgress {
        const amendBookingToken = journey?.amendBookingToken;

        const token = (() => {
            if (journey?.offerDetails?.bookingToken) {
                // Used in /search if an operator is amending an offer
                if (user?.isOperatorOrDispatcher()) return journey?.token;
                // Used in /customer-offer step
                return journey?.offerDetails?.bookingToken;
            }

            return journey?.token;
        })();

        // When an operator creates an offer he gets to the offer-confirmation page
        // On there we do fetch a journey instead of a booking like on booking-confirmation
        // Set disabled for the steps according to the overall completion of the process.
        const isComplete = journey.bookingProgress.completed;

        const steps: AppProgressStep[] = journey.bookingProgress.steps.map(
            step =>
                new AppProgressStep({
                    name: step.name,
                    completed: step.completed,
                    token,
                    amendBookingToken,
                }),
        );

        steps.push(
            new AppProgressStep({
                name: confirmationStep(journey, user),
                completed: isComplete,
                token,
                amendBookingToken,
            }),
        );

        return new AppProgress(
            steps,
            isComplete,
            !isComplete &&
                journey.offerDetails != null &&
                !user?.isOperatorOrDispatcher() &&
                journey.amendBookingToken == null,
        );
    }

    public readonly steps: AppProgressStep[];
    public readonly isComplete: boolean;
    public readonly isOfferAndNotOperator: boolean;

    constructor(steps: AppProgressStep[], isComplete: boolean, isOfferAndNotOperator: boolean) {
        this.steps = steps;
        this.isComplete = isComplete;
        this.isOfferAndNotOperator = isOfferAndNotOperator;
    }

    public isStepCompleted(step?: BookingStep): boolean {
        if (step == null) return false;
        return this.steps.find(s => s.name === step)?.completed ?? false;
    }

    public isPreviousStepCompleted(step?: BookingStep): boolean {
        if (step == null) return false;
        const idx = this.steps.findIndex(s => s.name === step);
        if (idx === -1) return false;
        return this.steps[idx - 1]?.completed ?? true;
    }

    public getBestAvailableStep(): AppProgressStep {
        return this.steps.reduce(
            (best, step) => (this.isPreviousStepCompleted(step.name) ? step : best),
            this.steps[0],
        );
    }

    public getStep(step?: BookingStep): AppProgressStep | undefined {
        if (step == null) return undefined;
        return this.steps.find(s => s.name === step);
    }
}

function confirmationStep(entity?: Entity, user?: AppUser | null): BookingStep {
    if (user?.isOperatorOrDispatcher()) {
        if (isAmending('offer', entity)) return BookingStep.OFFER_CONFIRMATION;
        if (isAmending('booking', entity)) return BookingStep.BOOKING_CONFIRMATION;
        return BookingStep.OFFER_CONFIRMATION;
    }
    return BookingStep.BOOKING_CONFIRMATION;
}
