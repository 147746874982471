import { type TooltipProps, Tooltip, tooltipClasses, styled } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.vars.palette.common.white,
        color: theme.vars.palette.grey[600],
        boxShadow: theme.vars.shadows[4],
        fontSize: theme.typography.body3.fontSize,
        padding: 30,
    },
}));
