import { boolean, string, type ObjectSchema } from 'yup';

import { contactDataSchema, customerGroupSchema, type IContactData, type ICustomerGroupValues } from '@/utils/account';

export interface IRegistration extends ICustomerGroupValues {
    readonly contactData: IContactData;
    readonly email: string;
    readonly password: string;
    readonly passwordConfirmation: string;
    readonly agb: boolean;
}

export const registrationSchema: ObjectSchema<IRegistration> = customerGroupSchema.shape({
    contactData: contactDataSchema,
    email: string().email().required(),
    password: string().required().min(3).max(30),
    passwordConfirmation: string().required().matchWith('password', { key: 'password.no_match' }),
    agb: boolean().defined().oneOf([true], { key: 'general.required' }),
});
