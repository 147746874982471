import { useMutation } from '@tanstack/react-query';

import callApi from '@/api';

/*
 * Api call to request a password reset link
 */
async function postRequestPasswordReset(email: string) {
    await callApi('public/send-password-reset-link', {
        method: 'POST',
        data: { email },
    });
}

export function usePwResetRequest() {
    return useMutation({ mutationFn: postRequestPasswordReset });
}
