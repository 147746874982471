import FlagIcon from '@mui/icons-material/Flag';
import LocationOn from '@mui/icons-material/LocationOn';
import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState, type FC } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { FormControlInput } from '@/components/form/form-control-input';
import { makeFieldIds, type FormFieldProps } from '@/components/form/mod';
import { useAutocompletePrediction, useGoogleGeocoder, type AutoCompleteSuggestion } from '@/core/google/mod';
import { TripCompanySearchForm } from '@/entity/trip/company/TripCompanySearchForm';

interface LocationFieldProps {
    readonly onAccept?: (v: AutoCompleteSuggestion | null) => void;
    readonly includeTripCompanies?: boolean;
}

export const LocationField: FC<FormFieldProps & LocationFieldProps> = ({
    name,
    id,
    label,
    disabled,
    fieldRef,
    helperText,
    onAccept,
    includeTripCompanies = false,
    ...formControlProps
}) => {
    const { getLocationDetails } = useGoogleGeocoder();
    const { predictions, updatePredictions } = useAutocompletePrediction();

    const { control } = useFormContext();
    const { field, fieldState } = useController({ name });
    const { error } = fieldState;

    const isError = Boolean(error);
    const [uid, helperUid] = makeFieldIds(name, id);

    // Display the trip company name if it's a trip company else the address
    const company = useWatch({ control, name: name.replace('location', 'tripCompany') });
    const fieldValue = company?.companyName ?? field.value?.address ?? '';

    const [inputValue, setInputValue] = useState(fieldValue);

    // Handler when typing in text box
    const handleInputChange = async (input: string) => {
        setInputValue(input);
        if (input !== '') updatePredictions({ input }, includeTripCompanies);
    };

    // Handler when accepting an option
    const handleChange = async (value: AutoCompleteSuggestion | null) => {
        if (value == null) {
            field.onChange(null);
            return;
        }

        // If it's a trip company use its location otherwise fetch the location details
        const location =
            value instanceof TripCompanySearchForm
                ? value.location
                : await getLocationDetails({ placeId: value.place_id });

        // Early abort if the new location is the same as the old one
        if (location.equals(field.value)) return;

        field.onChange(location);
        onAccept?.(value);
    };

    const handleBlur = () => {
        if (inputValue === '') {
            field.onChange(null);
        }
        field.onBlur();
    };

    return (
        <Autocomplete
            autoHighlight
            freeSolo
            autoComplete
            filterSelectedOptions
            includeInputInList
            id={uid}
            value={fieldValue}
            inputValue={inputValue}
            disabled={disabled}
            options={predictions}
            getOptionLabel={option => {
                if (typeof option === 'string') return option;
                if (option instanceof TripCompanySearchForm) return option.companyName;
                return option.description;
            }}
            isOptionEqualToValue={(o, v) => {
                if (o instanceof TripCompanySearchForm && v instanceof TripCompanySearchForm) return o.id === v.id;
                if (typeof o === 'string' && typeof v === 'string') return o === v;
                return false;
            }}
            renderInput={({ inputProps, InputProps, InputLabelProps }) => {
                return (
                    <FormControl fullWidth error={isError} {...formControlProps}>
                        <InputLabel htmlFor={uid} {...InputLabelProps} shrink={inputValue === '' ? undefined : true}>
                            {label}
                        </InputLabel>
                        <Box ref={InputProps.ref}>
                            <FormControlInput
                                name={name}
                                disabled={disabled}
                                fullWidth={formControlProps.fullWidth}
                                inputProps={inputProps}
                                {...InputProps}
                                ref={fieldRef}
                                id={uid}
                                aria-describedby={helperUid}
                                label={label}
                                inputRef={field.ref}
                            />
                        </Box>
                        {(isError || helperText) && (
                            <FormHelperText id={helperUid}>
                                {isError ? <ErrorMessage error={error} /> : helperText}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
            renderOption={(props, option: AutoCompleteSuggestion) => {
                const renderOption = (parts: ReturnType<typeof parse>, secondary: string, icon: React.ReactNode) => (
                    <ListItemButton data-cy="autocomplete-result" component="li" {...props}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText
                            primary={parts.map((part, i) => (
                                <Box key={i} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                                    {part.text}
                                </Box>
                            ))}
                            secondary={secondary}
                        />
                    </ListItemButton>
                );

                if (option instanceof TripCompanySearchForm) {
                    const parts = parse(
                        option.companyName,
                        match(option.companyName, inputValue, { insideWords: true, findAllOccurrences: true }),
                    );

                    return renderOption(parts, option.location.address, <FlagIcon />);
                }

                const matches = option.structured_formatting.main_text_matched_substrings || [];
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map(match => [match.offset, match.offset + match.length]),
                );

                return renderOption(parts, option.structured_formatting.secondary_text, <LocationOn />);
            }}
            filterOptions={x => x}
            onChange={(_, value) => {
                if (value != null && typeof value !== 'string') void handleChange(value);
            }}
            onBlur={handleBlur}
            onInputChange={(_, value: string) => {
                void handleInputChange(value);
            }}
        />
    );
};
