import callApi from '@/api';
import { getStorageJourneyToken, removeStorageJourneyToken, setStorageJourneyToken } from '@/config/storage';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { BookingJourney } from '@/entity/journey/BookingJourney';
import { BookingOfferJourney } from '@/entity/journey/BookingOfferJourney';

export interface GetBookingJourneyParams {
    token?: string;
    amendBookingToken?: string;
    step?: BookingStep;
    routeUuid?: string;
    jSessionId?: string;
}

/**
 * Api Call for the journey. It will return a {@link BookingJourney}.
 * It will call the correct endpoint depending on the given step.
 */
export async function getBookingJourney(params: GetBookingJourneyParams) {
    if (!params.step) throw new Error('step is required');

    if (params.step === BookingStep.ROUTE_PLANNING)
        return getBookingJourneySearchForm({ token: params.token, amendBookingToken: params.amendBookingToken });

    if (!params.token) throw new Error('token is required');

    if (params.step === BookingStep.BUS_SELECTION)
        return getBookingJourneySearchResults({ token: params.token, routeUuid: params.routeUuid });

    if (params.step === BookingStep.CONTACT_DATA || params.step === BookingStep.SUMMARY)
        return getBookingJourneyBooking({ token: params.token, jSessionId: params.jSessionId });

    if (params.step === BookingStep.CUSTOMER_OFFER || params.step === BookingStep.OFFER_CONFIRMATION)
        return getBookingJourneyForOffer({ token: params.token, step: params.step });

    throw new Error(`step ${params.step} is not supported`);
}

interface GetBookingJourneySearchResultsParams {
    token: string;
    routeUuid?: string;
}

async function getBookingJourneySearchResults(params: GetBookingJourneySearchResultsParams) {
    const { data } = await callApi(`public/findSearchResults`, {
        params,
    });
    setStorageJourneyToken(data.token);
    return new BookingJourney(data);
}

export interface GetBookingJourneySearchFormParams {
    token?: string;
    amendBookingToken?: string;
}

export async function getBookingJourneySearchForm(params: GetBookingJourneySearchFormParams) {
    // Attempt to use token from session storage
    const token = params.token ?? getStorageJourneyToken();

    if (!token && !params.amendBookingToken) return null;

    try {
        const { data } = await callApi(`public/searchFormJourney`, {
            params: {
                token,
                amendBookingToken: params.amendBookingToken,
            },
        });
        const journey = new BookingJourney(data);
        setStorageJourneyToken(journey.token);
        return journey;
    } catch (error) {
        // remove journey token from session if not returned from api
        removeStorageJourneyToken();
        throw error;
    }
}
interface GetBookingJourneyBookingParams {
    token: string;
    jSessionId?: string;
}

async function getBookingJourneyBooking(params: GetBookingJourneyBookingParams) {
    try {
        const { data } = await callApi(`public/bookingJourney`, {
            params,
        });
        const journey = new BookingJourney(data);
        setStorageJourneyToken(journey.token);
        return journey;
    } catch (error) {
        // remove journey token from session if not returned from api
        removeStorageJourneyToken();
        throw error;
    }
}

interface GetBookingJourneyForOfferParams {
    token: string;
    step: BookingStep;
}

async function getBookingJourneyForOffer(params: GetBookingJourneyForOfferParams) {
    try {
        const { data } = await callApi(`public/bookingOfferJourney`, {
            params: {
                token: params.token,
            },
        });
        const journey = new BookingOfferJourney(data);
        // Only set the token in session when not on offer confirmation
        if (params.step !== BookingStep.OFFER_CONFIRMATION) setStorageJourneyToken(journey.token);
        return journey;
    } catch (error) {
        // remove journey token from session if not returned from api
        removeStorageJourneyToken();
        throw error;
    }
}
