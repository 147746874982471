import { Stack } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { type MouseEvent, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { JOURNEY_BUS_TOGGLE } from '@/api/journey/booking/journey-booking-queries';
import { ActionButton } from '@/components/elements/action/action-button';
import { useAuth } from '@/core/auth/mod';
import { type Money } from '@/entity/basic/Money';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingRoute } from '@/entity/journey/route/BookingRoute';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { useDetermineBusSelectability } from '@/features/bus-selection';
import { PaymentSelector, PriceOverride } from '@/features/payment-selection';
import { type AddBusProps } from '@/hooks/useToggleBusRoute';

interface BusCardActionsProps {
    readonly result: BookingSearchResult;
    readonly route?: BookingRoute;
    readonly forcedPriceOption?: PriceOption;
    readonly onDetails?: (result: BookingSearchResult) => void;
    readonly onSelect?: (props: AddBusProps) => void;
}

export const BusCardActions: FC<BusCardActionsProps> = ({ result, route, forcedPriceOption, onDetails, onSelect }) => {
    const { t } = useTranslation();
    const isLoading = Boolean(useIsMutating({ mutationKey: [JOURNEY_BUS_TOGGLE] }));
    const { user } = useAuth();
    const determine = useDetermineBusSelectability();

    const handleSelect = async (priceOption: PriceOption) => onSelect?.({ result, priceOptionId: priceOption.id });
    const handleOverride = async (overridePrice: Money) => onSelect?.({ result, operatorPrice: overridePrice });

    const handleDetails = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDetails?.(result);
    };

    const { isSelectable, nonSelectableReason } = determine(result);

    return (
        <Stack direction="row" spacing={2}>
            {onDetails && (
                <ActionButton
                    fullWidth
                    gaEvent={{ action: 'misc', category: 'bus-details' }}
                    category="tertiary"
                    data-cy="bus-details-button"
                    onClick={handleDetails}>
                    {t('details')}
                </ActionButton>
            )}

            {onSelect && user?.isOperatorOrDispatcher() && (
                <PriceOverride
                    result={result}
                    pax={route?.pax ?? 1}
                    label={t('select_bus')}
                    disabled={!isSelectable}
                    loading={isLoading}
                    disabledTooltip={nonSelectableReason}
                    onOverride={handleOverride}
                />
            )}
            {onSelect && !user?.isOperatorOrDispatcher() && (
                <PaymentSelector
                    result={result}
                    forcedPriceOption={forcedPriceOption}
                    pax={route?.pax ?? 1}
                    label={t('select_bus')}
                    disabled={!isSelectable}
                    loading={isLoading}
                    disabledTooltip={nonSelectableReason}
                    onSelect={handleSelect}
                />
            )}
        </Stack>
    );
};
