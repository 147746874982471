import { type AppUser } from '@/entity/account/Account';
import { Admin } from '@/entity/account/admin/Admin';
import { SuperAdmin } from '@/entity/account/admin/SuperAdmin';
import { Customer } from '@/entity/account/customer/Customer';
import { Dispatcher } from '@/entity/account/dispatcher/Dispatcher';
import { Operator } from '@/entity/account/operator/Operator';
import { UserRole } from '@/entity/common-constants';

export const createAccountFromJson = (json: any): AppUser => {
    switch (json.role) {
        case UserRole.CUSTOMER: {
            return new Customer(json);
        }
        case UserRole.DISPATCHER: {
            return new Dispatcher(json);
        }
        case UserRole.OPERATOR: {
            return new Operator(json);
        }
        case UserRole.ADMIN: {
            return new Admin(json);
        }
        case UserRole.SUPER_ADMIN: {
            return new SuperAdmin(json);
        }
        default: {
            throw new Error(`Unknown Account Role ${json.role}`);
        }
    }
};
