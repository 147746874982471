import { type UseQueryResult } from '@tanstack/react-query';
import { createContext, useContext, useEffect } from 'react';

import { AppProgress, type Entity } from '@/core/app-progress/AppProgress';
import { AppProgressStep } from '@/core/app-progress/AppProgressStep';
import { DetailsBooking } from '@/entity/booking/DetailsBooking';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { OfferStatus } from '@/entity/common-constants';
import { BookingJourney } from '@/entity/journey/BookingJourney';

export interface IAppProgressContext {
    progress: AppProgress;
    currentStep?: BookingStep;
    isCurrentStepCompleted: boolean;
    isPrint: boolean;
    getPreviousStep(): AppProgressStep;
    getNextStep(): AppProgressStep;
    update(journeyOrBooking: BookingJourney | DetailsBooking | null): void;
}

export const AppProgressContext = createContext<IAppProgressContext>({
    progress: AppProgress.fromAny(null),
    currentStep: BookingStep.ROUTE_PLANNING,
    isCurrentStepCompleted: false,
    isPrint: false,
    getPreviousStep() {
        return new AppProgressStep({ name: BookingStep.ROUTE_PLANNING, completed: false });
    },
    getNextStep() {
        return new AppProgressStep({ name: BookingStep.ROUTE_PLANNING, completed: false });
    },
    update() {},
});

export function useAppProgress() {
    const context = useContext(AppProgressContext);

    return context;
}

export function useUpdateAppProgress({ data, isFetched }: UseQueryResult<BookingJourney | DetailsBooking | null>) {
    const { update } = useContext(AppProgressContext);

    useEffect(() => {
        if (isFetched && data) update(data);
    }, [update, isFetched, data]);
}

// We view the offer as a booking if it's already accepted by either the customer or the operator
export function isAmending(name: 'offer' | 'booking', entity?: Entity): boolean {
    const hasOfferDetails = entity?.offerDetails != null;
    const isOfferAccepted = entity?.offerDetails?.offerStatus === OfferStatus.ACCEPTED;

    const condition = (() => {
        if (name === 'offer') return hasOfferDetails && !isOfferAccepted;
        return !hasOfferDetails || isOfferAccepted;
    })();

    return (
        condition &&
        ((entity instanceof BookingJourney && entity.amendBookingToken != null) ||
            (entity instanceof DetailsBooking && entity.amendedAt.isValid))
    );
}
