import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
    downloadCustomerJourneySummaryPDF,
    downloadOfferComparisonPDF,
    downloadOperatorOfferPDF,
    getReviews,
    saveOperatorOffer,
    sendResellerOffer,
    toggleBusRoute,
} from '@/api/journey/booking/journey-booking-requests';
import { removeStorageJourneyToken } from '@/config/storage';

/** **************************************************************************************
 * React Queries for Journey Planning Step (1)
 ************************************************************************************** */

export const JOURNEY_BOOKING_QUERY_KEY = 'journey-booking';
export const JOURNEY_BUS_PRICE_LOG = 'journey-bus-price-log';

export const JOURNEY_CUSTOMER_JOURNEY_SUMMARY_PDF = 'journey-customer-journey-summary-pdf';
export const JOURNEY_OPERATOR_OFFER_PDF = 'journey-operator-offer-pdf';
export const JOURNEY_OFFER_COMPARISON_PDF = 'journey-offer-comparison-pdf';

export const JOURNEY_BUS_TOGGLE = 'journey-bus-toggle';

/** **************************************************************************************
 * React Queries for Bus Selection Step (2)
 ************************************************************************************** */

export const useMutateToggleBusRoute = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: toggleBusRoute,
        mutationKey: [JOURNEY_BUS_TOGGLE],
        onSuccess(journey) {
            queryClient.setQueriesData({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] }, journey);
        },
    });
};

export const useReviews = (companyId: number) => {
    return useQuery({
        queryKey: ['review-scores', companyId],
        queryFn: async () => getReviews(companyId),
        // 5 minutes
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });
};

/** **************************************************************************************
 * React Queries for Summary Step (4)
 ************************************************************************************** */

/**
 * Send the reseller offer to the customer
 */
export const useSendResellerOffer = () => {
    return useMutation({
        mutationFn: sendResellerOffer,
    });
};

/**
 * Save an operator offer to in operating tool
 */
export const useSaveOperatorOffer = () => {
    return useMutation({
        mutationFn: saveOperatorOffer,
    });
};

export const useDownloadCustomerJourneySummaryPDF = (token?: string) => {
    return useQuery({
        queryKey: [JOURNEY_CUSTOMER_JOURNEY_SUMMARY_PDF, token],
        queryFn: async () => downloadCustomerJourneySummaryPDF(token),
        enabled: false,
    });
};

export const useDownloadOperatorOfferPDF = (token?: string) => {
    return useQuery({
        queryKey: [JOURNEY_OPERATOR_OFFER_PDF, token],
        queryFn: async () => downloadOperatorOfferPDF(token),
        enabled: false,
    });
};

export const useDownloadOfferComparisonPDF = (token?: string) => {
    return useQuery({
        queryKey: [JOURNEY_OFFER_COMPARISON_PDF, token],
        queryFn: async () => downloadOfferComparisonPDF(token),
        enabled: false,
    });
};

/** **************************************************************************************
 * React Queries for all Journey Steps
 ************************************************************************************** */

export const useDeleteAllJourneyQueries = () => {
    const queryClient = useQueryClient();

    return () => {
        removeStorageJourneyToken();
        queryClient.removeQueries({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] });
    };
};
