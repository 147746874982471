import { Box, Card, Skeleton, Stack, Typography, styled } from '@mui/material';

import { nTimes } from '@/utils/helpers';

const ListGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridTemplateRows: 'max-content max-content max-content ',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
}));

export const ListSkeleton = () => {
    return (
        <ListGrid>
            {nTimes(9).map(i => (
                <Card key={i} sx={{ height: 300 }}>
                    <Stack gap={0.5}>
                        <Skeleton variant="rounded" width={250} height={140} />

                        <Stack gap={1.5} p={1.5}>
                            <Typography variant="body2">
                                <Skeleton width={220} />
                            </Typography>
                            <Typography variant="body2">
                                <Skeleton width={150} />
                            </Typography>
                            <Typography variant="body2">
                                <Skeleton width={150} />
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            ))}
        </ListGrid>
    );
};
