import { createFileRoute } from '@tanstack/react-router';
import { type InferType, object, string } from 'yup';

const R_PRINT_BOOKING_SUMMARY_SEARCH_PARAMS = object({
    token: string().when('jSessionId', {
        is: (jSessionId?: string) => jSessionId == null || jSessionId.length <= 0,
        then: schema => schema.required(),
    }),
    jSessionId: string(),
    variant: string().oneOf(['customer', 'reseller']),
});

export const Route = createFileRoute('/print/summary')({
    validateSearch(search): InferType<typeof R_PRINT_BOOKING_SUMMARY_SEARCH_PARAMS> {
        return R_PRINT_BOOKING_SUMMARY_SEARCH_PARAMS.cast(search);
    },
});

export { Route as PrintSummaryRoute };
