import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { useSnack } from '@/core/snack/mod';
import { type TripCompany } from '@/entity/trip/company/TripCompany';
import {
    FormGrid,
    FormRow,
    FormCell,
    StopLocation,
    FormAvatar,
    defaultJourneyFormValues,
    JourneySchema,
    useCalculateJourney,
    type IJourney,
} from '@/features/journey-planning';
import { useDialog } from '@/features/ui';

const COLUMNS = {
    left: '0',
    line: '40px',
    main: '2fr',
    off: '0',
};

interface TripCompanyFormProps {
    readonly company: TripCompany;
}

export const TripCompanyForm: FC<TripCompanyFormProps> = ({ company }) => {
    const calculateJourney = useCalculateJourney();
    const queryClient = useQueryClient();

    const { id } = useDialog();
    const navigate = useNavigate();
    const snack = useSnack();
    const { t } = useTranslation();

    const formMethods = useForm({
        resolver: yupResolver(JourneySchema, { recursive: true, abortEarly: false, strict: true }),
        defaultValues: defaultJourneyFormValues(undefined, undefined, {
            location: company.location,
            tripCompany: company.getFormValues(),
        }),
        mode: 'onBlur',
    });

    const onSubmit = async (values: IJourney) => {
        if (!calculateJourney.isIdle) return;

        try {
            await calculateJourney.mutateAsync(values);
            // I am not sure why, but this is necessary to properly fill out the search form after the redirect
            queryClient.removeQueries({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] });
            void navigate({ to: '/' });
        } catch (error) {
            console.log('error:', error);
            snack.push(t('validations:general.error'), 'error');
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form noValidate id={id} onSubmit={formMethods.handleSubmit(onSubmit)}>
                <FormGrid columns={COLUMNS}>
                    <FormRow>
                        <FormCell showLine={[false, true]} gridColumn="line">
                            <FormAvatar type="start location" color="primary" isDraggable={false} />
                        </FormCell>

                        <FormCell gridColumn="main">
                            <StopLocation position={getPosition('first')} />
                        </FormCell>
                    </FormRow>
                </FormGrid>

                <FormGrid columns={COLUMNS}>
                    <FormRow>
                        <FormCell showLine minHeight={4} gridColumn="line" />
                    </FormRow>
                </FormGrid>

                <FormGrid columns={COLUMNS}>
                    <FormRow>
                        <FormCell showLine={[true, false]} gridColumn="line">
                            <FormAvatar type="trip company" color="primary" isDraggable={false} />
                        </FormCell>

                        <FormCell gridColumn="main">
                            <StopLocation position={getPosition('last')} />
                        </FormCell>
                    </FormRow>
                </FormGrid>
            </form>
        </FormProvider>
    );
};

const getPosition = (position: 'first' | 'last') => {
    const isFirst = position === 'first';
    return {
        routeIndex: 0,
        index: isFirst ? 0 : 1,
        first: isFirst,
        last: !isFirst,
    };
};
