import { DialogActions as MuiDialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DialogVariantActions, type DialogVariant, type DialogVariantAction } from '../types';

import { DialogAction, type DialogActionProps } from './DialogAction';

type AnyAction<Variant extends DialogVariant = DialogVariant> =
    | false
    | (() => void)
    | Pick<DialogActionProps<Variant>, 'label' | 'on'>;

export interface DialogActionsProps<Variant extends DialogVariant = DialogVariant> {
    readonly actions?: PartialRecord<DialogVariantAction<Variant>, AnyAction<Variant>>;
    readonly variant: Variant;
    readonly disableTrivialClose?: boolean;
}

export const DialogActions = <Variant extends DialogVariant>({
    actions = {},
    variant,
    disableTrivialClose,
}: DialogActionsProps<Variant>) => {
    const { t } = useTranslation();

    // disableTrivialClose will hide the cancel button for variants that have it
    const actionVariants = DialogVariantActions[variant].filter(name => !(disableTrivialClose && name === 'cancel'));

    return (
        <MuiDialogActions>
            {actionVariants.map(name => {
                const action = (actions as PartialRecord<DialogVariantAction, AnyAction>)[name];

                if (action === false) return null;

                const label = typeof action === 'object' ? action.label : t(`ui.dialog.${variant}.actions.${name}`);

                return (
                    <DialogAction
                        key={name}
                        name={name}
                        label={label}
                        on={typeof action === 'function' ? action : action?.on}
                    />
                );
            })}
        </MuiDialogActions>
    );
};
