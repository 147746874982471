import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { Button, IconButton, MenuItem } from '@mui/material';
import { useRouter } from '@tanstack/react-router';
import uniqueId from 'lodash/uniqueId';
import { useState, type FC } from 'react';
import ga4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { NavButton } from '@/components/elements/nav/nav-button';
import { NavMenuItem } from '@/components/elements/nav/nav-menu-item';
import { useLayout } from '@/components/page/layout/mod';
import { NavMenu } from '@/components/page/navigation/mobile/nav-menu';
import { IS_DEVELOPMENT } from '@/config/env';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import { useAuth } from '@/core/auth/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';

export const AccountActions: FC = () => {
    const { user } = useAuth();
    const { isDesktop } = useLayout();
    const { appSettings } = useAppCtx();

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event?.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    if (appSettings.authentication.disableAuthentication) return null;

    if (isDesktop)
        return (
            <>
                {/* Dev role indicator */}
                {IS_DEVELOPMENT && user && (
                    <Button disabled style={{ color: user.getRoleColor() }}>
                        {user.getRoleName()}
                    </Button>
                )}
                {/* Registration / Profile */}
                <NavButton navigate={{ to: user ? '/account' : '/registration', search: {} }} />
                {/* Login / Logout */}
                <LoginLogoutBtn />
            </>
        );

    const id = uniqueId('menu');

    return (
        <>
            <IconButton aria-haspopup color="inherit" aria-controls={id} onClick={handleOpen}>
                <AccountCircleOutlined />
            </IconButton>
            <NavMenu keepMounted anchorEl={menuAnchor} id={id} open={Boolean(menuAnchor)} onClose={handleClose}>
                {/* Login / Logout */}
                <LoginLogoutBtn asMenuItem />
                {/* Registration / Profile */}
                <NavMenuItem navigate={{ to: user ? '/account' : '/registration', search: {} }} />
            </NavMenu>
        </>
    );
};

interface LoginLogoutBtnProps {
    readonly asMenuItem?: boolean;
}

export const LoginLogoutBtn: FC<LoginLogoutBtnProps> = ({ asMenuItem }) => {
    const { user, logout } = useAuth();
    const router = useRouter();
    const { t } = useTranslation();
    const { currentStep } = useAppProgress();

    const redirectBack = currentStep
        ? [
              BookingStep.BUS_SELECTION,
              BookingStep.CONTACT_DATA,
              BookingStep.SUMMARY,
              BookingStep.CUSTOMER_OFFER,
              BookingStep.OFFER_CONFIRMATION,
          ].includes(currentStep)
        : false;

    const handleLogout = () => {
        ga4.event('auth', { event_category: 'logout' });
        void logout({
            navigate: {
                to: '/login',
                search: { dl: true, rhref: redirectBack ? router.state.location.href : undefined },
            },
        });
    };

    if (user)
        return asMenuItem ? (
            <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
        ) : (
            <Button onClick={handleLogout}>{t('logout')}</Button>
        );

    return asMenuItem ? (
        <NavMenuItem navigate={{ to: '/login', search: redirectBack ? { rhref: router.state.location.href } : {} }} />
    ) : (
        <NavButton navigate={{ to: '/login', search: redirectBack ? { rhref: router.state.location.href } : {} }} />
    );
};
