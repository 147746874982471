import { Box, type BoxProps } from '@mui/material';
import { type CSSProperties } from 'react';

interface ImageProps extends BoxProps<'img'> {
    fit?: CSSProperties['objectFit'];
    wrapperProps?: BoxProps;
}

export const Image: React.FC<ImageProps> = ({ fit, wrapperProps, ...props }) => {
    return (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" {...wrapperProps}>
            <Box
                component="img"
                width="100%"
                height="100%"
                sx={{
                    objectFit: fit,
                    borderRadius(theme) {
                        return theme.vars.shape.borderRadius;
                    },
                }}
                {...props}
            />
        </Box>
    );
};
