import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { AccordionDetails, Box, IconButton } from '@mui/material';

import { RouteForm } from './route-form';

import { PageAccordion, PageAccordionSummary } from '@/components/elements/page-accordion/page-accordion';
import { RouteMainTitle } from '@/components/elements/route/route-main-title';
import { useJourneyPlanningForm } from '@/features/journey-planning';

export const RoutesArray: React.FC = () => {
    const {
        routesArray: { fields },
        selectedRouteIndex,
        selectRoute,
        deleteRoute,
    } = useJourneyPlanningForm();

    const expandable = fields.length > 1;

    return (
        <div role="presentation">
            {fields.map((route, routeIndex) => {
                const isSelected = selectedRouteIndex === routeIndex;

                const handleSelectRoute = () => selectRoute(routeIndex);
                const handleDeleteRoute = () => deleteRoute(routeIndex);

                return (
                    <PageAccordion
                        key={route.id}
                        expandable={expandable}
                        expanded={isSelected}
                        onChange={handleSelectRoute}>
                        <PageAccordionSummary expandable={expandable}>
                            <Box display="flex" alignItems="center" gap={1.5}>
                                <RouteMainTitle
                                    routeIndex={routeIndex}
                                    stops={route.stops}
                                    color={isSelected ? 'primary.main' : 'inherit'}
                                />
                                <IconButton onClick={handleDeleteRoute}>
                                    <DeleteOutlined fontSize="small" />
                                </IconButton>
                            </Box>
                        </PageAccordionSummary>
                        <AccordionDetails>
                            <RouteForm routeIndex={routeIndex} />
                        </AccordionDetails>
                    </PageAccordion>
                );
            })}
        </div>
    );
};
