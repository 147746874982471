import { Entity } from '@/entity/A_Entity';

export type TripCategoryGroup = 'ACCOMMODATION' | 'FOOD' | 'ACTIVITY';
// t('trip_company.filter.ACCOMMODATION')
// t('trip_company.filter.FOOD')
// t('trip_company.filter.ACTIVITY')
export class TripCategory extends Entity {
    public name: string;

    public visible: boolean;

    public group?: TripCategoryGroup;

    constructor(json: Record<string, any>) {
        super(json);

        this.name = json.name;
        this.visible = json.visible;
        this.group = json.group === '' ? undefined : json.group;
    }

    public toString(): string {
        return this.name;
    }
}
