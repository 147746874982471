import { type FC } from 'react';

import { Gallery } from '@/components/elements/gallery/gallery';
import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusImageProps {
    readonly bus: BookingBus;
    readonly disableLightBox?: boolean;
}

export const BusImage: FC<BusImageProps> = ({ bus, disableLightBox = true }) => {
    return (
        <Gallery
            navigation
            disableLightBox={disableLightBox}
            images={bus.getImageDocuments()}
            busSeats={bus.getTotalSeats()}
            alt={bus.name}
            fit="cover"
        />
    );
};
