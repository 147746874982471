import BigNumber from 'bignumber.js';
import { DateTime, type DateTimeFormatOptions, type Duration, type DurationUnit } from 'luxon';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useExchangeRates } from '@/api/public';
import {
    CURRENCY_COOKIE,
    DEFAULT_CURRENCY,
    DEFAULT_LANGUAGE,
    DEFAULT_REGION,
    currencyMaximumFractionDigits,
    setLocalizationCookie,
    type Currency,
    type Language,
    type Region,
} from '@/config/localization';
import { Money } from '@/entity/basic/Money';

export interface ILocalizationContext {
    setShouldSave: (value: boolean) => void;
    region: Region;
    setRegion: (value: Region) => void;
    language: Language;
    setLanguage: (value: Language) => void;
    currency: Currency;
    setCurrency: (value: Currency) => void;
    locale: string;
}

export const LocalizationContext = createContext<ILocalizationContext>({
    setShouldSave() {},
    region: DEFAULT_REGION,
    setRegion() {},
    language: DEFAULT_LANGUAGE,
    setLanguage() {},
    currency: DEFAULT_CURRENCY,
    setCurrency() {},
    locale: `${DEFAULT_LANGUAGE}-${DEFAULT_REGION}`,
});

export const OVERRIDEABLE_NAMESPACES = ['translation', 'tour'] as const;

// Localization Hook
export type LocalizationResource = {
    [key: string]: LocalizationResource | string;
};

export type LocalizationOverrides = {
    [lng in Language]?: {
        [ns in (typeof OVERRIDEABLE_NAMESPACES)[number]]?: LocalizationResource;
    };
};

interface UseLocalization {
    region: Region;
    language: Language;
    currency: Currency;
    setRegion: (value: Region) => void;
    setAndSaveRegion: (value: Region) => void;
    setLanguage: (value: Language) => void;
    setAndSaveLanguage: (value: Language) => void;
    setCurrency: (value: Currency) => void;
    addOverrides: (value: LocalizationOverrides) => void;
}

export function useLocalization(): UseLocalization {
    const context = useContext(LocalizationContext);
    const { i18n } = useTranslation();

    if (!context) throw new Error('useLocalization must be used within a LocalizationProvider');

    const {
        region,
        setRegion: setAppRegion,
        language,
        setLanguage: setAppLanguage,
        currency,
        setCurrency,
        setShouldSave,
    } = context;

    const setLanguage = (value: Language) => {
        if (value === 'cimode') void i18n.changeLanguage(value);
        else void i18n.changeLanguage(`${value}-${region}`);

        setAppLanguage(value);
    };

    const setRegion = (value: Region) => {
        void i18n.changeLanguage(`${language}-${value}`);
        setAppRegion(value);
    };

    return {
        region,
        language,
        currency,
        setRegion,
        setAndSaveRegion(value) {
            setRegion(value);
            setShouldSave(true);
        },
        setLanguage,
        setAndSaveLanguage(value) {
            setLanguage(value);
            setShouldSave(true);
        },
        setCurrency(value) {
            setLocalizationCookie(CURRENCY_COOKIE, value);
            setCurrency(value);
        },
        addOverrides(overrides) {
            const bundles = overrides[language] ?? {};

            for (const [namespace, bundle] of Object.entries(bundles)) {
                i18n.addResourceBundle(language, namespace, bundle, true, true);
            }
        },
    };
}

// Duration Format Hook
interface DurationFormatOptions {
    floor?: boolean;
    variant?: 'short' | 'long';
    keepZeros?: boolean;
    separator?: string;
}

interface UseLocalizedFormatters {
    localizeDateTime: (dateTime: DateTime) => DateTime;
    formatDateTime: (dateTime: DateTime, formatOptions?: DateTimeFormatOptions) => string;
    formatDuration: (duration: Duration, fmt: DurationUnit[], formatOptions?: DurationFormatOptions) => string;
    formatMoney: (money: Money) => string;
    formatNumber: (value: number) => string;
    formatPercent: (value: number) => string;
}

export function useLocalizedFormatters(): UseLocalizedFormatters {
    const context = React.useContext(LocalizationContext);
    const { t } = useTranslation();

    if (!context) throw new Error('useLocalizedFormats must be used within a LocalizationProvider');

    const { language } = context;

    return {
        localizeDateTime: dateTime => dateTime.setLocale(language),
        formatDateTime(dateTime, formatOptions = DateTime.DATETIME_SHORT) {
            return dateTime.setLocale(language).toLocaleString(formatOptions);
        },
        formatDuration(duration, format, formatOptions) {
            const { floor, variant, keepZeros, separator } = { variant: 'short', separator: ' ', ...formatOptions };
            const shifted = duration.reconfigure({ locale: language }).shiftTo(...format);

            // Reference dynamic translation keys for extraction
            //
            // t('units:years.long')           t('units:years.short')
            // t('units:quarters.long')        t('units:quarters.short')
            // t('units:months.long')          t('units:months.short')
            // t('units:weeks.long')           t('units:weeks.short')
            // t('units:days.long')            t('units:days.short')
            // t('units:hours.long')           t('units:hours.short')
            // t('units:minutes.long')         t('units:minutes.short')
            // t('units:seconds.long')         t('units:seconds.short')
            // t('units:milliseconds.long')    t('units:milliseconds.short')

            return (
                format
                    // Map values with unit label
                    .map<[number, string]>(unit => [
                        Number(shifted.get(unit)),
                        t(`units:${unit}.${variant}`, { count: shifted.get(unit) }),
                    ])
                    // Filter out zero values
                    .filter(([value]) => value !== undefined && (keepZeros || value > 0))
                    // Floor values
                    .map(([value, unit]) => [floor ? Math.floor(value) : value, unit])
                    // Join value and unit label
                    .map(pair => pair.join(' '))
                    // Join all parts
                    .join(separator)
            );
        },
        formatMoney(money) {
            const { currency, amount } = money;
            const maximumFractionDigits = currencyMaximumFractionDigits(currency);

            return new Intl.NumberFormat(language, {
                style: 'currency',
                currency,
                maximumFractionDigits,
            }).format(amount);
        },
        formatNumber(value) {
            return new Intl.NumberFormat(language).format(value);
        },
        formatPercent(value) {
            return new Intl.NumberFormat(language, { style: 'percent' }).format(value);
        },
    };
}

// Money Format Hook
export type ConvertToCurrency = (money: Money, toCurrency: Currency) => Money;

interface UseMoneyUtils {
    selectedCurrency: Currency;
    convertToCurrency: ConvertToCurrency;
    convertToSelectedCurrency: (money: Money) => Money;
    uniqueSelectedCurrencySum: (moneys: Money[]) => Money;
    isSelectedCurrency: (money: Money) => boolean;
}

export function useMoneyUtils(): UseMoneyUtils {
    const context = React.useContext(LocalizationContext);
    const { data: exchangeRates } = useExchangeRates();

    if (!context) throw new Error('useCurrencyUtils must be used within a LocalizationProvider');

    const selectedCurrency = context.currency;

    const convert = React.useCallback(
        (amount: BigNumber, fromCurrency: Currency, toCurrency: Currency): BigNumber => {
            // Nothing to convert
            if (fromCurrency === toCurrency) return amount;

            // Exchange rates are undefined
            if (exchangeRates === undefined) {
                console.error('Invalid exchangeRates');
                return amount;
            }

            let convertedAmount = amount.dividedBy(exchangeRates[fromCurrency]);

            if (toCurrency !== 'EUR') convertedAmount = convertedAmount.multipliedBy(exchangeRates[toCurrency]);

            return convertedAmount.decimalPlaces(2);
        },
        [exchangeRates],
    );

    return React.useMemo(
        () => ({
            selectedCurrency,
            convertToCurrency(money, toCurrency) {
                const { amount, currency } = money;

                const convertedAmount = convert(new BigNumber(amount), currency, toCurrency);

                return new Money(convertedAmount.toNumber(), toCurrency);
            },
            convertToSelectedCurrency(money) {
                const { amount, currency } = money;

                const convertedAmount = convert(new BigNumber(amount), currency, selectedCurrency);

                return new Money(convertedAmount.toNumber(), selectedCurrency);
            },
            uniqueSelectedCurrencySum(moneys) {
                const convertedSum = moneys.reduce(
                    (sum, { currency, amount }) => sum.plus(convert(new BigNumber(amount), currency, selectedCurrency)),
                    new BigNumber(0),
                );

                return new Money(convertedSum.toNumber(), selectedCurrency);
            },
            isSelectedCurrency: money => money.currency === selectedCurrency,
        }),
        [convert, selectedCurrency],
    );
}
