import { Button } from '@mui/material';
import React, { forwardRef } from 'react';
import ga4 from 'react-ga4';

import { type ActionButtonProps } from '@/components/elements/action/mod';
import { NavButton } from '@/components/elements/nav/nav-button';
import { useAppCtx } from '@/core/app-ctx/mod';

export const ActionButton: React.FC<React.PropsWithChildren<ActionButtonProps>> = forwardRef(
    ({ category = 'primary', intent = 'normal', navigate, gaEvent, onClick, ...navButtonProps }, ref) => {
        const {
            themeOverrides: {
                propertyMapping: { actionButton },
            },
        } = useAppCtx();

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (gaEvent) ga4.event(gaEvent.action, { event_category: gaEvent.category });
            onClick?.(event);
        };

        if (navigate)
            return (
                <NavButton
                    ref={ref}
                    variant={actionButton.category[category]}
                    color={actionButton.intent[intent]}
                    navigate={navigate}
                    onClick={handleClick}
                    {...navButtonProps}
                />
            );

        return (
            <Button
                ref={ref}
                variant={actionButton.category[category]}
                color={actionButton.intent[intent]}
                onClick={handleClick}
                {...navButtonProps}
            />
        );
    },
);
