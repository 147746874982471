import { DialogTitle, Dialog as MuiDialog, type DialogProps as MuiDialogProps } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import { useMemo } from 'react';

import { DialogContext, type DialogVariant, type IDialogContext } from '../types';

import { DialogActions, type DialogActionsProps } from './DialogActions';
import { DialogContent, type DialogContentProps } from './DialogContent';
import { DialogDescription, type DialogDescriptionProps } from './DialogDescription';

import { type UseDisclosure } from '@/hooks/useDisclosure';

interface DialogProps<Variant extends DialogVariant = DialogVariant>
    extends UseDisclosure,
        Pick<MuiDialogProps, 'maxWidth' | 'fullWidth'> {
    readonly variant: Variant;
    readonly title: string;
    readonly description?: DialogDescriptionProps['description'];
    readonly children?: DialogContentProps<Variant>['content'];
    readonly actions?: DialogActionsProps<Variant>['actions'];
    readonly disableTrivialClose?: boolean;
    readonly blurBackground?: boolean;
}

export const Dialog = <Variant extends DialogVariant>({
    variant,
    actions,
    title,
    description,
    state,
    maxWidth,
    fullWidth,
    children,
    blurBackground,
    disableTrivialClose,
    close,
}: DialogProps<Variant>) => {
    const id = useMemo(() => uniqueId(`${variant}-dialog`), [variant]);

    const value: IDialogContext = useMemo(
        () => ({
            id,
            close,
        }),
        [id, close],
    );

    const titleId = `${id}-title`;
    const descriptionId = `${id}-description`;

    const handleClose = () => {
        if (disableTrivialClose) return;
        close();
    };

    return (
        <DialogContext.Provider value={value}>
            <MuiDialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={state.open}
                aria-labelledby={titleId}
                aria-describedby={description ? descriptionId : undefined}
                slotProps={{
                    backdrop: {
                        sx: {
                            backdropFilter: blurBackground ? 'blur(5px)' : undefined,
                        },
                    },
                }}
                onClose={handleClose}>
                <DialogTitle id={titleId}>{title}</DialogTitle>
                {description && <DialogDescription id={descriptionId} description={description} />}
                {children && <DialogContent variant={variant} content={children} />}
                <DialogActions variant={variant} actions={actions} disableTrivialClose={disableTrivialClose} />
            </MuiDialog>
        </DialogContext.Provider>
    );
};
