import { Backdrop } from '@mui/material';
import React from 'react';

import { CircleLoader, type CircleLoaderProps } from '@/components/elements/loader/circle-loader';

interface BackdropLoaderProps extends CircleLoaderProps {
    readonly open: boolean;
}

export const BackdropLoader: React.FC<BackdropLoaderProps> = ({ open, label }) => {
    return (
        <Backdrop
            sx={{
                background: 'inherit',
                // TODO: We cannot use theme.vars here as this component is used outside of
                // The CSSVarsProvider.
                zIndex: `calc(var(--mui-zIndex-drawer) + 1)`,
            }}
            open={open}>
            <CircleLoader label={label} />
        </Backdrop>
    );
};
