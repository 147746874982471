import { Box } from '@mui/material';
import React from 'react';

interface AspectBoxProps {
    readonly ratio: number | '3:2' | '4:3' | '16:9';
}

export const AspectBox: React.FC<React.PropsWithChildren<AspectBoxProps>> = ({ ratio, children }) => {
    let frac: number;
    switch (ratio) {
        case '3:2': {
            frac = 2 / 3;
            break;
        }
        case '4:3': {
            frac = 3 / 4;
            break;
        }
        case '16:9': {
            frac = 9 / 16;
            break;
        }
        default: {
            frac = ratio;
        }
    }

    return (
        <Box position="relative" width="100%" pt={`${frac * 100}%`}>
            <Box position="absolute" top={0} left={0} width="100%" height="100%">
                {children}
            </Box>
        </Box>
    );
};
