import { ButtonGroup } from '@mui/material';
import React from 'react';

import { type ActionButtonGroupProps } from '@/components/elements/action/mod';
import { useAppCtx } from '@/core/app-ctx/mod';

export const ActionButtonGroup: React.FC<React.PropsWithChildren<ActionButtonGroupProps>> = ({
    category = 'primary',
    intent = 'normal',
    children,
    ...buttonGroupProps
}) => {
    const {
        themeOverrides: {
            propertyMapping: { actionButton },
        },
    } = useAppCtx();

    // Create a new Link component which does the linking and has the styles we want from an inline button
    return (
        <ButtonGroup
            variant={actionButton.category[category]}
            color={actionButton.intent[intent]}
            {...buttonGroupProps}>
            {children}
        </ButtonGroup>
    );
};
