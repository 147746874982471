import { Grid, Typography } from '@mui/material';
import React from 'react';
import { ReactCountryFlag } from 'react-country-flag';

import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusHeaderProps {
    readonly bus: BookingBus;
    readonly noWrap?: boolean;
}

export const BusHeader: React.FC<BusHeaderProps> = ({ bus, noWrap }) => {
    return (
        <Grid item xs>
            <Typography
                noWrap={noWrap}
                variant="h5"
                textOverflow="ellipsis"
                component="div"
                title={bus.company.companyName}>
                {bus.company.companyName}
            </Typography>
            <Typography variant="body3" component="div" display="flex" alignItems="center" gap={1}>
                <ReactCountryFlag
                    svg
                    countryCode={bus.company.contactData.country}
                    title={bus.company.contactData.country}
                />
                <span>{bus.company.contactData.postCodeAndCity} </span>
            </Typography>
        </Grid>
    );
};
