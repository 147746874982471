import { createContext, useContext } from 'react';

import { type MainCompanyBranchData } from '@/entity/main-company-branch-data/MainCompanyBranchData';

export interface IMainCompanyDataContext {
    mainCompanyData?: MainCompanyBranchData;
}

export const MainCompanyDataContext = createContext<IMainCompanyDataContext>({});

export function useMainCompanyData(): IMainCompanyDataContext {
    const context = useContext(MainCompanyDataContext);

    if (!context) throw new Error('useMainCompanyData must be used within a MainCompanyDataProvider');

    return context;
}
