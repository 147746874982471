import React from 'react';
import sanitizeHtml from 'sanitize-html';

interface Props {
    readonly element?: any;
    readonly html: any;
    readonly className?: string;
    readonly omitBreaks?: boolean;
    readonly sanitizeHtmlOptions?: any;
}

/**
 * Default options from https://github.com/apostrophecms/sanitize-html.
 * Also allow 'rel' attribute on 'a'.
 */
const defaultOptions = {
    allowedTags: [
        'address',
        'article',
        'aside',
        'footer',
        'header',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hgroup',
        'main',
        'nav',
        'section',
        'blockquote',
        'dd',
        'div',
        'dl',
        'dt',
        'figcaption',
        'figure',
        'hr',
        'li',
        'main',
        'ol',
        'p',
        'pre',
        'ul',
        'a',
        'abbr',
        'b',
        'bdi',
        'bdo',
        'br',
        'cite',
        'code',
        'data',
        'dfn',
        'em',
        'i',
        'kbd',
        'mark',
        'q',
        'rb',
        'rp',
        'rt',
        'rtc',
        'ruby',
        's',
        'samp',
        'small',
        'span',
        'strong',
        'sub',
        'sup',
        'time',
        'u',
        'var',
        'wbr',
        'caption',
        'col',
        'colgroup',
        'table',
        'tbody',
        'td',
        'tfoot',
        'th',
        'thead',
        'tr',
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
        a: ['href', 'name', 'target', 'rel'],
        // We don't currently allow img itself by default, but
        // these attributes would make sense if we did.
        img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false,
    parseStyleAttributes: true,
};

export const SanitizedInnerHTML: React.FC<Props> = ({ html, className, omitBreaks, sanitizeHtmlOptions, element }) => {
    const sanitizedHtml = sanitizeHtml(html || '', sanitizeHtmlOptions || defaultOptions);
    const finalHtml = omitBreaks ? sanitizedHtml.replace(/<br ?\/>/g, ' ') : sanitizedHtml;
    const elementToRender = typeof element === 'string' ? element : 'div';

    return React.createElement(elementToRender, {
        dangerouslySetInnerHTML: { __html: finalHtml },
        className,
    });
};
