import React, { useMemo } from 'react';
import ga4 from 'react-ga4';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LocationField } from '@/components/form/fields/location-field';
import { useAppCtx } from '@/core/app-ctx/mod';
import { type AutoCompleteSuggestion } from '@/core/google/mod';
import { useTour } from '@/core/tour/mod';
import { type SearchFormBasicTour } from '@/core/tour/SearchFormBasicTour';
import { type SearchFormFirstIntermediateTour } from '@/core/tour/SearchFormFirstIntermediateTour';
import { TripCompanySearchForm } from '@/entity/trip/company/TripCompanySearchForm';
import { type IPosition } from '@/features/journey-planning';

interface StopLocationProps {
    readonly position: IPosition;
}

export const StopLocation: React.FC<StopLocationProps> = ({ position }) => {
    const { register } = useTour<SearchFormBasicTour | SearchFormFirstIntermediateTour>();
    const { t } = useTranslation();
    const { appSettings } = useAppCtx();
    const { setValue } = useFormContext();

    const label = useMemo(() => {
        if (position.first) return t('search_form.from.location.label');
        if (position.last) return t('search_form.to.location.label');
        return t('search_form.intermediate.location.label');
    }, [position.first, position.last, t]);

    let specedFieldRef: ((instance: HTMLElement | null) => void) | undefined;
    if (position.first) specedFieldRef = register('from_location');
    if (position.last || position.index === 1) specedFieldRef = register('to_location');

    const name = `routes.${position.routeIndex}.stops.${position.index}.location`;

    const handleAccept = (value: AutoCompleteSuggestion | null) => {
        const tripCompanyField = name.replace('location', 'tripCompany');
        // If the selected value is a trip company set the tripCompany field as well
        if (value instanceof TripCompanySearchForm) {
            setValue(tripCompanyField, value.getFormValues());
            ga4.event('trip-company', { event_category: 'add-trip-company-route-planner' });
        } else {
            setValue(tripCompanyField, null);
        }
    };

    return (
        <LocationField
            required
            fullWidth
            includeTripCompanies={!appSettings.searchResults.disableTripCompanies}
            name={name}
            label={label}
            fieldRef={specedFieldRef}
            onFocus={event => {
                event.target.scrollIntoView({ block: 'center', inline: 'nearest' });
            }}
            onAccept={handleAccept}
        />
    );
};
