import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, styled, type AccordionProps, type AccordionSummaryProps } from '@mui/material';

interface PageAccordionProps extends AccordionProps {
    divider?: boolean;
    expandable?: boolean;
}

export const PageAccordion = styled(({ expandable, divider, ...props }: PageAccordionProps) => (
    <Accordion disableGutters {...props} />
))(({ theme, divider, expandable, expanded }) => ({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    opacity: expanded ? 1 : theme.vars.opacity.disabled,
    '.MuiAccordionDetails-root, .MuiAccordionSummary-root, .MuiAccordionActions-root': {
        padding: 0,
    },
    '.MuiAccordionSummary-root:hover:not(.Mui-disabled)': expandable === false && {
        cursor: 'default',
    },
    '&:before': {
        display: 'none',
    },
    '&:not(:last-of-type)': divider && {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.vars.palette.divider,
        paddingBottom: theme.spacing(0.5),
    },
}));

interface PageAccordionSummaryProps extends AccordionSummaryProps {
    expandable?: boolean;
}

export const PageAccordionSummary = styled(({ expandable, ...props }: PageAccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMore />} {...props} />
))(({ expandable }) => ({
    display: expandable ? 'flex' : 'none',

    '.MuiAccordionSummary-expandIconWrapper': expandable === false && {
        display: 'none',
    },
}));
