import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useRegistration } from '../api/registration';
import { registrationSchema, type IRegistration } from '../types/registration-form';

import { duplicateCustomerEmailValidation } from '@/api/user/endpoints';
import { ActionButton } from '@/components/elements/action/action-button';
import { InteractiveText } from '@/components/elements/text/interactive-text';
import { CheckField } from '@/components/form/fields/check-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { AuthInputs } from '@/components/form-inputs/auth-inputs';
import { ContactDataInputs } from '@/components/form-inputs/contact-data-inputs';
import { OrganizationInputs } from '@/components/form-inputs/organization-inputs';
import { trackRegisteredCustomerGroup } from '@/core/google/utils/ga-utils';
import { useSnack } from '@/core/snack/mod';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';
import { getDefaultRegistrationFormValues } from '@/utils/account';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const RegistrationForm: React.FC = () => {
    const { t } = useTranslation();
    const { mutate: register } = useRegistration();
    const navigate = useNavigate();
    const snack = useSnack();

    const formMethods = useForm<IRegistration>({
        defaultValues: getDefaultRegistrationFormValues(),
        resolver: yupResolver(registrationSchema),
        mode: 'onBlur',
    });

    const onSubmit = async (values: IRegistration) => {
        const email = formMethods.getValues('email');

        try {
            await duplicateCustomerEmailValidation(email);
            register(values, {
                async onSuccess({ id }: { id: number }) {
                    trackRegisteredCustomerGroup(values.customerGroupId, t);
                    if (id != null) await navigate({ to: '/registration/confirmation', search: { id: id.toString() } });
                },
                onError() {
                    snack.push(t('validations:general.error'), 'error');
                },
            });
        } catch {
            formMethods.setError('email', {
                type: 'duplicate-email-validation',
                message: t('validations:email.duplicate_email_customer'),
            });
        }
    };

    const agbDialog = useDisclosure();

    return (
        <>
            <FormProvider {...formMethods}>
                <form noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <AuthInputs />

                    <OrganizationInputs />

                    <ContactDataInputs withoutEmail />

                    <FormFieldRow>
                        <CheckField
                            label={
                                <Typography variant="body2">
                                    {t('account.core_data.subscribe_to_newsletter')}
                                </Typography>
                            }
                            name="subscribedToNewsletter"
                        />

                        <CheckField
                            label={
                                <Typography variant="body2">
                                    <Trans
                                        i18nKey="registration.agb"
                                        components={{
                                            button1: <InteractiveText color="primary" onClick={agbDialog.open} />,
                                        }}
                                    />
                                </Typography>
                            }
                            name="agb"
                        />
                    </FormFieldRow>

                    <FormFieldRow>
                        <ActionButton type="submit">{t('account.register')}</ActionButton>
                    </FormFieldRow>
                </form>
            </FormProvider>
            <Dialog
                {...agbDialog}
                variant="info"
                maxWidth="lg"
                title={t('agb.modal_title_busfinder')}
                description={
                    <SanitizedInnerHTML html={t('page_agb_content', { interpolation: { escapeValue: false } })} />
                }
            />
        </>
    );
};
