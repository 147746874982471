import { DateTime } from 'luxon';
import { mixed } from 'yup';

export const dateTimeSchema = mixed((input): input is DateTime => input instanceof DateTime)
    .transform((value: any, originalInput, ctx) => {
        if (ctx.isType(value)) return value;

        let converted: DateTime<true> | DateTime<false> = DateTime.invalid('invalid_date');

        if (value instanceof Date) converted = DateTime.fromJSDate(value);
        else if (typeof value === 'string') {
            converted = DateTime.fromISO(value);
            if (!converted.isValid) converted = DateTime.fromHTTP(value);
            if (!converted.isValid) converted = DateTime.fromRFC2822(value);
        } else converted = DateTime.fromObject(value);

        return converted;
    })
    .test({
        name: 'is_valid',
        test(value, ctx) {
            // First departure date time needs to be set
            const isInvalidDateTime = ctx.path === 'routes[0].stops[0].departureDateTime' && value == null;

            if (isInvalidDateTime || (value != null && !value.isValid))
                return ctx.createError({
                    message: {
                        // t("validations:date.invalid")
                        key: 'date.invalid',
                    },
                    path: ctx.path,
                });

            return true;
        },
    });
