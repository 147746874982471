import { useMutation, useQueryClient } from '@tanstack/react-query';

import callApi from '@/api';
import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { removeStorageJourneyToken } from '@/config/storage';

export async function deletePlanningJourney(token: string): Promise<void> {
    await callApi(`public/deleteJourney/${token}`, {
        method: 'DELETE',
    });
}

export const useResetJourney = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deletePlanningJourney,
        async onSuccess() {
            removeStorageJourneyToken();
            void queryClient.invalidateQueries({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] });
        },
    });
};
