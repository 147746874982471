import { type Filter, type FilterCounts } from '../types/filter';

export const defaultFilter: Filter = {
    categoriesName: new Set(),
    categoriesGroup: new Set(),
    attributes: new Set(),
    provinces: new Set(),
};

export const defaultFilterCounts: FilterCounts = {
    categoriesGroup: [],
    categoriesName: [],
    attributes: [],
    provinces: [],
};
