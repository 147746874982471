import { ContactDataAddress } from '@/entity/basic/ContactDataAddress';

export class School {
    public id: number;

    public schoolName: string;

    public contactData: ContactDataAddress;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.schoolName = json.schoolName;
        this.contactData = ContactDataAddress.fromJson(json.contactData);
    }
}
