import { Location } from '@/entity/basic/Location';
import { type ITripCompany } from '@/features/journey-planning';

export class TripCompanySearchForm {
    public id: number;

    public companyName: string;

    public location: Location;

    public recommendedDurationOfStayInMinutes: number;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.recommendedDurationOfStayInMinutes = json.recommendedDurationOfStayInMinutes;
        this.companyName = json.companyName;
        this.location = Location.fromJson(json.location);
    }

    public getFormValues(): ITripCompany {
        return {
            id: this.id,
            companyName: this.companyName,
            recommendedDurationOfStayInMinutes: this.recommendedDurationOfStayInMinutes,
        };
    }

    public isEqualToJson(json: Record<string, any>): boolean {
        if (json === undefined) return false;

        return [
            this.id === json.id,
            this.companyName === json.companyName,
            this.recommendedDurationOfStayInMinutes === json.recommendedDurationOfStayInMinutes,
            this.location.isEqualToJson(json.location),
        ].every(test => test);
    }

    public toString(): string {
        return `${this.companyName} - ${this.location}`;
    }
}
