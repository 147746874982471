import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query';

import { getUser } from '@/api/user/endpoints';

export const USER_QUERY_KEY = 'auth-user';

export async function queryUser(queryClient: QueryClient) {
    return queryClient.ensureQueryData({
        queryKey: [USER_QUERY_KEY],
        queryFn: getUser,
    });
}

export function useUser() {
    return useSuspenseQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: getUser,
        // initialData: null,
        staleTime: 0,
        refetchOnWindowFocus: true,
        // Refetch every 15 minutes
        refetchInterval: 1000 * 60 * 15,
    });
}
