import { useMutation, useQuery } from '@tanstack/react-query';

import { downloadBookingConfirmationPDF, fetchBookingByToken, submitFeedback } from '@/api/booking/booking-requests';
import { useUpdateAppProgress } from '@/core/app-progress/mod';

export const BOOKING_QUERY_KEY = 'booking';
export const BOOKING_CONFIRMATION_PDF = 'journey-operator-offer-pdf';

export const useBookingByToken = (bookingToken?: string) => {
    const result = useQuery({
        queryKey: [BOOKING_QUERY_KEY, bookingToken],
        queryFn: async () => fetchBookingByToken(bookingToken),
        initialData: null,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });

    useUpdateAppProgress(result);

    return result;
};

export const useDownloadBookingConfirmationPDF = (bookingToken?: string) => {
    return useQuery({
        queryKey: [BOOKING_CONFIRMATION_PDF, bookingToken],
        queryFn: async () => downloadBookingConfirmationPDF(bookingToken),
        enabled: false,
    });
};

export const useFeedback = () => {
    return useMutation({
        mutationFn: submitFeedback,
    });
};
