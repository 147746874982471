import { Box } from '@mui/material';
import useIntersectionObserver, { type MockIntersectionObserverEntry } from '@react-hook/intersection-observer';
import useResizeObserver from '@react-hook/resize-observer';
import { useRef, type FC, type ReactElement, type ReactNode, type ElementRef } from 'react';

import { useTile } from '@/components/page/layout/tile/mod';
import { type IAttachable } from '@/components/page/layout/tile/tile-part';

interface AttachableRenderProps {
    observer: MockIntersectionObserverEntry | IntersectionObserverEntry;
    isAttached: boolean;
}

interface AttachableProps {
    readonly attachable: IAttachable;
    readonly children?: Exclude<ReactNode, ReactElement> | FC<AttachableRenderProps>;
}

export const Attachable: FC<AttachableProps> = ({ attachable: { to, type }, children }) => {
    const ref = useRef<ElementRef<'div'> | null>(null);
    const observer = useIntersectionObserver(ref, { threshold: [1] });
    const { setSpacer, setAttachableRef } = useTile();

    const ratio = observer.intersectionRatio ?? 1;
    const isAttached = (ratio !== 0 && ratio < 1) || type === 'fixed';

    useResizeObserver(ref, () => {
        if (type === 'fixed' && ref.current?.clientHeight) setSpacer({ to, height: ref.current.clientHeight });
    });

    return (
        <Box
            ref={(el: HTMLDivElement) => {
                ref.current = el;
                setAttachableRef(el);
            }}
            sx={{
                position: type,
                top: to === 'top' ? '-1px' : undefined,
                bottom: to === 'bottom' ? '-1px' : undefined,
                marginTop: to === 'top' ? '1px' : undefined,
                marginBottom: to === 'bottom' ? '1px' : undefined,
                zIndex: theme => theme.zIndex.appBar - 1,
                width: '100%',
            }}>
            {typeof children === 'function' ? children({ observer, isAttached }) : children}
        </Box>
    );
};
