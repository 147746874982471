import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';

export const AuthInputs: React.FC = () => {
    const { t } = useTranslation();

    return (
        <FormFieldsContainer containerLabel={t('user_data')}>
            <FormFieldRow insertEmptyColumn>
                <TextField required name="email" label={t('email')} />
            </FormFieldRow>

            <FormFieldRow>
                <TextField required type="password" name="password" label={t('password')} />

                <TextField required type="password" name="passwordConfirmation" label={t('password_repeat')} />
            </FormFieldRow>
        </FormFieldsContainer>
    );
};
