import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useFeedback } from '@/api/booking/booking-queries';
import { ActionButton } from '@/components/elements/action/action-button';
import { TextField } from '@/components/form/fields/text-field';
import BaseModal, { type BaseModalProps } from '@/components/modals/defaults/BaseModal';
import { useAuth } from '@/core/auth/mod';
import { useSnack } from '@/core/snack/mod';
import { BusRatingIcons, ratingItems } from '@/features/bus-selection';

interface FeedBackFormValues {
    feedback: string;
    rating: number;
}

const FeedbackModal: React.FC<BaseModalProps> = props => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { mutateAsync: submitFeedback } = useFeedback();
    const snack = useSnack();
    const { handleClose } = props;

    const formMethods = useForm<FeedBackFormValues>({
        resolver: yupResolver(feedbackModalSchema),
        defaultValues: {
            feedback: '',
            rating: 0,
        },
        mode: 'onChange',
    });

    const rating = formMethods.watch('rating');

    const handleClick = async (value: number) => {
        const v = value === rating ? 0 : value;
        formMethods.setValue('rating', v);
        await formMethods.trigger('rating');
    };

    const handleSubmit = async (values: FeedBackFormValues) => {
        if (user)
            await submitFeedback(
                { user, body: values },
                {
                    onSuccess() {
                        snack.push(t('feedback_modal.success'), 'success');
                    },
                    onError() {
                        snack.push(t('validations:general.error'), 'error');
                    },
                },
            );
        handleClose();
    };

    const ModelContent = (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <Stack gap={2.5}>
                    <Typography gutterBottom variant="body2">
                        <Trans
                            i18nKey="feedback_modal.details"
                            values={{
                                firstName: user?.contactData.firstName,
                                lastName: user?.contactData.lastName,
                            }}
                        />
                    </Typography>

                    <Stack gap={1}>
                        <Typography fontWeight="fontWeightMedium">{t('feedback_modal.question')}</Typography>
                        <BusRatingIcons
                            hideResetIcon
                            highlightSelectedOnly
                            ratingItems={ratingItems()}
                            onClick={handleClick}
                        />
                        {formMethods.formState.errors.rating && (
                            <Typography variant="body3" color="error.main">
                                {t('feedback_modal.rating_error')}
                            </Typography>
                        )}
                    </Stack>

                    <TextField multiline name="feedback" label="Feedback" rows={6} />

                    <ActionButton type="submit">{t('feedback_modal.send_feedback')}</ActionButton>
                </Stack>
            </form>
        </FormProvider>
    );
    const modalProps = { ...props, content: ModelContent, hideActions: true };

    return <BaseModal {...modalProps} />;
};

export default FeedbackModal;

const feedbackModalSchema = Yup.object({
    feedback: Yup.string().defined(),
    rating: Yup.number().min(1).max(5).defined(),
});
