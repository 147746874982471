import callApi from '@/api/index';
import { type AppUser } from '@/entity/account/Account';
import { DetailsBooking } from '@/entity/booking/DetailsBooking';
import { fileFromResponse } from '@/utils/file';

export async function fetchBookingByToken(bookingToken?: string) {
    if (!bookingToken) return null;
    const params = { token: bookingToken };
    const { data } = await callApi(`public/findBookingByToken`, { params });

    return new DetailsBooking(data);
}

export async function downloadBookingConfirmationPDF(token?: string) {
    try {
        const response = await callApi<BlobPart>(`public/generateBookingConfirmationPDF`, {
            params: { token },
            responseType: 'blob',
        });
        return fileFromResponse(response);
    } catch (error) {
        throw error;
    }
}

export async function submitFeedback({ user, body }: { user: AppUser; body: { rating: number; feedback: string } }) {
    const { data } = await callApi(`api/customers/${user.id}/send-feedback`, {
        method: 'POST',
        data: body,
    });
    return data;
}
