import { Entity } from '@/entity/A_Entity';

export class BusBrand extends Entity {
    public name: string;

    public rating?: number;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
        this.rating = json.rating;
    }
}
