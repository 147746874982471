import { Box, Chip, CircularProgress } from '@mui/material';
import React from 'react';

export interface CircleLoaderProps {
    readonly label?: React.ReactNode;
    readonly fullHeight?: boolean;
    readonly minHeight?: number | string;
}

export const CircleLoader: React.FC<CircleLoaderProps> = ({ label, fullHeight, minHeight }) => {
    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: fullHeight ? '100%' : 'auto',
                minHeight,
            }}>
            <CircularProgress color="primary" />
            {label && (
                <Chip
                    color="primary"
                    variant="outlined"
                    label={label}
                    sx={{
                        height: 'auto',
                        mt: 2,
                        p: 0.75,
                        '.MuiChip-label': {
                            whiteSpace: 'initial',
                        },
                    }}
                />
            )}
        </Box>
    );
};
