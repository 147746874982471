import { createFileRoute } from '@tanstack/react-router';

import { BookingStep } from '@/entity/booking-progress/BookingProgress';

export const ContactDataRoute = createFileRoute('/contact-data')({
    beforeLoad() {
        return {
            step: BookingStep.CONTACT_DATA,
        };
    },
});

export { ContactDataRoute as Route };
