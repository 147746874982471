import Accommodation from '@/assets/icons/maps/accommodation.png';
import AccommodationHovered from '@/assets/icons/maps/accommodation_hovered.png';
import Activity from '@/assets/icons/maps/activity.png';
import ActivityHovered from '@/assets/icons/maps/activity_hovered.png';
import Food from '@/assets/icons/maps/food.png';
import FoodHovered from '@/assets/icons/maps/food_hovered.png';
import { type TripCategory } from '@/entity/trip/category/TripCategory';

interface TripIcon {
    categories: TripCategory[];
    hovered: boolean;
}

export const tripIcon = ({ categories, hovered }: TripIcon) => {
    for (const category of categories) {
        if (category.group === 'ACCOMMODATION') return hovered ? AccommodationHovered : Accommodation;
        if (category.group === 'FOOD') return hovered ? FoodHovered : Food;
    }

    return hovered ? ActivityHovered : Activity;
};
