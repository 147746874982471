import { Box, Stack, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalization } from '@/core/localization/mod';
import { useEmbedCtx } from '@/embed/mod';

export const DigitalClockSectionHeader: React.FC = () => {
    const { language } = useLocalization();
    const { t } = useTranslation();
    const { shadowRoot } = useEmbedCtx();

    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        const updateWidth = () => {
            const documentEl = shadowRoot ?? document;
            const digitalClock = documentEl.querySelector('.MuiMultiSectionDigitalClock-root');
            if (digitalClock) setWidth(digitalClock.clientWidth);
        };
        // Initial width calculation
        updateWidth();

        // Attach resize event listener to update width
        window.addEventListener('resize', updateWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [shadowRoot]);

    const columnCount = language === 'en' ? 3 : 2;
    const columnWidth = width / columnCount;

    return (
        <Stack direction="row" justifyContent="flex-end" width="100%" my={0.5} gridColumn={3}>
            <Typography display="flex" justifyContent="center" width={columnWidth}>
                {t('units:hours.short')}
            </Typography>
            <Typography display="flex" justifyContent="center" width={columnWidth}>
                {t('units:minutes.short')}
            </Typography>
            {language === 'en' && <Box width={columnWidth} />}
        </Stack>
    );
};
