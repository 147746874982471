import { array, boolean, number, object, type ObjectSchema, string, ValidationError } from 'yup';

import { type IStop, StopSchema } from './stop';

import { Days, SEARCH_FORM } from '@/utils/constants';

export interface IRoute {
    uuid?: string;
    pax: number;
    considerSplit: boolean;
    confirmSplit: boolean;
    stops: IStop[];
}

export const RouteSchema: ObjectSchema<IRoute> = object({
    uuid: string(),
    pax: number().integer().positive().min(5).max(500).default(20),
    considerSplit: boolean().default(false),
    confirmSplit: boolean().default(false),
    stops: array(StopSchema)
        .defined()
        .min(2)
        .test({
            name: 'duplicate_adjacent_location',
            test(stops, ctx) {
                for (let i = 0; i < stops.length - 1; i++) {
                    const current = stops[i].location;
                    const next = stops[i + 1]?.location;

                    // Adjacent locations are different
                    if (current == null || next == null || current.lat !== next.lat || current.lng !== next.lng)
                        continue;

                    return new ValidationError([
                        ctx.createError({
                            message: {
                                // t("validations:journey.duplicate_adjacent_location_after")
                                key: 'journey.duplicate_adjacent_location_after',
                            },
                            path: `${ctx.path}.${i}.location`,
                        }),
                        ctx.createError({
                            message: {
                                // t("validations:journey.duplicate_adjacent_location")
                                key: 'journey.duplicate_adjacent_location',
                            },
                            path: `${ctx.path}.${i + 1}.location`,
                        }),
                    ]);
                }
                return true;
            },
        })
        .test({
            name: 'departure_4_days_from_now',
            test(stops, ctx) {
                if (!stops || stops.length < 2) return true;

                const departureDateTime = stops[0].departureDateTime;

                if (departureDateTime == null) return true;

                if (departureDateTime < SEARCH_FORM.minDepartureDateTime) {
                    return ctx.createError({
                        message: {
                            // t('validations:journey.min_departure_date')
                            key: 'journey.min_departure_date',
                            values: {
                                minDays: Days.MIN_FROM_DATE_TIME,
                            },
                        },
                        path: `${ctx.path}[0].departureDateTime`,
                    });
                }

                return true;
            },
        }),
    // .test({
    //     name: 'required_departure_date',
    //     test(stops, ctx) {
    //         const errors = stops
    //             .slice(0, -1)
    //             .map((stop, i) =>
    //                 stop.departureDateTime == null
    //                     ? ctx.createError({
    //                           message: {
    //                               key: 'general.required',
    //                           },
    //                           path: `${ctx.path}.${i}.departureDateTime`,
    //                       })
    //                     : null,
    //             )
    //             .filter((x: ValidationError | null): x is ValidationError => x != null);

    //         if (errors.length <= 0) return true;

    //         return new ValidationError(errors);
    //     },
    // }),
    // This test is not required because it is not possible to edit the arrival DateTime
    // .test({
    //     name: 'arrival_before_departure',
    //     test: (stops, ctx) => {
    //         if (!stops || stops.length < 2) return true;

    //         for (let i = 1; i < stops.length; i++) {
    //             const p = stops[i - 1].departureDateTime;
    //             const c = stops[i].arrivalDateTime;

    //             // Location from the location field returns optional fields address components as undefined,
    //             // while the backend (from journey entity) returns them with null.
    //             // Comparing lat/lng is enough.
    //             if (p != null && c != null && DateTime.fromJSDate(c) < DateTime.fromJSDate(p))
    //                 return ctx.createError({
    //                     message: {
    //                         key: 'journey.arrival_before_departure',
    //                     },
    //                     path: `${ctx.path}[${i}].arrivalDateTime`,
    //                 });
    //         }

    //         return true;
    //     },
    // }),
});
