import { type BookingTask } from '@/entity/events/task/BookingTask';
import { BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { ReversalShort } from '@/features/company';

interface BusCancellationProps {
    readonly resultOrTask?: BookingSearchResult | BookingTask;
    readonly align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const BusCancellation: React.FC<BusCancellationProps> = ({ resultOrTask, align }) => {
    const priceOption =
        resultOrTask instanceof BookingSearchResult ? resultOrTask.selectedPriceOption : resultOrTask?.priceOption;

    if (
        !resultOrTask ||
        !resultOrTask.cancellationFreeNow ||
        !resultOrTask.freeCancellationDate?.isValid ||
        priceOption?.noRefund
    )
        return null;

    return <ReversalShort resultOrTask={resultOrTask} align={align} />;
};
