import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { CURRENCY_COOKIE, LOCALE_COOKIE } from '@/config/localization';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useLocalization } from '@/core/localization/mod';

// This component needs google to be initialized and needs to be placed under the GoogleProvider but above the AppRoutes
export const InitializeLocalization: React.FC<React.PropsWithChildren> = ({ children }) => {
    const savedLanguage = Cookies.get(LOCALE_COOKIE)?.split('-')[0];
    const savedRegion = Cookies.get(LOCALE_COOKIE)?.split('-')[1];
    const savedCurrency = Cookies.get(CURRENCY_COOKIE);

    // If the user has not yet chosen their region, we will set it using region detection.
    // useAutoSetRegion(savedRegion);
    // Initialize default integration localization settings (language, region, currency)
    useIntegrationLocalizationSettings(savedRegion, savedLanguage, savedCurrency);

    return <>{children}</>;
};

// function useAutoSetRegion(savedRegion?: string) {
//     const { setRegion } = useLocalization();
//     const { appSettings } = useAppCtx();
//     const { isPrint } = useAppProgress();

//     const shouldDetect = !savedRegion && !isPrint;
//     const detectedRegion = useRegionDetection(shouldDetect);

//     const [regionSet, setRegionSet] = useState(false);

//     const isDetectedRegionValid = detectedRegion && isSupportedRegion(detectedRegion);
//     const shouldSetRegion = isDetectedRegionValid && !regionSet && appSettings.localization.defaultRegion == null;

//     useEffect(() => {
//         if (shouldSetRegion) {
//             setRegion(detectedRegion);
//             setRegionSet(true);
//         }
//     }, [detectedRegion, setRegion, shouldSetRegion]);
// }

function useIntegrationLocalizationSettings(savedRegion?: string, savedLanguage?: string, savedCurrency?: string) {
    const [initialized, setInitialized] = useState({
        overrides: false,
        region: false,
        language: false,
        currency: false,
    });
    const {
        appSettings: {
            localization: { defaultCurrency, defaultLanguage, defaultRegion },
        },
        localizationOverrides,
    } = useAppCtx();
    const { setRegion, setLanguage, setCurrency, addOverrides } = useLocalization();

    useEffect(() => {
        if (defaultRegion && !savedRegion && !initialized.region) {
            setRegion(defaultRegion);
            setInitialized(init => ({ ...init, region: true }));
        }

        if (defaultLanguage && !savedLanguage && !initialized.language) {
            setLanguage(defaultLanguage);
            setInitialized(init => ({ ...init, language: true }));
        }

        if (defaultCurrency && !savedCurrency && !initialized.currency) {
            setCurrency(defaultCurrency);
            setInitialized(init => ({ ...init, currency: true }));
        }

        if (!initialized.overrides) {
            addOverrides(localizationOverrides);
            setInitialized(init => ({ ...init, overrides: true }));
        }
    }, [
        addOverrides,
        defaultCurrency,
        defaultLanguage,
        defaultRegion,
        initialized,
        savedCurrency,
        savedLanguage,
        savedRegion,
        setCurrency,
        setLanguage,
        setRegion,
        localizationOverrides,
    ]);
}
