import { createFileRoute } from '@tanstack/react-router';
import { number, object, string, array, type InferType } from 'yup';

import { createLayout } from '@/components/page/layout/createLayout';
import { BookingNavigation } from '@/components/page/navigation/booking-navigation';
import { JourneyPlanningProvider } from '@/core/journey-planning/JourneyPlanningProvider';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { JourneyPlanningTile, JourneyPlanningFormProvider, MapTile } from '@/features/journey-planning';

const { Layout } = createLayout({
    tiles: {
        search_form: <JourneyPlanningTile />,
        search_map: <MapTile />,
    },
    base: [
        { name: 'search_form', md: 7 },
        { name: 'search_map', md: 5 },
    ],
});

const HomePage: React.FC = () => {
    const { token, amendBookingToken } = Route.useSearch();

    return (
        <JourneyPlanningProvider token={token} amendBookingToken={amendBookingToken ?? undefined}>
            <JourneyPlanningFormProvider>
                <Layout useNavigation={BookingNavigation} />
            </JourneyPlanningFormProvider>
        </JourneyPlanningProvider>
    );
};

// TODO: These are not implemented anymore because they became unused
export type HomeActions = 'add_route' | 'edit_route' | 'reset_journey';

// helper for yup transform function
// https://github.com/jquense/yup/issues/298#issuecomment-559017330
function emptyStringToNull(value: any, originalValue: any) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

const SEARCH_PARAMS = object({
    token: string(),
    amendBookingToken: string(),
    action: string().oneOf<HomeActions>(['add_route', 'edit_route', 'reset_journey']),
    group: number().transform(emptyStringToNull).nullable(),

    fromAddress: string(),
    fromLat: number().transform(emptyStringToNull).nullable(),
    fromLng: number().transform(emptyStringToNull).nullable(),
    toAddress: string(),
    toLat: number().transform(emptyStringToNull).nullable(),
    toLng: number().transform(emptyStringToNull).nullable(),
    pax: number().transform(emptyStringToNull).nullable(),
    departureDateTime: string(),
    drawers: array().of(string()),
});

export const Route = createFileRoute('/')({
    component: HomePage,
    beforeLoad() {
        return {
            step: BookingStep.ROUTE_PLANNING,
        };
    },
    validateSearch(search): InferType<typeof SEARCH_PARAMS> {
        return SEARCH_PARAMS.cast(search);
    },
});

export { Route as HomePageRoute };
