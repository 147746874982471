import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/account/')({
    beforeLoad(): void {
        redirect({ to: '/account/profile', replace: true, throw: true });
    },
    component() {
        return null;
    },
});
