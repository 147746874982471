import type APIError from '@/api/APIError';
import { isAPIError } from '@/api/APIError';

export class IntegrationError extends Error {
    public error?: Error;
    public apiError?: APIError;

    constructor(error?: Error | APIError) {
        super('Integration Error');
        if (isAPIError(error)) this.apiError = error;
        else this.error = error;
    }

    public getDetails(): string {
        return this.apiError?.getDetails() ?? 'No details available';
    }
}

export function isIntegrationError(error: any): error is IntegrationError {
    return error instanceof IntegrationError;
}
