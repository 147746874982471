import { useQuery } from '@tanstack/react-query';

import callApi from '@/api';
import { fromJsonArray } from '@/entity';
import { TripCompany } from '@/entity/trip/company/TripCompany';

export async function getTripCompanies(params: { top: boolean }) {
    // TODO: Fix the URL
    // const apiUrl = IS_PRODUCTION ? ENV.apiUrl : 'https://app.bus.busworld24.com/tomcat/';

    const { data } = await callApi(`public/findTripCompanies`, { params });

    return fromJsonArray(TripCompany, data);
}

export const useFindTripCompanies = (params: { top: boolean }) =>
    useQuery({
        queryKey: ['trip-companies', params],
        queryFn: async () => getTripCompanies(params),
        refetchOnWindowFocus: false,
    });
