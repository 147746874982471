import { DateTime } from 'luxon';

import { Entity } from '@/entity/A_Entity';
import { AccountIdName } from '@/entity/account/AccountIdName';
import { CompanyIdName } from '@/entity/company/CompanyIdName';
import { fromJsonArray } from '@/entity/index';

export class Fleet extends Entity {
    public name: string;

    public externalFleetId?: string;

    public expiry?: DateTime;

    public companies: CompanyIdName[];

    public accounts: AccountIdName[];

    constructor(json: Record<string, any>) {
        super(json);

        this.name = json.name;
        this.externalFleetId = json.externalFleetId;
        this.expiry = json.expiry ? DateTime.fromISO(json.expiry) : undefined;
        this.companies = json.companies ? fromJsonArray(CompanyIdName, json.companies) : [];
        this.accounts = json.accounts ? fromJsonArray(AccountIdName, json.accounts) : [];
    }

    public toString(): string {
        return this.name;
    }
}
