import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { type FormFieldProps, makeFieldIds } from '@/components/form/mod';

export interface RadioButtonGroupOptions {
    value: boolean | string;
    label: string | number | React.ReactElement;
    id: string;
}

interface RadioButtonGroupProps extends FormFieldProps {
    readonly options: RadioButtonGroupOptions[];
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    name,
    id,
    options,
    label,
    helperText,
    fieldRef,
    ...formControlProps
}) => {
    const { field, fieldState } = useController({ name });
    const { onChange, onBlur, value, ref } = field;
    const { error } = fieldState;

    const isError = Boolean(error);
    const [uid, helperUid, labelUid] = makeFieldIds(name, id);

    const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const parsedValue = value === 'true' ? true : value === 'false' ? false : value;

        onChange?.(parsedValue);
    };

    return (
        <FormControl error={isError} {...formControlProps}>
            <FormLabel id={labelUid} component="legend" htmlFor={uid}>
                {label}
            </FormLabel>

            <RadioGroup id={uid} name={name} value={value} onChange={handleOnChange} onBlur={onBlur}>
                {options.map(option => (
                    <FormControlLabel
                        key={option.id}
                        ref={fieldRef}
                        value={option.value}
                        control={<Radio inputRef={ref} />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>

            <FormHelperText id={helperUid}>{isError ? <ErrorMessage error={error} /> : helperText}</FormHelperText>
        </FormControl>
    );
};
