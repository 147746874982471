import { Avatar, useTheme, type AvatarProps } from '@mui/material';
import React, { forwardRef } from 'react';

import { AvatarIcon } from './avatar-icon';

import { iff } from '@/utils/helpers';

export interface FormAvatarProps extends AvatarProps {
    type: 'pax' | 'location' | 'start location' | 'end location' | 'trip company';
    color?: 'primary' | 'warning' | 'error' | 'info' | 'success';
    isDraggable?: boolean;
}

export const FormAvatar: React.FC<FormAvatarProps> = forwardRef(
    ({ type, color = 'primary', isDraggable, ...props }, ref) => {
        const theme = useTheme();

        return (
            <Avatar
                ref={ref}
                {...props}
                sx={{
                    [theme.breakpoints.down('sm')]: {
                        width: 35,
                        height: 35,
                    },
                    backgroundColor(theme) {
                        return theme.vars.palette[color].main;
                    },
                    ':hover': iff(isDraggable, { cursor: 'grab' }),
                    ...props.sx,
                }}>
                <AvatarIcon type={type} />
            </Avatar>
        );
    },
);
