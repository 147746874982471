import { Unstable_Grid2 as Grid } from '@mui/material';
import { type Grid2Props } from '@mui/material/Unstable_Grid2';
import React from 'react';

interface GridTileProps {
    readonly md?: Grid2Props['md'];
    readonly lg?: Grid2Props['lg'];
}

export const GridTile: React.FC<React.PropsWithChildren<GridTileProps>> = ({ children, md = true, lg = false }) => {
    return (
        <Grid
            xs={12}
            md={md}
            lg={lg}
            sx={{
                flexGrow: 1,
            }}>
            {children}
        </Grid>
    );
};
