import { Stack, Typography } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButtonGroup } from '@/components/form/fields/radio-button-group';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAuth } from '@/core/auth/mod';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

const DriverRoomsChoice: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { integration } = useAppCtx();

    const company = integration?.companyName ?? t('driver_rooms_choice.modal.company');

    const options = [
        {
            value: true,
            label: t('driver_rooms_choice.modal.company_provides', { company }),
            id: uniqueId(),
        },
        {
            value: false,
            label: user?.isOperatorOrDispatcher()
                ? t('driver_rooms_choice.modal.customer_provides')
                : t('driver_rooms_choice.modal.i_provide'),
            id: uniqueId(),
        },
    ];

    return (
        <Stack gap={3}>
            <Typography variant="body1">
                <SanitizedInnerHTML element="span" html={t('driver_rooms_choice.modal.content', { company })} />
            </Typography>
            <RadioButtonGroup name="companyProvidesDriverRooms" options={options} />
        </Stack>
    );
};

export default DriverRoomsChoice;
