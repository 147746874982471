import { type AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

const REG_FILENAME = /filename\s*=\s*(["'])\s*(?<fileName>.+?)\s*\1/i;

export function fileFromContent(content: string, fileName: string): File {
    return new File([content], fileName);
}

export function fileFromResponse(response: AxiosResponse<BlobPart>): File {
    const fileName = fileNameFromResponse(response);
    return new File([response.data], fileName);
}

export const fileNameFromResponse = (response: AxiosResponse<BlobPart>): string => {
    const dispo: string | undefined = response.headers['content-disposition'];

    const matches: RegExpGroups<'fileName'> = dispo ? REG_FILENAME.exec(dispo) : null;

    return matches?.groups?.fileName ?? `${DateTime.now().toISO()}`;
};

export function downloadFile(file: File, overrideFileName?: string) {
    const url = URL.createObjectURL(file);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', overrideFileName ?? file.name);

    // Add the link to the document and click it
    document.body.append(link);
    link.click();

    // Cleanup
    link.remove();
    URL.revokeObjectURL(url);
}
