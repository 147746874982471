import { QueryClient } from '@tanstack/react-query';

/**
 *********************************************************************
 * React Query Client: Default Configuration                         *
 *********************************************************************
 */
export const QUERY_CLIENT = new QueryClient({
    defaultOptions: {
        queries: {
            // do not re-fetch on window focus
            refetchOnWindowFocus: false,
            // cache 1 second
            staleTime: 1000,
            // no silent retries
            retry: 0,
        },
    },
});
