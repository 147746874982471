import { type TourPlan } from '@/core/tour/mod';

// SearchForm tour
export type SearchFormBasicTour = 'pax' | 'from_location' | 'from_time' | 'to_location';

// t('tour:search_form_basic_tour.pax.desc')
// t('tour:search_form_basic_tour.pax.title')
// t('tour:search_form_basic_tour.from_location.desc')
// t('tour:search_form_basic_tour.from_location.title')
// t('tour:search_form_basic_tour.from_time.desc')
// t('tour:search_form_basic_tour.from_time.title')
// t('tour:search_form_basic_tour.to_location.desc')
// t('tour:search_form_basic_tour.to_location.title')
export const SearchFormBasicTourPlan: TourPlan<SearchFormBasicTour> = {
    name: 'search_form_basic_tour',
    order: ['pax', 'from_location', 'from_time', 'to_location'],
};
