import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BACK_ITEM } from '@/components/page/header/mod';
import { NavItem } from '@/components/page/navigation/nav-item';
import { type UseNavigation } from '@/components/page/navigation/useNavigation';

export const DesktopNavigation = ({ items, disableBack }: UseNavigation) => {
    const { t } = useTranslation();

    return (
        <>
            {!disableBack && (
                <>
                    <NavItem {...BACK_ITEM}>{t('back_to_home')}</NavItem>
                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{ borderColor: theme => theme.vars.palette.primary.main }}
                    />
                </>
            )}
            {items.map((item, i) => (
                <NavItem key={i} {...item} icon={undefined} />
            ))}
        </>
    );
};
