import { useQuery } from '@tanstack/react-query';

import callApi from '@/api';

export async function getCompanyRegistrationTemplate() {
    const { data } = await callApi('public/companyRegistrationTemplate');
    return data;
}
export const useCompanyRegistrationTemplate = () =>
    useQuery({
        queryKey: ['company-registration-template'],
        queryFn: getCompanyRegistrationTemplate,
        refetchOnWindowFocus: false,
    });
