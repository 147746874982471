import { type IStop, type IJourney, type IRoute } from '@/features/journey-planning';
import { SEARCH_FORM } from '@/utils/constants';

type ToStop = Partial<Pick<IStop, 'location' | 'tripCompany'>>;
type FromStop = Partial<Pick<IStop, 'location' | 'departureDateTime'>>;

export const defaultJourneyFormValues = (pax?: number, fromStop?: FromStop, toStop?: ToStop): IJourney => ({
    companyProvidesDriverRooms: false,
    applicableSchool: false,
    onlyOwnBuses: false,
    makePTVRequest: false,
    routes: [defaultRouteValues(pax, fromStop, toStop)],
});

export function defaultRouteValues(pax?: number, fromStop?: FromStop, toStop?: ToStop): IRoute {
    return {
        pax: pax ?? SEARCH_FORM.defaultPax,
        considerSplit: false,
        confirmSplit: false,
        stops: [
            {
                location: fromStop?.location ?? null,
                departureDateTime: fromStop?.departureDateTime ?? SEARCH_FORM.defaultDepartureDateTime,
                arrivalDateTime: null,
                localBus: null,
                tripCompany: null,
            },
            {
                location: toStop?.location ?? null,
                departureDateTime: null,
                arrivalDateTime: null,
                localBus: null,
                tripCompany: toStop?.tripCompany ?? null,
            },
        ],
    };
}
