import { Box, MenuList, Tooltip } from '@mui/material';
import { useEffect, useState, type FC, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentSelectorItem } from './payment-selector-item';
import { PriceOverrideInput } from './price-override-input';

import { ActionLoadingButton } from '@/components/elements/action/action-loading-button';
import { type ActionLoadingButtonProps } from '@/components/elements/action/mod';
import { useMoneyUtils } from '@/core/localization/mod';
import { type Money } from '@/entity/basic/Money';
import { type PriceOption } from '@/entity/events/task/PriceOption';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';

interface PriceOverrideProps {
    readonly result: BookingSearchResult;
    readonly pax: number;
    readonly label: string;
    readonly disabled?: boolean;
    readonly disabledTooltip?: string;
    readonly loading?: boolean;
    readonly buttonProps?: ActionLoadingButtonProps;
    readonly onOverride: (overridePrice: Money) => void;
}

export const PriceOverride: FC<PriceOverrideProps> = ({
    result,
    pax,
    label,
    disabled,
    disabledTooltip,
    loading,
    buttonProps,
    onOverride,
}) => {
    const { price, priceOptions } = result;
    const { convertToSelectedCurrency, convertToCurrency } = useMoneyUtils();
    const { t } = useTranslation();

    const [overrideValue, setOverrideValue] = useState(convertToSelectedCurrency(price));

    const priceOverrideDialog = useDisclosure();

    useEffect(() => {
        setOverrideValue(convertToSelectedCurrency(price));
    }, [convertToSelectedCurrency, price]);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        priceOverrideDialog.open();
        buttonProps?.onClick?.(event);
    };

    const handleChange = (value: Money) => setOverrideValue(value);

    const handleOverride = () => {
        onOverride(convertToCurrency(overrideValue, price.currency));
        priceOverrideDialog.close();
    };

    const handleSelect = (priceOption: PriceOption) => setOverrideValue(convertToSelectedCurrency(priceOption.price));

    const button = (
        <ActionLoadingButton
            fullWidth
            category="primary"
            loading={loading}
            disabled={disabled}
            {...buttonProps}
            onClick={handleClick}>
            {label}
        </ActionLoadingButton>
    );

    return (
        <>
            {disabled ? (
                <Tooltip disableFocusListener title={disabledTooltip}>
                    <Box sx={{ width: '100%', m: 0, p: 0 }}>{button}</Box>
                </Tooltip>
            ) : (
                button
            )}
            <Dialog
                {...priceOverrideDialog}
                title={t('operator.price_override.title')}
                variant="confirm"
                actions={{
                    ok: handleOverride,
                }}>
                <>
                    <MenuList>
                        {priceOptions.map(priceOption => {
                            const priceDisplayRows = result.priceOptionDisplayRows.find(
                                row => row.id === priceOption.id,
                            )?.priceDisplayRows;

                            return (
                                <PaymentSelectorItem
                                    key={priceOption.id}
                                    priceOption={priceOption}
                                    priceDisplayRows={priceDisplayRows}
                                    pax={pax}
                                    seats={result.bus.getTotalSeats()}
                                    onSelect={handleSelect}
                                />
                            );
                        })}
                    </MenuList>
                    <PriceOverrideInput
                        conversionWarnCurrency={result.price.currency}
                        value={overrideValue}
                        onChange={handleChange}
                    />
                </>
            </Dialog>
        </>
    );
};
