import { type GoogleMap } from '@react-google-maps/api';
import { type MutableRefObject, createContext } from 'react';

import { defaultFilterCounts } from '../constants/filter';

import { type FilterValue, type Filter, type FilterCounts } from './filter';

import { type TripCompany } from '@/entity/trip/company/TripCompany';

export interface PopupState {
    readonly position?: google.maps.LatLng;
    readonly company?: TripCompany;
}

export interface ITripCompanyContext {
    isFetching: boolean;
    pagedCompanies: TripCompany[];
    filteredCompanies: TripCompany[];
    filterCounts: FilterCounts;
    filter: Filter;
    filterLoading: boolean;
    pagination: {
        maxPage: number;
        currentPage: number;
        setPage: (page: number) => void;
    };
    hoveredCompany: number;
    mapRef: MutableRefObject<GoogleMap | null>;
    setFilter(name: keyof FilterCounts, value: FilterValue): void;
    resetFilter(): void;
    setHoveredCompany(id: number): void;
    calculateMapMarkers(): void;
}

export const TripCompanyContext = createContext<ITripCompanyContext>({
    isFetching: false,
    mapRef: { current: null },
    filteredCompanies: [],
    pagedCompanies: [],
    filterCounts: defaultFilterCounts,
    filter: { categoriesName: new Set(), categoriesGroup: new Set(), attributes: new Set(), provinces: new Set() },
    filterLoading: false,
    pagination: { maxPage: 0, currentPage: 0, setPage() {} },
    hoveredCompany: 0,
    setFilter() {},
    resetFilter() {},
    setHoveredCompany() {},
    calculateMapMarkers() {},
});
