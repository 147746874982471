import { createFileRoute, redirect } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

import { BookingStep } from '@/entity/booking-progress/BookingProgress';

const SEARCH_PARAMS = object({
    token: string().required(),
});

export const Route = createFileRoute('/customer-offer')({
    beforeLoad() {
        return {
            step: BookingStep.CUSTOMER_OFFER,
        };
    },
    validateSearch: (search: Record<string, unknown>): InferType<typeof SEARCH_PARAMS> => SEARCH_PARAMS.cast(search),
    onError(error) {
        if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as CustomerOfferRoute };
