import { Popover, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import { ActionButtonGroup } from '@/components/elements/action/action-button-group';
import { Heading } from '@/components/elements/heading';
import { useTour } from '@/core/tour/mod';

type Transition = 'next' | 'previous' | 'stop' | 'finish' | 'abort';

export const TourPopover: React.FC = () => {
    const { t } = useTranslation('tour');
    const { previous, next, stop, finish, abort, status, currentStepRef, currentStep, currentStepIdx, plan } =
        useTour<any>();
    const [open, setOpen] = useState(false);
    const [nextTransition, setNextTransition] = useState<Transition>();
    const [transitionDone, setTransitionDone] = useState<boolean>(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNextTransition = (transition: Transition) => () => {
        if (!transitionDone) return;

        setOpen(false);
        setNextTransition(transition);
    };

    const handleClose = (_e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') handleNextTransition('abort')();
        else handleNextTransition('stop')();
    };

    // Execute transition
    useEffect(() => {
        if (transitionDone) return;

        switch (nextTransition) {
            case 'next': {
                next();
                break;
            }
            case 'previous': {
                previous();
                break;
            }
            case 'stop': {
                stop();
                break;
            }
            case 'finish': {
                finish();
                break;
            }
            case 'abort': {
                abort();
                break;
            }
            default: {
                break;
            }
        }

        setTransitionDone(true);
    }, [abort, finish, next, nextTransition, previous, stop, transitionDone]);

    // Reset transition after state update and set open if started
    useEffect(() => {
        if (status === 'started' && transitionDone) setOpen(true);
    }, [status, transitionDone]);

    if (status === 'setup' || status === 'finished' || status === 'aborted' || currentStepRef == null) return null;

    return (
        <Popover
            sx={{
                zIndex(theme) {
                    return `calc(${theme.vars.zIndex.modal} - 1)`;
                },
            }}
            open={currentStepRef != null && open}
            anchorEl={currentStepRef}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        maxWidth: isMobile ? `calc(100vw - ${theme.spacing(4)})` : theme.breakpoints.values.md / 2,
                        width: '100%',
                        p: 2,
                        my: 1,
                    },
                },
            }}
            TransitionProps={{
                onExited() {
                    setTransitionDone(false);
                },
            }}
            onClose={handleClose}>
            <Stack spacing={2}>
                <Heading
                    primary
                    disableGutterBottom
                    title={t(`${plan.name}.${currentStep}.title`)}
                    subtitle={t('step', { current: currentStepIdx + 1, total: plan.order.length })}
                />
                <Typography variant="body2">{t(`${plan.name}.${currentStep}.desc`)}</Typography>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <ActionButton
                        gaEvent={{ action: 'tour', category: 'hide' }}
                        size="small"
                        category="secondary"
                        intent="negative"
                        onClick={handleNextTransition('abort')}>
                        {t('hide')}
                    </ActionButton>
                    <ActionButtonGroup category="secondary" size="small">
                        {currentStepIdx > 0 && (
                            <ActionButton category="secondary" onClick={handleNextTransition('previous')}>
                                {t('previous')}
                            </ActionButton>
                        )}
                        {currentStepIdx < plan.order.length - 1 ? (
                            <ActionButton category="secondary" onClick={handleNextTransition('next')}>
                                {t('next')}
                            </ActionButton>
                        ) : (
                            <ActionButton
                                gaEvent={{ action: 'tour', category: 'finished' }}
                                category="secondary"
                                intent="positive"
                                onClick={handleNextTransition('finish')}>
                                {t('finish')}
                            </ActionButton>
                        )}
                    </ActionButtonGroup>
                </Stack>
            </Stack>
        </Popover>
    );
};
