import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import PersonPinCircleOutlined from '@mui/icons-material/PersonPinCircleOutlined';
import PinDropOutlined from '@mui/icons-material/PinDropOutlined';

import { useLayout } from '@/components/page/layout/mod';
import { type FormAvatarProps } from '@/features/journey-planning';

interface AvatarIconProps {
    readonly type: FormAvatarProps['type'];
}

export const AvatarIcon = ({ type }: AvatarIconProps) => {
    const { isDesktop } = useLayout();

    if (type === 'pax') return <GroupOutlined fontSize={isDesktop ? 'medium' : 'small'} />;
    if (type === 'start location') return <PersonPinCircleOutlined fontSize={isDesktop ? 'medium' : 'small'} />;
    if (type === 'location') return <LocationOnOutlined fontSize={isDesktop ? 'medium' : 'small'} />;
    if (type === 'end location') return <PinDropOutlined fontSize={isDesktop ? 'medium' : 'small'} />;
    if (type === 'trip company') return <FlagOutlinedIcon fontSize={isDesktop ? 'medium' : 'small'} />;
    return null;
};
