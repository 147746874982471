import React from 'react';

import { ActionRow } from './action-row';
import { PaxRow } from './pax-row';
import { StopsArray } from './stops/stops-array';

interface RouteFormProps {
    readonly routeIndex: number;
}

export const RouteForm: React.FC<RouteFormProps> = ({ routeIndex }) => {
    return (
        <>
            <input type="hidden" name={`routes.${routeIndex}.uuid`} />
            <PaxRow routeIndex={routeIndex} />
            <StopsArray routeIndex={routeIndex} />
            <ActionRow routeIndex={routeIndex} />
        </>
    );
};
