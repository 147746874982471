import { type TFunction } from 'i18next';
import { DateTime, type Duration } from 'luxon';

import { Location } from '@/entity/basic/Location';
import { LegDetails, displayStatus } from '@/entity/journey/stop/LegDetails';
import { LocalBus } from '@/entity/journey/stop/LocalBus';
import { BookingTripCompany } from '@/entity/trip/company/BookingTripCompany';
import { LOCAL_BUS_MIN_KM, LOCAL_BUS_KM_PER_STAY_DAY } from '@/features/journey-planning';

export type BookingStopStatus = 'FULL_BREAK' | 'CONSIDER_SPLIT_ROUTE' | 'SPLIT_ROUTE';
export type BookingStopStatusDisplayable = Exclude<BookingStopStatus, 'FULL_BREAK'> | 'LOCAL_BUS_AND_SPLIT_ROUTE';
export class BookingStop {
    public static getLocalBusMinAndMaxKm(
        departureDateTime: DateTime | null,
        arrivalDateTime: DateTime | null,
    ): [number, number] {
        if (departureDateTime == null || arrivalDateTime == null) return [LOCAL_BUS_MIN_KM, LOCAL_BUS_KM_PER_STAY_DAY];

        const stayDuration = departureDateTime.diff(arrivalDateTime).normalize();
        // The maximum distance for a local bus is the number of days they stay at the stop times 200km
        const maxLocalBusKm = Math.max(Math.floor(stayDuration.as('days')), 1) * LOCAL_BUS_KM_PER_STAY_DAY;

        return [LOCAL_BUS_MIN_KM, maxLocalBusKm];
    }

    public uuid: string;

    public arrivalDateTime: DateTime;

    public departureDateTime: DateTime;

    public stayDuration: Duration;

    public location: Location;

    public localBus?: LocalBus;

    public legDetails?: LegDetails;

    public statuses: BookingStopStatus[];

    public tripCompany?: BookingTripCompany;

    // TODO: BUF-401: Do the actual type here
    public tripCompanyStatus?: unknown;

    constructor(json: Record<string, any>) {
        this.uuid = json.uuid;
        this.arrivalDateTime = DateTime.fromISO(json.arrivalDateTime);
        this.departureDateTime = DateTime.fromISO(json.departureDateTime);
        this.stayDuration = this.departureDateTime.diff(this.arrivalDateTime).normalize();
        this.location = Location.fromJson(json.location);
        this.localBus = json.localBus ? LocalBus.fromJson(json.localBus) : undefined;
        this.legDetails = json.legDetails ? new LegDetails(json.legDetails) : undefined;
        this.statuses = json.statuses ?? [];
        this.tripCompany = json.tripCompany ? new BookingTripCompany(json.tripCompany) : undefined;
        this.tripCompanyStatus = json.tripCompanyStatus;
    }

    public toString(): string {
        return `${this.location.toString()}_${this.arrivalDateTime.toString()}`;
    }

    public tFullBreak(t: TFunction): string | undefined {
        if (this.statuses.includes('FULL_BREAK')) return t('stop.status.full_break');

        return undefined;
    }

    public tConsiderSplitRoute(t: TFunction): string | undefined {
        if (this.statuses.includes('CONSIDER_SPLIT_ROUTE')) return t('stop.status.consider_split_route');

        return undefined;
    }

    public tSplitRoute(t: TFunction): string | undefined {
        if (this.statuses.includes('SPLIT_ROUTE')) return t('stop.status.split_route');

        return undefined;
    }

    public getAlerts() {
        const displayableStatuses = this.statuses.map(s => {
            if ((s === 'CONSIDER_SPLIT_ROUTE' || s === 'SPLIT_ROUTE') && this.localBus != null)
                return 'LOCAL_BUS_AND_SPLIT_ROUTE';
            if (s === 'FULL_BREAK') return null;
            return s;
        });

        return displayableStatuses.filter(Boolean).map(s => displayStatus(s!));
    }

    public hasLocalBusAndSplitRoute(): boolean {
        return this.statuses.some(s => s === 'CONSIDER_SPLIT_ROUTE' || s === 'SPLIT_ROUTE') && this.localBus != null;
    }

    public exceedsMaxOperatingTime(): boolean {
        return this.legDetails?.hasStatus('MAX_OPERATING_TIME_EXCEEDED') ?? false;
    }

    public hasSplitStatus(): boolean {
        return this.statuses.some(s => s === 'CONSIDER_SPLIT_ROUTE' || s === 'SPLIT_ROUTE');
    }
}
