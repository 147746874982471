import { useNavigate } from '@tanstack/react-router';
import React, { useEffect, useMemo } from 'react';

import { useBookingJourney } from '@/api/journey/booking/queries';
import { JourneyPlanningContext } from '@/core/journey-planning/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';

interface JourneyPlanningProviderProps {
    readonly token?: string;
    readonly amendBookingToken?: string;
}

export const JourneyPlanningProvider: React.FC<React.PropsWithChildren<JourneyPlanningProviderProps>> = ({
    token,
    amendBookingToken,
    children,
}) => {
    // Fetch Journey
    const { data: journey, isError } = useBookingJourney({
        step: BookingStep.ROUTE_PLANNING,
        token,
        amendBookingToken,
    });

    const navigate = useNavigate();

    // Clear token search param if there was an error
    useEffect(() => {
        if (isError) void navigate({ to: '/', search: {} });
    }, [isError, navigate]);

    const value = useMemo(
        () => ({
            journey,
        }),
        [journey],
    );

    return <JourneyPlanningContext.Provider value={value}>{children}</JourneyPlanningContext.Provider>;
};
