import CreditCard from '@mui/icons-material/CreditCard';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentOption } from '@/entity/common-constants';
import { type PriceOption } from '@/entity/events/task/PriceOption';

interface PaymentIconProps {
    readonly selectedPriceOption?: PriceOption;
}

export const PaymentIcon: React.FC<PaymentIconProps> = ({ selectedPriceOption }) => {
    const { t } = useTranslation();
    if (!selectedPriceOption) return null;

    if (selectedPriceOption.paymentOption === PaymentOption.ONLINE)
        return (
            <Tooltip
                arrow
                placement="top"
                title={<Typography component="legend">{t(`price_options.${selectedPriceOption.id}`)}</Typography>}>
                <CreditCard sx={{ fontSize: theme => theme.typography.price.fontSize }} />
            </Tooltip>
        );

    return (
        <Tooltip
            arrow
            placement="top"
            title={<Typography component="legend">{t(`price_options.${selectedPriceOption.id}`)}</Typography>}>
            <ReceiptLongOutlined sx={{ fontSize: theme => theme.typography.price.fontSize }} />
        </Tooltip>
    );
};
