import { Entity } from '@/entity/A_Entity';

export class BusCategory extends Entity {
    public name: string;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
    }
}
