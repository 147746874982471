import { Stack } from '@mui/material';
import { uniqueId } from 'lodash';
import { type FC } from 'react';

import { AttributeIcon } from './attribute-icon';

import { type TripAttribute } from '@/entity/trip/attribute/TripAttribute';

interface AttributeIconsProps {
    readonly attributes: TripAttribute[];
}

export const AttributeIcons: FC<AttributeIconsProps> = ({ attributes }) => {
    if (attributes.length === 0) return null;

    return (
        <Stack direction="row" gap={1} alignItems="center">
            {attributes.map(attribute => (
                <AttributeIcon key={uniqueId(attribute.icon)} attribute={attribute} />
            ))}
        </Stack>
    );
};
