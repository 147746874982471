import { Company } from '@/entity/company/Company';

export class DefaultTripCompany extends Company {
    public subtitle: string;

    public recommendedDurationOfStayInMinutes: number;

    public bookableOnline: boolean;

    public shortDescription?: string;

    public bookingConditions?: string;

    public productInformation?: string;

    public top: boolean;

    constructor(json: Record<string, any>) {
        super(json);

        this.subtitle = json.subtitle;
        this.recommendedDurationOfStayInMinutes = json.recommendedDurationOfStayInMinutes;
        this.bookableOnline = json.bookableOnline;
        this.shortDescription = json.shortDescription;
        this.bookingConditions = json.bookingConditions;
        this.productInformation = json.productInformation;
        this.top = json.top;
    }
}
