import { Account } from '@/entity/account/Account';
import { BusCompany } from '@/entity/company/bus/BusCompany';
import { Fleet } from '@/entity/fleet/Fleet';

export class Operator extends Account {
    public company: BusCompany;

    public fleet?: Fleet;

    constructor(json: Record<string, any>) {
        super(json);
        this.company = new BusCompany(json.company);
        this.fleet = json.fleet ? new Fleet(json.fleet) : undefined;
    }
}
