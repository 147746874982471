import { createContext, useContext, type RefCallback } from 'react';

export const CLASS_TOUR_HIGHLIGHT = 'tour-highlight';

interface TourNode {
    startOnFocus?: boolean;
}

// Tour status
export type TourStatus = 'setup' | 'ready' | 'started' | 'stopped' | 'aborted' | 'finished';

// Defines the order of the tour
export interface TourPlan<K extends string = string> {
    name: string;
    order: K[];
    nodes?: Partial<Record<K, TourNode>>;
}

export interface ITourContext<T extends string> {
    plan: TourPlan<T>;
    currentStep: string;
    currentStepIdx: number;
    currentStepRef?: HTMLElement | null;
    status: TourStatus;
    register: (highlight: T) => RefCallback<HTMLElement>;
    start: () => void;
    pause: () => void;
    stop: () => void;
    previous: () => void;
    next: () => void;
    finish: () => void;
    abort: () => void;
    reset: () => void;
}

export const TourContext = createContext<ITourContext<any>>({
    plan: {
        name: '',
        order: [],
        nodes: {},
    },
    currentStep: '',
    currentStepIdx: 0,
    status: 'setup',
    register: () => () => {},
    start() {},
    pause() {},
    stop() {},
    previous() {},
    next() {},
    finish() {},
    abort() {},
    reset() {},
});

export function useTour<T extends string>(): ITourContext<T> {
    return useContext<ITourContext<T>>(TourContext);
}
