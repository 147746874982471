export const SESSION_STORAGE_KEYS = {
    JOURNEY: 'journey',
};

export const getStorageJourneyToken = () => {
    const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.JOURNEY);
    return token ?? undefined;
};

export const setStorageJourneyToken = (token: string): void => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.JOURNEY, token);
};

export const removeStorageJourneyToken = (): void => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.JOURNEY);
};
