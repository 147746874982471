import { createFileRoute, redirect } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

const PASSWORD_RESET_SEARCH_PARAMS = object({
    token: string().required(),
});

export const Route = createFileRoute('/login/password-reset')({
    validateSearch(search): InferType<typeof PASSWORD_RESET_SEARCH_PARAMS> {
        return PASSWORD_RESET_SEARCH_PARAMS.cast(search);
    },
    onError(error) {
        if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as PasswordResetRoute };
