import type { ITripCompany } from '@/features/journey-planning';

export class BookingTripCompany {
    public id: number;
    public companyName: string;
    public recommendedDurationOfStayInMinutes: number;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.companyName = json.companyName;
        this.recommendedDurationOfStayInMinutes = json.recommendedDurationOfStayInMinutes;
    }

    public getFormValues(): ITripCompany {
        return {
            id: this.id,
            companyName: this.companyName,
            recommendedDurationOfStayInMinutes: this.recommendedDurationOfStayInMinutes,
        };
    }
}
