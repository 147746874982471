import { type ObjectSchema, array, boolean, object, string } from 'yup';

import { type IRoute, RouteSchema } from './route';

export interface IJourney {
    token?: string;
    name?: string;
    routes: IRoute[];
    applicableSchool: boolean;
    onlyOwnBuses: boolean;
    companyProvidesDriverRooms: boolean;
    makePTVRequest: boolean;
}

export const JourneySchema: ObjectSchema<IJourney> = object({
    token: string(),
    name: string(),
    routes: array(RouteSchema).defined().min(1),
    applicableSchool: boolean().default(false),
    onlyOwnBuses: boolean().default(false),
    companyProvidesDriverRooms: boolean().default(false),
    makePTVRequest: boolean().default(false),
});
