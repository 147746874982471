import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabularData } from '@/components/elements/tabular-data';
import { useMoneyUtils, useLocalizedFormatters } from '@/core/localization/mod';
import { PriceDisplayType, type PriceDisplayRow } from '@/entity/events/task/PriceDisplayRow';

interface DiscountTableProps {
    readonly priceDisplayRows?: PriceDisplayRow[];
}

export const DiscountTable: React.FC<DiscountTableProps> = ({ priceDisplayRows }) => {
    const { convertToSelectedCurrency, isSelectedCurrency } = useMoneyUtils();
    const { formatMoney, formatDateTime } = useLocalizedFormatters();
    const { t } = useTranslation();

    const basePrice = priceDisplayRows?.find(row => row.type === PriceDisplayType.BASE)?.price;
    const finalPrice = priceDisplayRows?.find(row => row.type === PriceDisplayType.FINAL)?.price;
    const discountDisplayRows = priceDisplayRows?.filter(row => row.discount && row.discount.amount < 0);

    const getDiscountsSection = () => {
        if (!discountDisplayRows) return [[]];

        const discountTableRows = discountDisplayRows.flatMap(row => {
            const discountRow = {
                label: t(`discounts.${row.type}`),
                value: formatMoney(convertToSelectedCurrency(row.discount!)),
            };

            if (row.discountValidUntil?.isValid) {
                return [
                    discountRow,
                    {
                        label: (
                            <Typography noWrap variant="body2">
                                {t('discount_valid_until', {
                                    interpolation: { escapeValue: false },
                                    date: formatDateTime(row.discountValidUntil, DateTime.DATE_SHORT),
                                })}
                            </Typography>
                        ),
                        value: null,
                        cellVariant: 'body' as const,
                    },
                ];
            }

            return [discountRow];
        });

        const basePriceTableRow = {
            label: null,
            value: formatMoney(convertToSelectedCurrency(basePrice!)),
        };

        return [[basePriceTableRow, ...discountTableRows]];
    };

    const getSummary = () => {
        if (!finalPrice) return [];
        return [
            {
                label: null,
                value: (
                    <Typography variant="body2" color="success.main">
                        {formatMoney(convertToSelectedCurrency(finalPrice))}
                    </Typography>
                ),
            },
            ...(isSelectedCurrency(finalPrice)
                ? []
                : [
                      {
                          label: null,
                          value: <Typography variant="body3">{formatMoney(finalPrice)}</Typography>,
                      },
                  ]),
        ];
    };

    const sections = getDiscountsSection();
    const summary = getSummary();

    return <TabularData sections={sections} summary={summary} textAlign="right" />;
};
