import { Entity } from '@/entity/A_Entity';
import { ContactData } from '@/entity/basic/ContactData';
import { Document } from '@/entity/basic/Document';
import { Location } from '@/entity/basic/Location';
import { ReviewScores } from '@/entity/company/ReviewScores';

export class BookingBusCompany extends Entity {
    public companyName: string;

    public logo?: Document;

    public uidNumber: string;

    public agb: string;

    public paymentConditions: string;

    public reversalConditions: string;

    public confirmationConclusion: string;

    public contactData: ContactData;

    public location: Location;

    public description: string;

    public dummy: boolean;

    public virtual: boolean;

    public freeCancellation: boolean;

    public freeCancellationDays: number;

    public offerDefaultDaysValidBeforeStart: number;

    public offerDefaultDaysRemindBeforeOfferEnds: number;

    public reviewScores: ReviewScores;

    // Hours before the booking starts for the buses to bookable
    public bookingLeadTime: number;

    public emergencyContactNumber: String;

    constructor(json: Record<string, any>) {
        super(json);
        this.companyName = json.companyName;
        this.logo = json.logo ? Document.fromJson(json.logo) : undefined;
        this.uidNumber = json.uidNumber;
        this.agb = json.agb;
        this.paymentConditions = json.paymentConditions;
        this.reversalConditions = json.reversalConditions;
        this.confirmationConclusion = json.confirmationConclusion;
        this.contactData = ContactData.fromJson(json.contactData);
        this.location = Location.fromJson(json.location);
        this.description = json.description;
        this.dummy = json.dummy;
        this.virtual = json.virtual;
        this.freeCancellation = json.freeCancellation;
        this.freeCancellationDays = json.freeCancellationDays;
        this.offerDefaultDaysValidBeforeStart = json.offerDefaultDaysValidBeforeStart;
        this.offerDefaultDaysRemindBeforeOfferEnds = json.offerDefaultDaysRemindBeforeOfferEnds;
        this.reviewScores = new ReviewScores(json.reviewScores);
        this.bookingLeadTime = json.bookingLeadTime;
        this.emergencyContactNumber = json.emergencyContactNumber;
    }
}
