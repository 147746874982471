import { Box, styled } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

import { type BusCardDetailedProps } from '../../types/bus-card-detailed';
import { PaymentIcons } from '../elements/payment-icons';
import { TopEquipmentsIcons } from '../elements/top-equipment-icons';
import { BusCardActions } from '../sections/bus-card-actions';
import { BusCardInfo } from '../sections/bus-card-info';
import { BusCardReview } from '../sections/bus-card-review';

import { BusCancellation } from '@/components/bus/bus-cancellation';
import { BusHeader } from '@/components/bus/bus-header';
import { BusImage } from '@/components/bus/bus-image';
import { BusPrice } from '@/components/bus/price/bus-price';
import { useAuth } from '@/core/auth/mod';

const BusCardGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    padding: theme.spacing(1),
    columnGap: theme.spacing(2),
    gridAutoFlow: 'row',
    [theme.breakpoints.down('small_mobile')]: {
        gridTemplateColumns: '0.3fr 0.7fr',
        gridTemplateRows: 'max-content max-content max-content auto max-content max-content',
        gridTemplateAreas: `
            "image image"
            "header header"
            "info info"
            "cancel cancel"
            "review price"
            "actions actions"
            `,
        '&>.review': { alignSelf: 'center' },
        '&>.equipment': { display: 'none' },
        '&>.image': { width: '85%', justifySelf: 'center' },
    },
    [theme.breakpoints.between('small_mobile', 'sm')]: {
        gridTemplateColumns: '0.35fr 0.10fr 0.55fr',
        gridTemplateRows: 'max-content max-content max-content max-content',
        gridTemplateAreas: `
            "image header header"
            "info info cancel"
            "review review price"
            "actions actions actions"
            `,
    },
    [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: '0.5fr 1.4fr 1.1fr',
        gridTemplateRows: 'max-content max-content max-content',
        gridTemplateAreas: `
            "image header price"
            "image info cancel"
            "image review actions"
            `,
    },
    [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: '0.6fr 1.3fr 1.1fr',
        gridTemplateRows: 'max-content max-content max-content',
        gridTemplateAreas: `
            "image header price"
            "image info cancel"
            "image review actions"
            `,
    },
    [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '31% minmax(20%, 30%) minmax(2%, 25%) auto',
        gridTemplateRows: 'max-content max-content max-content',
        gridTemplateAreas: `
            "image header header price"
            "image info cancel cancel"
            "image review actions actions"
            `,
    },
    '&>.item': {
        marginTop: theme.spacing(0.75),
        marginBottom: theme.spacing(0.75),
        [theme.breakpoints.down('small_mobile')]: {
            marginTop: theme.spacing(0.45),
            marginBottom: theme.spacing(0.45),
        },
        '&:empty': {
            padding: 0,
            margin: 0,
        },
    },
    '&>.image': { gridArea: 'image' },
    '&>.header': { gridArea: 'header' },
    '&>.price': { gridArea: 'price', justifySelf: 'end' },
    '&>.info': { gridArea: 'info' },
    '&>.cancel': { gridArea: 'cancel', justifySelf: 'end', alignSelf: 'end' },
    '&>.review': { gridArea: 'review' },
    '&>.actions': { gridArea: 'actions', alignSelf: 'end' },
    '&>.equipment': {
        gridArea: 'equipment',
        [theme.breakpoints.up('small_mobile')]: {
            display: 'none',
        },
    },
}));

interface CardAreaProps {
    readonly area: 'image' | 'header' | 'price' | 'equipment' | 'info' | 'cancel' | 'review' | 'actions';
}

const CardArea: FC<PropsWithChildren<CardAreaProps>> = ({ area, children }) => {
    return <div className={`item ${area}`}>{children}</div>;
};

export const BusCardDetailedDefault: FC<BusCardDetailedProps> = ({
    result,
    route,
    dragHandleProps,
    onDetails,
    onSelect,
}) => {
    const { user } = useAuth();
    const { bus } = result;

    return (
        <BusCardGrid
            {...dragHandleProps}
            sx={{
                cursor: dragHandleProps ? 'grab' : undefined,
                backgroundColor(theme) {
                    return user?.isOwnerOfSearchResult(result)
                        ? `color-mix(in srgb, ${theme.vars.palette.primary.light} 8%, transparent)`
                        : theme.vars.palette.background.paper;
                },
            }}>
            <CardArea area="image">
                <BusImage bus={bus} />
            </CardArea>
            <CardArea area="header">
                <BusHeader bus={bus} />
            </CardArea>
            <CardArea area="price">
                <BusPrice
                    pax={route?.pax ?? 1}
                    seats={bus.getTotalSeats()}
                    showFromString={result.priceOptions.length > 1}
                    priceDisplayRows={result.getLowestPriceOptionDisplayRows()}
                    priceToShow={result.getPriceToShow()}
                    icon={<PaymentIcons result={result} />}
                />
            </CardArea>
            <CardArea area="info">
                <BusCardInfo bus={bus} />
            </CardArea>
            <CardArea area="equipment">
                <TopEquipmentsIcons topEquipments={bus.topEquipments} />
            </CardArea>
            <CardArea area="cancel">
                <BusCancellation resultOrTask={result} align="right" />
            </CardArea>
            <CardArea area="review">
                <BusCardReview result={result} />
            </CardArea>
            <CardArea area="actions">
                <BusCardActions result={result} route={route} onDetails={onDetails} onSelect={onSelect} />
            </CardArea>
        </BusCardGrid>
    );
};
