import { useQuery } from '@tanstack/react-query';

import callApi from '@/api';
import { fromJsonArray } from '@/entity';
import { Integration } from '@/entity/integration/Integration';

async function fetchIntegrations(companyId?: number): Promise<Integration[]> {
    try {
        const endpoint =
            companyId == null
                ? 'api/booking-integrations?projection=booking'
                : `api/booking-integrations/search/findByCompanyId?companyId=${companyId}&projection=booking`;
        const { data } = await callApi(endpoint);
        return fromJsonArray(Integration, data._embedded['booking-integrations']);
    } catch (error) {
        throw error;
    }
}

export const useIntegrations = (companyId?: number) =>
    useQuery({
        queryKey: ['integrations', companyId],
        queryFn: async () => fetchIntegrations(companyId),
        initialData: [],
        enabled: true,
        staleTime: 0,
    });
