import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { useUser } from '@/api/user/queries';
import i18next, {
    DEFAULT_LANGUAGE,
    DEFAULT_REGION,
    LOCALE_COOKIE,
    SUPPORTED_REGIONS,
    getSavedCurrency,
    setLocalizationCookie,
    type Currency,
    type Language,
    type Region,
} from '@/config/localization';
import { LocalizationContext, type ILocalizationContext } from '@/core/localization/mod';
import { type AppUser } from '@/entity/account/Account';

function supportedRegion(region = ''): Region {
    return SUPPORTED_REGIONS.includes(region as Region) ? (region as Region) : DEFAULT_REGION;
}

function usersCurrency(user: AppUser | null): Currency {
    if (user?.isOperatorOrDispatcher()) return user.company.currency;
    return getSavedCurrency();
}

export const LocalizationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { i18n } = useTranslation();
    const { data: user } = useUser();

    const [currentLanguage, currentRegion] = i18n.language.split('-');

    const [region, setRegion] = React.useState<Region>(supportedRegion(currentRegion));
    const [language, setLanguage] = React.useState<Language>((currentLanguage as Language) ?? DEFAULT_LANGUAGE);
    const [currency, setCurrency] = React.useState<Currency>(usersCurrency(user));
    const [shouldSave, setShouldSave] = React.useState(false);

    useEffect(() => {
        if (shouldSave) {
            setLocalizationCookie(LOCALE_COOKIE, `${language}-${region}`);
            setShouldSave(false);
        }
    }, [shouldSave, language, region]);

    useEffect(() => {
        setCurrency(usersCurrency(user));
    }, [user]);

    const value: ILocalizationContext = React.useMemo(
        () => ({
            setShouldSave,
            region,
            setRegion,
            language,
            setLanguage,
            currency,
            setCurrency,
            locale: `${language}-${region}`,
        }),
        [region, language, currency],
    );

    return (
        <I18nextProvider i18n={i18next}>
            <LocalizationContext.Provider value={value}>
                <MuiLocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
                    {children}
                </MuiLocalizationProvider>
            </LocalizationContext.Provider>
        </I18nextProvider>
    );
};
