import { type ObjectSchema, number, object, string } from 'yup';
// Local Bus Definition
export interface ILocalBus {
    distance: number;
    description: string | null;
}

export const localBusSchema = (minDistance: number, maxDistance: number): ObjectSchema<ILocalBus> =>
    object({
        distance: number().min(minDistance).max(maxDistance).defined(),
        description: string().max(1000).defined().nullable(),
    });
