import { type Currency, getSavedCurrency } from '@/config/localization';
import { Money } from '@/entity/basic/Money';
import { fromJsonArrayWith } from '@/entity/index';

export class PriceSummary {
    // contains a list of all prices -> summarized and grouped by currency
    public total: Money[];

    // contains a list of all reduced prices (prices with applied coupon)
    // -> summarized and grouped by currency, but currently there can only be one
    public couponTotal: Money[];

    // contains a list of prices paid on invoice -> summarized and grouped by currency
    public invoice: Money[];

    // contains a list of prices paid online -> summarized and grouped by currency
    public online: Money[];

    constructor(json: Record<string, any>) {
        this.total = fromJsonArrayWith(Money.fromJson, json.total);
        this.couponTotal = fromJsonArrayWith(Money.fromJson, json.couponTotal);
        this.invoice = fromJsonArrayWith(Money.fromJson, json.invoice);
        this.online = fromJsonArrayWith(Money.fromJson, json.online);
    }

    // determines if all selected buses have the same currency
    public isSingleCurrency(): boolean {
        return this.total.length === 1;
    }

    // determines if the given currency is not already present in total prices
    public isNewCurrency(currency: Currency): boolean {
        return this.total.some(c => c.currency !== currency);
    }

    public isOnlyOnlinePayment() {
        return this.online.length > 0 && this.invoice.length === 0;
    }

    public isOnlyInvoicePayment() {
        return this.invoice.length > 0 && this.online.length === 0;
    }

    public isOnlineAndInvoicePayment() {
        return this.invoice.length > 0 && this.online.length > 0;
    }

    /** **********************************************************************************
     * Return sum of prices in original currency, if there is only a single currency.
     * Otherwise, we return zero in the user selected currency
     ********************************************************************************** */

    public getInvoicePriceInOriginalCurrency(): Money {
        return this.getOriginalCurrency(this.invoice);
    }

    public getOnlinePriceInOriginalCurrency(): Money {
        return this.getOriginalCurrency(this.online);
    }

    public getTotalPriceInOriginalCurrency(): Money {
        return this.getOriginalCurrency(this.total);
    }

    public getOriginalCurrency(prices: Money[]): Money {
        // single currency at all
        if (this.isSingleCurrency()) {
            // single currency for the given prices
            if (prices.length === 1) return prices[0];

            // no single currency for given prices, but we know in total we have a single one -> use this for zero value
            return new Money(0, this.total[0].currency);
        }

        // no prices at all -> return zero default currency
        if (this.total.length === 0) return new Money(0, getSavedCurrency());

        // multiple currencies -> throw error to prevent call of this getter (must be solved in development)
        throw new Error(
            'Failed to summarize prices in original currency. Reason: There are buses with multiple currencies selected!',
        );
    }

    public getCouponDiscountMoney() {
        const discountAmount =
            this.total.reduce((sum, current) => sum + current.amount, 0) -
            this.couponTotal.reduce((sum, current) => sum + current.amount, 0);
        return new Money(discountAmount, this.total[0].currency);
    }

    public didApplyCoupon() {
        return (
            this.couponTotal.reduce((sum, current) => sum + current.amount, 0) <
            this.total.reduce((sum, current) => sum + current.amount, 0)
        );
    }
}
