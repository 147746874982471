import { useGoogleMap } from '@react-google-maps/api';
import { type PropsWithChildren, useMemo, useEffect, type FC } from 'react';
import { createPortal } from 'react-dom';

import { type ControlPosition } from '../types';

type MapControlProps = PropsWithChildren<{
    readonly position: ControlPosition;
}>;

export const MapControl: FC<MapControlProps> = ({ children, position }) => {
    const controlContainer = useMemo(() => document.createElement('div'), []);
    const map = useGoogleMap();

    useEffect(() => {
        if (!map) return;

        const controls = map.controls[position];

        controls.push(controlContainer);

        return () => {
            const index = controls.getArray().indexOf(controlContainer);
            controls.removeAt(index);
        };
    }, [controlContainer, map, position]);

    // TODO: Fix type issue if I remove the Fragment
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20942
    return <>{createPortal(children, controlContainer)}</>;
};
