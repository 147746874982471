import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { createLayout } from '@/components/page/layout/createLayout';
import { PartnerLeafletTile, PartnerTile } from '@/features/partner-registration';

const { Layout } = createLayout({
    tiles: {
        partner_leaflet_tile: <PartnerLeafletTile />,
        partner_tile: <PartnerTile />,
    },
    base: [
        { name: 'partner_leaflet_tile', md: 4 },
        { name: 'partner_tile', md: 8, mobileBase: true },
    ],
});

export const PartnerRegistration: React.FC = () => {
    return <Layout />;
};

export const Route = createFileRoute('/partner-registration/')({
    component: PartnerRegistration,
});
