import { useMutation } from '@tanstack/react-query';

import callApi from '@/api';
import { setStorageJourneyToken } from '@/config/storage';
import { type IJourney, serializeSearchFormFields } from '@/features/journey-planning';

/**
 * Submit the Journey Planning Search Form with the given {@link IJourney}
 * On Success we persist the journey token in the storage
 */
export async function postPlanningJourney(formData: IJourney) {
    const { data } = await callApi<{ token: string }>('public/submitSearchForm', {
        method: 'POST',
        data: serializeSearchFormFields(formData),
    });
    setStorageJourneyToken(data.token);
    return data.token;
}

export const useSubmitJourney = () => {
    return useMutation({
        mutationFn: postPlanningJourney,
    });
};
