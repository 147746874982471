import { DateTime } from 'luxon';

import { type PaymentMethod } from '@/entity/basic/Gateway';

export class PaymentLog {
    public modifiedAt: DateTime;

    public paymentMethod: PaymentMethod;

    public status: string;

    constructor(json: Record<string, any>) {
        this.modifiedAt = DateTime.fromISO(json.modifiedAt);
        this.paymentMethod = json.paymentMethod;
        this.status = json.status;
    }
}
