import SaveOutlined from '@mui/icons-material/SaveOutlined';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDevJourneyPopulation } from '../api/dev-journey-population';
import { JourneySchema } from '../types/journey';

import { isAPIError } from '@/api/APIError';
import { ActionButton } from '@/components/elements/action/action-button';
import { ActionButtonGroup } from '@/components/elements/action/action-button-group';
import { CheckField } from '@/components/form/fields/check-field';
import { useLayout } from '@/components/page/layout/mod';
import { IS_PRODUCTION } from '@/config/env';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import { useAuth } from '@/core/auth/mod';
import { useModal } from '@/core/modal/mod';
import { useSnack } from '@/core/snack/mod';
import { BookingStep } from '@/entity/booking-progress/BookingProgress';
import { useJourneyPlanningForm } from '@/features/journey-planning';

interface SearchFormActionsProps {
    readonly onSubmit: () => void;
}

export const SearchFormActions: React.FC<SearchFormActionsProps> = ({ onSubmit }) => {
    const { t } = useTranslation();
    const {
        getBookingJourney,
        formMethods: {
            formState: { isValid, isValidating },
            watch,
            setValue,
        },
        selectedRouteIndex,
    } = useJourneyPlanningForm();

    const { openSaveJourneyModal } = useModal();
    const { user } = useAuth();
    const { appSettings } = useAppCtx();
    const { isDesktop } = useLayout();

    const isReseller = user?.isCustomer() && user.isReseller();
    const journey = getBookingJourney();

    const ptvIsActive = watch('makePTVRequest');
    const disableOwnBuses = useMemo(() => ptvIsActive, [ptvIsActive]);

    useEffect(() => {
        if (ptvIsActive) setValue('onlyOwnBuses', true);
    }, [ptvIsActive, setValue]);

    const handleSave = () => {
        if (!journey) return;

        openSaveJourneyModal({
            title: t('my_routes'),
            journey: journey?.getFormValues(),
            routeIndex: selectedRouteIndex,
            isLoggedIn: user != null,
        });
    };

    const showApplicableSchoolCheckbox = appSettings.searchResults.applicableSchool && (!user || isReseller);

    return (
        <Grid container columnSpacing={2} alignItems={{ sm: 'center' }} justifyContent="space-between">
            <Grid item xs={12} sm={3}>
                {isDesktop && (
                    <ActionButtonGroup
                        disabled={!isValid || isValidating}
                        category="secondary"
                        size="small"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            '& .MuiButton-root': { width: { xs: '100%', sm: 'auto' } },
                        }}>
                        {!appSettings.authentication.disableAuthentication && (
                            <ActionButton
                                category="secondary"
                                intent="neutral"
                                type="button"
                                disabled={!isValid || isValidating}
                                startIcon={<SaveOutlined fontSize="inherit" />}
                                onClick={handleSave}>
                                {t('search_form.journey.save')}
                            </ActionButton>
                        )}
                    </ActionButtonGroup>
                )}
            </Grid>

            <Grid container item xs={12} sm={4} md={4} justifyContent="center">
                {user?.isOperatorOrDispatcher() && (
                    <>
                        {journey?.amendBookingToken == null && (
                            <CheckField
                                name="onlyOwnBuses"
                                size="small"
                                disabled={disableOwnBuses}
                                label={<Typography variant="body2">{t('search_form.show_own_buses')}</Typography>}
                            />
                        )}

                        {user.company.ptvRouteCalculationEnabled && (
                            <CheckField
                                name="makePTVRequest"
                                size="small"
                                label={<Typography variant="body2">{t('dev.make_ptv_request')}</Typography>}
                            />
                        )}
                    </>
                )}

                {showApplicableSchoolCheckbox && (
                    <CheckField
                        name="applicableSchool"
                        size="small"
                        label={<Typography variant="body2">{t('search_form.applicable_school')}</Typography>}
                    />
                )}
            </Grid>

            <Grid item xs={12} sm={4} md={5}>
                {IS_PRODUCTION ? (
                    <ActionButton
                        fullWidth
                        sx={{ p: { md: 2 } }}
                        size="large"
                        gaEvent={{ action: 'angebote_anzeigen', category: 'angebote_anzeigen' }}
                        disabled={!journey?.isPossible() || !isValid || isValidating}
                        onClick={onSubmit}>
                        {t('search_form.submit')}
                    </ActionButton>
                ) : (
                    <DevSubmitButton
                        disabled={!journey?.isPossible() || !isValid || isValidating}
                        onSubmit={onSubmit}
                    />
                )}
            </Grid>
        </Grid>
    );
};

interface DevSubmitButtonProps {
    readonly onSubmit: () => void;
    readonly disabled?: boolean;
}

const DevSubmitButton: React.FC<DevSubmitButtonProps> = ({ onSubmit, disabled }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const snack = useSnack();
    const { formMethods } = useJourneyPlanningForm();
    const { mutateAsync: devJourneyPopulation } = useDevJourneyPopulation();
    const [contextMenu, setContextMenu] = React.useState<any>(null);
    const { progress } = useAppProgress();

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const onClickSummary = (step: BookingStep) => async () => {
        const formValues = formMethods.getValues();

        // Check if form data is valid
        if (!(await JourneySchema.isValid(formValues))) return;

        try {
            await devJourneyPopulation(formValues);
            const targetStep = progress.getStep(step);
            if (targetStep) await navigate(targetStep.navigate);
        } catch (error) {
            if (isAPIError(error)) snack.push(`${error.message}. ${error.getDetails()}`, 'error');
        }
    };

    return (
        <>
            <ActionButton
                fullWidth
                sx={{
                    p: 1.75,
                    fontSize(theme) {
                        return theme.typography.h4Sub.fontSize;
                    },
                }}
                disabled={disabled}
                gaEvent={{ action: 'angebote_anzeigen', category: 'angebote_anzeigen' }}
                size="medium"
                data-cy="search-form-submit-button"
                onContextMenu={handleContextMenu}
                onClick={onSubmit}>
                {t('search_form.submit')}
            </ActionButton>
            <Menu
                open={contextMenu !== null}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu === null ? undefined : { top: contextMenu.mouseY, left: contextMenu.mouseX }
                }
                onClose={handleClose}>
                <MenuItem onClick={onClickSummary(BookingStep.CONTACT_DATA)}>Contact Data</MenuItem>
                <MenuItem onClick={onClickSummary(BookingStep.SUMMARY)}>Summary</MenuItem>
            </Menu>
        </>
    );
};
