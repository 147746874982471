import React from 'react';

import { type Language } from '@/config/localization';

type Match = [number, number];

export interface MatchState {
    tPartMatches: Match[];
    tValueMatches: Match[];
}

export interface TBaseNode {
    tKey: string;
    tPart: string;
    tPath: string[];
    tPartMatches: Match[];
    hasOverrides: boolean;
}

export interface TParentNode extends TBaseNode {
    isLeaf: false;
    childMatches: boolean;
}

export interface TLeafNode extends TBaseNode {
    isLeaf: true;
    tValue: string;
    tOverrides: PartialRecord<Language, string>;
    tValueMatches: Match[];
}

export interface TLeafNodeStagedOverrides {
    stagedOverrides: PartialRecord<Language, string>;
}

export type TNode = TParentNode | TLeafNode;

export interface ITranslatorContext {
    dfsTree: TNode[];
    filterValue: string;
    selectedKey?: string;
    filter(value: string): void;
    getLeafNode(tKey: string): (TLeafNode & TLeafNodeStagedOverrides) | undefined;
    selectNode(tKey: string): void;
    updateNode(tKey: string, lng: Language, value: string): void;
    reset(tKey?: string, lng?: Language): void;
    isDirty(tKey: string): boolean;
}

export const TranslatorContext = React.createContext<ITranslatorContext>({
    dfsTree: [],
    filterValue: '',
    filter() {},
    getLeafNode() {
        return undefined;
    },
    selectNode() {},
    updateNode() {},
    reset() {},
    isDirty() {
        return false;
    },
});
