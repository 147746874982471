import { type NavigateOptions } from '@tanstack/react-router';
import { createContext, useContext } from 'react';

import { type PostLoginParams } from '@/api/auth/endpoints';
import { type AppUser } from '@/entity/account/Account';

export interface LoginOptions {
    navigate?: NavigateOptions;
    redirectHref?: string;
}
export interface LogoutOptions {
    navigate?: NavigateOptions;
}

export interface IAuthContext {
    user: AppUser | null;
    login: (data: PostLoginParams, options?: LoginOptions) => Promise<void>;
    logout: (options?: LogoutOptions) => Promise<void>;
    isFetchingUser: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isNotLoggedInOrCustomer: boolean;
}

export const AuthContext = createContext<IAuthContext>({
    async login() {
        throw new Error('no ctx');
    },
    async logout() {
        throw new Error('no ctx');
    },
    isFetchingUser: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isNotLoggedInOrCustomer: true,
    user: null,
});

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
}
