import { DialogContent as MuiDialogContent } from '@mui/material';
import { type ReactNode } from 'react';

import { type DialogVariant } from '../types';

export interface DialogContentProps<Variant extends DialogVariant = DialogVariant> {
    readonly variant: Variant;
    readonly content: ReactNode;
}

export const DialogContent = <Variant extends DialogVariant>({ content }: DialogContentProps<Variant>) => {
    return <MuiDialogContent>{content}</MuiDialogContent>;
};
