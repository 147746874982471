import * as Yup from 'yup';

import { type GeocoderResult } from '@/core/google/mod';
import { type Basic } from '@/entity/basic/I_Basic';

export interface ILocation {
    readonly address: string;
    readonly lat: number;
    readonly lng: number;
    readonly country?: string;
    readonly state?: string;
    readonly postCode?: string;
    readonly city?: string;
    readonly street?: string;
    readonly streetNumber?: string;
}

export const LocationSchema: Yup.ObjectSchema<ILocation> = Yup.object({
    address: Yup.string().defined(),
    lat: Yup.number().defined(),
    lng: Yup.number().defined(),
    country: Yup.string(),
    state: Yup.string(),
    postCode: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    streetNumber: Yup.string(),
});

export class Location implements Basic<Location>, ILocation {
    public static fromJson(json: Record<string, any>): Location {
        return new Location(
            json.address,
            json.lat,
            json.lng,
            json.country,
            json.state,
            json.postCode,
            json.city,
            json.street,
            json.streetNumber,
        );
    }

    public static fromGeocodeResult(result: GeocoderResult): Location {
        const { formatted_address, geometry, address_components } = result;

        // Filter address components which contain the given type
        const _f = (type: string) => address_components.filter(c => c.types.includes(type));
        // Combine values of address components with the given type into a string
        const _e = (types: string | string[], short = false) => {
            const e = (Array.isArray(types) ? types : [types])
                .flatMap(_f)
                .map(c => (short ? c.short_name : c.long_name))
                .filter(val => val !== '')
                .join('/');
            return e === '' ? undefined : e;
        };

        const country = _e('country', true);
        const state = _e('administrative_area_level_1');
        const postCode = _e('postal_code');

        // city is locality or sublocality
        const city = _e('locality') ?? _e('sublocality');

        // street is route or intersection
        const street = _e('route') ?? _e('intersection');

        // streetNumber is followed by subpremise(s): only append subpremise(s) when streetNumber is not empty
        const streetNumber = _e('street_number') ? _e(['street_number', 'subpremise']) : undefined;

        return new Location(
            formatted_address,
            geometry.location.lat(),
            geometry.location.lng(),
            country,
            state,
            postCode,
            city,
            street,
            streetNumber,
        );
    }

    public readonly address: string;
    public readonly lat: number;
    public readonly lng: number;
    public readonly country?: string;
    public readonly state?: string;
    public readonly postCode?: string;
    public readonly city?: string;
    public readonly street?: string;
    public readonly streetNumber?: string;

    constructor(
        address: string,
        lat: number,
        lng: number,
        country?: string,
        state?: string,
        postCode?: string,
        city?: string,
        street?: string,
        streetNumber?: string,
    ) {
        this.address = address;
        this.lat = lat;
        this.lng = lng;
        this.country = country ?? undefined;
        this.state = state ?? undefined;
        this.postCode = postCode ?? undefined;
        this.city = city ?? undefined;
        this.street = street ?? undefined;
        this.streetNumber = streetNumber ?? undefined;
    }

    public isEqualToJson(json: Record<string, any>): boolean {
        if (json === undefined) return false;

        return [
            this.address === json.address,
            this.lat === json.lat,
            this.lng === json.lng,
            this.country === json.country,
            this.state === json.state,
            this.postCode === json.postCode,
            this.city === json.city,
            this.street === json.street,
            this.streetNumber === json.streetNumber,
        ].every(test => test);
    }

    public equals(other: Location | ILocation): boolean {
        return other != null && this.address === other.address && this.lat === other.lat && this.lng === other.lng;
    }

    public toString(): string {
        return `${this.lat}_${this.lng}`;
    }

    public toGMapsLatLng(): google.maps.LatLng {
        return new google.maps.LatLng(this.lat, this.lng);
    }

    public getCityOrState(other: Location | ILocation): [string, string] {
        if (this.city && other.city && this.city !== other.city) return [this.city, other.city];

        const thisParts = this.address
            .split(',')
            .map(x => x.trim())
            .reverse();
        const otherParts = other.address
            .split(',')
            .map(x => x.trim())
            .reverse();

        return [thisParts[1] ?? thisParts[0], otherParts[1] ?? otherParts[0]];
    }

    public toFormValues(): ILocation {
        return {
            address: this.address,
            lat: this.lat,
            lng: this.lng,
            country: this.country,
            state: this.state,
            postCode: this.postCode,
            city: this.city,
            street: this.street,
            streetNumber: this.streetNumber,
        };
    }
}
