import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { type Breakpoint, Container, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppCtx } from '@/core/app-ctx/mod';

interface FooterProps {
    readonly maxWidth?: Breakpoint | false;
}

export const Footer: React.FC<FooterProps> = ({ maxWidth = false }) => {
    const { apiVersion, uiVersion } = useAppCtx();
    const { t } = useTranslation();

    return (
        <Container
            component="footer"
            maxWidth={maxWidth}
            sx={{ textAlign: 'right', position: 'absolute', bottom: 0, left: 0, right: 0, fontSize: '1rem' }}>
            <Tooltip
                title={
                    <div>
                        <div>{t('ui_version', { uiVersion })}</div>
                        <div>{t('api_version', { apiVersion })}</div>
                    </div>
                }>
                <InfoOutlined fontSize="inherit" />
            </Tooltip>
        </Container>
    );
};
