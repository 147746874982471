export class Pageable<T> {
    public content: T[];

    public totalPages: number;

    constructor(content: T[], totalPages: number) {
        this.content = content;
        this.totalPages = totalPages;
    }
}
