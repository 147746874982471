import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import ga4 from 'react-ga4';

import { type IPosition, useJourneyPlanningForm } from '@/features/journey-planning';

interface StopRemoveProps {
    readonly position: IPosition;
    readonly isAnyFieldBeingDragged: boolean;
}

export const StopRemove: React.FC<StopRemoveProps> = ({ position, isAnyFieldBeingDragged }) => {
    const {
        formMethods: { trigger },
        stopsArray: { remove, fields },
    } = useJourneyPlanningForm();

    const handleRemove = useCallback(() => {
        remove(position.index);
        void trigger(`routes.${position.routeIndex}.stops`);
        ga4.event('search-form', { event_category: 'remove-intermediate' });
    }, [remove, position.index, position.routeIndex, trigger]);

    if (fields.length <= 2 || isAnyFieldBeingDragged) return null;

    return (
        <IconButton onClick={handleRemove}>
            <DeleteOutlineOutlined />
        </IconButton>
    );
};
