import { useLoadScript, type Libraries } from '@react-google-maps/api';
import React from 'react';

import { ENV } from '@/config/env';
import { GoogleMapsContext } from '@/core/google/mod';

const GoogleMapsLibraries: Libraries = ['places'];

/**
 * The Google Provider takes care of proper script initializations for using google related actions.
 */
export const GoogleProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const value = useLoadScript({
        googleMapsApiKey: ENV.googleApiKey,
        libraries: GoogleMapsLibraries,
    });

    return <GoogleMapsContext.Provider value={value}>{children}</GoogleMapsContext.Provider>;
};
