import { Entity } from '@/entity/A_Entity';
import { ContactData } from '@/entity/basic/ContactData';

export class MainCompanyBranchData extends Entity {
    public name: string;

    public registrationNumber: string;

    public uidNumber: string;

    public contactData: ContactData;

    public invoiceEmail: string;

    public customerEmail: string;

    public bookingEmail: string;

    public customerPhone: string;

    public openingHours: string;

    public countryPhone: Record<string, string>;

    public allPhones: Record<string, string>;

    constructor(json: Record<string, any>) {
        super(json);

        this.name = json.name;
        this.registrationNumber = json.registrationNumber;
        this.uidNumber = json.uidNumber;
        this.contactData = ContactData.fromJson(json.contactData);
        this.invoiceEmail = json.invoiceEmail;
        this.customerEmail = json.customerEmail;
        this.bookingEmail = json.bookingEmail;
        this.customerPhone = json.customerPhone;
        this.openingHours = json.openingHours;
        this.countryPhone = json.countryPhone;
        this.allPhones = json.allPhones;
    }

    public toString(): string {
        return this.name;
    }
}
