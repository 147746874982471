import { type AlertColor } from '@mui/material';
import { createContext, useContext } from 'react';

type Message = string | React.ReactNode;

export interface Snack {
    message: Message;
    severity?: AlertColor;
}

export interface ISnackContext {
    push: (message: Message, severity?: AlertColor, icon?: React.ReactNode) => void;
}

export const SnackContext = createContext<ISnackContext>({
    push() {},
});

export function useSnack() {
    const context = useContext(SnackContext);

    return context;
}
