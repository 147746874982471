import Route from '@mui/icons-material/Route';
import { Chip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/core/auth/mod';
import { useLocalizedFormatters } from '@/core/localization/mod';
import { useJourneyPlanningForm } from '@/features/journey-planning';

export const StatisticsChip: React.FC = () => {
    const { t } = useTranslation();
    const { getBookingRoute, formMethods } = useJourneyPlanningForm();
    const { formatDuration } = useLocalizedFormatters();
    const { selectedRouteIndex } = useJourneyPlanningForm();
    const { user } = useAuth();

    const route = getBookingRoute(selectedRouteIndex);

    if (!user?.isOperatorOrDispatcher() || !formMethods.formState.isValid) return null;

    const stats = [];

    if (route) {
        const drivingDistance = t('search_form.route.driving_distance', {
            value: route.calculatedTravelWithBreaks.distanceInKm,
        });
        const drivingDuration = t('search_form.route.driving_duration', {
            value: formatDuration(route.calculatedTravelWithBreaks.duration, ['days', 'hours', 'minutes'], {
                floor: true,
            }),
        });

        const firstStop = route.stops[0];
        const lastStop = route.stops[route.stops.length - 1];

        const travelDuration = t('search_form.route.travel_duration', {
            value: formatDuration(
                lastStop.arrivalDateTime.diff(firstStop.departureDateTime),
                ['days', 'hours', 'minutes'],
                {
                    floor: true,
                },
            ),
        });

        stats.push(drivingDistance, drivingDuration, travelDuration);
    }

    return (
        <Chip
            size="small"
            icon={<Route />}
            label={
                <Stack direction="row" gap={2}>
                    {stats.map(stat => (
                        <div key={stat}>{stat}</div>
                    ))}
                </Stack>
            }
            variant="outlined"
        />
    );
};
