import { useTranslation } from 'react-i18next';

import { useMutateToggleBusRoute } from '@/api/journey/booking/journey-booking-queries';
import { useLayout } from '@/components/page/layout/mod';
import { useJourney } from '@/core/journey/mod';
import { useSnack } from '@/core/snack/mod';
import { type Money } from '@/entity/basic/Money';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { useJourneyToken } from '@/hooks/useJourneyToken';
import { type BusSelectionTileset } from '@/main/routes/search/route.lazy';
import { type PriceOptionId } from '@/utils/constants';

interface ToggleBus {
    select: boolean;
    result?: BookingSearchResult;
    priceOptionId?: PriceOptionId;
    operatorPrice?: Money;
    routeUuid?: string;
}

interface ToggleBuses {
    results?: BookingSearchResult[];
    priceOptionId?: PriceOptionId;
    routeUuid?: string;
}

export type AddBusProps = Pick<ToggleBus, 'result' | 'operatorPrice' | 'priceOptionId'>;
// TODO: Do we need the priceOptionId on the removeBus call?
export type RemoveBusProps = Pick<ToggleBus, 'result' | 'priceOptionId'>;

interface ToggleBusReturn {
    addBus: (args: AddBusProps) => Promise<void>;
    addBuses: (args: ToggleBuses) => Promise<void>;
    removeBus: (args: RemoveBusProps) => Promise<void>;
    isPending: boolean;
    isSuccess: boolean;
}

export default function useToggleBusRoute(): ToggleBusReturn {
    const { t } = useTranslation();
    const { mutateAsync: toggleBusRoute, isPending, isSuccess } = useMutateToggleBusRoute();
    const { routeDetails } = useJourney();
    const layout = useLayout<BusSelectionTileset>();
    const snack = useSnack();
    const token = useJourneyToken();

    const handleToggleBusRoute = async ({ select, result, priceOptionId, operatorPrice, routeUuid }: ToggleBus) => {
        const toggle = async () => {
            const routeId = routeUuid || routeDetails?.uuid;
            if (!token || !routeId) throw new Error('Token or routeUuid is missing');
            await toggleBusRoute(
                {
                    token,
                    routeUuid: routeId,
                    busId: result?.bus.id,
                    select,
                    priceOptionId,
                    operatorPrice,
                },
                {
                    onSettled() {
                        layout.show('route_selection_tile');
                    },
                    onSuccess() {
                        if (select && result?.selected) snack.push(t('update_bus.success'), 'success');
                        else if (select) snack.push(t('add_bus.success'), 'success');
                        else snack.push(t('remove_bus.success'), 'success');
                    },
                    onError() {
                        if (select) snack.push(t('add_bus.error'), 'error');
                        else snack.push(t('remove_bus.error'), 'error');
                    },
                },
            );
        };

        await toggle();
    };

    return {
        async addBus(args) {
            await handleToggleBusRoute({
                ...args,
                select: true,
            });
        },
        async addBuses(args) {
            if (!args.results) return;
            // To change buses in a route other than the currently selected one, you'll need to provide the routeUuid
            await Promise.all(
                args.results.map(async result =>
                    handleToggleBusRoute({
                        result,
                        select: true,
                        priceOptionId: args.priceOptionId,
                        routeUuid: args.routeUuid,
                    }),
                ),
            );
        },
        async removeBus(args) {
            await handleToggleBusRoute({ ...args, select: false });
        },
        isPending,
        isSuccess,
    };
}
