import { Alert, Card, Stack, styled } from '@mui/material';
import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { type BusCardConciseProps } from '../../types/bus-card-concise';
import { PaymentIcon } from '../elements/payment-icon';

import { BusCardConciseActions } from './bus-card-concise-actions';

import { BusCancellation } from '@/components/bus/bus-cancellation';
import { BusHeader } from '@/components/bus/bus-header';
import { BusImage } from '@/components/bus/bus-image';
import { BusRoom } from '@/components/bus/bus-room';
import BusSeatsInfo from '@/components/bus/bus-seats-info';
import { BusPrice } from '@/components/bus/price/bus-price';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAuth } from '@/core/auth/mod';
import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useJourney } from '@/core/journey/mod';
import { BookingSearchResult, BusRouteStatus } from '@/entity/search-results/BookingSearchResult';

const BusCardConciseGrid = styled(Card)(({ theme }) => ({
    display: 'grid',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    gap: theme.spacing(1),
    columnGap: theme.spacing(2),
    gridAutoFlow: 'row',
    [theme.breakpoints.down('small_mobile')]: {
        gap: theme.spacing(0.75),
        gridTemplateColumns: 'minmax(35%, 60%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
          "image image"
          "header price"
          "info info"
          "room room"
          "actions actions"
          "error error"
          `,
        '&>.image': { width: '85%', justifySelf: 'center' },
    },
    [theme.breakpoints.between('small_mobile', 'sm')]: {
        gridTemplateColumns: '20% minmax(30%, 60%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
            "image header price"
            "image info info"
            "image room room"
            "actions actions actions"
            "error error error"
            `,
    },
    [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateColumns: '30% minmax(30%, 60%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
            "image header price"
            "image info info"
            "image room room"
            "image actions actions"
            "error error error"
            `,
    },
    [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: '0.5fr 0.5fr',
        gridTemplateRows: 'repeat(6, max-content)',
        gridTemplateAreas: `
        "image price"
        "header header"
        "info info"
        "room room"
        "actions actions"
        "error error"
        `,
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        gridTemplateColumns: '20% minmax(30%, 50%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
          "image header price"
          "image info info"
          "image room room"
          "image actions actions"
          "error error error"
          `,
    },
    [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: '30% minmax(30%, 45%) auto',
        gridTemplateRows: 'repeat(5, max-content)',
        gridTemplateAreas: `
          "image header price"
          "image info info"
          "image room room"
          "image actions actions"
          "error error error"
          `,
    },
    '&>.image': { gridArea: 'image' },
    '&>.header': { gridArea: 'header' },
    '&>.price': { gridArea: 'price', justifySelf: 'end' },
    '&>.info': { gridArea: 'info' },
    '&>.actions': { gridArea: 'actions', justifySelf: 'end', alignSelf: 'end' },
    '&>.room': { gridArea: 'room' },
    '&>.error': { gridArea: 'error', marginBottom: theme.spacing(1) },
}));
interface CardAreaProps {
    readonly area: 'image' | 'header' | 'price' | 'info' | 'error' | 'actions' | 'room';
}

const CardArea: React.FC<PropsWithChildren<CardAreaProps>> = ({ area, children }) => {
    return <div className={area}>{children}</div>;
};

export const BusCardConciseDefault: React.FC<BusCardConciseProps> = ({ resultOrTask }) => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { integration } = useAppCtx();
    const { routeDetails, journey } = useJourney();
    const { singleCompanyTranslationKey } = useIntegrationTranslation();

    const { bus, price } = resultOrTask;

    const result = resultOrTask instanceof BookingSearchResult ? resultOrTask : undefined;
    const task = resultOrTask instanceof BookingSearchResult ? undefined : resultOrTask;

    return (
        <BusCardConciseGrid>
            <CardArea area="image">
                <BusImage bus={bus} />
            </CardArea>
            <CardArea area="header">{!integration?.singleCompany && <BusHeader noWrap bus={bus} />}</CardArea>
            <CardArea area="price">
                <BusPrice
                    pax={routeDetails?.pax ?? 1}
                    seats={bus.getTotalSeats()}
                    disableDiscounts={user?.isOperatorOrDispatcher()}
                    priceToShow={price}
                    priceDisplayRows={resultOrTask.priceDisplayRows}
                    icon={<PaymentIcon selectedPriceOption={result?.selectedPriceOption || task?.priceOption} />}
                />
            </CardArea>
            <CardArea area="info">
                <Stack direction="row" justifyContent="space-between">
                    <BusSeatsInfo bus={bus} variant="seats" />
                    <BusCancellation resultOrTask={result} />
                </Stack>
            </CardArea>
            <CardArea area="actions">
                <BusCardConciseActions result={result} pax={routeDetails?.pax ?? 1} />
            </CardArea>
            <CardArea area="room">
                <BusRoom
                    variant="short"
                    companyProvides={routeDetails?.driverRoomsNeeded && journey?.companyProvidesDriverRooms}
                    userProvides={routeDetails?.driverRoomsNeeded && !journey?.companyProvidesDriverRooms}
                />
            </CardArea>
            {resultOrTask instanceof BookingSearchResult && resultOrTask.hasInvalidStatus() && (
                <CardArea area="error">
                    <Alert severity="error">
                        {/* Also show the payment explanation for no or invalid price options, so that the user know why they can't select the bus */}
                        {resultOrTask.status === BusRouteStatus.INVALID_PRICE_OPTION ||
                        resultOrTask.status === BusRouteStatus.NO_PRICE_OPTIONS ? (
                            <>
                                {t(`bus_selection.status.${resultOrTask.status}`)}{' '}
                                {t(
                                    `bus_content.payment_explanation_status.${singleCompanyTranslationKey}.${resultOrTask.paymentExplanation}`,
                                )}
                            </>
                        ) : (
                            t(`bus_selection.status.${resultOrTask.status}`)
                        )}
                    </Alert>
                </CardArea>
            )}
        </BusCardConciseGrid>
    );
};
