import { useMutation } from '@tanstack/react-query';

import { type ICompanyRegistration } from '../types';

import callApi from '@/api';

export async function postCompanyRegistration(body: ICompanyRegistration) {
    const { data } = await callApi<{ token: string }>('public/submitCompanyRegistration', {
        method: 'POST',
        data: body,
    });

    return data;
}

export const useCompanyRegistration = () => useMutation({ mutationFn: postCompanyRegistration });
