import { useContext } from 'react';

import { type ITripCompanyContext, TripCompanyContext } from '../types';

export const useTripCompanies = (): ITripCompanyContext => {
    const context = useContext(TripCompanyContext);

    if (!context) throw new Error('useTripCompanies must be used within TripCompanyContext.Provider');

    return context;
};
