import { useGoogleMap } from '@react-google-maps/api';
import { type FC, useEffect, useRef } from 'react';

import { type TripCompanyPopupProps, TripCompanyPopup } from './trip-company-popup';

interface PopupOverlayProps extends Omit<TripCompanyPopupProps, 'overlay'> {
    readonly open: boolean;
}

export const PopupOverlay: FC<PopupOverlayProps> = ({ open, ...rest }) => {
    const overlayRef = useRef(new google.maps.OverlayView());
    const map = useGoogleMap();

    useEffect(() => {
        const overlay = overlayRef.current;
        if (map) {
            // If we don't define these methods, the overlay will throw an error, when it's unmounted
            overlay.draw = () => {};
            overlay.onAdd = () => {};
            overlay.onRemove = () => {};
            overlay.setMap(map);
        }

        return () => {
            overlay.setMap(null);
        };
    }, [map]);

    if (!overlayRef.current || !open) return null;

    return <TripCompanyPopup {...rest} overlay={overlayRef.current} />;
};
