import { type Currency } from '@/config/localization';
import { Company } from '@/entity/company/Company';

export class BusCompany extends Company {
    public defaultTaskPostProcessingTime: number;

    public bookingLeadTime: number;

    public dummy: boolean;

    public virtual: boolean;

    public integrationOnlyBuses: boolean;

    public partner: boolean;

    public offerDefaultDaysValidBeforeStart: number;

    public offerDefaultDaysRemindBeforeOfferEnds: number;

    public sendDriverDetailsXDaysBeforeJourney: number;

    public arrivalReturnFlatRateCalculation: boolean;

    public currency: Currency;

    public externCustomers: string;

    public externTasks: string;

    public furtherBases: boolean;

    public ratioCompany: boolean;

    public isEmileWeber: boolean;

    public ptvRouteCalculationEnabled: boolean;

    constructor(json: Record<string, any>) {
        super(json);
        this.bookingLeadTime = json.bookingLeadTime;
        this.defaultTaskPostProcessingTime = json.defaultTaskPostProcessingTime;
        this.dummy = json.dummy;
        this.virtual = json.virtual;
        this.integrationOnlyBuses = json.integrationOnlyBuses;
        this.offerDefaultDaysValidBeforeStart = json.offerDefaultDaysValidBeforeStart;
        this.offerDefaultDaysRemindBeforeOfferEnds = json.offerDefaultDaysRemindBeforeOfferEnds;
        this.partner = json.partner;
        this.sendDriverDetailsXDaysBeforeJourney = json.sendDriverDetailsXDaysBeforeJourney;
        this.arrivalReturnFlatRateCalculation = json.arrivalReturnFlatRateCalculation;
        this.currency = json.currency;
        this.externCustomers = json.externCustomers;
        this.externTasks = json.externTasks;
        this.furtherBases = json.furtherBases;
        this.ratioCompany = json.ratioCompany;
        this.isEmileWeber = json.emileWeber;
        this.ptvRouteCalculationEnabled = json.ptvRouteCalculationEnabled;
    }

    public toString(): string {
        return this.companyName;
    }
}
