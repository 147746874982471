import {
    FilledInput,
    Input,
    OutlinedInput,
    useFormControl,
    type FilledInputProps,
    type InputProps,
    type OutlinedInputProps,
} from '@mui/material';
import { forwardRef, type FC } from 'react';

type FormControlInputProps = InputProps | OutlinedInputProps | FilledInputProps;

export const FormControlInput: FC<FormControlInputProps> = forwardRef(({ ...props }, ref) => {
    const fc = useFormControl();

    switch (fc?.variant) {
        case 'standard': {
            return <Input {...props} ref={ref} />;
        }
        case 'filled': {
            return <FilledInput {...props} ref={ref} />;
        }
        default: {
            return <OutlinedInput {...props} ref={ref} />;
        }
    }
});
