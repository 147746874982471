import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCustomerGroups } from '@/api/public';
import { LocationField } from '@/components/form/fields/location-field';
import { SchoolAutocomplete } from '@/components/form/fields/school-autocomplete';
import { SelectField } from '@/components/form/fields/select-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { useGoogleGeocoder } from '@/core/google/mod';
import { CustomerGroupType } from '@/entity/common-constants';

interface OrganizationInputsProps {
    readonly withCostCenterAndPoNumber?: boolean;
}

export const OrganizationInputs: React.FC<OrganizationInputsProps> = ({ withCostCenterAndPoNumber }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const { getLocationDetails } = useGoogleGeocoder();

    const customerGroupId = useWatch({
        control,
        name: 'customerGroupId',
    });

    const { customerGroups, customerGroupsForSelect } = useCustomerGroups();

    const currentCustomerGroup = useMemo(
        () => customerGroups.find(customerGroup => customerGroup.id === customerGroupId),
        [customerGroupId, customerGroups],
    );

    const handleSchoolSelect = async (address: string) => {
        const location = await getLocationDetails({ address });
        setValue('organizationLocation', location);
    };

    useEffect(() => {
        setValue('customerGroupName', currentCustomerGroup?.name);
    }, [currentCustomerGroup?.name, setValue]);

    const NameAndLocationFields = (
        <FormFieldRow>
            <TextField required name="organizationName" label={t('account.organization_data.organization_name')} />

            <LocationField
                required
                fullWidth
                label={t('account.organization_data.organization_location')}
                name="organizationLocation"
            />
        </FormFieldRow>
    );

    const renderOrganizationInputs = () => {
        switch (currentCustomerGroup?.type) {
            case CustomerGroupType.BUSINESS: {
                return (
                    <>
                        {NameAndLocationFields}
                        {withCostCenterAndPoNumber ? (
                            <FormFieldRow>
                                <TextField name="organizationUID" label={t('account.organization_data.uid')} />
                                <TextField
                                    name="organizationPoNumber"
                                    label={t('account.organization_data.po_number')}
                                />
                                <TextField
                                    name="organizationCostCenter"
                                    label={t('account.organization_data.cost_center')}
                                />
                            </FormFieldRow>
                        ) : (
                            <FormFieldRow insertEmptyColumn>
                                <TextField name="organizationUID" label={t('account.organization_data.uid')} />
                            </FormFieldRow>
                        )}
                    </>
                );
            }
            case CustomerGroupType.RESELLER: {
                return (
                    <>
                        {NameAndLocationFields}
                        <FormFieldRow insertEmptyColumn>
                            <TextField name="organizationUID" label={t('account.organization_data.uid')} />
                        </FormFieldRow>
                    </>
                );
            }

            case CustomerGroupType.SCHOOL: {
                return (
                    <FormFieldRow>
                        <SchoolAutocomplete
                            label={t('school_autocomplete')}
                            name="organizationName"
                            onChangeAddress={handleSchoolSelect}
                        />

                        <LocationField fullWidth label={t('school_location')} name="organizationLocation" />
                    </FormFieldRow>
                );
            }

            default: {
                return null;
            }
        }
    };

    return (
        <FormFieldsContainer containerLabel={t('customer_group')}>
            <FormFieldRow>
                <SelectField
                    required
                    name="customerGroupId"
                    label={t('customer_group')}
                    options={customerGroupsForSelect}
                    data-cy="customerGroup"
                />
            </FormFieldRow>

            {/* //TODO: BUF-503: Check if customer is eligible for discount */}
            {renderOrganizationInputs()}
        </FormFieldsContainer>
    );
};
