import { Box, Pagination, Stack, styled } from '@mui/material';

import { useTripCompanies } from '../hooks/use-trip-companies';

import { ListSkeleton } from './skeletons/list-skeleton';
import { TripCompanyCard } from './trip-company-card';

import { useScrollbar } from '@/components/elements/scrollbar/mod';

const ListGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridTemplateRows: 'max-content max-content max-content ',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
}));

export const TripCompaniesList: React.FC = () => {
    const {
        isFetching,
        pagedCompanies,
        pagination: { maxPage, currentPage, setPage },
    } = useTripCompanies();
    const scrollbar = useScrollbar();

    if (isFetching) return <ListSkeleton />;

    return (
        <Stack gap={3}>
            <ListGrid>
                {pagedCompanies.map(company => (
                    <TripCompanyCard key={company.id} company={company} />
                ))}
            </ListGrid>
            {maxPage > 1 && (
                <Pagination
                    count={maxPage}
                    page={currentPage}
                    onChange={(_e, page) => {
                        setPage(page);
                        scrollbar.scrollToTop();
                    }}
                />
            )}
        </Stack>
    );
};
