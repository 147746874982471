import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

import { ErrorMessage } from '@/components/form/error-message';
import { type FormFieldProps, makeFieldIds } from '@/components/form/mod';

export interface SelectFieldOptions {
    value?: string | number;
    label: React.ReactNode;
}

interface SelectFieldProps extends FormFieldProps {
    readonly multiple?: boolean;
    readonly options: SelectFieldOptions[];
}

export const SelectField: React.FC<SelectFieldProps> = ({
    name,
    id,
    label,
    helperText,
    multiple,
    options,
    fieldRef,
    ...formControlProps
}) => {
    const { field, fieldState } = useController({ name, defaultValue: '' });
    const { onChange, onBlur, value, ref } = field;
    const { error } = fieldState;

    const isError = Boolean(error);
    const [uid, helperUid, labelUid] = makeFieldIds(name, id);

    return (
        <FormControl fullWidth error={isError} {...formControlProps}>
            <InputLabel id={labelUid} htmlFor={uid}>
                {label}
            </InputLabel>
            <Select
                ref={fieldRef}
                value={value}
                name={name}
                label={label}
                labelId={labelUid}
                inputRef={ref}
                id={uid}
                aria-describedby={helperUid}
                multiple={multiple}
                onBlur={onBlur}
                onChange={onChange}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id={helperUid}>{isError ? <ErrorMessage error={error} /> : helperText}</FormHelperText>
        </FormControl>
    );
};
