import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReversalLong } from './reversal-long';

import { InteractiveText } from '@/components/elements/text/interactive-text';
import { useBooking } from '@/core/booking/mod';
import { useLocalizedFormatters } from '@/core/localization/mod';
import { BookingTask } from '@/entity/events/task/BookingTask';
import { BookingSearchResult } from '@/entity/search-results/BookingSearchResult';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

interface ReversalShortProps {
    readonly resultOrTask: BookingSearchResult | BookingTask;
    readonly dontOpenModal?: boolean;
    readonly align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const ReversalShort: React.FC<ReversalShortProps> = ({
    resultOrTask,
    dontOpenModal = false,
    align = 'inherit',
}) => {
    const { t } = useTranslation();
    const { formatDateTime } = useLocalizedFormatters();
    const { booking } = useBooking();
    const { freeCancellationDate, cancellationFreeNow } = resultOrTask;

    const noRefund =
        resultOrTask instanceof BookingTask
            ? resultOrTask.priceOption?.noRefund
            : resultOrTask.selectedPriceOption?.noRefund;

    const onlyNoRefundOptions =
        resultOrTask instanceof BookingSearchResult &&
        resultOrTask.priceOptions.every(priceOption => priceOption.noRefund);

    const formattedDate = useMemo(
        () => formatDateTime(freeCancellationDate, DateTime.DATE_SHORT),
        [formatDateTime, freeCancellationDate],
    );

    const reversalInfoDialog = useDisclosure();

    const dialog = (
        <Dialog
            {...reversalInfoDialog}
            variant="info"
            maxWidth="lg"
            title={t('summary.reversal_company', {
                companyName: resultOrTask.bus.company.companyName,
            })}>
            <Stack gap={2}>
                <ReversalLong resultOrTask={resultOrTask} booking={booking} />
                <Typography variant="body2" component="div">
                    <SanitizedInnerHTML html={resultOrTask.bus.company.reversalConditions} />
                </Typography>
            </Stack>
        </Dialog>
    );

    if (noRefund || onlyNoRefundOptions) {
        return (
            <Typography variant="body2" align={align}>
                <Trans
                    i18nKey="summary.reversal_info.quit_short"
                    tOptions={{ interpolation: { escapeValue: false } }}
                />
            </Typography>
        );
    }

    if (freeCancellationDate?.isValid && cancellationFreeNow) {
        return (
            <>
                <Typography variant="body2" color="success.main" align={align}>
                    <Trans
                        tOptions={{ interpolation: { escapeValue: false } }}
                        i18nKey="summary.reversal_info.until_short"
                        values={{ formattedDate }}
                        components={{
                            button: <InteractiveText disabled={dontOpenModal} onClick={reversalInfoDialog.open} />,
                        }}
                    />
                </Typography>
                {dialog}
            </>
        );
    }

    return (
        <>
            <Typography variant="body2" align={align}>
                <Trans
                    i18nKey="summary.reversal_info.no_free_cancellation_with_conditions"
                    tOptions={{ interpolation: { escapeValue: false } }}
                    components={{
                        button: <InteractiveText disabled={dontOpenModal} onClick={reversalInfoDialog.open} />,
                    }}
                />
            </Typography>
            {dialog}
        </>
    );
};
