import PermDataSettingOutlined from '@mui/icons-material/PermDataSettingOutlined';

import { NavButton } from '@/components/elements/nav/nav-button';
import { NavIconButton } from '@/components/elements/nav/nav-icon-button';
import { useLayout } from '@/components/page/layout/mod';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useEmbedCtx } from '@/embed/mod';

export const EditorActions: React.FC = () => {
    const { isEmbedding } = useEmbedCtx();
    const { editMode } = useAppCtx();
    const { isDesktop } = useLayout();

    if (!editMode || isEmbedding) return null;

    return isDesktop ? (
        <NavButton navigate={{ to: '/editor', search: {} }} />
    ) : (
        <NavIconButton navigate={{ to: '/editor', search: {} }}>
            <PermDataSettingOutlined />
        </NavIconButton>
    );
};
