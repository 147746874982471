import { Tooltip, Zoom } from '@mui/material';
import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalizedFormatters } from '@/core/localization/mod';
import { type LegDetails } from '@/entity/journey/stop/LegDetails';
import { useJourneyPlanningForm } from '@/features/journey-planning';
import { useTimeout } from '@/utils/helpers';

interface LegInfoProps {
    readonly legDetails?: LegDetails;
    readonly autoOpen?: boolean;
    readonly hideTooltip?: boolean;
}

export const LegInfoTooltip: React.FC<PropsWithChildren<LegInfoProps>> = ({
    legDetails,
    hideTooltip,
    autoOpen,
    children,
}) => {
    const { formatDuration } = useLocalizedFormatters();
    const { didCalculate } = useJourneyPlanningForm();
    const { t } = useTranslation();
    const { fire } = useTimeout();

    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        if (didCalculate && autoOpen) {
            handleOpen();
            fire(handleClose, 2500);
        }
    }, [autoOpen, didCalculate, fire]);

    const title = () => {
        if (!legDetails) return null;

        const duration = formatDuration(legDetails.durationWithBreaks, ['hours', 'minutes'], { floor: true });
        const length = `${Math.round(legDetails.distanceInMeters / 1000)} km`;
        const driverBreak = legDetails.getDriverBreak();
        const driverBreakLabel = driverBreak ? t(driverBreak.t_title) : '';

        return `${length} | ${duration} ${driverBreakLabel}`;
    };
    return (
        <Tooltip
            arrow
            placement="left"
            TransitionComponent={Zoom}
            title={title()}
            open={open && !hideTooltip}
            onOpen={handleOpen}
            onClose={handleClose}>
            {children as any}
        </Tooltip>
    );
};
