import { createFileRoute, redirect } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

const SEARCH_PARAMS = object({
    token: string().required(),
});

export const Route = createFileRoute('/partner-registration/confirmation')({
    validateSearch(search): InferType<typeof SEARCH_PARAMS> {
        return SEARCH_PARAMS.cast(search);
    },
    onError(error) {
        if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as PartnerRegistrationConfirmationRoute };
