import { Box } from '@mui/material';
import React from 'react';

import { type FormColumn } from './form-grid';
import { FormLine } from './form-line';

import { useLayout } from '@/components/page/layout/mod';
import { type IPosition } from '@/features/journey-planning';

interface FormCellProps {
    readonly gridColumn: FormColumn | `${FormColumn} / ${FormColumn}`;
    readonly only?: 'mobile' | 'desktop' | 'large desktop';
    readonly showLine?: boolean | [boolean, boolean];
    readonly minHeight?: number;
    readonly legDetailsOf?: IPosition;
    readonly whiteOverlay?: boolean;
}

export const FormCell: React.FC<React.PropsWithChildren<FormCellProps>> = ({
    gridColumn,
    only,
    showLine = false,
    children,
    minHeight,
    legDetailsOf,
    whiteOverlay,
}) => {
    const { isDesktop, isLargeDesktop } = useLayout();

    if (isDesktop && only === 'mobile') return null;
    if (!isDesktop && only === 'desktop') return null;
    if (!isLargeDesktop && only === 'large desktop') return null;

    const position: [boolean, boolean] = Array.isArray(showLine) ? showLine : [showLine, showLine];

    return (
        <Box
            sx={{
                gridColumn,
                alignSelf: showLine ? 'stretch' : undefined,
                py: showLine ? 0 : 0.5,
                minHeight: theme => theme.spacing(minHeight ?? 0),
                position: 'relative',
                zIndex: 0,
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: -8,
                    height: '100%',
                    zIndex: -1,
                    backgroundColor(theme) {
                        return theme.vars.palette.background.paper;
                    },
                    width(theme) {
                        if (!whiteOverlay) return 0;
                        return {
                            xs: `calc(100vw - 2 * ${theme.spacing(1)})`,
                            sm: `calc(100vw - 2 * ${theme.spacing(2)})`,
                            md: 0,
                        };
                    },
                },
            }}>
            {showLine ? (
                <FormLine position={position} legDetailsOf={legDetailsOf}>
                    {children}
                </FormLine>
            ) : (
                children
            )}
        </Box>
    );
};
