import { SUPPORTED_CURRENCIES, type Currency } from '@/config/localization';

export type ExchangeRates = Record<Currency, number>;

// Parse out all supported currencies from the fetched data
export function parseSupportedCurrencies(data: any): ExchangeRates {
    const entries: { currency: string; rate: string }[] = data.Cube.Cube.Cube;

    const rates: Partial<ExchangeRates> = { EUR: 1 };

    entries.forEach((entry: { currency: string; rate: string }) => {
        if (!SUPPORTED_CURRENCIES.includes(entry.currency as any)) return;

        rates[entry.currency as Currency] = Number.parseFloat(entry.rate);
    });

    return rates as ExchangeRates;
}
