import { createContext, useContext } from 'react';

export type Spacer = {
    to: 'top' | 'bottom';
    height: number;
} | null;

export interface ITileContext {
    spacer: Spacer;
    setSpacer(spacer: Spacer): void;
    setAttachableRef(ref: HTMLDivElement | null): void;
}

export const TileContext = createContext<ITileContext>({
    spacer: null,
    setSpacer() {},
    setAttachableRef() {},
});

export function useTile() {
    return useContext<ITileContext>(TileContext);
}
