import { createFileRoute, redirect } from '@tanstack/react-router';
import { object, string, type InferType, array } from 'yup';

import { BookingStep } from '@/entity/booking-progress/BookingProgress';

const SEARCH_PARAMS = object({
    token: string().required(),
    drawers: array().of(string()),
});

export const Route = createFileRoute('/booking-confirmation')({
    beforeLoad() {
        return {
            step: BookingStep.BOOKING_CONFIRMATION,
        };
    },
    validateSearch(search): InferType<typeof SEARCH_PARAMS> {
        return SEARCH_PARAMS.cast(search);
    },
    onError(error) {
        if (error.routerCode === 'VALIDATE_SEARCH') redirect({ to: '/', replace: true, throw: true });
    },
});

export { Route as BookingConfirmationRoute };
