import { type Context } from 'react';

import { Layout, type ExtractNames, type LayoutProps, type Tiles } from '@/components/page/layout/layout';
import { LayoutContext, type ILayoutContext } from '@/components/page/layout/mod';

export type TilesetOf<C> = C extends Context<ILayoutContext<infer T>> ? T : never;

// Layout Factory
export function createLayout<T extends Tiles>(props: LayoutProps<T>) {
    const TypedLayoutContext = LayoutContext as unknown as Context<ILayoutContext<ExtractNames<T>>>;

    return {
        LayoutContext: TypedLayoutContext,
        Layout: ({ useNavigation }: Pick<LayoutProps<T>, 'useNavigation'>) => (
            <Layout context={TypedLayoutContext} {...props} useNavigation={useNavigation} />
        ),
    };
}
