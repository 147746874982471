import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import callApi from '@/api/index';
import { useAuth } from '@/core/auth/mod';
import { fromJsonArray } from '@/entity';
import { CustomerGroupType } from '@/entity/common-constants';
import { CustomerGroup } from '@/entity/customergroup/CustomerGroup';
import { MainCompanyBranchData } from '@/entity/main-company-branch-data/MainCompanyBranchData';
import { School } from '@/entity/school/School';
import { parseSupportedCurrencies } from '@/utils/exchange-rates';

/**
 *********************************************************************
 * React Queries                                                     *
 *********************************************************************
 */

/**
 * Use exchange rates fetched by {@link fetchExchangeRates}
 */
export const useExchangeRates = () =>
    useQuery({
        queryKey: ['exchange-rates'],
        queryFn: fetchExchangeRates,
        /* Stale time: 12 hours */
        staleTime: 1000 * 60 * 60 * 12,
        /* Cache time: 24 hours */
        gcTime: 1000 * 60 * 60 * 24,
        refetchOnWindowFocus: false,
    });

/**
 * Use current api version fetched by {@link fetchAPIVersion}
 */
export const useApiVersion = () =>
    useQuery({ queryKey: ['api-version'], queryFn: fetchAPIVersion, refetchOnWindowFocus: false });

/**
 * Use the busfinder company data fetched by {@link fetchMainCompanyBranchData}
 */
export const useMainCompanyBranchData = () =>
    useQuery({
        queryKey: ['main-company-branch-data'],
        queryFn: fetchMainCompanyBranchData,
        refetchOnWindowFocus: false,
    });

/**
 * Use customer groups fetched by {@link fetchCustomerGroups}
 */

export const useCustomerGroups = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const isReseller = user?.isCustomer() && user.isReseller();

    const result = useQuery({
        queryKey: ['customer-groups'],
        queryFn: fetchCustomerGroups,
        refetchOnWindowFocus: false,
    });

    const customerGroups = (result?.data ?? []).filter(customerGroup => {
        if (isReseller) return customerGroup.type !== CustomerGroupType.RESELLER;
        return true;
    });

    const customerGroupsForSelect = customerGroups.map(customerGroup => ({
        value: customerGroup.id,
        label: t(`customer_groups.id.${customerGroup.id}`),
    }));

    return {
        customerGroups,
        customerGroupsForSelect,
    };
};

/**
 * Use all schools {@link fetchAllSchools}
 */
export const useAllSchools = () =>
    useQuery({ queryKey: ['all-schools'], queryFn: fetchAllSchools, refetchOnWindowFocus: false });

/**
 * Use schools fetched by keyword {@link fetchSchoolsByKeyword}
 */
export const useSchoolsByKeyword = (keyword: string) =>
    useQuery({
        queryKey: ['schools-by-keyword', keyword],
        queryFn: async () => fetchSchoolsByKeyword(keyword),
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

/**
 *********************************************************************
 * Api Calls                                                         *
 *********************************************************************
 */

/**
 * Fetch exchange rates and store them in Cookie
 */
export async function fetchExchangeRates() {
    const { data } = await callApi('public/exchange-rates');
    return parseSupportedCurrencies(data);
}

/**
 * Fetch api version
 */
export async function fetchAPIVersion() {
    const { data } = await callApi<{ version: string }>('public/version');
    return data.version;
}

/**
 * Fetch the busfinder company data
 */
export async function fetchMainCompanyBranchData() {
    const { data } = await callApi('public/mainCompanyBranchData');

    return new MainCompanyBranchData(data);
}

/**
 * Fetch customer groups
 */
export async function fetchCustomerGroups() {
    const { data } = await callApi('public/customer-groups');
    return fromJsonArray(CustomerGroup, data);
}

/**
 * Fetch all schools
 */
export async function fetchAllSchools() {
    const { data } = await callApi('public/schools');
    return fromJsonArray(School, data);
}

/**
 * Fetch all schools
 */
export async function fetchSchoolsByKeyword(keyword: string) {
    const { data } = await callApi('public/schools/search', {
        params: {
            keyword,
        },
    });
    return fromJsonArray(School, data);
}
