import { HttpStatusCode } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
    duplicateAccountEmailValidation,
    duplicateCompanyNameValidation,
    duplicateCustomerEmailValidation,
} from '@/api/user/endpoints';

/**
 *********************************************************************
 * Async duplicate validation                                                  *
 *********************************************************************
 */
export type AsyncCheckDuplicateType = 'customer-email' | 'account-email' | 'company-name';

type AsyncCheckDuplicateReturnType = [
    boolean,
    string,
    (value: string, triggerValidationFn: () => Promise<boolean>) => Promise<boolean>,
    () => void,
];

const emailSchema = Yup.object().shape({
    email: Yup.string().email().required(),
});

export const useAsyncCheckDuplicate = (validationType: AsyncCheckDuplicateType): AsyncCheckDuplicateReturnType => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const checkDuplicateEmail = async (email: string, triggerValidationFn: () => Promise<boolean>) => {
        const isEmailValid = await emailSchema.isValid({ email });

        if (!isEmailValid) return false;

        try {
            if (validationType === 'customer-email') {
                await duplicateCustomerEmailValidation(email);
            } else if (validationType === 'account-email') {
                await duplicateAccountEmailValidation(email);
            }
            setIsValid(true);
            return await triggerValidationFn();
        } catch (error: any) {
            if (error.code === HttpStatusCode.Conflict) {
                setErrorMessage(t('validations:email.duplicate_email_customer'));
            }
            if (error.code === HttpStatusCode.BadRequest) {
                setErrorMessage(t('validations:email.duplicate_email'));
            }
            setIsValid(false);
            return triggerValidationFn();
        }
    };

    const checkDuplicateCompanyName = async (companyName: string, triggerValidationFn: () => Promise<boolean>) => {
        try {
            await duplicateCompanyNameValidation(companyName);
            setIsValid(true);
            return await triggerValidationFn();
        } catch (error: any) {
            if (error.code === HttpStatusCode.Conflict) {
                setErrorMessage(t('validations:company.duplicate_company'));
            }
            setIsValid(false);
            return triggerValidationFn();
        }
    };

    const resetState = useCallback(() => {
        setIsValid(true);
        setErrorMessage('');
    }, []);

    return [
        isValid,
        errorMessage,
        validationType === 'company-name' ? checkDuplicateCompanyName : checkDuplicateEmail,
        resetState,
    ];
};

const regInteger = /^-?\d+$/;
const regTwoDecimalPlaces = /^\d+(\.\d{0,2})?$/;

export const isInteger = (value: any): boolean => regInteger.test(value);
export const isTwoDecimal = (value: any): boolean => regTwoDecimalPlaces.test(value);
export const isIntegerPercent = (value: any) =>
    value == null || (isInteger(value) && Number(value) >= -100 && Number(value) <= 100);
