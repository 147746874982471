import React from 'react';

import { RegistrationForm } from '../components/registration-form';

import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';

export const RegistrationTile: React.FC = () => {
    return (
        <Tile>
            <TilePart>
                <RegistrationForm />
            </TilePart>
        </Tile>
    );
};
