import { Tooltip } from '@mui/material';
import { type DraggableProvided } from '@rechnerherz/dnd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormAvatar, type FormAvatarProps } from '../grid/form-avatar';

import { type IPosition, useJourneyPlanningForm, type IStop, getAvatarType } from '@/features/journey-planning';

interface StopAvatarProps {
    readonly position: IPosition;
    readonly stop: IStop;
    readonly draggableProvided: DraggableProvided;
    readonly isAnyFieldBeingDragged: boolean;
    readonly isDragDisabled: boolean;
}

export const StopAvatar: React.FC<StopAvatarProps> = ({
    position,
    stop,
    draggableProvided,
    isAnyFieldBeingDragged,
    isDragDisabled,
}) => {
    const { getBookingStop } = useJourneyPlanningForm();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const draggable = !isAnyFieldBeingDragged && !isDragDisabled;
    const bookingStop = getBookingStop(position);
    const prevBookingStop = getBookingStop({ ...position, index: position.index - 1 });

    const color: FormAvatarProps['color'] = useMemo(() => {
        const severity = bookingStop?.legDetails?.statusSeverity();
        const prevSeverity = prevBookingStop?.legDetails?.statusSeverity();

        if (severity === 'error' || severity === 'warning') return severity;
        if (prevSeverity === 'error' || prevSeverity === 'warning') return prevSeverity;
        return 'primary';
    }, [bookingStop?.legDetails, prevBookingStop?.legDetails]);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <Tooltip
            open={open && !isAnyFieldBeingDragged}
            title={t('drag_hint')}
            placement="left-end"
            disableInteractive={!draggable}
            disableHoverListener={!draggable}
            disableFocusListener={!draggable}
            disableTouchListener={!draggable}
            onClose={handleClose}
            onOpen={handleOpen}>
            <FormAvatar
                type={getAvatarType(position, stop.tripCompany)}
                color={color}
                isDraggable={draggable}
                {...draggableProvided.dragHandleProps}
            />
        </Tooltip>
    );
};
