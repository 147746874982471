import { KeyboardArrowDown } from '@mui/icons-material';
import { Alert, IconButton, AlertTitle, Collapse } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type DisplayStatus } from '@/entity/journey/stop/LegDetails';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const StopAlert = ({ severity, t_description, t_title }: DisplayStatus) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const toggleOpen = () => setOpen(!open);

    return (
        <Alert
            severity={severity}
            action={
                <IconButton size="small" onClick={toggleOpen}>
                    <KeyboardArrowDown
                        sx={{
                            transition: t =>
                                t.transitions.create('transform', {
                                    duration: t.transitions.duration.short,
                                }),
                            transform: open ? 'rotate(180deg)' : undefined,
                        }}
                    />
                </IconButton>
            }
            sx={{
                '.MuiAlert-icon': {
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                },
            }}>
            <AlertTitle data-cy="stop-alert">{t(t_title)}</AlertTitle>
            <Collapse in={open}>
                <SanitizedInnerHTML html={t(t_description)} />
            </Collapse>
        </Alert>
    );
};
