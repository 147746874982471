import { createContext } from 'react';

interface IBusDndContext {
    isDraggingBus: boolean;
    isPending: boolean;
}

export const BusDndContext = createContext<IBusDndContext>({
    isDraggingBus: false,
    isPending: false,
});
