import { createContext } from 'react';
import { type UseFormReturn, type UseFieldArrayReturn } from 'react-hook-form';

import { type BookingJourney } from '@/entity/journey/BookingJourney';
import { type BookingRoute } from '@/entity/journey/route/BookingRoute';
import { type BookingStop } from '@/entity/journey/stop/BookingStop';
import { type IJourney, type IPosition } from '@/features/journey-planning';

export interface IJourneyPlanningFormContext {
    formMethods: UseFormReturn<IJourney>;
    routesArray: UseFieldArrayReturn<IJourney, 'routes'>;
    stopsArray: UseFieldArrayReturn<IJourney, `routes.${number}.stops`>;
    selectedRouteIndex: number;
    formId: string;
    isCalculating: boolean;
    didCalculate: boolean;
    submitJourney: () => void;
    resetJourney: (noConfirm?: boolean) => void;
    selectRoute: (index: number) => void;
    deleteRoute: (index: number) => void;
    getBookingJourney: () => BookingJourney | null;
    getBookingRoute: (routeIndex: number) => BookingRoute | null;
    getBookingStop: (position: IPosition) => BookingStop | null;
}

export const JourneyPlanningFormContext = createContext<IJourneyPlanningFormContext>({} as any);
