import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Stack, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

interface RecommendedDurationProps {
    readonly duration: number;
}

export const RecommendedDuration: FC<RecommendedDurationProps> = ({ duration }) => {
    const { t } = useTranslation();

    if (duration === 0) return null;

    return (
        <Stack direction="row" gap={0.5} alignItems="center">
            <Tooltip
                arrow
                placement="top"
                title={<Typography>{t('trip_company.recommended_duration', { duration })}</Typography>}>
                <WatchLaterIcon fontSize="inherit" />
            </Tooltip>

            <Typography variant="body3">
                {duration} {t('units:minutes.short')}
            </Typography>
        </Stack>
    );
};
