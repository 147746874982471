import { useState } from 'react';

import { type PopupState } from '../types';

import { PopupOverlay } from './popup-overlay';
import { TripCompanyDialog } from './trip-company-dialog';
import { TripCompanyMarker } from './trip-company-marker';

import { type TripCompany } from '@/entity/trip/company/TripCompany';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useTimeout } from '@/utils/helpers';

interface TripCompanyMapOverlayProps {
    readonly tripCompanies?: TripCompany[];
}

export const TripCompanyMapOverlay = ({ tripCompanies }: TripCompanyMapOverlayProps) => {
    const [open, setOpen] = useState(false);
    const [popupState, setPopupState] = useState<PopupState>();

    const { fire, clear } = useTimeout();
    const tripCompanyDialog = useDisclosure();

    const handleMouseOver = (state: PopupState) => {
        setPopupState({ ...state });
        setOpen(true);
        clear();
    };
    const handleMouseOut = () => fire(() => setOpen(false), 200);
    const handleMouseOverPopup = () => clear();
    const handleMouseLeavePopup = () => setOpen(false);
    const handleClick = async () => tripCompanyDialog.open();

    if (!tripCompanies) return null;

    return (
        <>
            {tripCompanies.map(company => (
                <TripCompanyMarker
                    key={company.id}
                    company={company}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                />
            ))}
            <PopupOverlay
                {...popupState}
                open={open}
                onClick={handleClick}
                onMouseLeave={handleMouseLeavePopup}
                onMouseOver={handleMouseOverPopup}
            />
            {popupState?.company && <TripCompanyDialog company={popupState?.company} {...tripCompanyDialog} />}
        </>
    );
};
