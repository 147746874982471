import { Box, type BoxProps } from '@mui/material';
import React from 'react';

import leafletBackgroundSVG from '@/assets/images/leaflet-background-3.svg';
import { Scrollbar } from '@/components/elements/scrollbar/scrollbar';
import { useTile } from '@/components/page/layout/tile/mod';
import { TileProvider } from '@/components/page/layout/tile/tile-provider';
import { useAppCtx } from '@/core/app-ctx/mod';
import { iff } from '@/utils/helpers';

interface TileProps extends BoxProps {
    readonly leafletBackground?: boolean;
    readonly disableScrollbar?: boolean;
}

export const Tile: React.FC<TileProps> = ({ children, ...props }) => {
    return (
        <TileProvider>
            <TileRoot {...props}>{children}</TileRoot>
        </TileProvider>
    );
};

const TileRoot = ({ children, leafletBackground, disableScrollbar, sx, ...rest }: TileProps) => {
    const { isIntegration } = useAppCtx();
    const { spacer } = useTile();

    return (
        <Box
            className="Tile-root"
            sx={{
                height: '100%',
                ...iff(leafletBackground && !isIntegration, {
                    background: `white url(${leafletBackgroundSVG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom left',
                    backgroundSize: 'contain',
                }),
                // Add padding to the top of the content if it's fixed top positioned, so the content doesn't overlap with the fixed element
                ...iff(spacer?.to === 'top', {
                    '.TilePart-root:nth-of-type(2)': {
                        paddingTop: `${spacer?.height}px`,
                    },
                }),

                // Add padding to the bottom of the content if it's fixed bottom positioned, so the content doesn't overlap with the fixed element
                ...iff(spacer?.to === 'bottom', {
                    '.TilePart-root:nth-last-of-type(2)': {
                        paddingBottom: `${spacer?.height}px`,
                    },
                }),

                ...sx,
            }}
            {...rest}>
            {disableScrollbar ? children : <Scrollbar>{children}</Scrollbar>}
        </Box>
    );
};
