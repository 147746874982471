import { createContext, useContext } from 'react';

import { type BookingJourney } from '@/entity/journey/BookingJourney';

interface IJourneyPlanningContext {
    journey: BookingJourney | null;
}

export const JourneyPlanningContext = createContext<IJourneyPlanningContext>({
    journey: null,
});

export function useJourneyPlanning() {
    const context = useContext(JourneyPlanningContext);

    return context;
}
