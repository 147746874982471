import { Loader } from '../components/loader';
import { RoutesArray } from '../components/routes/routes-array';
import { SearchFormActions } from '../components/search-form-actions';

import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';
import { SearchFormBasicTourPlan } from '@/core/tour/SearchFormBasicTour';
import { TourProvider } from '@/core/tour/TourProvider';
import { useJourneyPlanningForm } from '@/features/journey-planning';

export const JourneyPlanningTile: React.FC = () => {
    const { submitJourney } = useJourneyPlanningForm();

    return (
        <Tile leafletBackground>
            <Loader />
            <TourProvider autoStart plan={SearchFormBasicTourPlan}>
                <TilePart>
                    <form noValidate onSubmit={submitJourney}>
                        <RoutesArray />
                    </form>
                </TilePart>
                <TilePart attachable={{ type: 'sticky', to: 'bottom' }}>
                    <SearchFormActions onSubmit={submitJourney} />
                </TilePart>
            </TourProvider>
        </Tile>
    );
};
