import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type ChipVariant } from './types';

import { TripCompany } from '@/entity/trip/company/TripCompany';

interface TripCompanyChipProps extends ChipVariant {
    readonly name?: string;
}

export const TripCompanyChip: React.FC<TripCompanyChipProps> = ({ name, variant = 'filled' }) => {
    const { t } = useTranslation();

    if (name == null) return null;

    const handleClick = () => window.open(TripCompany.buildDetailsUrl(name), '_blank');

    return (
        <Chip
            icon={<FlagOutlinedIcon />}
            label={t('trip_company.trip_company')}
            size="small"
            color="warning"
            variant={variant}
            onClick={handleClick}
        />
    );
};
