import { useNavigate, useSearch } from '@tanstack/react-router';
import { mapValues } from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';

import { type Tiles, type ExtractNames } from '@/components/page/layout/layout';

export const useDrawerNavigation = <T extends Tiles>(tiles: T, isDesktop: boolean) => {
    const navigate = useNavigate({ from: '/' as const });
    // Open State for Drawer Tiles
    const [drawerTilesOpen, setDrawerTilesOpen] = useState<Record<ExtractNames<T>, boolean>>(
        mapValues(tiles, () => false),
    );

    const params: { drawers?: string[] } = useSearch({ strict: false });
    const drawersParams = useMemo(() => params.drawers ?? [], [params.drawers]);

    // Close specific Drawer Tile
    const handleClose = useCallback(
        (name: ExtractNames<T>) => {
            // Remove the drawer from the query params as well
            if (!isDesktop) {
                const updatedDrawers = drawersParams.filter(dt => dt !== name);
                void navigate({
                    search: prev => ({ ...prev, drawers: updatedDrawers.length === 0 ? undefined : updatedDrawers }),
                });
            }
            setDrawerTilesOpen({ ...drawerTilesOpen, [name]: false });
        },
        [drawerTilesOpen, drawersParams, isDesktop, navigate],
    );

    const handleShowBase = useCallback(() => {
        // Close all drawers and remove them from the query params
        if (!isDesktop) void navigate({ search: prev => ({ ...prev, drawers: undefined }) });
        setDrawerTilesOpen(mapValues(drawerTilesOpen, () => false));
    }, [drawerTilesOpen, isDesktop, navigate]);

    // Show specific Drawer Tile
    const handleShow = useCallback(
        (name: ExtractNames<T>) => {
            setDrawerTilesOpen({ ...drawerTilesOpen, [name]: true });

            if (drawersParams.includes(name) || isDesktop) return;

            // Add the drawer to the query params
            void navigate({
                search(prev) {
                    const prevDrawers = prev.drawers ?? [];
                    const drawers = new Set([...prevDrawers, name]);
                    return { ...prev, drawers: Array.from(drawers) };
                },
            });
        },
        [drawerTilesOpen, drawersParams, isDesktop, navigate],
    );

    // Reset drawers query params on mount
    useEffect(() => {
        void navigate({ search: prev => ({ ...prev, drawers: undefined }), replace: true });
    }, [navigate]);

    // Auto open drawers based on query params. This is only for mobile when the user is using the browser back/forward buttons
    useEffect(() => {
        if (isDesktop) return;
        if (drawersParams.length > 0) setDrawerTilesOpen(mapValues(tiles, (_, name) => drawersParams.includes(name)));
        else if (drawersParams.length === 0) setDrawerTilesOpen(mapValues(tiles, () => false));
    }, [drawersParams, isDesktop, tiles]);

    return {
        handleClose,
        handleShowBase,
        handleShow,
        drawerTilesOpen,
    };
};
