import { Box } from '@mui/material';
import { type FC, type MouseEvent, type PropsWithChildren } from 'react';

interface InteractiveTextProps {
    readonly onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
    readonly disabled?: boolean;
    readonly color?: 'primary' | 'warning' | 'error' | 'info' | 'success';
}

export const InteractiveText: FC<PropsWithChildren<InteractiveTextProps>> = ({
    onClick,
    disabled,
    color,
    children,
}) => {
    const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        onClick?.(e);
    };

    return (
        <Box
            component="span"
            sx={{
                fontWeight: disabled ? 'normal' : 'bold',
                cursor: disabled ? 'normal' : 'pointer',
                '&:hover': { textDecoration: disabled ? 'none' : 'underline' },
                color(theme) {
                    return color ? theme.vars.palette[color].main : 'currentColor';
                },
            }}
            onClick={disabled ? undefined : handleClick}>
            {children}
        </Box>
    );
};
