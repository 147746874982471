import { ContactData } from '@/entity/basic/ContactData';

export class OfferCreatedBy {
    public contactData: ContactData;

    public email: string;

    constructor(json: Record<string, any>) {
        this.contactData = ContactData.fromJson(json.contactData);
        this.email = json.email;
    }
}
