import { type ObjectSchema, object, string } from 'yup';

export interface IPwReset {
    readonly password: string;
    readonly passwordConfirmation: string;
}

export const pwResetSchema: ObjectSchema<IPwReset> = object({
    password: string().required(),
    passwordConfirmation: string().required().matchWith('password', { key: 'password.no_match' }),
});
