import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteDateTimeField } from '@/components/form/fields/time/route-date-time-field';
import { useTour } from '@/core/tour/mod';
import { type SearchFormBasicTour } from '@/core/tour/SearchFormBasicTour';
import { type SearchFormFirstIntermediateTour } from '@/core/tour/SearchFormFirstIntermediateTour';
import { DefaultStop } from '@/entity/journey/saved/DefaultStop';
import { type IJourney, type IPosition, type IStop, useJourneyPlanningForm } from '@/features/journey-planning';

export interface StopArrivalProps {
    readonly position: IPosition;
    readonly stop?: IStop;
    readonly isAnyFieldBeingDragged: boolean;
}

export const StopArrival: React.FC<StopArrivalProps> = ({ position, stop, isAnyFieldBeingDragged }) => {
    const { t } = useTranslation();
    const { register } = useTour<SearchFormBasicTour | SearchFormFirstIntermediateTour>();
    const {
        formMethods: { trigger },
        stopsArray: { replace, fields },
    } = useJourneyPlanningForm();

    const [prevValue, setPrevValue] = useState<DateTime | null>(stop?.arrivalDateTime ?? null);

    const handleAccept = (value: any) => {
        if (value == null || !(value instanceof DateTime) || !value.isValid) {
            replace(fields);
            return;
        }

        if (prevValue && prevValue !== value) {
            const shiftDuration = value.diff(prevValue);
            const isShiftPositive = shiftDuration.as('milliseconds') > 0;

            let didShiftBreakTime = false;

            replace(
                fields
                    .reverse()
                    .map((field, i) => {
                        if (didShiftBreakTime) return field;

                        // If the shift duration is positive, add break time of same duration to the previous intermediate
                        if (isShiftPositive && i !== 0) {
                            didShiftBreakTime = true;

                            return {
                                ...field,
                                departureDateTime: field.departureDateTime?.plus(shiftDuration) ?? null,
                            };
                        }

                        // Deduct shift duration from latest break time on an intermediate if possible
                        if (DefaultStop.canShiftBreakTime(field, shiftDuration)) didShiftBreakTime = true;

                        return {
                            ...field,
                            arrivalDateTime: field.arrivalDateTime?.plus(shiftDuration) ?? null,
                            departureDateTime: field.departureDateTime?.plus(shiftDuration) ?? null,
                        };
                    })
                    .reverse(),
            );

            void trigger(`routes.${position.routeIndex}.stops`);
        }

        setPrevValue(value);
    };

    if (position.first || stop?.arrivalDateTime == null) return null;

    return (
        <RouteDateTimeField<IJourney>
            deferHookFormPropagation
            visible={!isAnyFieldBeingDragged}
            fieldRef={position.index === 1 ? register('to_time') : undefined}
            label={t('search_form.to.datetime.label')}
            name={`routes.${position.routeIndex}.stops.${position.index}.arrivalDateTime`}
            disabled={!position.last}
            onAccept={handleAccept}
        />
    );
};
