import { type FormControlProps } from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import { type ElementType, type ReactElement, type RefCallback } from 'react';

export type FormFieldProps<D extends ElementType = 'div'> = Omit<FormControlProps<D>, 'children' | 'error'> & {
    name: string;
    id?: string;
    label?: string | number | ReactElement;
    helperText?: string | number | ReactElement;
    fieldRef?: RefCallback<HTMLElement>;
};

export function makeFieldIds(name: string, id?: string): [string, string, string] {
    const uid = id ?? uniqueId(name);
    return [uid, `${uid}-helper-text`, `${uid}-label`];
}
