import { Box } from '@mui/material';
import React from 'react';

import { useLayout } from '@/components/page/layout/mod';

interface FormRowProps {
    readonly only?: 'mobile' | 'desktop';
}

export const FormRow: React.FC<React.PropsWithChildren<FormRowProps>> = ({ children, only }) => {
    const { isDesktop } = useLayout();

    if (isDesktop && only === 'mobile') return null;
    if (!isDesktop && only === 'desktop') return null;

    return <Box sx={{ display: 'contents' }}>{children}</Box>;
};
