import { SvgIcon, Tooltip, Typography, type SvgIconProps } from '@mui/material';
import React from 'react';

import DirectbankSvg from '@/assets/icons/payment-methods/directbank.svg?react';
import EpsSvg from '@/assets/icons/payment-methods/eps.svg?react';
import MaestroSvg from '@/assets/icons/payment-methods/maestro.svg?react';
import MastercardSvg from '@/assets/icons/payment-methods/mastercard.svg?react';
import PaypalSvg from '@/assets/icons/payment-methods/paypal.svg?react';
import VisaSvg from '@/assets/icons/payment-methods/visa.svg?react';
import { PaymentMethod } from '@/entity/basic/Gateway';

const ICON_PROPS: Record<PaymentMethod, SvgIconProps & { component: React.FunctionComponent }> = {
    [PaymentMethod.DIRECTBANK]: { width: 60, height: 40, component: DirectbankSvg, viewBox: '0 0 70 40' },
    [PaymentMethod.EPS]: { width: 60, height: 40, component: EpsSvg, viewBox: '0 0 70 40' },
    [PaymentMethod.MAESTRO]: { width: 60, height: 40, component: MaestroSvg, viewBox: '0 0 70 40' },
    [PaymentMethod.MASTERCARD]: { width: 60, height: 40, component: MastercardSvg, viewBox: '0 0 70 40' },
    [PaymentMethod.PAYPAL]: { width: 60, height: 40, component: PaypalSvg, viewBox: '0 0 124 33' },
    [PaymentMethod.VISA]: { width: 60, height: 40, component: VisaSvg, viewBox: '0 0 100 40' },
    [PaymentMethod.INVOICE]: { width: 0, height: 0, component: React.Fragment, viewBox: '0 0 0 0' },
};

interface PaymentMethodIconProps extends SvgIconProps {
    readonly type: PaymentMethod;
}

export const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = ({ type, ...props }) => {
    const { width, height } = props;
    const { component, viewBox, width: defaultWidth, height: defaultHeight } = ICON_PROPS[type];

    return (
        <Tooltip
            title={
                <Typography variant="body2" textTransform="capitalize">
                    {type.toLowerCase()}
                </Typography>
            }>
            <div>
                <SvgIcon
                    {...props}
                    component={component}
                    viewBox={viewBox}
                    sx={{
                        height: height || defaultHeight,
                        width: width || defaultWidth,
                    }}
                />
            </div>
        </Tooltip>
    );
};
