import { SvgIcon, useTheme, type SvgIconProps } from '@mui/material';
import React from 'react';

import MidibusSvg from '@/assets/icons/bus-types/midibus.svg?react';
import MidibusFilledSvg from '@/assets/icons/bus-types/midibus_filled.svg?react';
import MinibusSvg from '@/assets/icons/bus-types/minibus.svg?react';
import MinibusFilledSvg from '@/assets/icons/bus-types/minibus_filled.svg?react';
import ReisebusSvg from '@/assets/icons/bus-types/reisebus.svg?react';
import ReisebusFilledSvg from '@/assets/icons/bus-types/reisebus_filled.svg?react';
import VanSvg from '@/assets/icons/bus-types/van.svg?react';
import VanFilledSvg from '@/assets/icons/bus-types/van_filled.svg?react';
import XLBusSvg from '@/assets/icons/bus-types/xl_bus.svg?react';
import XLBusFilledSvg from '@/assets/icons/bus-types/xl_bus_filled.svg?react';

export type BusType = 'van' | 'minibus' | 'midibus' | 'reisebus' | 'xl-bus';

const ICON_PROPS: Record<BusType, { outlined: React.FunctionComponent; filled: React.FunctionComponent }> = {
    van: { outlined: VanSvg, filled: VanFilledSvg },
    minibus: { outlined: MinibusSvg, filled: MinibusFilledSvg },
    midibus: { outlined: MidibusSvg, filled: MidibusFilledSvg },
    reisebus: { outlined: ReisebusSvg, filled: ReisebusFilledSvg },
    'xl-bus': { outlined: XLBusSvg, filled: XLBusFilledSvg },
};

interface BusTypeIconProps extends SvgIconProps {
    readonly variant: 'filled' | 'outlined';
    readonly outlineColor?: 'primary' | 'gray';
    readonly fillColor?: 'primary' | 'gray';
    readonly type: BusType;
    readonly height?: number | string;
    readonly width?: number | string;
    readonly cursor?: string;
}

export const BusTypeIcon: React.FC<BusTypeIconProps> = ({
    variant = 'outlined',
    outlineColor = 'primary',
    fillColor = 'primary',
    type,
    height = 30,
    width = 140,
    cursor = 'pointer',
    ...props
}) => {
    const theme = useTheme();
    return (
        <SvgIcon
            {...props}
            inheritViewBox
            color="primary"
            sx={{
                cursor,
                height,
                width,
                '& path, & polygon, & rect, & circle, & line': {
                    stroke:
                        variant === 'filled' && fillColor === 'gray'
                            ? theme.palette.grey[400]
                            : outlineColor === 'gray'
                              ? theme.palette.grey[800]
                              : theme.palette.primary.main,
                    fill:
                        variant === 'filled' && fillColor === 'gray'
                            ? theme.palette.grey[400]
                            : variant === 'filled'
                              ? theme.palette.primary.main
                              : theme.palette.background.paper,
                },
            }}
            component={ICON_PROPS[type][variant]}
        />
    );
};
