import { useQueryClient, useMutation } from '@tanstack/react-query';

import { type IntegrationOverrideables } from '../types';

import callApi from '@/api';
import { Integration } from '@/entity/integration/Integration';

async function patchIntegration({
    id,
    body,
}: {
    id: number;
    body: Partial<IntegrationOverrideables>;
}): Promise<Integration> {
    const { data } = await callApi(`api/booking-integrations/${id}?projection=booking`, {
        method: 'PATCH',
        data: body,
    });

    return new Integration(data);
}

export const usePatchIntegration = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: patchIntegration,
        onSuccess(data) {
            queryClient.setQueryData<Integration[]>(['integrations'], oldData => {
                if (oldData == null) return oldData;

                const idx = oldData.findIndex(integration => integration.id === data.id);

                // If we do not find the integration in the old data refetch all integrations
                if (idx < 0) {
                    void queryClient.invalidateQueries({ queryKey: ['integrations'] });
                    return oldData;
                }

                // Patch the old data with the new integration
                const newData = [...oldData];
                newData[idx] = data;
                return newData;
            });
        },
    });
};
