import { DateTime } from 'luxon';

import { type Basic } from '@/entity/basic/I_Basic';
import { Location } from '@/entity/basic/Location';

export class TimeAndLocation implements Basic<TimeAndLocation> {
    public static fromJson(json: Record<string, any>): TimeAndLocation {
        return new TimeAndLocation(DateTime.fromISO(json.time), Location.fromJson(json.location), json.tripCompanyId);
    }

    public time: DateTime;

    public location: Location;

    public tripCompanyId: number;

    constructor(time: DateTime, location: Location, tripCompanyId: number) {
        this.time = time;
        this.location = location;
        this.tripCompanyId = tripCompanyId;
    }

    public equals(other: TimeAndLocation): boolean {
        return other != null && this.location.equals(other.location) && this.time.equals(other.time);
    }

    public toString(): string {
        return `Time=(${this.time.toFormat('DD.MM.YYYY HH:mm')}), Location=(${this.location.toString()})`;
    }
}
