import { type Language } from '@/config/localization';
import { Entity } from '@/entity/A_Entity';
import { ContactData } from '@/entity/basic/ContactData';
import { Document } from '@/entity/basic/Document';
import { Location } from '@/entity/basic/Location';

export abstract class Company extends Entity {
    public companyName: string;

    public registeredCompanyName: string;

    public logo?: Document;

    public uidNumber?: string;

    public agb?: string;

    public paymentConditions?: string;

    public reversalConditions?: string;

    public confirmationConclusion?: string;

    public contactData: ContactData;

    public location: Location;

    public description?: string;

    public visible: boolean;

    public freeCancellation: boolean;

    public freeCancellationDays: number;

    public language: Language;

    constructor(json: Record<string, any>) {
        super(json);

        this.companyName = json.companyName;
        this.registeredCompanyName = json.registeredCompanyName;
        this.contactData = ContactData.fromJson(json.contactData);
        this.location = Location.fromJson(json.location);
        this.logo = json.logo ? Document.fromJson(json.logo) : undefined;
        this.visible = json.visible;
        this.freeCancellation = json.freeCancellation;
        this.freeCancellationDays = json.freeCancellationDays;
        this.agb = json.agb;
        this.uidNumber = json.uidNumber;
        this.description = json.description;
        this.paymentConditions = json.paymentConditions;
        this.reversalConditions = json.reversalConditions;
        this.confirmationConclusion = json.confirmationConclusion;
        this.language = json.language;
    }

    public toString(): string {
        return `${this.companyName} - ${this.registeredCompanyName}`;
    }
}

export enum CompanyOrigin {
    ADMIN_CREATE = 'ADMIN_CREATE',
    ONLINE_REGISTRATION = 'ONLINE_REGISTRATION',
    DAIMLER_IMPORT = 'DAIMLER_IMPORT',
}
