import { useMatches, useNavigate } from '@tanstack/react-router';
import { useCallback, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import { AppProgress } from '@/core/app-progress/AppProgress';
import { AppProgressContext, type IAppProgressContext } from '@/core/app-progress/mod';
import { useAuth } from '@/core/auth/mod';
import { type DetailsBooking } from '@/entity/booking/DetailsBooking';
import { type BookingJourney } from '@/entity/journey/BookingJourney';

export const AppProgressProvider: FC<PropsWithChildren> = ({ children }) => {
    const { user } = useAuth();
    const matches = useMatches();
    const navigate = useNavigate();

    const isPrint = useMemo(() => matches[matches.length - 1]?.context?.print ?? false, [matches]);

    // Extract the current step from the router context
    const currentStep = useMemo(() => matches[matches.length - 1]?.context?.step, [matches]);

    const [progress, setProgress] = useState<AppProgress>(AppProgress.fromAny(null, user));

    const getPreviousStep = useCallback(() => {
        const idx = progress.steps.findIndex(step => step.name === currentStep);

        return progress.steps[idx - 1] ?? progress.steps[0];
    }, [currentStep, progress]);

    const getNextStep = useCallback(() => {
        const idx = progress.steps.findIndex(step => step.name === currentStep);

        return progress.steps[idx + 1] ?? progress.steps[progress.steps.length - 1];
    }, [currentStep, progress]);

    const update = useCallback(
        (journeyOrBooking: BookingJourney | DetailsBooking | null) => {
            const progress = AppProgress.fromAny(journeyOrBooking, user);
            setProgress(progress);

            // Do not navigate if we are printing or we are not on a progress step page
            if (isPrint || currentStep == null) return;

            // Validate if the user can be on the currentStep as dictated by the progress
            if (progress.isPreviousStepCompleted(currentStep)) return;

            const best = progress.getBestAvailableStep();
            void navigate(best.navigate);
        },
        [currentStep, isPrint, navigate, user],
    );

    const value: IAppProgressContext = useMemo(() => {
        return {
            progress,
            currentStep,
            isCurrentStepCompleted: progress.isStepCompleted(currentStep),
            isPrint,
            getPreviousStep,
            getNextStep,
            update,
        };
    }, [progress, currentStep, isPrint, getPreviousStep, getNextStep, update]);

    return <AppProgressContext.Provider value={value}>{children}</AppProgressContext.Provider>;
};
