import { type ReactEventHandler, useRef } from 'react';

export function nTimes(n: number, from = 0): number[] {
    return Array.from({ length: n }).map((_, i) => from + i);
}

interface IUseTimeout {
    fire: (cb: TimerHandler, ms: number) => void;
    clear: () => void;
}

export function useTimeout(): IUseTimeout {
    const timeout = useRef<number>();

    return {
        fire(cb, ms) {
            if (timeout.current) clearTimeout(timeout.current);
            timeout.current = setTimeout(cb, ms);
        },
        clear() {
            if (timeout.current) clearTimeout(timeout.current);
        },
    };
}

export function iff<T>(check: unknown, x: T): T | undefined {
    if (check) return x;
}

export function noPropagate<T>(handler: ReactEventHandler<T>): ReactEventHandler<T> {
    return e => {
        handler(e);
        e.stopPropagation();
    };
}

export function isEmpty(string: string): boolean {
    return !string || string.length === 0;
}

function isBlank(string: string): boolean {
    return !string || string.trim().length === 0;
}

export function joinNotEmptyToString(delimiter: string, ...args: string[]): string {
    return args.filter(string => !isEmpty(string)).join(delimiter);
}

export function joinNotBlankToString(delimiter: string, ...args: string[]): string {
    return args.filter(string => !isBlank(string)).join(delimiter);
}

export function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
    return value !== null && value !== undefined;
}
