import { Box, Stack, styled } from '@mui/material';
import { type FC, type PropsWithChildren } from 'react';

import { type BusCardDetailedProps } from '../../types/bus-card-detailed';
import { TopEquipmentsIcons } from '../elements/top-equipment-icons';
import { BusCardActions } from '../sections/bus-card-actions';

import { BusCancellation } from '@/components/bus/bus-cancellation';
import { BusImage } from '@/components/bus/bus-image';
import BusSeatsInfo from '@/components/bus/bus-seats-info';
import { BusPrice } from '@/components/bus/price/bus-price';
import { useAuth } from '@/core/auth/mod';
import { useJourney } from '@/core/journey/mod';

const BusCardGridSingleCompany = styled(Box)(({ theme }) => ({
    display: 'grid',
    padding: theme.spacing(1),
    columnGap: theme.spacing(2),
    gridAutoFlow: 'row',
    [theme.breakpoints.down('small_mobile')]: {
        gridTemplateColumns: '0.3fr 0.7fr',
        gridTemplateRows: 'max-content max-content max-content auto max-content max-content',
        gridTemplateAreas: `
            "image image"
            "header header"
            "info info"
            "cancel cancel"
            "equipment price"
            "actions actions"
            `,
        '&>.review': { display: 'none' },
    },
    [theme.breakpoints.between('small_mobile', 'sm')]: {
        gridTemplateColumns: '0.35fr 0.12fr 0.53fr',
        gridTemplateRows: 'max-content max-content max-content max-content',
        gridTemplateAreas: `
            "image header header"
            "image cancel cancel"
            "image equipment price"
            "actions actions actions"
            `,
    },

    [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '30% minmax(20%, 30%) minmax(2%, 10%) auto',
        gridTemplateRows: 'max-content max-content max-content',
        gridTemplateAreas: `
            "image header header price"
            "image cancel cancel cancel"
            "image equipment actions actions"
            `,
    },
    '&>.item': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('small_mobile')]: {
            marginTop: theme.spacing(0.75),
            marginBottom: theme.spacing(0.75),
        },
        '&:empty': {
            padding: 0,
            margin: 0,
        },
    },
    '&>.image': { gridArea: 'image' },
    '&>.price': { gridArea: 'price', justifySelf: 'end' },
    '&>.header': { gridArea: 'header' },
    '&>.cancel': { gridArea: 'cancel', justifySelf: 'end' },
    '&>.equipment': { gridArea: 'equipment', alignSelf: 'end' },
    '&>.actions': { gridArea: 'actions', alignSelf: 'end' },
}));

interface CardAreaProps {
    readonly area: 'image' | 'seats' | 'price' | 'equipment' | 'actions' | 'brand' | 'cancel' | 'header';
}

const CardArea: FC<PropsWithChildren<CardAreaProps>> = ({ area, children }) => {
    return <div className={`item ${area}`}>{children}</div>;
};

export const BusCardDetailedSingleCompany: FC<BusCardDetailedProps> = ({
    result,
    route,
    dragHandleProps,
    onDetails,
    onSelect,
}) => {
    const { user } = useAuth();
    const { bus } = result;
    const { journey } = useJourney();

    // On single company integrations, if all results have the same price options, we show the same price option as the first selected one.
    const firstSelectedPriceOption = journey?.allResultsHaveSamePriceOptions()
        ? journey.routes[0].getSelectedSearchResults()[0]?.selectedPriceOption
        : undefined;

    const priceToShow = firstSelectedPriceOption
        ? result?.priceOptions.find(p => p.id === firstSelectedPriceOption.id)?.price
        : result.getPriceToShow();

    const priceDisplayRows = firstSelectedPriceOption
        ? result?.priceOptionDisplayRows.find(row => row.id === firstSelectedPriceOption.id)?.priceDisplayRows
        : result.getLowestPriceOptionDisplayRows();

    const showFromString = firstSelectedPriceOption ? false : result.priceOptions.length > 1;

    return (
        <BusCardGridSingleCompany
            {...dragHandleProps}
            sx={{
                cursor: dragHandleProps ? 'grab' : undefined,
                backgroundColor(theme) {
                    return user?.isOwnerOfSearchResult(result)
                        ? `color-mix(in srgb, ${theme.vars.palette.primary.light} 8%, transparent)`
                        : theme.vars.palette.background.paper;
                },
            }}>
            <CardArea area="image">
                <BusImage bus={bus} />
            </CardArea>
            <CardArea area="header">
                <Stack gap={0.5}>
                    <BusSeatsInfo bus={bus} variant="bus-info" />
                    <BusSeatsInfo bus={bus} variant="seats" />
                </Stack>
            </CardArea>
            <CardArea area="price">
                <BusPrice
                    pax={route?.pax ?? 1}
                    seats={bus.getTotalSeats()}
                    showFromString={showFromString}
                    priceDisplayRows={priceDisplayRows}
                    priceToShow={priceToShow}
                />
            </CardArea>
            <CardArea area="cancel">
                <BusCancellation resultOrTask={result} align="right" />
            </CardArea>
            <CardArea area="equipment">
                <TopEquipmentsIcons topEquipments={bus.topEquipments} />
            </CardArea>
            <CardArea area="actions">
                <BusCardActions
                    forcedPriceOption={firstSelectedPriceOption}
                    result={result}
                    route={route}
                    onDetails={onDetails}
                    onSelect={onSelect}
                />
            </CardArea>
        </BusCardGridSingleCompany>
    );
};
