import { Drawer, type GridSize } from '@mui/material';
import React, { type RefObject } from 'react';

interface DrawerTileProps {
    readonly open: boolean;
    readonly onClose: () => void;
    readonly modalRef: RefObject<HTMLElement>;
    readonly isDesktop: boolean;
    readonly md?: GridSize;
}

export const DrawerTile: React.FC<React.PropsWithChildren<DrawerTileProps>> = ({
    open,
    onClose,
    modalRef,
    isDesktop,
    md,
    children,
}) => {
    return (
        <Drawer
            // Mobile issue: the split modal appears on every bus selection due to the route-selection drawer unmounting. So we use the 'persistent' variant on mobile.
            variant={isDesktop ? 'temporary' : 'persistent'}
            anchor="right"
            open={open}
            ModalProps={{ container: modalRef.current }}
            PaperProps={{
                sx: {
                    overscrollBehavior: 'contain',
                    width: isDesktop && md && md !== 'auto' ? `calc((100% / 12) * ${md})` : '100%',
                },
            }}
            onClose={onClose}>
            {children}
        </Drawer>
    );
};
