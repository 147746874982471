import { IconButton, type IconButtonProps } from '@mui/material';
import { useLinkProps } from '@tanstack/react-router';
import { type LinkOptions } from '@tanstack/react-router';
import React from 'react';

export interface NavIconButtonProps extends IconButtonProps {
    readonly navigate: LinkOptions;
    readonly activeClassName?: string;
}

export const NavIconButton: React.FC<React.PropsWithChildren<NavIconButtonProps>> = ({
    navigate,
    children,
    activeClassName,
    ...buttonProps
}) => {
    const linkProps = useLinkProps({
        ...navigate,
        onClick: buttonProps.onClick as any,
        onFocus: buttonProps.onFocus as any,
        onMouseEnter: buttonProps.onMouseEnter as any,
        onMouseLeave: buttonProps.onMouseLeave as any,
        onTouchStart: buttonProps.onTouchStart as any,
        disabled: buttonProps.disabled,
        activeProps: {
            className: activeClassName,
        },
    }) as IconButtonProps;

    return (
        <IconButton {...buttonProps} {...linkProps}>
            {children}
        </IconButton>
    );
};
