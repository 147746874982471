import { Container, Menu, type MenuProps } from '@mui/material';
import { type PropsWithChildren } from 'react';

interface NavMenuProps extends MenuProps {}

export const NavMenu = ({ children, ...props }: PropsWithChildren<NavMenuProps>) => {
    return (
        <Menu
            {...props}
            sx={{
                top: 2,
                left: 16,
                '.MuiPaper-root': {
                    borderRadius: 0,
                    width: '100vw',
                    maxWidth: '100vw',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
                '.MuiMenuItem-root': {
                    paddingLeft: '9px',
                },
            }}>
            <Container>{children} </Container>
        </Menu>
    );
};
