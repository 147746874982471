import { DetailsBooking } from '@/entity/booking/DetailsBooking';
import { BookingJourney } from '@/entity/journey/BookingJourney';

export class BookingOfferJourney extends BookingJourney {
    public booking?: DetailsBooking;

    constructor(json: Record<string, any>) {
        super(json);
        this.booking = json.booking ? new DetailsBooking(json.booking) : undefined;
    }
}
