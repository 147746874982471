import { DateTime } from 'luxon';

import { Money } from '@/entity/basic/Money';

export enum PriceDisplayType {
    BASE = 'BASE',
    RELEASE = 'RELEASE',
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS',
    SCHOOL = 'SCHOOL',
    RESELLER = 'RESELLER',
    SPECIAL = 'SPECIAL',
    CASHBACK = 'CASHBACK',
    COUPON = 'COUPON',
    FINAL = 'FINAL',
}

export class PriceDisplayRow {
    static fromJson(json: Record<string, any>): PriceDisplayRow {
        return new PriceDisplayRow(json);
    }

    public type: PriceDisplayType;

    public price: Money;

    public discount?: Money;

    public discountValidUntil?: DateTime;

    constructor(json: Record<string, any>) {
        this.type = json.type;
        this.price = Money.fromJson(json.price);
        this.discount = json.discount ? Money.fromJson(json.discount) : undefined;
        this.discountValidUntil = DateTime.fromISO(json.discountValidUntil);
    }
}
