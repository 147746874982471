import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '@/components/elements/action/action-button';
import { type ActionButtonIntent } from '@/components/elements/action/mod';

export interface BaseModalProps {
    readonly title?: string | JSX.Element;
    readonly content?: string | JSX.Element;
    readonly actions?: JSX.Element;
    readonly handleClose: () => void;
    readonly onProceed?: (values?: any) => void;
    readonly proceedText?: string | null;
    readonly proceedButtonType?: ActionButtonIntent;
    readonly onCancel?: () => void;
    readonly cancelText?: string | null;
    readonly cancelButtonType?: ActionButtonIntent;
    readonly maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
    readonly preventCloseOnOverlayClick?: boolean;
    readonly preventCloseOnProceed?: boolean;
    readonly hideActions?: boolean;
    readonly padding?: boolean;
    readonly blurBackground?: boolean;
    readonly open?: boolean;
}

const BaseModal: React.FC<BaseModalProps> = props => {
    const { t } = useTranslation();
    const {
        title,
        content,
        actions,
        handleClose,
        onProceed,
        proceedText = t('confirm'),
        proceedButtonType = 'normal',
        onCancel,
        cancelText = t('cancel'),
        cancelButtonType = 'negative',
        maxWidth,
        preventCloseOnOverlayClick,
        hideActions,
        padding,
        blurBackground,
        open = true,
    } = props;
    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={maxWidth}
            slotProps={{
                backdrop: {
                    style: blurBackground ? { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' } : {},
                },
            }}
            PaperProps={{ sx: { p: padding ? 3 : 0 } }}
            onClose={preventCloseOnOverlayClick ? undefined : handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {typeof content === 'string' ? (
                    <DialogContentText variant="body2" sx={{ textAlign: 'justify' }}>
                        {content}
                    </DialogContentText>
                ) : (
                    content
                )}
            </DialogContent>
            {hideActions ? null : (
                <DialogActions>
                    {actions ?? (
                        <>
                            <ActionButton
                                category="secondary"
                                intent={cancelButtonType}
                                onClick={() => {
                                    onCancel?.();
                                    handleClose();
                                }}>
                                {cancelText}
                            </ActionButton>
                            <ActionButton
                                category="primary"
                                intent={proceedButtonType}
                                onClick={() => {
                                    onProceed?.();
                                    handleClose();
                                }}>
                                {proceedText}
                            </ActionButton>
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default BaseModal;
