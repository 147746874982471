import Close from '@mui/icons-material/Close';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import { Box, Chip, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime, Duration } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ChipVariant } from './types';

import { ActionButton } from '@/components/elements/action/action-button';
import { useLocalizedFormatters } from '@/core/localization/mod';
import { type BookingStop } from '@/entity/journey/stop/BookingStop';
import { type IStop } from '@/features/journey-planning';

interface BreakTimeChipProps extends ChipVariant {
    readonly bookingStop: BookingStop | null;
    readonly onSave: (duration: Partial<IStop>) => void;
}

export const BreakTimeChip: React.FC<BreakTimeChipProps> = ({ bookingStop, variant, onSave }) => {
    const { t } = useTranslation();
    const { formatDuration } = useLocalizedFormatters();
    const [breakDuration, setBreakDuration] = useState<Duration>(
        Duration.fromObject({ days: 0, hours: 0, minutes: 0, ...bookingStop?.stayDuration.toObject() }).normalize(),
    );
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    if (!bookingStop) return null;

    const { arrivalDateTime, stayDuration } = bookingStop;

    const isStay = stayDuration.as('minutes') >= 1;
    const fStayDuration = formatDuration(stayDuration, ['days', 'hours', 'minutes'], {
        floor: true,
        variant: 'long',
    });

    if (!isStay) return null;

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangeDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        const days = event.target.value === '' ? 0 : Number(event.target.value);

        setBreakDuration(breakDuration.set({ days }));
    };

    const handleChangeTime = (value: DateTime | null) => {
        const hours = value?.hour ?? 0;
        const minutes = value?.minute ?? 0;

        setBreakDuration(breakDuration.set({ hours, minutes }));
    };

    const handleErrorTime = (reason: any) => setError(reason);

    const handleSave = () => {
        if (error) return;
        onSave({
            departureDateTime: arrivalDateTime.plus(breakDuration),
        });

        return handleClose();
    };

    const handleDelete = () => {
        onSave({
            departureDateTime: DateTime.fromObject(arrivalDateTime.toObject()),
        });
    };

    return (
        <>
            <Chip
                label={fStayDuration}
                color="success"
                size="small"
                variant={variant}
                icon={<FreeBreakfastOutlinedIcon />}
                onClick={handleOpen}
                onDelete={handleDelete}
            />
            <Dialog keepMounted open={open} maxWidth="xs" onClose={handleClose}>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography>{t('break_time.title')}</Typography>

                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <Grid
                    container
                    item
                    justifyContent="center"
                    spacing={2}
                    padding={2}
                    direction={{ xs: 'column', sm: 'row' }}>
                    <Grid item xs={3}>
                        <TextField
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            label={t('units:days.long', { count: breakDuration.days })}
                            value={breakDuration.days}
                            onChange={handleChangeDays}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TimePicker
                            ampm={false}
                            label={t('units:hours.long_other')}
                            value={DateTime.fromObject({ hour: breakDuration.hours, minute: breakDuration.minutes })}
                            onError={handleErrorTime}
                            onChange={handleChangeTime}
                        />
                    </Grid>
                </Grid>

                <DialogActions>
                    <ActionButton type="button" category="primary" disabled={Boolean(error)} onClick={handleSave}>
                        {t('save')}
                    </ActionButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
