/*
Customer (no Reseller)  ROUTE_PLANNING, BUS_SELECTION, CONTACT_DATA, SUMMARY, (BOOKING_CONFIRMATION)
Customer (Reseller)     ROUTE_PLANNING, BUS_SELECTION, CONTACT_DATA, SUMMARY, (BOOKING_CONFIRMATION)
Customer (accept Offer) CUSTOMER_OFFER,                CONTACT_DATA, SUMMARY, (BOOKING_CONFIRMATION)
Operator/Dispatcher     ROUTE_PLANNING, BUS_SELECTION, CONTACT_DATA, SUMMARY, (OFFER_CONFIRMATION)
*/

export enum BookingStep {
    ROUTE_PLANNING = 'ROUTE_PLANNING',
    BUS_SELECTION = 'BUS_SELECTION',
    CUSTOMER_OFFER = 'CUSTOMER_OFFER',
    CONTACT_DATA = 'CONTACT_DATA',
    SUMMARY = 'SUMMARY',
    // These are confirmation steps that don't exist as a BookingStep in the backend:
    BOOKING_CONFIRMATION = 'BOOKING_CONFIRMATION',
    OFFER_CONFIRMATION = 'OFFER_CONFIRMATION',
}

export interface BookingProgress {
    completed: boolean;
    completedExceptSummary: boolean;
    steps: BookingProgressStep[];
}

interface BookingProgressStep {
    name: BookingStep;
    completed: boolean;
    previous: BookingStep;
    next: BookingStep;
}
