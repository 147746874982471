import { yupResolver } from '@hookform/resolvers/yup';
import { type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePwResetRequest } from '../api/pw-reset-request';
import { pwResetRequestSchema } from '../types/pw-reset-request-form';

import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { useSnack } from '@/core/snack/mod';
import { useDialog } from '@/features/ui';

export const PwResetRequestForm: FC = () => {
    const { t } = useTranslation();
    const { id, close } = useDialog();
    const snack = useSnack();
    const requestPasswordResetLink = usePwResetRequest();
    const formMethods = useForm({
        resolver: yupResolver(pwResetRequestSchema),
    });

    const handleValidSubmit = async (values: { email: string }) => {
        close();
        if (!requestPasswordResetLink.isIdle) return;
        try {
            await requestPasswordResetLink.mutateAsync(values.email);
        } finally {
            snack.push(t('forgot_password.success.text'), 'success');
        }
    };

    return (
        <FormProvider {...formMethods}>
            <form noValidate id={id} onSubmit={formMethods.handleSubmit(handleValidSubmit)}>
                <FormFieldsContainer>
                    <FormFieldRow>
                        <TextField name="email" label={t('account.core_data.email')} />
                    </FormFieldRow>
                </FormFieldsContainer>
            </form>
        </FormProvider>
    );
};
