import { useMutation } from '@tanstack/react-query';

import callApi from '@/api';
import { type IRegistration } from '@/features/auth';

async function postRegistration(body: IRegistration) {
    const { data } = await callApi<{ id: number }>('public/registration', {
        method: 'POST',
        data: body,
    });

    return data;
}

export function useRegistration() {
    return useMutation({ mutationFn: postRegistration });
}
