import { yupResolver } from '@hookform/resolvers/yup';
import Close from '@mui/icons-material/Close';
import ModeOfTravelOutlined from '@mui/icons-material/ModeOfTravelOutlined';
import {
    Box,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { type ChipVariant } from './types';

import { ActionButton } from '@/components/elements/action/action-button';
import { NumberField } from '@/components/form/fields/number-field';
import { TextField } from '@/components/form/fields/text-field';
import { useTour } from '@/core/tour/mod';
import { type SearchFormFirstIntermediateTour } from '@/core/tour/SearchFormFirstIntermediateTour';
import { BookingStop } from '@/entity/journey/stop/BookingStop';
import {
    type IPosition,
    type IStop,
    useJourneyPlanningForm,
    type ILocalBus,
    localBusSchema,
} from '@/features/journey-planning';
import { noPropagate } from '@/utils/helpers';

interface LocalBusChipProps extends ChipVariant {
    readonly stop: BookingStop | null;
    readonly position: IPosition;
    readonly onSave: (stop: Partial<IStop>) => void;
}

export const LocalBusChip: React.FC<LocalBusChipProps> = ({ stop, position, variant, onSave }) => {
    const { t } = useTranslation();
    const { formMethods } = useJourneyPlanningForm();
    const { register } = useTour<SearchFormFirstIntermediateTour>();

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSubmit = (localBus: ILocalBus | null) => {
        onSave({ localBus });
    };

    const handleDelete = () => {
        onSave({ localBus: null });
    };

    const isDisabled = stop ? stop.stayDuration.as('minutes') < 60 : true;
    const name = useMemo(
        () => `routes.${position.routeIndex}.stops.${position.index}.localBus` as const,
        [position.index, position.routeIndex],
    );

    // If they've set a local bus but then change the date times so that the local bus is no longer valid, remove it
    useEffect(() => {
        if (isDisabled && stop?.localBus) formMethods.setValue(name, null);
    }, [formMethods, isDisabled, name, stop?.localBus]);

    const isInvalid = formMethods.formState.errors.routes?.[position.routeIndex]?.stops?.[position.index]?.localBus;
    // If the local bus max distance changes, we need to revalidate the field to ensure it's still valid
    useEffect(() => {
        void formMethods.trigger(name);
        if (isInvalid)
            formMethods.setValue(name, null, {
                shouldDirty: true,
                shouldValidate: true,
            });
    }, [formMethods, isInvalid, name]);

    const chipLabel = () => {
        if (isDisabled) return t('search_form.local_bus.disabled');
        if (stop?.localBus)
            return t('search_form.local_bus.bus_used_locally', {
                distance: `${stop?.localBus.distance}km`,
            });

        return t('search_form.local_bus.use_bus_locally');
    };

    if (!stop) return null;

    return (
        <>
            <Tooltip
                disableFocusListener
                disableHoverListener={!isDisabled}
                title={t('search_form.local_bus.tt_disabled')}>
                <Box>
                    <Chip
                        ref={!position.last && position.index === 1 ? register('local_bus') : undefined}
                        sx={{ width: '100%' }}
                        disabled={isDisabled}
                        icon={<ModeOfTravelOutlined />}
                        label={chipLabel()}
                        color="primary"
                        size="small"
                        variant={variant}
                        onClick={handleOpen}
                        onDelete={stop.localBus ? handleDelete : undefined}
                    />
                </Box>
            </Tooltip>
            {/* A sub form within a portal so it cannot interfere with the parent form */}
            <Dialog fullWidth open={open} keepMounted={false} disablePortal={false} onClose={handleClose}>
                <LocalBusForm stop={stop} onClose={handleClose} onSubmit={handleSubmit} />
            </Dialog>
        </>
    );
};

interface LocalBusFormProps {
    readonly stop: BookingStop;
    readonly onClose: () => void;
    readonly onSubmit: (value: ILocalBus | null) => void;
}

const LocalBusForm: React.FC<LocalBusFormProps> = ({ stop, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const [minDistance, maxDistance] = BookingStop.getLocalBusMinAndMaxKm(stop.departureDateTime, stop.arrivalDateTime);

    const formMethods = useForm({
        resolver: yupResolver(localBusSchema(minDistance, maxDistance)),
        defaultValues: stop.localBus ?? undefined,
        mode: 'onTouched',
        reValidateMode: 'onBlur',
    });

    const { isValid } = formMethods.formState;

    const handleSubmit = (values: ILocalBus) => {
        onSubmit(values);
        onClose();
    };
    const handleReset = () => {
        onSubmit(null);
        onClose();
    };
    return (
        <FormProvider {...formMethods}>
            <form onSubmit={noPropagate(formMethods.handleSubmit(handleSubmit))} onReset={noPropagate(handleReset)}>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {t('search_form.local_bus.use_bus_locally')}

                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography>
                        <Trans
                            i18nKey="search_form.local_bus.use_bus_locally_description"
                            values={{
                                minDistance,
                                maxDistance,
                            }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={1}>
                        <NumberField
                            required
                            variant="outlined"
                            name="distance"
                            label={t('search_form.local_bus.km')}
                        />
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            name="description"
                            rows={5}
                            label={t('search_form.local_bus.description')}
                        />
                        <Typography variant="body2">{t('search_form.local_bus.warning')}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ActionButton category="secondary" intent="negative" type="reset">
                        {t('reset')}
                    </ActionButton>
                    <ActionButton category="primary" type="submit" disabled={!isValid}>
                        {t('save')}
                    </ActionButton>
                </DialogActions>
            </form>
        </FormProvider>
    );
};
