import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type TopEquipment } from '@/components/bus/mod';

interface TopEquipmentsIconsProps {
    readonly topEquipments: TopEquipment[];
}

export const TopEquipmentsIcons: React.FC<TopEquipmentsIconsProps> = ({ topEquipments }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={0.5}>
            {topEquipments.map(eq => (
                <Tooltip
                    key={eq.id}
                    arrow
                    placement="top"
                    sx={{ color: theme => (eq.color ? theme.vars.palette[eq.color].main : undefined) }}
                    title={<Typography>{t(`bus.top_equipment.description.${eq.key}`)}</Typography>}>
                    {eq.icon}
                </Tooltip>
            ))}
        </Stack>
    );
};
