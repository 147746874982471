export class CompanyIdName {
    public readonly id: number;

    public companyName: string;

    public contactDataCountry: string;

    public active: boolean;

    constructor(json: Record<string, any>) {
        this.id = json.id;
        this.companyName = json.companyName;
        this.contactDataCountry = json.contactDataCountry;
        this.active = json.active;
    }

    public toString(): string {
        return this.companyName;
    }
}
