import { Grid, LinearProgress, Stack, Typography, linearProgressClasses, styled } from '@mui/material';
import React from 'react';

import { type ReviewScores } from '@/entity/company/ReviewScores';

interface CompanyRatingsProps {
    readonly reviewScores: ReviewScores;
}

export const CompanyRatings: React.FC<CompanyRatingsProps> = ({ reviewScores }) => {
    const { count, categoryScores } = reviewScores;

    if (count === 0) return null;
    return (
        <Grid container item spacing={2.5}>
            {Object.entries(categoryScores).map(([key, value]) => (
                <Grid key={key} item xs={12} sm={6}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        {/* TODO: BUF-262: translations for key */}
                        <Typography>{key}</Typography>

                        <Stack direction="row" alignItems="center" gap={1.5}>
                            <ReviewBar value={value * 20} variant="determinate" />
                            <Typography variant="body3" fontWeight="fontWeightBold">
                                {value.toFixed(1)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

const ReviewBar = styled(LinearProgress)(({ theme }) => ({
    width: 90,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[300],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.vars.palette.primary.main,
    },
}));
