import { createContext, useContext } from 'react';

import { type DetailsBooking } from '@/entity/booking/DetailsBooking';
import { type DetailsBookingRoute } from '@/entity/booking/route/DetailsBookingRoute';
import { type BookingTask } from '@/entity/events/task/BookingTask';

interface IBookingContext {
    isBookingError: boolean;
    isFetching: boolean;
    booking: DetailsBooking | null;
    selectedTask?: BookingTask;
    selectTask: (task: BookingTask) => void;
    routeDetails?: DetailsBookingRoute;
    selectedRouteUuid?: string;
    selectRoute: (uuid?: string) => void;
}

export const BookingProviderContext = createContext<IBookingContext>({
    isFetching: false,
    isBookingError: false,
    routeDetails: undefined,
    selectedTask: undefined,
    selectedRouteUuid: undefined,
    booking: null,
    selectTask() {},
    selectRoute() {},
});

export function useBooking() {
    const context = useContext(BookingProviderContext);

    return context;
}
