import { type TourPlan } from '@/core/tour/mod';

// SearchForm tour
export type SearchFormFirstIntermediateTour = 'to_location' | 'from_time' | 'to_time' | 'local_bus';

// t('tour:search_form_first_intermediate_tour.to_location.desc')
// t('tour:search_form_first_intermediate_tour.to_location.title')
// t('tour:search_form_first_intermediate_tour.to_time.desc')
// t('tour:search_form_first_intermediate_tour.to_time.title')
// t('tour:search_form_first_intermediate_tour.from_time.desc')
// t('tour:search_form_first_intermediate_tour.from_time.title')
// t('tour:search_form_first_intermediate_tour.local_bus.desc')
// t('tour:search_form_first_intermediate_tour.local_bus.title')
export const SearchFormFirstIntermediateTourPlan: TourPlan<SearchFormFirstIntermediateTour> = {
    name: 'search_form_first_intermediate_tour',
    order: ['to_location', 'to_time', 'from_time', 'local_bus'],
};
