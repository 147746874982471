import { SvgIcon, type SvgIconProps } from '@mui/material';

import happy_gray from '@/assets/icons/ratings/happy_gray.svg?react';
import happy_yellow from '@/assets/icons/ratings/happy_yellow.svg?react';
import neutral_gray from '@/assets/icons/ratings/neutral_gray.svg?react';
import neutral_orange from '@/assets/icons/ratings/neutral_orange.svg?react';
import sad_gray from '@/assets/icons/ratings/sad_gray.svg?react';
import sad_red from '@/assets/icons/ratings/sad_red.svg?react';
import very_happy_gray from '@/assets/icons/ratings/very_happy_gray.svg?react';
import very_happy_green from '@/assets/icons/ratings/very_happy_green.svg?react';
import very_happy_light_green from '@/assets/icons/ratings/very_happy_light_green.svg?react';

const BUS_RATING_MAP = {
    very_happy_light_green,
    very_happy_green,
    very_happy_gray,
    happy_yellow,
    happy_gray,
    neutral_orange,
    neutral_gray,
    sad_red,
    sad_gray,
};

type BusRatingType = keyof typeof BUS_RATING_MAP;

interface BusRatingIconProps extends SvgIconProps {
    readonly type: BusRatingType;
    readonly height?: number | string;
    readonly width?: number | string;
}
export const BusRatingIcon: React.FC<BusRatingIconProps> = props => {
    const { type, width = 17.5, height = 17.5, ...rest } = props;

    return (
        <SvgIcon
            {...rest}
            inheritViewBox
            sx={{
                height,
                width,
            }}
            component={BUS_RATING_MAP[type]}
        />
    );
};
