import { createContext, useContext, type Context } from 'react';

export interface ILayoutContext<T extends string> {
    show: (name: T) => void;
    hide: (name: T) => void;
    showBase: () => void;
    isDesktop: boolean;
    isLargeDesktop: boolean;
    isSmallMobile: boolean;
    isDrawer: (name: T) => boolean;
    isDrawerOpen: (name: T) => boolean;
}

export const LayoutContext = createContext<ILayoutContext<''>>({
    show() {},
    hide() {},
    showBase() {},
    isDesktop: true,
    isLargeDesktop: true,
    isSmallMobile: true,
    isDrawer() {
        return false;
    },
    isDrawerOpen() {
        return true;
    },
});

export function useLayout<T extends string>() {
    const TypedLayoutContext = LayoutContext as unknown as Context<ILayoutContext<T>>;

    return useContext<ILayoutContext<T>>(TypedLayoutContext);
}
