import { type Currency } from '@/config/localization';
import { type Basic } from '@/entity/basic/I_Basic';

export class Money implements Basic<Money> {
    static fromJson(json: Record<string, any>): Money {
        return new Money(json.amount, json.currency);
    }

    public readonly amount: number;

    public readonly currency: Currency;

    constructor(amount: number, currency: Currency) {
        this.amount = amount;
        this.currency = currency;
    }

    public equals(other: Money): boolean {
        return other != null && this.amount === other.amount && this.currency === other.currency;
    }

    public toString(): string {
        return `${this.amount} ${this.currency}`;
    }

    public divisionBy(n: number): Money {
        return new Money(this.amount / n, this.currency);
    }
}
