import { useDebounceCallback } from '@react-hook/debounce';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useLocalization } from '@/core/localization/mod';
import { Location } from '@/entity/basic/Location';
import { type TripCompanySearchForm } from '@/entity/trip/company/TripCompanySearchForm';
import { CENTER_REGION } from '@/features/map/';
import { queryTripCompaniesByName } from '@/features/trip-companies';
/**
 * re-export types for simply access
 */
export type AutocompleteService = google.maps.places.AutocompleteService;
export type AutocompletionRequest = google.maps.places.AutocompletionRequest;
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type DirectionsService = google.maps.DirectionsService;
export type DirectionsRequest = google.maps.DirectionsRequest;
export type DirectionsStatus = google.maps.DirectionsStatus;
export type DirectionsResult = google.maps.DirectionsResult;

export type Geocoder = google.maps.Geocoder;
export type GeocoderRequest = google.maps.GeocoderRequest;
export type GeocoderStatus = google.maps.GeocoderStatus;
export type GeocoderResult = google.maps.GeocoderResult;

// Google Maps Context
export interface TGoogleMapsContext {
    isLoaded: boolean;
    loadError: Error | undefined;
    url: string;
}

export const GoogleMapsContext = createContext<TGoogleMapsContext>({
    isLoaded: false,
    loadError: undefined,
    url: '',
});

// Google Maps Hook
export function useGoogleMaps(): TGoogleMapsContext {
    const context = useContext(GoogleMapsContext);

    if (!context) throw new Error('useGoogleMaps must be used within the GoogleProvider');

    return context;
}

export function checkGoogle(gmc: TGoogleMapsContext): boolean {
    return window.google != null && gmc.isLoaded && !gmc.loadError;
}

export type AutoCompleteSuggestion = TripCompanySearchForm | AutocompletePrediction;

interface IUseAutocompletePrediction {
    predictions: AutoCompleteSuggestion[];
    updatePredictions: (request: AutocompletionRequest, includeTripCompanies: boolean) => void;
}

export function useAutocompletePrediction(): IUseAutocompletePrediction {
    const googleMapsContext = useGoogleMaps();
    const queryClient = useQueryClient();
    const { region, language } = useLocalization();

    const [predictions, setPredictions] = useState<AutoCompleteSuggestion[]>([]);
    const autoCompleteService = useRef<AutocompleteService | null>(null);

    const updatePredictions: IUseAutocompletePrediction['updatePredictions'] = useDebounceCallback(
        async (request, includeTripCompanies) => {
            // Init
            if (autoCompleteService.current == null && checkGoogle(googleMapsContext))
                autoCompleteService.current = new window.google.maps.places.AutocompleteService();

            // To narrow down predictions, we aim for places with either a street address or those categorized as establishments. Unfortunately, we can't combine both criteria in a single request.
            // See: https://developers.google.com/maps/documentation/javascript/supported_types#table3
            // A workaround is to make two separate requests: one targets 'establishment' types for businesses, and the other focuses on restricted geocode types for precise addresses.
            const getPlacePredictions = async (types: string[]) => {
                if (autoCompleteService.current == null) return rejectUninstantiated('AutocompleteService');

                const { lat, lng } = CENTER_REGION[region];
                const location = new google.maps.LatLng(lat, lng);

                return autoCompleteService.current.getPlacePredictions({
                    locationBias: new google.maps.Circle({ center: location, radius: 100000 }),
                    language,
                    region,
                    types,
                    ...request,
                });
            };

            const companies = includeTripCompanies ? await queryTripCompaniesByName(queryClient, request.input) : [];

            const geocodeResponse = await getPlacePredictions([
                'street_address',
                'subpremise',
                'route',
                'intersection',
                'point_of_interest',
            ]);
            const establishmentResponse = await getPlacePredictions(['establishment']);
            const allPredictions = [...establishmentResponse.predictions, ...geocodeResponse.predictions];

            // We should use the place_id instead of the description to filter, but a location can have 2 different place_ids, but the same description. Try Volksgarten, Wien.
            const uniquePredictions = uniqBy(allPredictions, prediction => prediction.description);

            // Limit the google place predictions to only 5 results
            const p = sortPredictions(uniquePredictions, request.input).slice(0, 5);

            // Add the trip companies to the predictions
            const finalPredictions = [...companies, ...p];

            setPredictions(finalPredictions);
        },
        300,
    );

    return {
        predictions,
        updatePredictions,
    };
}

const sortPredictions = (predictions: AutocompletePrediction[], input: string): AutocompletePrediction[] =>
    predictions.sort(
        (a, b) => countSharedConsecutiveChars(b.description, input) - countSharedConsecutiveChars(a.description, input),
    );

const countSharedConsecutiveChars = (str1: string, str2: string): number => {
    const [string1, string2] = [str1.toLowerCase(), str2.toLowerCase()];

    let count = 0;

    for (const [index, char] of Array.from(string1).entries()) {
        if (char !== string2[index]) break;
        count++;
    }

    return count;
};

// Google Maps Geocoder Hook
interface UseGoogleGeocoder {
    getGeocodes: (request: GeocoderRequest) => Promise<GeocoderResult[]>;
    getLocationDetails: (request: GeocoderRequest) => Promise<Location>;
}

export function useGoogleGeocoder(): UseGoogleGeocoder {
    const googleMapsContext = useGoogleMaps();
    const geocoder = useRef<Geocoder | null>(null);
    const { language } = useLocalization();

    const getGeocodes = async (request: GeocoderRequest) => {
        // Init
        if (geocoder.current == null && checkGoogle(googleMapsContext))
            geocoder.current = new window.google.maps.Geocoder();

        // Check
        if (!geocoder.current) return rejectUninstantiated('Geocoder');

        const response = await geocoder.current.geocode(request);
        return response.results;
    };

    const getLocationDetails = async (request: GeocoderRequest) => {
        const [result] = await getGeocodes({ language, ...request });

        const adjustedResult = adjustLatLngForWhitelist(result);

        return Location.fromGeocodeResult(adjustedResult);
    };

    return {
        getGeocodes,
        getLocationDetails,
    };
}

// This handles situations where some locations may have lat/lng values that will throw a ZeroResultsException. This occurs when there are no accessible roads within a 5km radius.
function adjustLatLngForWhitelist(result: GeocoderResult): GeocoderResult {
    const { geometry, place_id } = result;

    // Flughafen Köln/Bonn, Kennedystraße, 51147 Köln, Germany.
    if (place_id === 'ChIJ78boUtfevkcRyEtFyzYll2Q') {
        const lat = 50.8815274;
        const lng = 7.1163472;
        geometry.location = new google.maps.LatLng(lat, lng);
    }

    return result;
}

export function useGoogleDirectionsService(request: DirectionsRequest | null) {
    return useQuery({
        queryKey: ['google-maps-directions-service', JSON.stringify(request)],
        async queryFn() {
            if (request == null) return null;

            try {
                const service = new window.google.maps.DirectionsService();
                let response: DirectionsResult | null = null;

                await service.route(request, (r, status) => {
                    if (status === google.maps.DirectionsStatus.OK && r) response = r;
                });

                return response;
            } catch {
                return rejectUninstantiated('DirectionsService');
            }
        },
        refetchOnWindowFocus: false,
    });
}

// Region Detection Hook
export const useRegionDetection = (shouldDetect: boolean) => {
    const googleMapsContext = useGoogleMaps();
    const { getGeocodes } = useGoogleGeocoder();

    const [detectedRegion, setDetectedRegion] = useState<string | undefined>(undefined);
    const hasAskedForPermission = useRef(false);

    useEffect(() => {
        const locateRegion = async (position: GeolocationPosition) => {
            const {
                coords: { latitude, longitude },
            } = position;
            const results = await getGeocodes({ location: { lat: Number(latitude), lng: Number(longitude) } });
            setDetectedRegion(extractRegion(results));
        };
        if (shouldDetect && !hasAskedForPermission.current && checkGoogle(googleMapsContext)) {
            navigator.geolocation.getCurrentPosition(
                locateRegion,
                error => {
                    console.warn(error);
                },
                {
                    enableHighAccuracy: false,
                    timeout: 3000,
                    maximumAge: Number.POSITIVE_INFINITY,
                },
            );
            hasAskedForPermission.current = true;
        }
    }, [getGeocodes, googleMapsContext, shouldDetect]);

    return detectedRegion;
};

const extractRegion = (results: GeocoderResult[]) => {
    const region = results[0].address_components.find(component => component.types.includes('country'))!.short_name;
    return region;
};

// Utils
async function rejectUninstantiated(name: string): Promise<never> {
    throw new Error(`google.maps.${name} not instantiated`);
}

// Google Analytics Context
export interface IGoogleAnalyticsContext {
    dummy?: any;
}

export const GoogleAnalyticsContext = createContext<IGoogleAnalyticsContext>({});

// Google Analytics Hook
export function useGoogleAnalytics() {
    const context = useContext(GoogleAnalyticsContext);
    return context;
}
