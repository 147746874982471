import { Entity } from '@/entity/A_Entity';

export class TripAttribute extends Entity {
    public name: string;

    public icon: string;

    public visible: boolean;

    constructor(json: Record<string, any>) {
        super(json);

        this.name = json.name;
        this.icon = json.icon;
        this.visible = json.visible;
    }

    public toString(): string {
        return this.name;
    }
}
