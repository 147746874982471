import { BusRating } from '@/components/bus/bus-rating';
import { useLayout } from '@/components/page/layout/mod';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface BusCardReviewProps {
    result: BookingSearchResult;
}

export const BusCardReview: React.FC<BusCardReviewProps> = ({ result }) => {
    const { bus } = result;
    const { isSmallMobile } = useLayout();

    if (bus.company.reviewScores.count === 0) return null;

    return (
        <BusRating
            defaultValue={bus.company.reviewScores.totalScore}
            reviewCount={isSmallMobile ? undefined : bus.company.reviewScores.count}
        />
    );
};
