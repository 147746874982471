import { Alert, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState, type FC, type PropsWithChildren, type SyntheticEvent } from 'react';

import { SnackContext, type ISnackContext, type Snack } from '@/core/snack/mod';

interface PackSnack extends Snack {
    key: number;
    icon?: React.ReactNode;
}

export const SnackProvider: FC<PropsWithChildren> = ({ children }) => {
    const [snackPack, setSnackPack] = useState<PackSnack[]>([]);
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState<PackSnack>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (snackPack.length > 0 && !active) {
            // Set a new snack active if we don't have one
            setActive({ ...snackPack[0] });
            setSnackPack(prev => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length > 0 && active && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }
    }, [snackPack, active, open]);

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const handleExited = () => {
        setActive(undefined);
    };

    const value: ISnackContext = useMemo(
        () => ({
            push(message, severity = 'info', icon = undefined) {
                if (isMobile) return;
                const key = Date.now();
                setSnackPack(prev => [...prev, { message, severity, key, icon }]);
            },
        }),
        [isMobile],
    );
    return (
        <SnackContext.Provider value={value}>
            {children}
            <Snackbar
                key={active?.key}
                data-cy={`snack-${active?.severity}`}
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                TransitionProps={{ onExited: handleExited }}
                message={active?.message}
                onClose={handleClose}>
                <Alert
                    severity={active?.severity}
                    icon={active?.icon}
                    sx={{ width: '100%', boxShadow: 1 }}
                    onClose={handleClose}>
                    {active?.message}
                </Alert>
            </Snackbar>
        </SnackContext.Provider>
    );
};
