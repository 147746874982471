import React from 'react';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FormFieldError extends Omit<FieldError, 'message'> {
    message?: TErrorMessage | string;
}

export interface TErrorMessage {
    /** key into translation file */
    key: string;

    /** token values */
    values?: Record<string, any>;
}

export interface ErrorMessageProps {
    readonly error?: FormFieldError;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
    const { t } = useTranslation();

    if (error?.message == null) return null;

    const { message } = error;

    if (typeof message === 'string') return <>{message}</>;

    const { key, values } = message;
    return <>{t(`validations:${key}`, values as any)}</>;
};
