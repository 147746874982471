import { useEffect, useMemo, useReducer, useState, type Reducer } from 'react';

import { CLASS_TOUR_HIGHLIGHT, TourContext, useTour, type TourPlan, type TourStatus } from '@/core/tour/mod';
import { TourPopover } from '@/core/tour/TourPopover';

// function getInitialStatus(name: string): TourStatus {
//     const status = localStorage.getItem(name);
//     if (status && ['aborted', 'finished'].includes(status)) return status as TourStatus;
//     return 'setup';
// }

function setStorage(name: string, type: Extract<TourStatus, 'aborted' | 'finished' | 'setup'>) {
    switch (type) {
        case 'aborted':
        case 'finished': {
            localStorage.setItem(name, type);
            sessionStorage.removeItem(name);
            break;
        }
        default: {
            localStorage.removeItem(name);
            sessionStorage.removeItem(name);
        }
    }
}

// Step Ref Reducer
type StepRefs = Record<string, HTMLElement | null>;

interface StepRefRegister {
    step: string;
    instance: HTMLElement | null;
}

function stepRefRegister(state: StepRefs, action: StepRefRegister): StepRefs {
    const { step, instance } = action;
    return { ...state, [step]: instance };
}

interface TourProviderProps {
    plan: TourPlan;
    autoStart?: true | 'chained';
}

export const TourProvider: React.FC<React.PropsWithChildren<TourProviderProps>> = ({ plan, autoStart, children }) => {
    const parentTour = useTour();

    // Start with state setup
    const [status, setStatus] = useState<TourStatus>('aborted');
    // The current step idx
    const [currentStepIdx, setCurrentStepIdx] = useState(0);
    // All the registered step refs
    const [stepRefs, register] = useReducer<Reducer<StepRefs, StepRefRegister>>(stepRefRegister, {});
    // Memoize the current step ref
    const [currentStep, currentStepRef] = useMemo(() => {
        const c = plan.order[currentStepIdx];

        return [c, c ? stepRefs[c] : null];
    }, [currentStepIdx, plan.order, stepRefs]);

    // BUF-1053: Completely disable the tour until the A/B testing concludes
    // const deactivate = useMemo(
    //     () => appSettings.tutorial.disableSearchFormTour || user,
    //     [appSettings.tutorial.disableSearchFormTour, user],
    // );

    // BUF-1053: Completely disable the tour until the A/B testing concludes
    // useEffect(() => {
    //     if (!isFetchingUser && !deactivate) setStatus(getInitialStatus(plan.name));
    // }, [deactivate, isFetchingUser, plan.name]);

    // Memoized provider context
    const value = useMemo(
        () => ({
            plan,
            currentStep,
            currentStepIdx,
            currentStepRef,
            status,
            register: (step: string) => (instance: HTMLElement | null) => {
                register({ step, instance });
            },
            start() {
                if (status === 'stopped') setStatus('started');
            },
            pause() {
                if (status === 'started') setStatus('stopped');
            },
            stop() {
                if (status === 'started') {
                    setStatus('stopped');
                    setCurrentStepIdx(0);
                }
            },
            previous() {
                if (currentStepIdx > 0) setCurrentStepIdx(currentStepIdx - 1);
            },
            next() {
                if (currentStepIdx < plan.order.length - 1) setCurrentStepIdx(currentStepIdx + 1);
            },
            finish() {
                setStorage(plan.name, 'finished');
                setStatus('finished');
            },
            abort() {
                setStorage('search_form_basic_tour', 'aborted');
                setStorage('search_form_first_intermediate_tour', 'aborted');
                setStatus('aborted');
            },
            reset() {
                setStorage(plan.name, 'setup');
                setStatus('setup');
            },
        }),
        [plan, currentStep, currentStepIdx, currentStepRef, status],
    );

    // Set state from setup to ready
    useEffect(() => {
        if (status !== 'setup') return;

        // Check if all refs are available and set ready
        if (plan.order.every(step => Object.keys(stepRefs).includes(step))) setStatus('ready');
    }, [autoStart, plan.order, status, stepRefs]);

    // Autostart the tour
    useEffect(() => {
        if (status !== 'ready') return;

        if (autoStart === true) setStatus('started');
        else if (autoStart === 'chained' && parentTour.status !== 'started') setStatus('started');
    }, [autoStart, parentTour.status, status]);

    useEffect(() => {
        plan.order.forEach(step => stepRefs[step]?.classList.remove(CLASS_TOUR_HIGHLIGHT));

        if (status === 'started') {
            currentStepRef?.classList.add(CLASS_TOUR_HIGHLIGHT);
        }
    }, [plan.order, status, stepRefs, currentStepRef]);

    return (
        <TourContext.Provider value={value}>
            {children}
            <TourPopover />
        </TourContext.Provider>
    );
};
