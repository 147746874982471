import AddLocationOutlined from '@mui/icons-material/AddLocationOutlined';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import Directions from '@mui/icons-material/Directions';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormCell } from './grid/form-cell';
import { FormGrid } from './grid/form-grid';
import { FormRow } from './grid/form-row';

import { ActionButton } from '@/components/elements/action/action-button';
import { useAuth } from '@/core/auth/mod';
import { type BookingRoute } from '@/entity/journey/route/BookingRoute';
import { useJourneyPlanningForm } from '@/features/journey-planning';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';

interface ActionRowProps {
    readonly routeIndex: number;
}

export const ActionRow: React.FC<ActionRowProps> = ({ routeIndex }) => {
    const { t } = useTranslation();
    const {
        getBookingRoute,
        stopsArray,
        formMethods: {
            formState: { isValid },
        },
        deleteRoute,
    } = useJourneyPlanningForm();
    const { append, fields } = stopsArray;
    // TODO: make location a real location instance
    const hasReturnLocation = fields[0]?.location?.address === fields[fields.length - 1]?.location?.address;

    const route = getBookingRoute(routeIndex);

    const handleRowAction = (type: 'add-return' | 'add-stop') => {
        const firstLocation = type === 'add-return' ? fields[0]?.location : null;

        append(
            {
                location: firstLocation ? { ...firstLocation } : null,
                arrivalDateTime: null,
                departureDateTime: null,
                localBus: null,
                tripCompany: null,
            },
            { shouldFocus: type === 'add-stop' },
        );
    };

    const handleDeleteRoute = () => {
        deleteRoute(routeIndex);
    };

    const buttons = (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <ActionButton
                gaEvent={{ action: 'search-form', category: 'add_return' }}
                category="primary"
                intent="normal"
                type="button"
                startIcon={<SyncAltIcon />}
                disabled={!isValid || hasReturnLocation}
                onClick={() => handleRowAction('add-return')}>
                {t('search_form.intermediate.actions.add_return')}
            </ActionButton>
            <ActionButton
                gaEvent={{ action: 'search-form', category: 'add_stop' }}
                category="secondary"
                intent="neutral"
                startIcon={<AddLocationOutlined />}
                type="button"
                disabled={!isValid}
                onClick={() => handleRowAction('add-stop')}>
                {t('search_form.intermediate.actions.add')}
            </ActionButton>
            <ActionButton
                category="secondary"
                intent="neutral"
                startIcon={<DeleteOutlineOutlined />}
                type="button"
                onClick={handleDeleteRoute}>
                {t('search_form.intermediate.actions.clear')}
            </ActionButton>
        </Stack>
    );

    return (
        <FormGrid>
            <FormRow>
                <FormCell only="large desktop" gridColumn="line">
                    <DevLegDetails route={route} />
                </FormCell>
                <FormCell gridColumn="main">{buttons}</FormCell>
            </FormRow>
        </FormGrid>
    );
};

interface DevLegDetailsProps {
    readonly route?: BookingRoute | null;
}

const DevLegDetails: React.FC<DevLegDetailsProps> = ({ route }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const isAdminOperatorOrDispatcher = user?.isAdminOperatorOrDispatcher();

    const legDetailsDialog = useDisclosure();

    if (!isAdminOperatorOrDispatcher || route?.message === undefined) return null;

    return (
        <>
            <IconButton onClick={legDetailsDialog.open}>
                <Directions />
            </IconButton>
            <Dialog
                {...legDetailsDialog}
                variant="info"
                maxWidth="lg"
                title={t('dev.travel_leg_messages')}
                description={<pre>{route?.message}</pre>}
            />
        </>
    );
};
