import { type Basic } from '@/entity/basic/I_Basic';

export enum PaymentMethod {
    INVOICE = 'INVOICE',
    EPS = 'EPS',
    MAESTRO = 'MAESTRO',
    MASTERCARD = 'MASTERCARD',
    DIRECTBANK = 'DIRECTBANK',
    VISA = 'VISA',
    PAYPAL = 'PAYPAL',
}

export class Gateway implements Basic<Gateway> {
    public static fromJson(json: Record<string, any>): Gateway {
        return new Gateway(json.id, json.description);
    }

    public id: PaymentMethod;

    public description: string;

    constructor(id: PaymentMethod, description: string) {
        this.id = id;
        this.description = description;
    }

    public equals(other: Gateway): boolean {
        return other != null && this.id === other.id;
    }

    public toString(): string {
        return this.id;
    }
}
