import { type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { NavButton } from '@/components/elements/nav/nav-button';
import { type NavigationItem } from '@/components/page/navigation/useNavigation';

type NavItemProps = NavigationItem;

export const NavItem: FC<PropsWithChildren<NavItemProps>> = ({ navigate, disabled, icon, children, highlight }) => {
    const { t } = useTranslation();
    const ctaText = t('account.referral.cta_text');

    return (
        <NavButton
            disableRipple
            navigate={navigate}
            disabled={disabled}
            variant={highlight ? 'cta' : undefined}
            startIcon={icon}
            sx={{
                minWidth: 0,
                ...(highlight && {
                    '&:after': {
                        content: `"${ctaText}"`, // Use translation function here if needed
                    },
                }),
            }}>
            {children}
        </NavButton>
    );
};
