import { createContext, useContext } from 'react';

export const COOKIE_CONSENT = 'BUSFINDER_COOKIE_CONSENT';

export type CookieConsent = 'necessary' | 'marketing';
export const COOKIE_CONSENT_VALUES: CookieConsent[] = ['necessary', 'marketing'];

export interface ICookieBannerContext {
    cookieConsent: CookieConsent[];
    isNecessaryAccepted?: boolean;
    isMarketingAccepted?: boolean;
    setCookieConsent: (c: CookieConsent[]) => void;
}

export const CookieBannerContext = createContext<ICookieBannerContext>({
    cookieConsent: [],
    isNecessaryAccepted: false,
    isMarketingAccepted: false,
    setCookieConsent() {},
});

export function useCookieBanner() {
    const context = useContext(CookieBannerContext);
    return context;
}
