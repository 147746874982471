import { Divider, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { type ReactNode } from 'react';

interface Item {
    label: ReactNode;
    value: ReactNode;
    cellVariant?: 'head' | 'body' | 'footer';
}

interface TabularDataProps {
    readonly sections: Item[][];
    readonly summary?: Item[];
    readonly variant?: 'head' | 'body' | 'footer';
    readonly verticalAlign?: 'bottom' | 'middle' | 'top' | 'baseline';
    readonly textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export const TabularData: React.FC<TabularDataProps> = ({
    sections,
    summary,
    variant = 'head',
    verticalAlign = 'baseline',
    textAlign = 'left',
}) => {
    return (
        <Table
            sx={{
                '& .MuiTableCell-root': {
                    padding: 0,
                    border: 0,
                    verticalAlign,
                },
                '& .MuiTableCell-head': {
                    fontWeight: theme => theme.typography.fontWeightBold,
                    width: 0,
                    paddingRight: 2,
                },
                '& .MuiTableCell-body': {
                    py: 0.3,
                    paddingRight: 2,
                    textAlign,
                    width: '50%',
                },
                '& tbody:not(:first-of-type)': {
                    borderStyle: 'solid',
                    borderTopColor: 'transparent',
                    borderWidth: '1em 0 0 0',
                },
            }}>
            {/* Only show sections if there are any */}
            {sections[0].length > 0 &&
                sections.map((s, i) => (
                    <TableBody key={i}>
                        {s.map(
                            (r, j) =>
                                (r.value || r.label) && (
                                    <TableRow key={`${i}-${j}`}>
                                        <TableCell variant={r.cellVariant ?? variant}>
                                            {/* Use an empty space if label is null to maintain consistent table cell height */}
                                            {r.label ?? '\u00A0'}
                                        </TableCell>
                                        <TableCell align="right">{r.value}</TableCell>
                                    </TableRow>
                                ),
                        )}
                    </TableBody>
                ))}

            {summary && (
                <>
                    {/* If there is only a summary section, hide the divider */}
                    {sections[0].length > 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Divider
                                        sx={{
                                            borderColor(theme) {
                                                return theme.vars.palette.grey[600];
                                            },
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                    <TableBody>
                        {summary.map((r, index) => {
                            return (
                                <TableRow key={`${r.label}-${index}`}>
                                    <TableCell variant={variant}>
                                        {/* Use an empty space if label is null to maintain consistent table cell height */}
                                        {r.label ?? '\u00A0'}
                                    </TableCell>
                                    <TableCell>{r.value}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </>
            )}
        </Table>
    );
};
