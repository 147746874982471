import LocalOffer from '@mui/icons-material/LocalOffer';
import { Grid, Typography, TableRow, Divider, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useLayout } from '@/components/page/layout/mod';
import { useBooking } from '@/core/booking/mod';
import { useLocalizedFormatters, useMoneyUtils } from '@/core/localization/mod';
import { type PriceSummary } from '@/entity/journey/PriceSummary';
import { useJourneyOrBooking } from '@/hooks/useJourneyOrBooking';

const StyledTable = styled('table')(({ theme }) => ({
    borderCollapse: 'collapse',
    width: '100%',
    ...theme.typography.body1,
}));

const TableCell = styled('td')(() => ({
    textAlign: 'right',
}));

interface FinalPriceCouponProps {
    readonly priceSummary: PriceSummary;
}

export const FinalPriceCoupon: React.FC<FinalPriceCouponProps> = ({ priceSummary }) => {
    const { t } = useTranslation();
    const { formatMoney } = useLocalizedFormatters();
    const { uniqueSelectedCurrencySum, convertToSelectedCurrency } = useMoneyUtils();
    const journeyOrBooking = useJourneyOrBooking();
    const { booking } = useBooking();
    const { isDesktop } = useLayout();

    if (!journeyOrBooking) return null;

    const totalPrice = uniqueSelectedCurrencySum(priceSummary.total);
    const finalPrice = uniqueSelectedCurrencySum(priceSummary.couponTotal);
    const discount = convertToSelectedCurrency(priceSummary.getCouponDiscountMoney());

    return (
        <Grid container justifyContent="space-between">
            {isDesktop && (
                <Grid item xs>
                    <Typography fontWeight="fontWeightBold" variant="body1">
                        {t('bus.selection.total_cost')}
                    </Typography>
                </Grid>
            )}

            {booking && <Grid item xs={6} />}
            <Grid item sm xs={12}>
                <StyledTable>
                    <tbody>
                        <TableRow>
                            <TableCell colSpan={2}>{formatMoney(totalPrice)}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <Typography display="flex" alignItems="center" gap={1} mr={5}>
                                    <LocalOffer fontSize="inherit" color="primary" /> {journeyOrBooking.couponCode}
                                </Typography>
                            </TableCell>
                            <TableCell>-{formatMoney(discount)}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2}>
                                <Divider sx={{ my: 1 }} />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography fontWeight="fontWeightBold" color="success.main">
                                    {formatMoney(finalPrice)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </tbody>
                </StyledTable>
            </Grid>
        </Grid>
    );
};
