import { type CouponType } from '@/entity/common-constants';

export class BookingCoupon {
    public type: CouponType;

    public code: string;

    public discount: number;

    constructor(json: Record<string, any>) {
        this.type = json.type;
        this.code = json.code;
        this.discount = json.discount;
    }
}
