import { createContext } from 'react';

export type TabName =
    | 'bus-overview'
    | 'customer-reviews'
    | 'bus-details'
    | 'company-details'
    | 'booking-details'
    | 'payment-details'
    | 'price-log';

export interface BusTab {
    label: string;
    icon: React.JSX.Element;
    component: React.JSX.Element;
    name: TabName;
}

export interface IBusDetailsContext {
    tabs: BusTab[];
    selectedTabIndex: number;
    selectTab: (tab: TabName | number) => void;
}

export const BusDetailsContext = createContext<IBusDetailsContext>({
    tabs: [],
    selectedTabIndex: 0,
    selectTab() {},
});
