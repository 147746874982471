import { createContext } from 'react';

import {
    type IFilterCounts,
    type IFilter,
    type ExtraFilter,
    type FilterPropertyName,
    type FilterValue,
} from '../../filter/types';

import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

interface IBusSelectionContext {
    // Filtered Search Results
    filteredResults: BookingSearchResult[];
    // Filtered Search Results on the page we are currently at
    pagedResults: BookingSearchResult[];
    // Result Filter
    filter: IFilter | Record<string, never>;
    filterLoading: boolean;
    filterCounts: IFilterCounts | Record<string, never>;
    appliedExtraFilters: Partial<ExtraFilter> | null;
    setFilterProperty: (propertyName: FilterPropertyName, value: FilterValue) => void;
    applyFilterProperty: (propertyName: FilterPropertyName, value: FilterValue) => void;
    handleCompaniesAndEquipmentsFilter: (
        filterItemId: number,
        filterType: 'busCompanies' | 'equipments',
        filterImmediately: boolean,
    ) => void;
    setFilter: (filter: IFilter) => void;
    resetFilter: () => void;
    applyFilter: () => void;
    // Result Details
    pagination: {
        maxPage: number;
        currentPage: number;
        setPage: (page: number) => void;
    };
    hidePrices: boolean;
}

export const BusSelectionContext = createContext<IBusSelectionContext>({
    // Filtered Search Results
    filteredResults: [],
    // Filtered Search Results on the page we are currently at
    pagedResults: [],
    // Result Filter
    filter: {},
    filterLoading: false,
    filterCounts: {},
    appliedExtraFilters: null,
    setFilterProperty() {},
    applyFilterProperty() {},
    handleCompaniesAndEquipmentsFilter() {},
    setFilter() {},
    resetFilter() {},
    applyFilter() {},
    pagination: {
        maxPage: 0,
        currentPage: 0,
        setPage() {},
    },
    hidePrices: false,
});
