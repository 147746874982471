import { AirlineSeatLegroomExtra } from '@mui/icons-material';
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import AirlineSeatFlatAngledOutlinedIcon from '@mui/icons-material/AirlineSeatFlatAngledOutlined';
import AirlineSeatIndividualSuiteOutlinedIcon from '@mui/icons-material/AirlineSeatIndividualSuiteOutlined';
import AirlineSeatLegroomExtraOutlinedIcon from '@mui/icons-material/AirlineSeatLegroomExtraOutlined';
import AirlineSeatReclineExtraOutlinedIcon from '@mui/icons-material/AirlineSeatReclineExtraOutlined';
import BluetoothOutlinedIcon from '@mui/icons-material/BluetoothOutlined';
import Brightness5OutlinedIcon from '@mui/icons-material/Brightness5Outlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CleanHandsOutlinedIcon from '@mui/icons-material/CleanHandsOutlined';
import Coffee from '@mui/icons-material/Coffee';
import DevicesFoldOutlinedIcon from '@mui/icons-material/DevicesFoldOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import DownhillSkiingOutlinedIcon from '@mui/icons-material/DownhillSkiingOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import ElectricalServices from '@mui/icons-material/ElectricalServices';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HeightOutlinedIcon from '@mui/icons-material/HeightOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LunchDining from '@mui/icons-material/LunchDining';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import MicrowaveOutlinedIcon from '@mui/icons-material/MicrowaveOutlined';
import MusicVideoOutlinedIcon from '@mui/icons-material/MusicVideoOutlined';
import PanoramaWideAngleOutlinedIcon from '@mui/icons-material/PanoramaWideAngleOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import SettingsInputHdmiOutlinedIcon from '@mui/icons-material/SettingsInputHdmiOutlined';
import SportsBarOutlinedIcon from '@mui/icons-material/SportsBarOutlined';
import TableRestaurantOutlinedIcon from '@mui/icons-material/TableRestaurantOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import UsbOutlinedIcon from '@mui/icons-material/UsbOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import WaterDropOutlined from '@mui/icons-material/WaterDropOutlined';
import Wc from '@mui/icons-material/Wc';
import Wifi from '@mui/icons-material/Wifi';

export interface TopEquipment {
    key: string;
    id: number;
    name: string;
    icon: JSX.Element;
    color?: 'eco';
}

// TODO: BUF-631: Translate the names
export const TOP_EQUIPMENTS: TopEquipment[] = [
    {
        key: 'COFFEE',
        id: 15,
        icon: <Coffee fontSize="small" />,
        name: 'Kaffeemachine',
    },
    {
        key: 'WIFI',
        id: 6,
        icon: <Wifi fontSize="small" />,
        name: 'WLAN',
    },
    {
        key: 'WC',
        id: 20,
        icon: <Wc fontSize="small" />,
        name: 'Toilette',
    },
    {
        key: 'VOLT',
        id: 12,
        icon: <ElectricalServices fontSize="small" />,
        name: '220V Steckdosen in jeder Sitzreihe',
    },
    {
        key: 'BISTRO',
        id: 37,
        icon: <LunchDining fontSize="small" />,
        name: 'Bistro-Bus',
    },
    {
        key: 'CO2',
        id: 65,
        icon: <WaterDropOutlined fontSize="small" />,
        name: 'CO2-Einsparung',
        color: 'eco',
    },
];

export const EQUIPMENT_ICONS = [
    { id: 1, icon: <AirlineSeatReclineExtraOutlinedIcon fontSize="small" /> }, // Liegesitze
    { id: 2, icon: <AirlineSeatIndividualSuiteOutlinedIcon fontSize="small" /> }, // Schlafsitze
    { id: 3, icon: <AirlineSeatLegroomExtra fontSize="small" /> }, // 2+1 Bestuhlung
    { id: 4, icon: <AirlineSeatFlatAngledOutlinedIcon fontSize="small" /> }, // Sitze seitlich verstellbar
    { id: 5, icon: <MeetingRoomOutlinedIcon fontSize="small" /> }, // Clubecke
    { id: 6, icon: <Wifi fontSize="small" /> }, // WLAN
    { id: 7, icon: <TvOutlinedIcon fontSize="small" /> }, // TV
    { id: 8, icon: <CircleOutlinedIcon fontSize="small" /> }, // DVD
    { id: 9, icon: <DevicesOutlinedIcon fontSize="small" /> }, // 2 Monitore
    { id: 10, icon: <TvOutlinedIcon fontSize="small" /> }, // Bildschirme am Vordersitz
    { id: 11, icon: <ElectricalServices fontSize="small" /> }, // 220V Steckdosen
    { id: 12, icon: <ElectricalServices fontSize="small" /> }, // 220V Steckdosen in jeder Sitzreihe
    { id: 13, icon: <KitchenOutlinedIcon fontSize="small" /> }, // Bordküche
    { id: 14, icon: <SportsBarOutlinedIcon fontSize="small" /> }, // Offenes Bier
    { id: 15, icon: <LocalCafeOutlinedIcon fontSize="small" /> }, // Kaffeemaschine
    { id: 17, icon: <KitchenOutlinedIcon fontSize="small" /> }, // Kühlschrank
    { id: 18, icon: <MicrowaveOutlinedIcon fontSize="small" /> }, // Mikrowelle
    { id: 19, icon: <WaterDropOutlined fontSize="small" /> }, // Wasserkocher
    { id: 20, icon: <Wc fontSize="small" /> }, // Toilette
    { id: 21, icon: <Wc fontSize="small" /> }, // Toilette ganzjährig
    { id: 22, icon: <TableRestaurantOutlinedIcon fontSize="small" /> }, // Tische
    { id: 23, icon: <Wifi fontSize="small" /> }, // WLAN in der EU
    { id: 24, icon: <DevicesOutlinedIcon fontSize="small" /> }, // 3 Monitore
    { id: 25, icon: <TvOutlinedIcon fontSize="small" /> }, // 1 Monitor
    { id: 26, icon: <PanoramaWideAngleOutlinedIcon fontSize="small" /> }, // Panoramadach
    { id: 27, icon: <UsbOutlinedIcon fontSize="small" /> }, // USB Ladeanschluss in jeder Sitzreihe
    { id: 28, icon: <TableRestaurantOutlinedIcon fontSize="small" /> }, // Tische auf Kundenwunsch einbaubar
    { id: 29, icon: <AcUnitOutlinedIcon fontSize="small" /> }, // Klimaanlage
    { id: 30, icon: <UsbOutlinedIcon fontSize="small" /> }, // USB Audio
    { id: 31, icon: <AccessibleOutlinedIcon fontSize="small" /> }, // Rollstuhlplätze
    { id: 32, icon: <ElevatorOutlinedIcon fontSize="small" /> }, // Rollstuhl Hebebühne
    { id: 33, icon: <RadioButtonCheckedOutlinedIcon fontSize="small" /> }, // CD Radio
    { id: 34, icon: <ChairOutlinedIcon fontSize="small" /> }, // Ledersitze
    { id: 35, icon: <VideocamOutlinedIcon fontSize="small" /> }, // Bugkamera am Monitor
    { id: 36, icon: <ChairOutlinedIcon fontSize="small" /> }, // Teilleder Sitze
    { id: 37, icon: <LunchDining fontSize="small" /> }, // Bistro-Bus
    { id: 38, icon: <TimerOutlinedIcon fontSize="small" /> }, // Oldtimer
    { id: 39, icon: <HeightOutlinedIcon fontSize="small" /> }, // Kopfstützen höhenverstellbar
    { id: 40, icon: <DevicesFoldOutlinedIcon fontSize="small" /> }, // Klapptische
    { id: 41, icon: <EventSeatOutlinedIcon fontSize="small" /> }, // Mittelarmlehnen
    { id: 42, icon: <AirlineSeatLegroomExtraOutlinedIcon fontSize="small" /> }, // Fußstützen
    { id: 43, icon: <UsbOutlinedIcon fontSize="small" /> }, // Zentrale USB-Ladestation
    { id: 44, icon: <UsbOutlinedIcon fontSize="small" /> }, // USB-Lade Stecker für den Fahrgast im Taxi
    { id: 45, icon: <EventSeatOutlinedIcon fontSize="small" /> }, // Armlehne im Taxi
    { id: 46, icon: <CleanHandsOutlinedIcon fontSize="small" /> }, // Desinfektionsspender im WC
    { id: 47, icon: <CleanHandsOutlinedIcon fontSize="small" /> }, // Desinfektionsspender im Bereich der Einstiege
    { id: 48, icon: <FilterAltOutlinedIcon fontSize="small" /> }, // Filter mit antiviraler Funktion
    { id: 49, icon: <Brightness5OutlinedIcon fontSize="small" /> }, // Ambientebeleuchtung / LED Sternenhimmel
    { id: 50, icon: <SettingsInputHdmiOutlinedIcon fontSize="small" /> }, // HDMI Anschluß für Bild und Ton
    { id: 51, icon: <BluetoothOutlinedIcon fontSize="small" /> }, // Bluetooth Verbindung für Ton
    { id: 52, icon: <KitchenOutlinedIcon fontSize="small" /> }, // Heissluftofen in der Bordküche
    { id: 53, icon: <DriveEtaOutlinedIcon fontSize="small" /> }, // Anhängerkupplung
    { id: 54, icon: <LockOutlinedIcon fontSize="small" /> }, // 3-Punkt-Gurte
    { id: 55, icon: <KitchenOutlinedIcon fontSize="small" /> }, // 2 Kühlschänke
    { id: 56, icon: <KitchenOutlinedIcon fontSize="small" /> }, // 3 Kühlschränke
    { id: 57, icon: <AirlineSeatLegroomExtraOutlinedIcon fontSize="small" /> }, // Fußauflagen
    { id: 58, icon: <LiveTvOutlinedIcon fontSize="small" /> }, // Netflix
    { id: 59, icon: <MusicVideoOutlinedIcon fontSize="small" /> }, // Spotify
    { id: 60, icon: <AirlineSeatReclineExtraOutlinedIcon fontSize="small" /> }, // Ergonomische Massagesitze mit Liegefunktion
    { id: 61, icon: <FastfoodOutlinedIcon fontSize="small" /> }, // Snack- und Getränkeautomat
    { id: 62, icon: <LocalLaundryServiceOutlinedIcon fontSize="small" /> }, // Waschraum
    { id: 63, icon: <FastfoodOutlinedIcon fontSize="small" /> }, // Würstchenkocher/Wasserbad
    { id: 64, icon: <PanoramaWideAngleOutlinedIcon fontSize="small" /> }, // Panoramafenster
    { id: 65, icon: <WaterDropOutlined fontSize="small" /> }, // CO2-Einsparung
    { id: 66, icon: <DownhillSkiingOutlinedIcon fontSize="small" /> }, // Skibox
    { id: 67, icon: <KeyboardVoiceOutlinedIcon fontSize="small" /> }, // Mikrofon
];
