import React, { useEffect, useRef } from 'react';
import NewWindow from 'react-new-window';

import { type IntegrationOverrideables } from '../../types';

function setData(w: Window | null, previewData: IntegrationOverrideables | null, integrationKey: string) {
    if (w == null) return;

    const busFinder = w.document.querySelector('bus-finder');

    if (busFinder == null) return;

    busFinder.integrationKey = integrationKey;
    busFinder.themeOverrides = previewData?.themeOverrides ?? null;
    busFinder.translationOverrides = previewData?.translationOverrides ?? null;
    busFinder.settings = previewData?.settings ?? null;
}

interface IntegrationPreviewProps {
    readonly open: boolean;
    readonly onClose: () => void;
    readonly integrationKey: string;
    readonly previewData: IntegrationOverrideables | null;
}

export const IntegrationPreview: React.FC<IntegrationPreviewProps> = ({
    open,
    onClose,
    integrationKey,
    previewData,
}) => {
    const windowRef = useRef<Window | null>(null);

    useEffect(() => {
        setData(windowRef.current, previewData, integrationKey);
    }, [integrationKey, previewData]);

    useEffect(() => {
        if (!open) cleanup();
    }, [open]);

    const setup = (w: Window) => {
        windowRef.current = w;
        w.addEventListener('DOMContentLoaded', () => {
            setData(w, previewData, integrationKey);
        });
    };

    const cleanup = () => {
        windowRef.current?.close();
        windowRef.current = null;
    };

    const handleClose = () => {
        cleanup();
        onClose();
    };

    if (!open) return null;

    return (
        <NewWindow
            features={{
                width: 1024,
                height: 800,
                target: 'integration-preview',
            }}
            url="/extra/integration-preview/"
            onUnload={handleClose}
            onBlock={handleClose}
            onOpen={setup}
        />
    );
};
