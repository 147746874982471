import React from 'react';

import { PartnerRegistrationForm } from '../components/partner-registration-form';

import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';

export const PartnerTile: React.FC = () => {
    return (
        <Tile>
            <TilePart>
                <PartnerRegistrationForm />
            </TilePart>
        </Tile>
    );
};
