import { Entity } from '@/entity/A_Entity';
import { Money } from '@/entity/basic/Money';

export class BookingCalculation extends Entity {
    public extraHourRate: Money;

    public extraKilometreRate: Money;

    constructor(json: Record<string, any>) {
        super(json);

        this.extraHourRate = Money.fromJson(json.extraHourRate);
        this.extraKilometreRate = Money.fromJson(json.extraKilometreRate);
    }
}
