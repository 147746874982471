import { createFileRoute, redirect } from '@tanstack/react-router';

import { queryUser } from '@/api/user/queries';
import { UserRole } from '@/entity/common-constants';
import { IntegrationEditorProvider } from '@/features/integration-editor';

export const Route = createFileRoute('/editor')({
    async beforeLoad({ context }) {
        const user = await queryUser(context.queryClient);
        if (!user?.isRole([UserRole.ADMIN, UserRole.OPERATOR, UserRole.SUPER_ADMIN]))
            redirect({ to: '/login', replace: true, search: { rhref: '/editor/overview' }, throw: true });
        if (!context.appCtx?.editMode || context.embedCtx?.isEmbedding) {
            redirect({ to: '/', replace: true, throw: true });
        }
    },
    component: IntegrationEditorProvider,
});
