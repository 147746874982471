/** **************************************************************************************
 * Development Only!
 ************************************************************************************** */

import { useQueryClient, useMutation } from '@tanstack/react-query';

import callApi from '@/api';
import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { setStorageJourneyToken } from '@/config/storage';
import { BookingJourney } from '@/entity/journey/BookingJourney';
import { type IJourney, serializeSearchFormFields } from '@/features/journey-planning';

/**
 * Submit the Journey Planning Search Form with the given {@link IJoureny}
 * and populates the journey in the backend ready for submit booking
 *
 * Returns the finished populated journey as {@link BookingJourney}
 */
export async function postDevJourneyPopulation(body: IJourney) {
    const { data } = await callApi('public/dev-journey-population', {
        method: 'POST',
        data: serializeSearchFormFields(body),
    });
    const journey = new BookingJourney(data);
    setStorageJourneyToken(journey.token);
    return journey;
}

export const useDevJourneyPopulation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: postDevJourneyPopulation,
        async onSuccess(journey) {
            queryClient.setQueriesData({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] }, journey);
        },
    });
};
