import { DateTime } from 'luxon';

export abstract class Entity {
    public readonly id: number;

    public readonly identifier: string;

    public readonly active: boolean;

    public readonly className: string;

    public readonly createdAt: DateTime;

    public readonly modifiedAt: DateTime;

    protected constructor(json: Record<string, any>) {
        this.id = json.id;
        this.identifier = json.identifier;
        this.active = json.active;
        this.className = json.className;
        this.createdAt = DateTime.fromISO(json.createdAt);
        this.modifiedAt = DateTime.fromISO(json.modifiedAt);
    }
}
