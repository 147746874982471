import React from 'react';

import { type AppSettings } from '@/core/app-ctx/AppSettings';
import { type LocalizationOverrides } from '@/core/localization/mod';
import { type ThemeOverrides } from '@/core/theme/ThemeOverrides';
import { type Integration } from '@/entity/integration/Integration';

export interface IntegrationOverrideables {
    themeOverrides: ThemeOverrides | null;
    translationOverrides: LocalizationOverrides | null;
    settings: AppSettings | null;
}

export interface Hook {
    type: 'reset' | 'save';
    handler: () => void;
}

type CleanupFn = () => void;

export interface IIntegrationEditorContext {
    dirtyThemeOverrides: boolean;
    dirtyTranslationOverrides: boolean;
    dirtySettings: boolean;
    integrations: Integration[];
    selectedIntegration: Integration | null;
    isPending: boolean;
    previewOpen: boolean;
    selectIntegrationById(id: number): void;
    save(data: Partial<IntegrationOverrideables>, onSuccess?: (integration: Integration) => void): void;
    preview(overrides: Partial<IntegrationOverrideables>): void;
    setPreviewOpen(open: boolean): void;
    triggerHook(type: Hook['type']): void;
    hook(hook: Hook): CleanupFn;
}

export const IntegrationEditorContext = React.createContext<IIntegrationEditorContext>({
    dirtyThemeOverrides: false,
    dirtyTranslationOverrides: false,
    dirtySettings: false,
    integrations: [],
    selectedIntegration: null,
    isPending: false,
    previewOpen: false,
    selectIntegrationById() {},
    save() {},
    preview() {},
    setPreviewOpen() {},
    triggerHook() {},
    hook() {
        return () => {};
    },
});
