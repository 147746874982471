import { Box, useTheme } from '@mui/material';
import { InfoWindow, useGoogleMap } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import ga4 from 'react-ga4';

import { popupWidth } from '../constants/popup';
import { useAddCompanyToJourney } from '../hooks/use-add-company-to-journey';
import { type PopupState } from '../types';

import { TripCompanyDetails } from './trip-company-details';

import { useJourneyPlanning } from '@/core/journey-planning/mod';

export interface TripCompanyPopupProps extends PopupState {
    readonly onMouseOver: () => void;
    readonly onMouseLeave: () => void;
    readonly onClick?: () => void;
    readonly overlay: google.maps.OverlayView;
}

export const TripCompanyPopup = ({ company, onClick, onMouseLeave, onMouseOver, ...rest }: TripCompanyPopupProps) => {
    const theme = useTheme();
    const { journey } = useJourneyPlanning();
    const infoWindow = useRef<InfoWindow>(null);
    const boxRef = useRef<HTMLDivElement>(null);
    const addCompanyToJourney = useAddCompanyToJourney();

    const { pixelOffset, popupPosition, onDomReady } = useInfoWindow({
        ...rest,
        box: boxRef.current,
    });

    const handleDomReady = () => {
        infoWindow.current?.containerElement?.addEventListener('mouseover', onMouseOver);
        infoWindow.current?.containerElement?.addEventListener('mouseleave', onMouseLeave);
        onDomReady();
    };

    const handleDetails = () => ga4.event('trip-company', { event_category: 'open-details-from-results' });
    const handleClick = () => {
        if (journey && company) void addCompanyToJourney(company);
        else onClick?.();
    };

    if (!company) return null;

    return (
        <>
            <InfoWindow
                ref={infoWindow}
                zIndex={theme.zIndex.tooltip}
                position={popupPosition}
                options={{ pixelOffset, disableAutoPan: true }}
                onDomReady={handleDomReady}>
                <Box ref={boxRef} width={popupWidth}>
                    <TripCompanyDetails company={company} onClick={handleClick} onDetails={handleDetails} />
                </Box>
            </InfoWindow>
        </>
    );
};

interface InfoWindowProps extends PopupState {
    box: HTMLDivElement | null;
    overlay: google.maps.OverlayView;
}

const useInfoWindow = (props: InfoWindowProps) => {
    const { box, overlay, position } = props;
    const map = useGoogleMap();

    const [popupPosition, setPopupPosition] = useState<google.maps.LatLng>(new google.maps.LatLng(80, 0));
    const [pixelOffset, setPixelOffset] = useState<google.maps.Size | null>(null);

    // The height of the popup is not known until it is rendered. We open up the popup outside of the map, get the height, and then calculate the pixel offset. Not ideal, but it works.
    const getPixelOffset = useCallback(
        (height: number) => {
            let xOffset = 0;
            let yOffset = 0;
            let yOffsetFromMarker = 38;

            const defaultOffset = new google.maps.Size(0, yOffsetFromMarker);

            if (!map || !position) return defaultOffset;

            // Our point of interest
            const location = overlay.getProjection().fromLatLngToContainerPixel(position);
            const neBounds = map.getBounds()?.getNorthEast();

            if (!neBounds || !location) return defaultOffset;

            // Get edge of map in pixels: North East corner
            const neProjection = overlay.getProjection().fromLatLngToContainerPixel(neBounds);

            if (!neProjection) return defaultOffset;

            // Horizontal Adjustment
            if (location.x < popupWidth / 2) {
                xOffset = popupWidth / 2 - location.x;
            } else if (location.x > neProjection.x - popupWidth / 2) {
                xOffset = neProjection.x - popupWidth / 2 - location.x;
            }

            // Vertical Adjustment
            if (location.y < height + yOffsetFromMarker) {
                yOffset = location.y + height - (location.y - neProjection.y);
                yOffsetFromMarker = -yOffsetFromMarker / 2;
            }

            return new google.maps.Size(xOffset, yOffset - yOffsetFromMarker);
        },
        [map, overlay, position],
    );

    useEffect(() => {
        if (box) {
            setPixelOffset(getPixelOffset(box.clientHeight));
            if (position) setPopupPosition(position);
        }
    }, [map, overlay, position, box?.clientHeight, box, getPixelOffset]);

    const onDomReady = () => setPopupPosition(new google.maps.LatLng(80, 0));

    return { pixelOffset, popupPosition, onDomReady };
};
