import axios, {
    type AxiosError,
    type AxiosInstance,
    type AxiosRequestConfig,
    type AxiosRequestHeaders,
    type AxiosResponse,
    type Method,
    type RawAxiosRequestHeaders,
    type ResponseType,
} from 'axios';
import Cookies from 'js-cookie';

import APIError, { type APIErrorResponse } from '@/api/APIError';
import { ENV } from '@/config/env';
import { LOCALE_COOKIE } from '@/config/localization';

/**
 *********************************************************************
 * Types                                                             *
 *********************************************************************
 */

/**
 * Represents key value pairs for request uri params
 */
export type Params = Record<string, string | number | undefined>;

export interface CallApiConfig {
    method?: Method;
    params?: Params;
    data?: any;
    headers?: AxiosRequestHeaders;
    responseType?: ResponseType;
}

/**
 *********************************************************************
 * Headers                                                           *
 *********************************************************************
 */
export const JSON_HEADERS: RawAxiosRequestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const INTEGRATION_KEY_HEADER = 'X-BUS-INTEGRATION-KEY';
export const AUTHENTICATION_TOKEN_HEADER = 'X-BUS-AUTHENTICATION-TOKEN';
export const AUTHENTICATION_TOKEN_STORAGE_KEY = 'BUSFINDER-AUTHENTICATION-TOKEN';

export const acceptLanguageHeader = (language: string) => ({ 'Accept-Language': language });
export const authenticationTokenHeader = (authenticationToken: string) => ({
    [AUTHENTICATION_TOKEN_HEADER]: authenticationToken,
});

/**
 *********************************************************************
 * Axios Configuration                                               *
 *********************************************************************
 */
axios.defaults.baseURL = ENV.apiUrl;
axios.defaults.withCredentials = true;

/**
 * Executes API calls and returns a promise with the received data.
 * On failure, an appropriate error object will be thrown holding all relevant information
 *
 * Supports JSON and FormData bodies.
 */
export default async function callApi<T = Record<string, any>>(
    url: string,
    config: Omit<AxiosRequestConfig, 'url' | 'withCredentials'> = {},
    axiosInstance?: AxiosInstance,
): Promise<AxiosResponse<T>> {
    if (!url) throw new Error('Missing endpoint');

    let headers: RawAxiosRequestHeaders = { ...config.headers };

    // Add JSON Headers unless data is FormData
    if (!(config.data instanceof FormData)) headers = { ...headers, ...JSON_HEADERS };

    // Add Accept-Language header if locale is set
    const savedLocale = Cookies.get(LOCALE_COOKIE);
    if (savedLocale != null) headers = { ...headers, ...acceptLanguageHeader(savedLocale) };

    // Add authentication token header
    const authenticationToken = localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY);
    if (authenticationToken != null) headers = { ...headers, ...authenticationTokenHeader(authenticationToken) };

    try {
        return await (axiosInstance || axios)
            .request<T>({
                ...config,
                url,
                withCredentials: true,
                headers,
            })
            .then(response => {
                // read the authentication token header and save it in local storage
                if (response.headers[AUTHENTICATION_TOKEN_HEADER.toLowerCase()] != null) {
                    localStorage.setItem(
                        AUTHENTICATION_TOKEN_STORAGE_KEY,
                        response.headers[AUTHENTICATION_TOKEN_HEADER.toLowerCase()],
                    );
                }
                return response;
            });
    } catch (error) {
        throw new APIError(error as AxiosError<APIErrorResponse, T>);
    }
}
