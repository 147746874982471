import { useQueryClient, type DefaultOptions } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { HttpStatusCode } from 'axios';
import { useState, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { isAPIError } from '@/api/APIError';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';

export const QueryErrorHandler: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const [errorKey, setErrorKey] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const navigate = useNavigate();
    const errorDialog = useDisclosure();

    const queryClient = useQueryClient();

    const options: DefaultOptions = {
        mutations: {
            onError(error, variables: any) {
                if (!isAPIError(error)) return;
                if (error.code === HttpStatusCode.Unauthorized) return;

                setErrorKey(error.key ?? null);
                setToken(variables.token);
                errorDialog.open();
            },
        },
    };

    queryClient.setDefaultOptions(options);

    return (
        <>
            {children}
            <Dialog
                {...errorDialog}
                variant="info"
                title={t('backend:exception.title')}
                description={t(errorKey ?? 'backend:exception.generic')}
                actions={{
                    ok() {
                        if (errorKey === 'backend:exception.booking.bus_unavailable')
                            void navigate({
                                to: '/search',
                                search: {
                                    token: token ?? '',
                                },
                            });
                    },
                }}
            />
        </>
    );
};
