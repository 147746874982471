import React from 'react';

import { LoginForm } from '../components/login-form';

import { Tile } from '@/components/page/layout/tile/tile';
import { TilePart } from '@/components/page/layout/tile/tile-part';
import { LoginRouteIndex } from '@/main/routes/login';

export const LoginTile: React.FC = () => {
    const { dl, rhref } = LoginRouteIndex.useSearch();

    return (
        <Tile>
            <TilePart>
                <LoginForm navigate={{ to: '/', search: {} }} redirectHref={rhref} didLogout={dl} />
            </TilePart>
        </Tile>
    );
};
