import { type LinkOptions } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NavNameProps {
    readonly navigate: LinkOptions;
    readonly select?: 'name';
}

export const NavName: React.FC<NavNameProps> = ({ navigate, select = 'name' }) => {
    const { t } = useTranslation();

    const to = typeof navigate.to === 'string' ? navigate.to : '';

    const tPath = to !== '/' && to.endsWith('/') ? to.slice(0, -1) : to;

    return <>{t(`routes:${tPath}.${select}`)}</>;
};
