import { DateTime } from 'luxon';

import { type Basic } from '@/entity/basic/I_Basic';
import { OfferCreatedBy } from '@/entity/basic/offer-details/OfferCreatedBy';
import { type OfferStatus } from '@/entity/common-constants';
import { BookingBusCompany } from '@/entity/company/bus/BookingBusCompany';

export abstract class OfferDetails implements Basic<OfferDetails> {
    public bookingToken: string;

    public offerStatus: OfferStatus;

    public offerNotes?: string;

    public daysValidBeforeStart: number;

    public daysRemindBeforeOfferEnds: number;

    public validUntil: DateTime;

    public reminderDate?: DateTime;

    public company?: BookingBusCompany;

    public offerCreatedBy?: OfferCreatedBy;

    constructor(json: Record<string, any>) {
        this.bookingToken = json.bookingToken;
        this.offerStatus = json.offerStatus;
        this.daysValidBeforeStart = json.daysValidBeforeStart;
        this.daysRemindBeforeOfferEnds = json.daysRemindBeforeOfferEnds;
        this.validUntil = DateTime.fromISO(json.validUntil);
        this.reminderDate = json.reminderDate ? DateTime.fromISO(json.reminderDate) : undefined;
        this.company = json.company ? new BookingBusCompany(json.company) : undefined;
        this.offerNotes = json.offerNotes;
        this.offerCreatedBy = json.offerCreatedBy ? new OfferCreatedBy(json.offerCreatedBy) : undefined;
    }

    public equals(): boolean {
        return false;
    }

    public toString(): string {
        return `Offer(offerStatus=${this.offerStatus}, daysValidBeforeStart=${this.daysValidBeforeStart},daysRemindBeforeOfferEnds=${this.daysRemindBeforeOfferEnds})`;
    }
}
