import callApi from '@/api';

/**
 * Api Call for login request
 * @param options login options including credentials
 */
export interface PostLoginParams {
    username: string;
    password: string;
}
export async function postLogin(options: PostLoginParams): Promise<void> {
    const formData = new FormData();
    formData.append('username', options.username);
    formData.append('password', options.password);

    await callApi<void>(`public/login`, { method: 'POST', data: formData });
}

/**
 * Api Call for logout request
 */
export async function postLogout(): Promise<void> {
    await callApi(`public/logout`, { method: 'POST' });
}
