import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

import { queryUser } from '@/api/user/queries';

export const Route = createFileRoute('/account')({
    async beforeLoad({ context }) {
        const user = await queryUser(context.queryClient);
        if (user == null) redirect({ to: '/login', replace: true, search: { rhref: '/account/profile' }, throw: true });
    },
    component: Outlet,
});
