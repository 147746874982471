import { useQueryClient, useMutation } from '@tanstack/react-query';

import callApi from '@/api';
import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { setStorageJourneyToken } from '@/config/storage';
import { BookingJourney } from '@/entity/journey/BookingJourney';
import { type IJourney, serializeSearchFormFields } from '@/features/journey-planning';

export async function patchPlanningJourney(formData: IJourney) {
    const { data } = await callApi('public/calculateJourney', {
        method: 'PATCH',
        data: serializeSearchFormFields(formData),
    });

    const journey = new BookingJourney(data);
    setStorageJourneyToken(journey.token);
    return journey;
}

export const useCalculateJourney = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: patchPlanningJourney,
        async onSuccess(journey: BookingJourney) {
            queryClient.setQueriesData({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] }, journey);
        },
    });
};
