import { type RatingItem } from '../types/rating';

import { BusRatingIcon } from '@/assets/icons/ratings/BusRatingIcon';

export const ratingItems = (width = 32, height = 22): RatingItem[] => [
    {
        value: 1,
        activeVariant: <BusRatingIcon type="sad_red" width={width} height={height} />,
        inactiveVariant: <BusRatingIcon type="sad_gray" width={width} height={height} />,
    },
    {
        value: 2,
        activeVariant: <BusRatingIcon type="neutral_orange" width={width} height={height} />,
        inactiveVariant: <BusRatingIcon type="neutral_gray" width={width} height={height} />,
    },
    {
        value: 3,
        activeVariant: <BusRatingIcon type="happy_yellow" width={width} height={height} />,
        inactiveVariant: <BusRatingIcon type="happy_gray" width={width} height={height} />,
    },
    {
        value: 4,
        activeVariant: <BusRatingIcon type="very_happy_light_green" width={width} height={height} />,
        inactiveVariant: <BusRatingIcon type="very_happy_gray" width={width} height={height} />,
    },
    {
        value: 5,
        activeVariant: <BusRatingIcon type="very_happy_green" width={width} height={height} />,
        inactiveVariant: <BusRatingIcon type="very_happy_gray" width={width} height={height} />,
    },
];
