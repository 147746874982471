import { type TripCompany } from '@/entity/trip/company/TripCompany';
import { type ITripCompany, type FormAvatarProps, type IPosition } from '@/features/journey-planning';

export const getAvatarType = (position: IPosition, tripCompany?: TripCompany | ITripCompany | null) => {
    let type: FormAvatarProps['type'] = 'location';
    if (position.first) type = 'start location';
    if (position.last) type = 'end location';
    if (tripCompany) type = 'trip company';
    return type;
};
