import { Alert, Button } from '@mui/material';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isAmending } from '@/core/app-progress/mod';
import { useAuth } from '@/core/auth/mod';
import { useIntegrationTranslation } from '@/core/integration-translation/mod';
import { useJourney } from '@/core/journey/mod';
import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { useJourneyPlanningForm, useResetJourney } from '@/features/journey-planning';

export const PageWarning: React.FC = () => {
    const { user } = useAuth();
    const { journey: journeyPlanning } = useJourneyPlanning();
    const { journey: journeyBooking } = useJourney();
    const { mutate: resetJourneyMutation } = useResetJourney();
    const { resetJourney } = useJourneyPlanningForm();
    const { bookingModeTranslationKey } = useIntegrationTranslation();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const journey = journeyPlanning || journeyBooking;

    const handleCancelAmendBooking = useCallback(async () => {
        if (journey)
            resetJourneyMutation(journey.token, {
                async onSuccess() {
                    void navigate({ to: '/', search: {}, replace: true });
                    resetJourney?.(true);
                },
            });
    }, [journey, navigate, resetJourney, resetJourneyMutation]);

    const matchRoute = useMatchRoute();
    const isAccount = matchRoute({ to: '/account' });
    const isHome = matchRoute({ to: '/' });

    const showAmendBookingWarning = useMemo(() => !isAccount && journey?.amendBookingToken, [isAccount, journey]);

    const showSavedJourneyWarning = useMemo(() => journey?.name && isHome, [isHome, journey?.name]);

    useEffect(() => {
        if (!user && journey?.amendBookingToken) void handleCancelAmendBooking();
    }, [handleCancelAmendBooking, journey?.amendBookingToken, user]);

    if (showAmendBookingWarning) {
        return (
            <Alert
                severity="warning"
                action={
                    <Button size="small" color="inherit" onClick={handleCancelAmendBooking}>
                        {isAmending('offer', journey)
                            ? t(`bookings.amend.offer.cancel_amend`)
                            : t(`bookings.amend.${bookingModeTranslationKey}.cancel_amend`)}
                    </Button>
                }>
                {isAmending('offer', journey)
                    ? t(`bookings.amend.offer.warning_text`)
                    : t(`bookings.amend.${bookingModeTranslationKey}.warning_text`)}
            </Alert>
        );
    }

    if (showSavedJourneyWarning) {
        return <Alert severity="info">{t('search_form.journey.warning_text')}</Alert>;
    }

    return null;
};
