import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { CardActionArea, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import ga4 from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { AttributeIcons } from './attribute-icons';
import { PreviewImage } from './preview-image';
import { RecommendedDuration } from './recommened-duration';

import { ActionButton } from '@/components/elements/action/action-button';
import { useJourneyPlanning } from '@/core/journey-planning/mod';
import { TripCompany } from '@/entity/trip/company/TripCompany';

interface TripCompanyDetailsProps {
    readonly company: TripCompany;
    readonly onClick: () => void;
    readonly onDetails?: () => void;
}

export const TripCompanyDetails = ({ company, onClick, onDetails }: TripCompanyDetailsProps) => {
    const { t } = useTranslation();
    const { journey } = useJourneyPlanning();

    const handleOpenDetails = () => {
        onDetails?.();
        window.open(TripCompany.buildDetailsUrl(company.companyName), '_blank');
    };

    const handleClick = () => {
        ga4.event('trip-company', { event_category: 'add-trip-company-explore' });
        onClick();
    };

    // TODO: BUF-1412 remove hardcoded route index and use the selected route index
    const isDisabled = useMemo(
        () => journey?.routes[0].stops.at(-1)?.tripCompany?.id === company.id,
        [company.id, journey?.routes],
    );

    return (
        <Stack justifyContent="space-between" height="100%">
            <CardActionArea
                disableRipple
                sx={{
                    ' .MuiCardActionArea-focusHighlight': {
                        background: 'transparent',
                    },
                }}
                onClick={handleOpenDetails}>
                <Stack gap={0.5}>
                    <PreviewImage logo={company.logo} documents={company.documents} alt={company.companyName} />

                    <Stack px={1.5} pt={1.5} gap={1.5}>
                        <Typography fontWeight="fontWeightBold" variant="body2" color="primary">
                            {company.companyName}
                        </Typography>

                        <AttributeIcons attributes={company.attributes} />

                        <RecommendedDuration duration={company.recommendedDurationOfStayInMinutes} />

                        <Typography variant="body3">{company.subtitle}</Typography>
                    </Stack>
                </Stack>
            </CardActionArea>
            {/* If the cta-btn class name is on the ActionButton, there is a weird animation on the box rounded icon on Chrome for some reason...  */}
            <Stack direction="row" justifyContent="end" alignItems="center" p={0.5} className="cta-btn">
                <ActionButton
                    disabled={isDisabled}
                    endIcon={<AddBoxRoundedIcon />}
                    category="secondary"
                    onClick={handleClick}>
                    {t('trip_company.add_to_route')}
                </ActionButton>
            </Stack>
        </Stack>
    );
};
