import { Pageable } from '@/entity/pagination/Pageable';

/**
 * Creates an array of the given type, by using the constructor on each element in a JSON array.
 */
export function fromJsonArray<T>(
    constructor: { new (jsonConstructor: Record<string, any>): T },
    json: Record<string, any>,
): T[] {
    return json != null && Array.isArray(json) ? json.map((element: any) => new constructor(element)) : [];
}

export function fromJsonArrayPageable<T>(
    constructor: { new (jsonConstructor: Record<string, any>): T },
    json: Record<string, any>,
) {
    const content =
        json?.content && Array.isArray(json.content)
            ? json.content.map((element: any) => new constructor(element))
            : [];
    return new Pageable<T>(content, json.totalPages);
}

/**
 * Creates an array of the given type, by using the given provider on each element in a JSON array.
 */
export function fromJsonArrayWith<T>(
    provider: (json: Record<string, any>) => T | undefined,
    json: Record<string, any>,
): T[] {
    return json != null && Array.isArray(json) ? json.map((element: any) => provider(element)!) : [];
}
