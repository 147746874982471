import { boolean, object, string, type ObjectSchema } from 'yup';

import { type ICompanyRegistration } from '../types';

import { DEFAULT_LANGUAGE } from '@/config/localization';
import { Gender } from '@/entity/basic/ContactData';
import { LocationSchema } from '@/entity/basic/Location';
import { fullContactDataSchema } from '@/utils/account';
import { CompanyPlan } from '@/utils/constants';

export function getCompanyRegistrationSchema(
    companyNameDuplicateErrorMessage: string,
    isCompanyNameTaken: boolean,
): ObjectSchema<ICompanyRegistration> {
    return object({
        location: LocationSchema.defined().nullable().default(null),
        contactData: fullContactDataSchema,
        companyName: string()
            .required()
            .test('duplicate-company-name', companyNameDuplicateErrorMessage, () => isCompanyNameTaken),
        registeredCompanyName: string().required(),
        language: string().required(),
        uid: string().required(),
        password: string().required().min(3).max(30),
        // t('validations:password.no_match')
        passwordConfirmation: string().required().matchWith('password', { key: 'password.no_match' }),
        email: string().email().required(),
        // t('validations:agb')
        agb: boolean().required().isTrue({ key: 'agb' }),
        onlinePayment: boolean().required(),

        api: boolean().required(),
        apis: object().when('api', {
            is: true,
            then: schema =>
                schema.shape({
                    ratio: boolean().required(),
                    csbus: boolean().required(),
                    turista: boolean().required(),
                    kuschick: boolean().required(),
                    deltaplan: boolean().required(),
                }),
        }),
        orderApi: boolean().when('api', {
            is: true,
            then: schema => schema.defined(),
        }),
        apiCustomText: string().when('api', {
            is: true,
            then: schema => schema.defined(),
        }),

        bdo: boolean().required(),
        bdoNationalAssociation: string().when('bdo', {
            is: true,
            then: schema => schema.defined(),
        }),
        bdoNumber: string().when('bdo', {
            is: true,
            then: schema => schema.defined(),
        }),

        couponCode: string().defined(),
        companyPlan: string().oneOf([CompanyPlan.INTERN, CompanyPlan.PRO, CompanyPlan.STARTER]).required(),
    }).test({
        name: 'location_required',
        test(stop, ctx) {
            if (stop.location == null)
                return ctx.createError({
                    message: {
                        key: 'general.required',
                    },
                    path: `${ctx.path}.location`,
                });

            return true;
        },
    });
}

export function getCompanyRegistrationDefaultValues(): ICompanyRegistration {
    return {
        companyName: '',
        registeredCompanyName: '',
        language: DEFAULT_LANGUAGE,
        uid: '',
        contactData: {
            gender: Gender.OTHER,
            country: 'AT',
            city: '',
            firstName: '',
            lastName: '',
            postCode: '',
            street: '',
            phone: '',
            email: '',
        },
        location: null,
        onlinePayment: false,
        api: false,
        apis: { csbus: false, deltaplan: false, kuschick: false, ratio: false, turista: false },
        orderApi: false,
        bdo: false,
        companyPlan: CompanyPlan.PRO,
        agb: false,

        email: '',
        password: '',
        passwordConfirmation: '',

        apiCustomText: '',
        bdoNationalAssociation: '',
        bdoNumber: '',
        couponCode: '',
    };
}
