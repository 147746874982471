import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormAvatar } from './grid/form-avatar';
import { FormCell } from './grid/form-cell';
import { FormGrid } from './grid/form-grid';
import { FormRow } from './grid/form-row';

import { NumberField } from '@/components/form/fields/number-field';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useTour } from '@/core/tour/mod';
import { type SearchFormBasicTour } from '@/core/tour/SearchFormBasicTour';
import { useTimeout } from '@/utils/helpers';

interface PaxRowProps {
    readonly routeIndex: number;
}

export const PaxRow: React.FC<PaxRowProps> = ({ routeIndex }) => {
    const { t } = useTranslation();

    const { register } = useTour<SearchFormBasicTour>();
    const { toggleEditMode } = useAppCtx();

    const activation = useRef(0);
    const timeoute = useTimeout();

    const handleClick = () => {
        if (activation.current >= 10) {
            toggleEditMode();
            activation.current = 0;
        } else {
            activation.current += 1;
            timeoute.fire(() => {
                activation.current = 0;
            }, 500);
        }
    };

    return (
        <FormGrid gutterBottom>
            <FormRow>
                <FormCell gridColumn="line">
                    <FormAvatar type="pax" onClick={handleClick} />
                </FormCell>
                <FormCell gridColumn="main">
                    <NumberField
                        propagateOnBlur
                        required
                        fullWidth={false}
                        fieldRef={register('pax')}
                        name={`routes.${routeIndex}.pax`}
                        label={t('search_form.pax.label')}
                    />
                </FormCell>
            </FormRow>
        </FormGrid>
    );
};
