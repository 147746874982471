import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/registration')({
    async beforeLoad({ context }) {
        if (context.appCtx?.appSettings.authentication.disableAuthentication) {
            redirect({ to: '/', replace: true, throw: true });
        }
    },
    component: Outlet,
});
