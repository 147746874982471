import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack } from '@mui/material';
import { useMatches } from '@tanstack/react-router';
import uniqueId from 'lodash/uniqueId';
import React, { useMemo, useState } from 'react';

import { BACK_ITEM } from '@/components/page/header/mod';
import { BookingProgressMenu } from '@/components/page/navigation/mobile/booking-progress-menu';
import { DefaultMenu } from '@/components/page/navigation/mobile/default-menu';
import { NavMenu } from '@/components/page/navigation/mobile/nav-menu';
import { NavItem } from '@/components/page/navigation/nav-item';
import { type UseNavigation } from '@/components/page/navigation/useNavigation';
import { useAppProgress } from '@/core/app-progress/mod';

export const MobileNavigation = ({ items = [], disableBack, isBookingProgress }: UseNavigation) => {
    const matches = useMatches();
    const hideBackItem = useHideBackItem();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const id = uniqueId('menu');

    const currentItem = useMemo(
        () => items.find(item => matches.some(match => match.id === (item.navigate.to ?? '/'))),
        [items, matches],
    );

    const backItem = disableBack || hideBackItem ? undefined : BACK_ITEM;

    return (
        <>
            <IconButton aria-haspopup color="inherit" aria-controls={id} onClick={handleOpen}>
                <Stack spacing={1} direction="row" alignItems="center">
                    {isBookingProgress ? <FormatListBulletedOutlinedIcon /> : <MenuIcon />}
                    {currentItem && <NavItem {...currentItem} icon={undefined} />}
                </Stack>
            </IconButton>

            <NavMenu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} id={id} onClose={handleClose}>
                {isBookingProgress ? (
                    <BookingProgressMenu items={items} backItem={backItem} />
                ) : (
                    <DefaultMenu items={items} backItem={backItem} />
                )}
            </NavMenu>
        </>
    );
};

const useHideBackItem = () => {
    const matches = useMatches();
    const { progress } = useAppProgress();

    // Only show the back item if we are not on a booking progress page
    const isBookingProgressUrl = useMemo(() => {
        const currentMatch = matches[matches.length - 1];
        // We want to show the back item on the booking-confirmation page
        const bookingSteps = progress.steps.filter(step => step.navigate.to !== '/booking-confirmation');

        return bookingSteps.find(step => step.navigate.to === currentMatch?.id);
    }, [matches, progress]);

    return isBookingProgressUrl;
};
