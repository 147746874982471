import callApi from '@/api/index';
import { fromJsonArray } from '@/entity';
import { DefaultJourney } from '@/entity/journey/saved/DefaultJourney';
import { type IJourney, serializeSearchFormFields } from '@/features/journey-planning';

/** ****************************************************************
 * Journey Entities CRUD
 ***************************************************************** */

export async function getSavedJourneys(accountId?: number) {
    if (!accountId) {
        return [];
    }
    const { data } = await callApi(`api/customers/${accountId}/journeys`);
    return fromJsonArray(DefaultJourney, data);
}

export async function postSaveJourney(body: IJourney): Promise<void> {
    await callApi('public/saveJourney', {
        method: 'POST',
        data: serializeSearchFormFields(body),
    });
}
