import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postLogin, postLogout } from '@/api/auth/endpoints';
import { USER_QUERY_KEY } from '@/api/user/queries';

export function useLogin() {
    const client = useQueryClient();

    return useMutation({
        mutationFn: postLogin,
        async onSuccess() {
            await client.resetQueries({ queryKey: [USER_QUERY_KEY] });
        },
    });
}

export function useLogout() {
    const client = useQueryClient();

    return useMutation({
        mutationFn: postLogout,
        async onSuccess() {
            await client.resetQueries({ queryKey: [USER_QUERY_KEY] });
        },
    });
}
