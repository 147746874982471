import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { useLayout } from '@/components/page/layout/mod';
import { type IPosition } from '@/features/journey-planning';
import { iff } from '@/utils/helpers';

export type FormColumn = 'left' | 'line' | 'main' | 'off';
type ColumnsLayout = Record<FormColumn, string>;

const COLUMNS: ColumnsLayout = {
    left: '1fr',
    line: '40px',
    main: '2fr',
    off: '40px',
};

const COLUMNS_MOBILE: ColumnsLayout = {
    left: '0',
    line: '40px',
    main: '1fr',
    off: '40px',
};

function gridTemplateColumns(isDesktop: boolean, columns?: ColumnsLayout): string {
    return Object.entries(columns ?? (isDesktop ? COLUMNS : COLUMNS_MOBILE))
        .map(([name, value]) => `[${name}] ${value}`)
        .join(' ');
}

interface FormGridProps {
    readonly position?: IPosition;
    readonly gutterBottom?: true;
    readonly columns?: Record<FormColumn, string>;
}

export const FormGrid: React.FC<React.PropsWithChildren<FormGridProps>> = ({
    gutterBottom,
    columns,
    position,
    children,
}) => {
    const { isDesktop } = useLayout();

    // Intermediate stops on mobile are gray to distinguish them better
    const isGray = useMemo(() => position && !position.first && !position.last && !isDesktop, [position, isDesktop]);

    return (
        <Box
            sx={{
                display: 'grid',
                justifyContent: 'stretch',
                columnGap: 1,
                alignItems: 'center',
                gridTemplateColumns: gridTemplateColumns(isDesktop, columns),
                mb: iff(gutterBottom, isDesktop ? 4 : 2),
                backgroundColor(theme) {
                    return isGray
                        ? `color-mix(in srgb, ${theme.vars.palette.background.paper}, ${theme.vars.palette.grey[200]} 60%)`
                        : 'transparent';
                },
            }}>
            {children}
        </Box>
    );
};
