import React from 'react';

import { useMainCompanyBranchData } from '@/api/public';
import { MainCompanyDataContext, type IMainCompanyDataContext } from '@/core/main-company-data/mod';

export const MainCompanyDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { data: mainCompanyData } = useMainCompanyBranchData();

    const value: IMainCompanyDataContext = React.useMemo(
        () => ({
            mainCompanyData,
        }),
        [mainCompanyData],
    );

    return <MainCompanyDataContext.Provider value={value}>{children}</MainCompanyDataContext.Provider>;
};
