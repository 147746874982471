import { type PropsWithChildren, useMemo, useState, useEffect } from 'react';

import { TileContext, type ITileContext, type Spacer } from '@/components/page/layout/tile/mod';

export const TileProvider = ({ children }: PropsWithChildren) => {
    const [spacer, setSpacer] = useState<Spacer>(null);
    // Using a ref didn't work, because the effect didn't trigger when the ref changed
    const [attachableRef, setAttachableRef] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (attachableRef == null) setSpacer(null);
    }, [attachableRef]);

    const value: ITileContext = useMemo(() => {
        return {
            spacer,
            setSpacer(s: Spacer) {
                setSpacer(s);
            },
            setAttachableRef(ref: HTMLDivElement | null) {
                setAttachableRef(ref);
            },
        };
    }, [spacer]);

    return <TileContext.Provider value={value}>{children}</TileContext.Provider>;
};
