import { Button, type ButtonProps } from '@mui/material';
import { useLinkProps, type LinkOptions } from '@tanstack/react-router';
import React from 'react';

import { NavName } from '@/components/elements/nav/nav-name';

export interface NavButtonProps extends ButtonProps {
    readonly navigate: LinkOptions;
    readonly activeClassName?: string;
}

export const NavButton: React.FC<React.PropsWithChildren<NavButtonProps>> = ({
    navigate,
    children,
    activeClassName,
    ...buttonProps
}) => {
    const linkProps = useLinkProps({
        ...navigate,
        onClick: buttonProps.onClick as any,
        onFocus: buttonProps.onFocus as any,
        onMouseEnter: buttonProps.onMouseEnter as any,
        onMouseLeave: buttonProps.onMouseLeave as any,
        onTouchStart: buttonProps.onTouchStart as any,
        disabled: buttonProps.disabled,
        activeProps: {
            className: activeClassName,
        },
    }) as ButtonProps;

    return (
        <Button {...buttonProps} {...linkProps}>
            {children ?? <NavName navigate={navigate} />}
        </Button>
    );
};
