import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { DEFAULT_EXTRA_FILTER, PAX_FILTER_ELEMENTS } from '../constants';
import { type ResultOrder, type ExtraFilter, type IFilter } from '../types';

import { type BusType } from '@/assets/icons/bus-types/BusTypeIcon';
import { type ConvertToCurrency } from '@/core/localization/mod';
import { type Account } from '@/entity/account/Account';
import { Money } from '@/entity/basic/Money';
import { BusRouteStatus, type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export function getDifference<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> | null {
    const difference: Partial<T> = {};

    Object.entries(obj1).forEach(([key]) => {
        if (!isEqual(obj1[key], obj2[key])) {
            difference[key as keyof T] = obj2[key];
        }
    });

    return isEmpty(difference) ? null : difference;
}

export function getExtraFiltersFromFilter(filter: IFilter) {
    const extraFilters = { ...DEFAULT_EXTRA_FILTER };

    const isExtraFilterKey = (key: string): key is keyof ExtraFilter => key in extraFilters;

    Object.keys(filter)
        .filter(isExtraFilterKey)
        .forEach(key => {
            extraFilters[key] = filter[key] as any;
        });

    return extraFilters;
}

export const getFilteredResults = (searchResults: BookingSearchResult[], filter: IFilter, user: Account | null) => {
    let userCompanyId: number | undefined;
    if (user?.isOperatorOrDispatcher()) userCompanyId = user.company.id;

    return searchResults?.filter(result => {
        // Conditionals for filtering
        const filterByOnlinePayment = !filter.onlinePayment || result.isOnlinePaymentPossible();
        const filterOwnBuses = !userCompanyId || !filter.ownBuses || result.bus.company.id === userCompanyId;
        const filterByFreeCancellation =
            !filter.freeCancellation || (result.cancellationFreeNow && result.freeCancellationDate);
        const filterByBusStars = Math.round((result.bus.calculatedRating / 100) * 5) >= filter.busStars;
        const filterByEuroNorm = (result.bus.engine.rank ?? 0) >= filter.euroNorm;
        const filterBySeatDistance = result.bus.stars >= filter.busSeatDistance;
        const filterByCompanyRating = filter.minCompanyRating.length === 0 || filterByRating(result, filter);

        const filterByEquipments = filter.equipments.every(equipmentId =>
            result.bus.equipments.some(busEquipment => busEquipment.id === equipmentId),
        );
        const filterByCompany = filter.busCompanies.length === 0 || filter.busCompanies.includes(result.bus.company.id);
        const filterByPax =
            filter.pax.length === 0 ||
            filter.pax.some(
                paxFilter =>
                    result.bus.getTotalSeats() <= paxFilter.maxValue &&
                    result.bus.getTotalSeats() >= paxFilter.minValue,
            );
        const filterByNotSelected = !result.selected;
        const filterByNotOverlapping = result.status !== BusRouteStatus.OVERLAPPING_ROUTES;

        return (
            filterByOnlinePayment &&
            filterByFreeCancellation &&
            filterByBusStars &&
            filterByEuroNorm &&
            filterBySeatDistance &&
            filterByCompanyRating &&
            filterByEquipments &&
            filterByCompany &&
            filterByPax &&
            filterByNotSelected &&
            filterByNotOverlapping &&
            filterOwnBuses
        );
    });
};

export const getSortedResults = (
    searchResults: BookingSearchResult[],
    sortBy: ResultOrder,
    convertToCurrency: ConvertToCurrency,
    remainingRoutePax: number,
) => {
    switch (sortBy) {
        case 'best_results':
        case 'cheapest_first': {
            return searchResults.sort(priceSortASC(convertToCurrency));
        }
        case 'stars_best_first': {
            return searchResults.sort(busStarsSort);
        }
        case 'engine_best_first': {
            return searchResults.sort(busEngineSort);
        }
        case 'company_asc': {
            return searchResults.sort(busCompanySortASC);
        }
        case 'company_desc': {
            return searchResults.sort(busCompanySortDESC);
        }
        case 'best_rating_first': {
            return searchResults.sort(busCompanyRatingSortDESC);
        }
        case 'cheapest_by_price_per_seat': {
            return searchResults.sort(pricePerSeatSort(convertToCurrency, remainingRoutePax));
        }
        default: {
            return searchResults.sort(priceSortASC(convertToCurrency));
        }
    }
};

export const pricePerSeatSort =
    (convertToCurrency: ConvertToCurrency, remainingRoutePax: number) =>
    (a: BookingSearchResult, b: BookingSearchResult) => {
        /**
         * Calculate price per seat based on bus seats and remaining pax.
         * - If bus seats >= entered pax, use remaining pax for calculation.
         * - If bus seats < entered pax, use bus seats for calculation as multiple buses will be needed.
         * - If additional buses are needed, use the remaining pax for the next bus.
         */
        const divisorA = Math.min(a.bus.getTotalSeats(), remainingRoutePax);
        const divisorB = Math.min(b.bus.getTotalSeats(), remainingRoutePax);

        const moneyA = new Money(a.getPriceToShow().amount / divisorA, a.getPriceToShow().currency);
        const moneyB = new Money(b.getPriceToShow().amount / divisorB, b.getPriceToShow().currency);

        const euroA = convertToCurrency(moneyA, 'EUR').amount;
        const euroB = convertToCurrency(moneyB, 'EUR').amount;

        return euroA - euroB;
    };

export const priceSortASC =
    (convertToCurrency: ConvertToCurrency) => (a: BookingSearchResult, b: BookingSearchResult) => {
        const euroA = convertToCurrency(a.getPriceToShow(), 'EUR').amount;
        const euroB = convertToCurrency(b.getPriceToShow(), 'EUR').amount;

        return euroA - euroB;
    };

export const busStarsSort = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (a.bus.calculatedRating < b.bus.calculatedRating) return 1;

    if (a.bus.calculatedRating > b.bus.calculatedRating) return -1;

    return 0;
};

export const busEngineSort = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (!a.bus.engine.rank || !b.bus.engine.rank) return 0;

    if (a.bus.engine.rank < b.bus.engine.rank) return 1;

    if (a.bus.engine.rank > b.bus.engine.rank) return -1;

    return 0;
};

export const busCompanySortASC = (a: BookingSearchResult, b: BookingSearchResult) => {
    return a.bus.company.companyName.localeCompare(b.bus.company.companyName);
};

export const busCompanySortDESC = (a: BookingSearchResult, b: BookingSearchResult) => {
    return b.bus.company.companyName.localeCompare(a.bus.company.companyName);
};

export const busCompanyRatingSortDESC = (a: BookingSearchResult, b: BookingSearchResult) => {
    if (a.bus.company.reviewScores.totalScore < b.bus.company.reviewScores.totalScore) return 1;

    if (a.bus.company.reviewScores.totalScore > b.bus.company.reviewScores.totalScore) return -1;

    return 0;
};

const filterByRating = (result: BookingSearchResult, filter: IFilter) => {
    const { minCompanyRating } = filter;
    const rating = result.bus.company.reviewScores.totalScore;

    const ratingConditions = [];

    // Very bad
    if (minCompanyRating.includes(1)) ratingConditions.push(rating > 0 && rating < 1.5);

    // Bad
    if (minCompanyRating.includes(2)) ratingConditions.push(rating < 2.5 && rating >= 1.5);

    // Neutral
    if (minCompanyRating.includes(3)) ratingConditions.push(rating < 3.5 && rating >= 2.5);

    // Good
    if (minCompanyRating.includes(4)) ratingConditions.push(rating < 4.5 && rating >= 3.5);

    // Very good
    if (minCompanyRating.includes(5)) ratingConditions.push(rating >= 4.5);

    return ratingConditions.some(condition => condition);
};

export const busTypeByPax = (pax: number): BusType => {
    return PAX_FILTER_ELEMENTS.find(paxElement => paxElement.minValue <= pax && paxElement.maxValue >= pax)!.type;
};
