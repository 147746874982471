import callApi, { type Params } from '@/api/index';
import { removeStorageJourneyToken, setStorageJourneyToken } from '@/config/storage';
import { fromJsonArray } from '@/entity';
import { type Money } from '@/entity/basic/Money';
import { BookingJourney } from '@/entity/journey/BookingJourney';
import { ReviewBooking } from '@/entity/reviews/ReviewBooking';
import { type PriceOptionId } from '@/utils/constants';
import { fileFromResponse } from '@/utils/file';

/**
 * Fetch a journey for the search form by the given token.
 * It will return a {@link BookingJourney}
 * On Success we persist the journey token in the storage
 * On Error we remove the token
 */
export async function fetchSearchFormJourney(token?: string, amendBookingToken?: string) {
    if (!token && !amendBookingToken) return null;
    return fetchJourney('searchFormJourney', { token, amendBookingToken });
}

/**
 * Fetch a journey for the actual booking progress.
 * At this point, the bus selection of the journey is already completed
 */
export async function fetchBookingJourney(token: string, jSessionId?: string) {
    return fetchJourney('bookingJourney', { token }, jSessionId);
}

async function fetchJourney(
    endpoint: string,
    { token, amendBookingToken }: { token?: string; amendBookingToken?: string },
    jSessionId?: string,
) {
    try {
        if (!token && !amendBookingToken) throw new Error('token is required');

        const params = { token, amendBookingToken, jSessionId };
        const { data } = await callApi(`public/${endpoint}`, { params });
        const journey = new BookingJourney(data);
        setStorageJourneyToken(journey.token);
        return journey;
    } catch (error) {
        // remove journey token from session if not returned from api
        removeStorageJourneyToken();
        throw error;
    }
}

interface ToggleBusRouteMutationArgs {
    token: string;
    routeUuid: string;
    select: boolean;
    busId?: number;
    priceOptionId?: PriceOptionId;
    operatorPrice?: Money;
}

/**
 * Patch for Bus Selection.
 * Allows selecting/deselecting a bus for a route with a price option.
 * It will return a {@link BookingJourney} with the selected result
 */
export async function toggleBusRoute(body: ToggleBusRouteMutationArgs) {
    const { data } = await callApi('public/toggleBusRoute', {
        method: 'PATCH',
        data: body,
    });

    return new BookingJourney(data);
}

/**
 * Send the reseller offer to the customer
 */
export async function sendResellerOffer(token?: string) {
    const params: Params = { token };

    const { data } = await callApi(`public/sendResellerOffer`, { params, method: 'POST' });

    return data;
}

/**
 * Save an operator offer to in operating tool
 */
export async function saveOperatorOffer({ token, body }: { token: string; body: any }) {
    const params: Params = { token };

    const { data } = await callApi(`public/saveOperatorOffer`, { params, method: 'POST', data: body });

    return data;
}

export async function downloadCustomerJourneySummaryPDF(token?: string) {
    try {
        const response = await callApi<BlobPart>(`public/generateCustomerJourneySummaryPDF`, {
            params: { token },
            responseType: 'blob',
        });
        return fileFromResponse(response);
    } catch (error) {
        throw error;
    }
}

export async function downloadOperatorOfferPDF(token?: string) {
    try {
        const response = await callApi<BlobPart>(`public/generateOperatorOfferPDF`, {
            params: { token },
            responseType: 'blob',
        });
        return fileFromResponse(response);
    } catch (error) {
        throw error;
    }
}

export async function downloadOfferComparisonPDF(token?: string) {
    try {
        const response = await callApi<BlobPart>(`public/generateOfferComparisonPDF`, {
            params: { token },
            responseType: 'blob',
        });
        return fileFromResponse(response);
    } catch (error) {
        throw error;
    }
}

export async function getReviews(companyId?: number) {
    const { data } = await callApi(`public/reviews/${companyId}`);
    return fromJsonArray(ReviewBooking, data);
}
