import { createFileRoute } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

// Child: Print Booking Confirmation
const R_PRINT_BOOKING_CONFIRMATION_SEARCH_PARAMS = object({
    token: string().required(),
});

export const Route = createFileRoute('/print/booking-confirmation')({
    validateSearch(search): InferType<typeof R_PRINT_BOOKING_CONFIRMATION_SEARCH_PARAMS> {
        return R_PRINT_BOOKING_CONFIRMATION_SEARCH_PARAMS.cast(search);
    },
});

export { Route as PrintBookingConfirmationRoute };
