import { ContactData } from '@/entity/basic/ContactData';
import { Location } from '@/entity/basic/Location';
import { type CustomerBookingType } from '@/features/contact-data';

export class CustomerDTO {
    public contactData: ContactData;

    public notes: string;

    public customerComment: string;

    public customerGroupId: number;

    public customerGroupName: string;

    public email: string;

    public externCustomerId: number;

    public organizationLocation: Location | null;

    public organizationName: string | null;

    public organizationUID: string | null;

    public password: string;

    public registration: boolean;

    public subscribedToNewsletter: boolean;

    public type: CustomerBookingType;

    public ratioCustomerId?: number;

    public organizationPoNumber?: string;

    public organizationCostCenter?: string;

    constructor(json: Record<string, any>) {
        this.contactData = ContactData.fromJson(json.contactData);
        this.notes = json.notes;
        this.customerComment = json.customerComment;
        this.customerGroupId = json.customerGroupId;
        this.customerGroupName = json.customerGroupName;
        this.email = json.email;
        this.externCustomerId = json.externCustomerId;
        this.organizationLocation = json.organizationLocation ? Location.fromJson(json.organizationLocation) : null;
        this.organizationName = json.organizationName;
        this.organizationUID = json.organizationUID;
        this.password = json.password;
        this.registration = json.registration;
        this.subscribedToNewsletter = json.subscribedToNewsletter;
        this.type = json.type;
        this.ratioCustomerId = json.ratioCustomerId;
        this.organizationPoNumber = json.organizationPoNumber;
        this.organizationCostCenter = json.organizationCostCenter;
    }
}
